import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteWalls: null,
        wallTypes: null,
        currentWall: { id: "", state: "", isHighlightFromMap: false }
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    
    /*
     * Getter for siteWalls
     */
    siteWalls(state) {
        return state.siteWalls;
    },

    wallTypes(state) {
        return state.wallTypes;
    },

    currentWall(state) {
        return state.currentWall;
    },

    getWallById(state) {
        return id => _.find(state.siteWalls, ["id", id]);
    },

    getWallTypeById(state) {
        return id => _.find(state.wallTypes, ["id", id]);
    },

    getWallColorById(state) {
        return function(id) {
            const wall     = _.find(state.siteWalls, ["id", id]);
            const wallType = _.find(state.wallTypes, ["id", wall.wallType]);
            if (wallType) {
                return wallType.color;
            }
        }
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_WALLS(state, data) {
        if (!state.siteWalls) {
            state.siteWalls = [];
        }
        state.siteWalls = data;
    },

    SET_WALL_TYPES(state, data) {
        if (!state.wallTypes) {
            state.wallTypes = [];
        }
        state.wallTypes = data;
    },

    REMOVE_WALL_BY_ID(state, wallId) {
        // Find wall index
        let index = _.findIndex(state.siteWalls, { id: wallId });
        state.siteWalls.splice(index, 1);
    },

    SET_WALL(state, wallData) {
        if (state.siteWalls) {
            // Find wall index
            let index = _.findIndex(state.siteWalls, { id: wallData.id });
            if (index >= 0) {
                // Update - replace the wall in the array of walls
                state.siteWalls.splice(index, 1, wallData);
            } else {
                // Creation
                state.siteWalls.push(wallData);
            }
        } else {
            state.siteWalls = [];
            state.siteWalls.push(wallData);
        }
    },

    SET_CURRENT_WALL(state, wallData) {
        state.currentWall = wallData;
    },

    RESET_DEFAULT_WALLS_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Action used to create a wall
     */
    createWall({ commit }, payload) {
        axiosBackend
            .post("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/walls", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current wall in store
                commit("SET_CURRENT_WALL", { id: "", state: "" });

                // Store created wall in store
                commit("SET_WALL", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("wall_wallSuccessfullyCreated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of walls type
     */
    getWallTypes({ commit }) {
        axiosBackend
            .get("/walltypes", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store walls type
                commit("SET_WALL_TYPES", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of site walls
     */
    getSiteWalls({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/walls", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store walls
                commit("SET_WALLS", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one wall by its id
     */
    getWallById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/walls/" + payload.wallId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_WALL", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing wall
     */
    updateWall({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/walls/" + payload.id, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current wall in store
                commit("SET_CURRENT_WALL", { id: "", state: "" });

                // Set user Data in VueX Auth store
                commit("SET_WALL", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("wall_wallSuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing wall polygon
     */
    updateWallPolygon({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/walls/" + payload.id + "/polygon", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current wall in store
                commit("SET_CURRENT_WALL", { id: "", state: "" });

                // Set user Data in VueX Auth store
                commit("SET_WALL", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("wall_wallSuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a wall
     */
    deleteWall({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/walls/" + payload.wallId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_WALL_BY_ID", payload.wallId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("wall_wallSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    setCurrentWall({ commit }, payload) {
        commit("SET_CURRENT_WALL", payload);
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetWallsState ({ commit }) {
        commit('RESET_DEFAULT_WALLS_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
