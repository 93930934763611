/*
 * State: properties to store
 */
const state = {
    /*
     * Indicate if the delete modal has to be displayed
     */
    showImportAutocalibrationTagsModal: false,

    /*
     * Indicate if the modal has to be hide
     */
    hideImportAutocalibrationTagsModal: false,

    /*
     * Indicate if an upload of auto tags is in progress or not
     */
    isUploadAutocalibrationTagsInProgress: false
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    /*
     * Toggle showImportAutocalibrationTagsModal property
     */
    SHOW_IMPORT_AUTOCALIBRATION_TAGS_MODAL(state) {
        state.showImportAutocalibrationTagsModal = true;
    },

    /*
     * Toggle hideImportAutocalibrationTagsModal property
     */
    HIDE_IMPORT_AUTOCALIBRATION_TAGS_MODAL(state) {
        state.hideImportAutocalibrationTagsModal = true;
    },

    /*
     * Reset showImportAutocalibrationTagsModal property
     */
    RESET_SHOW_IMPORT_AUTOCALIBRATION_TAGS_MODAL(state) {
        state.showImportAutocalibrationTagsModal = false;
    },

    /*
     * Reset hideImportAutocalibrationTagsModal property
     */
    RESET_HIDE_IMPORT_AUTOCALIBRATION_TAGS_MODAL(state) {
        state.hideImportAutocalibrationTagsModal = false;
    },

    /*
     * Set isUploadGatewaysInProgress property
     */
    SET_UPLOAD_AUTOCALIBRATION_TAGS_IN_PROGRESS(state, value) {
        state.isUploadAutocalibrationTagsInProgress = value;
    }
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for showImportAutocalibrationTagsModal
     */
    showImportAutocalibrationTagsModal(state) {
        return state.showImportAutocalibrationTagsModal;
    },
    /*
     * Getter for hideImportAutocalibrationTagsModal
     */
    hideImportAutocalibrationTagsModal(state) {
        return state.hideImportAutocalibrationTagsModal;
    },
    /*
     * Getter for isUploadAutocalibrationTagsInProgress
     */
    isUploadAutocalibrationTagsInProgress(state) {
        return state.isUploadAutocalibrationTagsInProgress;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    showImportAutocalibrationTagsModal({ commit }, data) {
        commit("SHOW_IMPORT_AUTOCALIBRATION_TAGS_MODAL", data);
    },

    hideImportAutocalibrationTagsModal({ commit }, data) {
        commit("HIDE_IMPORT_AUTOCALIBRATION_TAGS_MODAL", data);
    },

    resetShowImportAutocalibrationTagsModal({ commit }) {
        commit("RESET_SHOW_IMPORT_AUTOCALIBRATION_TAGS_MODAL", {});
    },

    resetHideImportAutocalibrationTagsModal({ commit }) {
        commit("RESET_HIDE_IMPORT_AUTOCALIBRATION_TAGS_MODAL", {});
    },

    setIsUploadAutocalibrationTagsInProgress({ commit }, value) {
        commit("SET_UPLOAD_AUTOCALIBRATION_TAGS_IN_PROGRESS", value);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
