<template>
    <div class="modal fade show" id="confirmleavepagemodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_question") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ $t("common-confirmLeavePage") }}</p>
                </div>
                <div class="modal-footer">
                    <button id="confirmLeavePageNoButton" type="button" @click="cancelLeavePageButton" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_no") }}</button>
                    <button id="confirmLeavePageYesButton" type="button" @click="confirmLeavePageButton" data-dismiss="modal" class="btn btn-brand">{{ $t("common_yes") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        confirmLeavePageButton() {
            console.log("Component(confirmLeavePageModal)::confirmLeavePageButton() - called");
            this.$emit("confirmleavepageyes");
        },
        cancelLeavePageButton() {
            console.log("Component(cancelLeavePageModal)::cancelLeavePageButton() - called");
            this.$emit("confirmleavepageno");
        },
        display() {
            console.log("Component(cancelLeavePageModal)::display() - called");
            $("#confirmleavepagemodal").modal("show");
        }
    }
};
</script>

<style scoped></style>
