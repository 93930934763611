import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";
import authHelper from "../../helpers/authHelper";

const { detect } = require("detect-browser");
const _browser = detect();


/*
 * State: properties to store
 */
const state = {
    user               : null,
    isSessionExpired   : false,
    permissions        : [],
    userRoleNameForSite: null
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    /*
     * Getter for user
     */
    user(state) {
        return state.user;
    },

    userLanguage(state) {
        return state.user ? state.user.preferredLanguage : null;
    },

    isSessionExpired(state) {
        return state.isSessionExpired;
    },

    hasAccess(state) {
        return (siteId, feature) => {
            let index = _.findIndex(state.permissions, {
                siteId: siteId
            });

            if (index >= 0) {
                // permissions are of the type "ASSET_PROVISIONING.ASSET_MANAGEMENT.write"
                let featureArray = feature.split(".");

                if (!state.permissions[index].permissions[featureArray[0]]) {
                    // feature is not in this site's user permissions
                    return false;
                }
                if (featureArray.length > 1) {
                    // subFeature
                    if (!state.permissions[index].permissions[featureArray[0]][featureArray[1]]) {
                        // subFeature is not in this site's user permissions
                        return false;
                    }
                }
                if (featureArray.length === 3) {
                    // Permissions (read / write)
                    if (!state.permissions[index].permissions[featureArray[0]][featureArray[1]][featureArray[2]]) {
                        // permission is not in this site's user permissions
                        return false;
                    }
                }
                return true;
            }
            return false;
        };
    },

    // Returns true if user has ALL configuration permissions (like ADMIN for example)
    hasFullConfigAccess(state) {
        return siteId => {
            let index = _.findIndex(state.permissions, {
                siteId: siteId
            });

            if (index >= 0) {
                let featuresArray = [
                    "SITE_CONFIGURATION.SITE_INFORMATIONS", "SITE_CONFIGURATION.BUILDING_AND_FLOOR", "SITE_CONFIGURATION.GATEWAY", "SITE_CONFIGURATION.AUTOCALIBRATION_TAG", "SITE_CONFIGURATION.INDOOR_AREA", "SITE_CONFIGURATION.GEOFENCE",
                    "SITE_CONFIGURATION.WALL", "SITE_CONFIGURATION.NOTIFICATION", "SITE_CONFIGURATION.PUSH_BUTTON_ALERT",
                    "ASSET_PROVISIONING.TAG_MANAGEMENT", "ASSET_PROVISIONING.ASSET_MANAGEMENT", "ASSET_PROVISIONING.ASSET_CATEGORY_MANAGEMENT",
                    "ANALYTICS.DASHBOARD", "ANALYTICS.CONTACT_EVENTS",
                    "GUEST_ACCESS", "TROUBLESHOOTING", "REPORTING",
                    "SITE_PERMISSION.USER_MANAGEMENT", "SITE_PERMISSION.ROLE_MANAGEMENT"
                ];

                for (let feature of featuresArray) {
                    let featureArray = feature.split(".");

                    if (!state.permissions[index].permissions[featureArray[0]]) {
                        // feature is not in this site's user permissions
                        return false;
                    }
                    if (featureArray.length > 1) {
                        // subFeature
                        if (!state.permissions[index].permissions[featureArray[0]][featureArray[1]]) {
                            // subFeature is not in this site's user permissions
                            return false;
                        }
                    }
                }
                return true;
            }
            return false;
        };
    },

    // Returns true if user has NO configuration permission (so only access to dashboard)
    hasNoConfigAccess(state) {
        return siteId => {
            let index = _.findIndex(state.permissions, {
                siteId: siteId
            });

            if (index >= 0) {
                let featuresArray = ["SITE_CONFIGURATION", "ASSET_PROVISIONING", "SITE_PERMISSION", "GUEST_ACCESS", "ANALYTICS"];

                for (let feature of featuresArray) {
                    let featureArray = feature.split(".");

                    if (state.permissions[index].permissions[featureArray[0]]) {
                        // user has access to at least one subfeature in this feature's family
                        return false;
                    }
                }
            }
            return true;
        };
    },

    // Returns the current user role name
    userRoleNameForSite: state => {
        return state.userRoleNameForSite;
    }

};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {

    SET_USER_DATA(state, userData) {
        state.user = userData.user;
    },

    SET_SESSION_EXPIRED(state, sessionData) {
        state.isSessionExpired = sessionData;
    },

    SET_USER_SITE_PERMISSIONS(state, {
        siteId,
        features
    }) {
        // Rework features array received from backend, before push it into store
        let sitePermissions = {};

        for (let feature of features) {
            let featureName = feature.name;
            sitePermissions[featureName] = {};

            if (feature.subFeatures) {
                for (let subFeature of feature.subFeatures) {
                    let subFeatureName = subFeature.name;
                    sitePermissions[featureName][subFeatureName] = {};

                    if (subFeature.permissions) {
                        for (let permission in subFeature.permissions) {
                            sitePermissions[featureName][subFeatureName][permission] = subFeature.permissions[permission];
                        }
                    }
                }
            }
        }

        // Store user permissions for this siteId
        // Find site index
        let index = _.findIndex(state.permissions, {
            siteId: siteId
        });
        if (index >= 0) {
            // Replace the site permissions in the array of permissions
            state.permissions.splice(index, 1, {
                siteId: siteId,
                permissions: sitePermissions
            });
        } else {
            state.permissions.push({
                siteId: siteId,
                permissions: sitePermissions
            });
        }
    },

    RESET_USER_PERMISSIONS(state) {
        state.permissions = [];
    },

    SET_USER_ROLE_NAME(state, userRole) {
        state.userRoleNameForSite = userRole.role.name;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /**
     * Display toast if user is already logged
     * @param commit
     */
    showUserAlreadyLogged({
        commit
    }) {
        // Display notification
        commit("SHOW_NOTIFICATION", {
            text: i18n.t("error_userAlreadyLogged"),
            type: "error"
        });
    },

    /*
     * Action used to check if there is already a token or not in local storage.
     * If a token is present and is not expired, use it automatically.
     */
    tryAutoLogin({
        commit,
        dispatch
    }) {
        // Check if we already have a token in local storage
        const token = localStorage.getItem("token");
        // Token is valid, get user data from local storage
        const user = localStorage.getItem("user");
        // Check if the token is still valid or not
        const expirationDate = localStorage.getItem("expirationDate");
        if (!user || !token || !expirationDate) {
            !user ? console.log("auth::tryAutoLogin() - No user found in local storage") : "";
            !expirationDate ? console.log("auth::tryAutoLogin() - No expirationDate found in local storage") : "";
            !token ? console.log("auth::tryAutoLogin() - No token found in local storage") : "";
            return;
        }
        console.log("auth::tryAutoLogin() - A token has been found in local storage. Expiration date is:", expirationDate);
        const now = new Date();
        if (now >= new Date(expirationDate)) {
            console.log("auth::tryAutoLogin() - Token has expired.");
            // Token is no more valid, it's expired
            return;
        }

        const userData = JSON.parse(user);
        console.log("auth::tryAutoLogin() - Token is valid, retrieve user data from local storage.", userData);
        commit("SET_USER_DATA", {
            user: userData
        });

        console.log("auth::tryAutoLogin() - Token: Ask refresh token");
        dispatch("refreshToken");

        // start timer to detect token expiration
        console.log("auth::tryAutoLogin() - Token: Start refresh token timer");
        dispatch("setRefreshTokenTimer");

        if (window.location.pathname === "/") {
            router.push({
                name: "home"
            });
        } else {
            router.push(window.location.pathname + window.location.search);
        }
    },

    /*
     * Action used to check email address on Sign Up
     */
    verifyUser({
        commit
    }, activationToken) {
        axiosBackend
            .get("/user/verify/" + activationToken, {})
            .then(res => {
                let textKey = "user_verified";
                if (res.data && res.data.message) {
                    textKey = res.data.message;
                }
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t(textKey),
                    type: "success"
                });
            })
            .catch(error => {
                console.log(error);
                if (error.data.errorCode === 500) {
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("user_verifiedFailed"),
                        type: "error"
                    });
                }
                // else display error defined in axiosbackend
            });
    },

    /*
     * Action used to check email address on Sign Up
     */
    // eslint-disable-next-line
    confirmInvitationToJoinSite({
        commit
    }, inviteeId) {
        axiosBackend
            .get("/user/invitation/" + inviteeId, {})
            .then(() => {
                console.log("auth::confirmInvitationToJoinSite() - Invitation has been confirmed.");
            })
            .catch(error => {
                console.log(error);
            });
    },

    /*
     * Action used to Sign Up a new user locally
     */
    signup({
        commit
    }, authData) {
        axiosBackend
            .post("/user/signup", {
                firstName: authData.firstname,
                lastName: authData.lastname,
                email: authData.email,
                password: authData.password,
                jobTitle: authData.jobtitle,
                companyName: authData.companyname,
                nickName: authData.nickName
            })
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("user_signUpSuccessfullyCreated"),
                    type: "success"
                });

                // Go to home page
                router.push({
                    name: "signin"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to Reset a password for a user
     */
    reset({
        commit
    }, authData) {
        axiosBackend
            .post("/user/reset", {
                email: authData.email
            })
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("user_resetSuccessfullyCreated"),
                    type: "success"
                });

                // Go to home page
                router.push({
                    name: "signin"
                });
            })
            .catch(() => {});
    },

    /*
     * Action used to Update a password for a user
     */
    updatePassword({
        commit
    }, authData) {
        axiosBackend
            .post("/user/password/reset/" + router.currentRoute.params.token, {
                email: authData.email,
                password: authData.password
            })
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("user_updatePasswordSuccessfullyCreated"),
                    type: "success"
                });

                // Go to home page
                router.push({
                    name: "signin"
                });
            })
            .catch(() => {});
    },

    /*
     * Action used to start automatically a timer that will be called once token expiration time will be reached.
     */
    setRefreshTokenTimer({
        commit,
        dispatch
    }) {
        console.log("auth::setRefreshTokenTimer() - Token: setRefreshTokenTimer() timer is (in seconds): ", process.env.VUE_APP_REFRESH_TOKEN_TIMER);

        if (authHelper.isLocalStorageAuthenticated()) {
            console.log("auth::setRefreshTokenTimer() - Token: setRefreshTokenTimer() token is valid so start a timer to ask refresh token");

            // Init a timeout that will be automatically called when expirate date will be reached
            setTimeout(() => {
                console.log("auth::setRefreshTokenTimer() - Token: setRefreshTokenTimer() timeout! Ask refresh token");
                dispatch("refreshToken");
            }, process.env.VUE_APP_REFRESH_TOKEN_TIMER * 1000);
        } else if (authHelper.isLocalStorageTokenExpired()) {
            commit("SET_SESSION_EXPIRED", true);
        }
    },

    /*
     * Action used to Sign In a user locally (not OV Cirrus)
     */
    login({
        dispatch
    }, authData) {
        // Call API on Backend
        axiosBackend
            .post(
                "/user/signin", {
                    email: authData.email,
                    password: authData.password
                }, {}
            )
            .then(res => {
                // Compute expiration date based on expiresIn value retrieved in response
                const now = new Date();
                const expirationDate = new Date(now.getTime() + res.data.expires_in * 1000);

                // Store Auth data in local Storage
                localStorage.setItem("token", res.data.access_token);
                localStorage.setItem("expirationDate", expirationDate);

                // Set token in axios instance for next API Calls
                axiosBackend.defaults.headers.common["Authorization"] = "Bearer " + res.data.access_token;
                
                let pushyTokenAppId = localStorage.getItem("pushyTokenAppId");
                let pushyToken = localStorage.getItem("pushyToken");
                let pushyTokenAuth = localStorage.getItem("pushyTokenAuth");
                const pushyAppID = process.env.VUE_APP_PUSHYAPPID;

                // Get user info
                dispatch("getUserProfile");

                // Init logout timer
                dispatch("setRefreshTokenTimer");

                // Reset permissions
                dispatch("resetPermissions");

                if(pushyTokenAppId && pushyToken && pushyTokenAuth && pushyTokenAppId == pushyAppID){
                    var config = {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        }
                    };

                    console.log("auth::login() - pushyAppID has been retrieved");

                    const nav_language         = (navigator && navigator.language ? " / " + navigator.language : "");
                    const navigatorFingerPrint = _browser.name + " (" + _browser.os  + ")" + nav_language;

                    console.log("auth::login() - navigatorFingerPrint is now ready and equals = "+navigatorFingerPrint);
                    console.log("auth::login() - Register now the browser to Pushy notification service");

                    var jsonData = {
                        deviceToken: pushyToken,
                        navigator  : navigatorFingerPrint
                    };
        
                    // Print device token to console
                    console.log("auth::login() - Call now the backend to store this device token");
        
                    // Send the token to the backend server
                    axiosBackend.post("/device/token", jsonData, config).then(res => {
                        console.log("auth::login() - Successfully registered and save to the Backend.");
                    }).catch(err => {
                        console.error(err);
                        if(err.data && err.data.errorCode == 401){
                            // request Web Push Notification
                            localStorage.removeItem('pushyTokenAppId');
                            localStorage.removeItem('pushyTokenAuth');
                            localStorage.removeItem('pushyToken');
                            dispatch("requestWebPushNotification");
                        }
                    });
                }else{
                    // request Web Push Notification
                    dispatch("requestWebPushNotification");
                }
            });
    },

    /*
     * Action used to refresh the current token
     */
    refreshToken({
        commit,
        dispatch
    }) {
        if (authHelper.isLocalStorageAuthenticated()) {
            console.log("auth::refreshToken() - Token: refreshToken() token exists and not expired, ask refresh token...");

            // Call API on Backend
            axiosBackend
                .post(
                    "/user/token/refresh", {
                        email: JSON.parse(localStorage.getItem("user")).loginEmail
                    }, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        hideSpinner: true
                    }
                )
                .then(res => {
                    console.log("auth::refreshToken() - Token: refreshToken() POST /user/token/refresh returns: ", res);

                    // Compute expiration date based on expiresIn value retrieved in response
                    const now = new Date();
                    const expirationDate = new Date(now.getTime() + res.data.expires_in * 1000);

                    // Store Auth data in local Storage
                    localStorage.setItem("token", res.data.access_token);
                    localStorage.setItem("expirationDate", expirationDate);

                    // Set token in axios instance for next API Calls
                    axiosBackend.defaults.headers.common["Authorization"] = "Bearer " + res.data.access_token;

                    // Init logout timer
                    dispatch("setRefreshTokenTimer");
                })
                .catch(() => {});
        } else if (authHelper.isLocalStorageTokenExpired()) {
            commit("SET_SESSION_EXPIRED", true);
        }
    },

    /*
     * Action used to Logout a user and go to signin
     */
    logout() {
        console.log("auth::logout() - Clear the local storage");
        authHelper.clearLocalStorage();
        router.replace("/signin");
    },

    /*
     * Action used to Logout a user and go to signup
     */
    // eslint-disable-next-line
    logoutAndSignUp({
        commit
    }, params) {
        console.log("auth::logoutAndSignUp() - Clear the local storage and go to signup");
        authHelper.clearLocalStorage();
        var queryParams = params ? params : {};
        router.replace({
            path: "/signup",
            query: queryParams
        });
    },

    /*
     * Action used to fetch user data from backend
     */
    getUserProfile({
        commit
    }) {
        if (!localStorage.getItem("token")) {
            return;
        }

        axiosBackend
            .get("/user/profile", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store user data in local storage
                localStorage.setItem("user", JSON.stringify(res.data.data));

                // Set user Data in VueX Auth store
                commit("SET_USER_DATA", {
                    user: res.data.data
                });

                // Go to home page
                router.push({
                    name: "home"
                });
            })
            .catch(() => {});
    },

    /*
     * Action used to fetch user data from backend
     */
    updateUserProfile({
        commit
    }, userData) {
        if (!localStorage.getItem("token")) {
            return;
        }

        axiosBackend
            .put("/user/profile", userData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                let jsonDataRes = null;
                if (res && res.data && res.data.data) {
                    jsonDataRes = JSON.stringify(res.data.data);
                }

                // Store user data in local storage
                localStorage.setItem("user", jsonDataRes);

                // Set user Data in VueX Auth store
                commit("SET_USER_DATA", {
                    user: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("user_userProfileSuccessfullyUpdated"),
                    type: "success"
                });

                // Go to home page
                router.push({
                    name: "home",
                    params: {
                        fromAction: "updateAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update its language
     */
    updateUserLanguage({
        commit
    }, userLanguage) {
        if (!localStorage.getItem("token")) {
            return;
        }
        var userData = {};
        userData.preferredLanguage = userLanguage.preferredLanguage;

        axiosBackend
            .put("/user/profile", userData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "content-type": "application/json"
                }
            })
            .then(res => {
                let jsonDataRes = null;
                if (res && res.data && res.data.data) {
                    jsonDataRes = JSON.stringify(res.data.data);
                }

                // Store user data in local storage
                localStorage.setItem("user", jsonDataRes);

                // Set user Data in VueX Auth store
                commit("SET_USER_DATA", {
                    user: res.data.data
                });

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get permissions of the current user on a given site
     */
    getPermissions({commit, state}, payload) {

        let siteId   = payload.siteId;
        let callback = payload.callback;
        let to       = payload.to;
        let next     = payload.next;
        let from     = payload.from;

        // Only proceed if user site permissions are not already in the vuex store
        let index = _.findIndex(state.permissions, {
            siteId: siteId
        });
        if (index < 0) {
            let features = localStorage.getItem("features");
            if (features && features[siteId]) {
                // Update permissions in store
                commit("SET_USER_SITE_PERMISSIONS", {
                    siteId: siteId,
                    features: JSON.parse(features[siteId])
                });
            } else {
                // nothing found in localStorage, we will get permissions from backend
                // user will be identified on backend side thanks to the token provided in request headers
                axiosBackend
                    .get("/sites/" + siteId + "/permissions", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        }
                    })
                    .then(res => {
                        let jsonDataRes = null;
                        if (res && res.data && res.data.data && res.data.data.role && res.data.data.role.features) {
                            jsonDataRes = {};
                            jsonDataRes[siteId] = JSON.stringify(res.data.data.role.features);
                        }

                        // Store user data in local storage
                        localStorage.setItem("features", jsonDataRes);

                        // Update permissions in store
                        commit("SET_USER_SITE_PERMISSIONS", {
                            siteId: siteId,
                            features: res.data.data.role.features
                        });

                        // Set user role
                        commit("SET_USER_ROLE_NAME", {
                            role: res.data.data.role
                        });

                        callback(from, to, next, siteId);
                    })
                    .catch(error => console.log(error));
            }
        } else {
            callback(from, to, next, siteId);
        }
    },

    /*
     * Action used to reset previously loaded permissions
     */
    resetPermissions({
        commit
    }) {
        // Update permissions in store
        commit("RESET_USER_PERMISSIONS", {});
    },

    /*
     * Action used to fetch user data from backend
     */
    requestWebPushNotification({ commit }) {

        // Print device token to console
        console.log("auth::requestWebPushNotification() - called");

        const pushyAppID = process.env.VUE_APP_PUSHYAPPID;
        const pushyTokenAppId = localStorage.getItem('pushyTokenAppId') || null;

        console.log("auth::requestWebPushNotification() - pushyAppID has been retrieved");

        const nav_language         = (navigator && navigator.language ? " / " + navigator.language : "");
        const navigatorFingerPrint = _browser.name + " (" + _browser.os  + ")" + nav_language;

        console.log("auth::requestWebPushNotification() - navigatorFingerPrint is now ready and equals = "+navigatorFingerPrint);
        console.log("auth::requestWebPushNotification() - Register now the browser to Pushy notification service");

        // Check Web Push registration
        const isRegisteredPushy = Pushy.isRegistered();
        if (isRegisteredPushy && pushyTokenAppId != null && pushyAppID == pushyTokenAppId) {
            console.log("auth::requestWebPushNotification() - Already registered to Pushy with Device token : " + localStorage.getItem('pushyToken'));
            return;
        }

        // Register token to Pushy Service
        Pushy.register({ appId: pushyAppID }).then(function (deviceToken) {

            // Print device token to console
            console.log("auth::requestWebPushNotification() - Registered successfully to Pushy. The Device token is : " + deviceToken);

            var jsonData = {
                deviceToken: deviceToken,
                navigator  : navigatorFingerPrint
            };

            var config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            };

            // Print device token to console
            console.log("auth::requestWebPushNotification() - Call now the backend to store this device token");

            // Send the token to the backend server
            axiosBackend.post("/device/token", jsonData, config).then(res => {
                
                console.log("auth::requestWebPushNotification() - Successfully registered and save to the Backend.");
            }).catch(err => {

                console.error("Pushy register error: ", err);
            });

        }).catch(function (err) {
            // Handle registration errors
            console.error("Error :: ",err);
        });

    },

};

export default {
    state,
    getters,
    mutations,
    actions
};