import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        dashboardsOfUserOnSite: null,
        currentDashboard: null,
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    dashboardsOfUserOnSite(state) {
        return state.dashboardsOfUserOnSite;
    },

    currentDashboard(state) {
        return state.currentDashboard;
    },

    createAnotherDashboard(state) {
        return state.createAnother;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_USER_DASHBOARDS_ON_SITE(state, data) {
        state.dashboardsOfUserOnSite = data.dashboards;
    },

    REMOVE_DASHBOARD_BY_ID(state, dashboardId) {
        // Find dashboard index
        let index = _.findIndex(state.dashboardsOfUserOnSite, { id: dashboardId });
        state.dashboardsOfUserOnSite.splice(index, 1);
    },

    SET_CURRENT_DASHBOARD(state, dashboardData) {
        if (!state.dashboardsOfUserOnSite) {
            state.dashboardsOfUserOnSite = [];
        }
        // Set the current dashboard
        state.currentDashboard = dashboardData.currentDashboard;
        // Find dashboard index
        let index = _.findIndex(state.dashboardsOfUserOnSite, { id: dashboardData.currentDashboard.id });
        if (index >= 0) {
            // Replace the dashboard in the array of dashboards
            state.dashboardsOfUserOnSite.splice(index, 1, dashboardData.currentDashboard);
        } else {
            state.dashboardsOfUserOnSite.push(dashboardData.currentDashboard);
        }
    },

    SET_CREATE_ANOTHER_DASHBOARD(state, createAnother) {
        state.createAnother = createAnother;
    },

    UNSET_CURRENT_DASHBOARD(state) {
        state.currentDashboard = null;
    },

    RESET_DEFAULT_DASHBOARDS_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherDashboard({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_DASHBOARD", createAnother);
    },

    /*
     * Action used to create a dashboard
     */
    createDashboard({ commit }, payload) {
        payload.refreshInterval = parseInt(payload.refreshInterval);
        commit("SET_CREATE_ANOTHER_DASHBOARD", payload.createAnother);
        axiosBackend
            .post(
                "/sites/" + payload.siteId + "/analytics/dashboards",
                {
                    name: payload.name,
                    refreshInterval: payload.refreshInterval
                },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("analytics_dashboardSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to dashboards list page
                router.push({ name: "dashboards", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of dashboards of the user in the site
     */
    getDashboardsOfUserOnSite({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/analytics/dashboards", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store user dashboards in dashboardsOfUserOnSite
                commit("SET_USER_DASHBOARDS_ON_SITE", {
                    dashboards: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one dashboard by its id
     */
    getDashboardById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/analytics/dashboards/" + payload.dashboardId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_CURRENT_DASHBOARD", {
                    currentDashboard: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing dashboard
     */
    updateDashboard({ commit }, payload) {
        payload.refreshInterval = parseInt(payload.refreshInterval);
        var stayOnPage = payload.stayOnPage || false;
        delete payload.stayOnPage;
        axiosBackend
            .put("/sites/" + payload.siteId + "/analytics/dashboards/" + payload.dashboardId, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                
                if (!stayOnPage) {// Route to dashboards page
                    // Display notification
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("analytics_dashboardSuccessfullyUpdated"),
                        type: "success"
                    });
                    router.push({ name: "dashboards", params: { fromAction: "updateAPI" } });
                } else {
                    commit("SET_CURRENT_DASHBOARD", {
                        currentDashboard: res.data.data
                    });
                }
                
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a dashboard
     */
    deleteDashboard({ commit, dispatch }, data) {
        axiosBackend
            .delete("/sites/" + data.siteId + "/analytics/dashboards/" + data.dashboardId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_DASHBOARD_BY_ID", data.dashboardId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("analytics_dashboardSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetDashboardsState ({ commit }) {
        commit('RESET_DEFAULT_DASHBOARDS_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
