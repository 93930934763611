<template>
    <div
        class="modal fade show"
        id="aboutModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modalAboutCustomWidth" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("main_about") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="kt-iconbox kt-iconbox--info">
                        <div class="kt-iconbox__body" style="text-align: center;">
                            <div v-if="this.releasedFor == 'ALE'" class="kt-iconbox__icon" style="margin-top: 10px;margin-bottom: 10px;">
                                <img class="logoAleAbout" src="/assets/ale/logo.png" />
                            </div>
                            <div class="kt-iconbox__desc" style="margin-top: 20px;">
                                <h5 v-if="this.releasedFor == 'ALE'" class="kt-iconbox__title kt-font-brand">OmniAccess Stellar Asset Tracking</h5>
                                <h5 v-else class="kt-iconbox__title kt-font-brand">Wireless Asset Tracking</h5>
                                <div class="kt-iconbox__content">
                                    <div>{{ $t("common_version") }}: <span class="kt-font-dark kt-font-regular">{{ version }}</span></div>
                                    <div>{{ $t("common_server") }}: <span class="kt-font-dark kt-font-regular">{{ serverVersion }}</span></div>
                                    <br />
                                    <div>© {{ currentYear }} {{ this.releasedFor == "HAN" ? "HAN Networks" : "Alcatel-Lucent Enterprise" }}</div>
                                    <div v-if="this.releasedFor == 'ALE'">
                                        {{ $t("main_moreInfo") }}
                                        <a
                                            href="https://www.al-enterprise.com"
                                            target="_blank"
                                            rel="noopener"
                                            class="kt-link"
                                        >www.al-enterprise.com</a>
                                    </div>
                                    <br />
                                    <div>

                                        <a
                                            v-if="this.releasedFor == 'ALE'"
                                            href="/doc/omniaccess_stellar_at_terms.pdf"
                                            target="_blank"
                                            rel="noopener"
                                            class="kt-link"
                                        >{{ $t("main_legal") }}</a>
                                        <a
                                            v-else
                                            href="http://www.han-networks.com/low-u.php"
                                            target="_blank"
                                            rel="noopener"
                                            class="kt-link"
                                        >{{ $t("main_legal") }}</a>
                                        |
                                        <a
                                            v-if="this.releasedFor == 'ALE'"
                                            href="/doc/omniaccess-stellar-asset-tracking-data-privacy-notice-en.pdf"
                                            target="_blank"
                                            rel="noopener"
                                            class="kt-link"
                                        >{{ $t("main_privacy") }}</a>
                                        <a
                                            v-else
                                            href="http://www.han-networks.com/low-y.php"
                                            target="_blank"
                                            rel="noopener"
                                            class="kt-link"
                                        >{{ $t("main_privacy") }}</a>
                                        |
                                        <a
                                            href="/open-source"
                                            target="_blank"
                                            rel="noopener"
                                            class="kt-link"
                                        >{{ $t("main_thirdPartySoft") }}</a>
                                        |
                                        <a
                                            v-if="this.releasedFor == 'ALE'"
                                            href="/doc/OmniAccess_Stellar_Asset_Tracking_Production_Notes.pdf"
                                            target="_blank"
                                            rel="noopener"
                                            class="kt-link"
                                        >{{ $t("main_releaseNoteLink") }}</a>
                                        <a
                                            v-else
                                            href="/doc/han/Wireless_Asset_Tracking_Production_Notes_103.pdf"
                                            target="_blank"
                                            rel="noopener"
                                            class="kt-link"
                                        >{{ $t("main_releaseNoteLink") }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        id="aboutOkButton"
                        type="button"
                        class="btn btn-brand"
                        data-dismiss="modal"
                    >{{ $t("common_close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import axiosBackend from "../../axios-backend";

export default {
    name: "aboutmodal",
    data() {
        console.log("Component(aboutModal)::data() - called");
        return {
            version: "N/A",
            serverVersion: "N/A",
            releasedFor: process.env.VUE_APP_RELEASED_FOR,
            currentYear: new Date().getFullYear()
        };
    },
    created: function() {
        console.log("Component(aboutModal)::created() - called");
        this.getVersion();
        this.getServerVersion();
    },
    mounted: function() {
        console.log("Component(aboutModal)::mounted() - Init metronic layout");
    },
    destroyed: function() {
        console.log("Component(aboutModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {},
    methods: {
        getVersion: function() {
            //console.log("Component(aboutModal)::getVersion() - called");
            axios
                .get("/version.txt")
                .then(res => {
                    if (res && res.data) {
                        this.version = res.data;
                        axios
                            .get("/commit.txt")
                            .then(res => {
                                if (res && res.data) {
                                    this.version =
                                        this.version + "#" + res.data;
                                }
                                console.log(
                                    "Component(aboutModal)::getVersion() - Version with commit = " +
                                        this.version
                                );
                            })
                            .catch(error =>
                                console.info(
                                    "Component(aboutModal)::getVersion() - Error = " +
                                        error
                                )
                            );
                    }
                    console.log(
                        "Component(aboutModal)::getVersion() - Version = " +
                            this.version
                    );
                })
                .catch(error =>
                    console.error(
                        "Component(aboutModal)::getVersion() - Error = " + error
                    )
                );
        },
        getServerVersion: function() {
            //console.log("Component(aboutModal)::getServerVersion() - called");
            axiosBackend
                .get("/version")
                .then(res => {
                    if (res && res.data) {
                        this.serverVersion = res.data.data;
                    }
                    if (!this.serverVersion || this.serverVersion.error) {
                        this.serverVersion = "N/A";
                    }
                    console.log(
                        "Component(aboutModal)::getServerVersion() - Backend version = " +
                            this.serverVersion
                    );
                })
                .catch(error => {
                    if (error) {
                        console.error(
                            "Component(aboutModal)::getServerVersion() - Error = " +
                                error
                        );
                    }
                });
        }
    }
};
</script>

<style scoped>
.logoAleAbout {
    width: 130px;
}
.modalAboutCustomWidth {
    max-width: 580px;
}
.marginR30 {
    margin-right: 30px;
}
</style>
