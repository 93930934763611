/*
 * State: properties to store
 */
const state = {
    /*
     * Indicate if the delete modal has to be displayed
     */
    showDeleteModal: false,

    /*
     * Indicate if the delete modal has to be hide
     */
    hideDeleteModal: false,

    /*
     * Body Text content currently displayed in the delete modal
     */
    deleteModalTextContent: "",

    /*
     * Name of action to do when user will click on delete button
     */
    actionName: "",

    /*
     * If of object to delete, could be siteId, floorId, buildingId, applicationId...
     */
    params: {}
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    /*
     * Toggle showDeleteModal property
     */
    SHOW_DELETE_MODAL(state, data) {
        state.showDeleteModal = true;
        state.deleteModalTextContent = data.textContent;
        state.actionName = data.actionName;
        state.params = data.params;
    },

    /*
     * Toggle hideDeleteModal property
     */
    HIDE_DELETE_MODAL(state) {
        state.hideDeleteModal = true;
    },

    /*
     * Reset showDeleteModal property
     */
    RESET_SHOW_DELETE_MODAL(state) {
        state.showDeleteModal = false;
    },

    /*
     * Reset hideDeleteModal property
     */
    RESET_HIDE_DELETE_MODAL(state) {
        state.hideDeleteModal = false;
    }
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for showDeleteModal
     */
    showDeleteModal(state) {
        return state.showDeleteModal;
    },
    /*
     * Getter for hideDeleteModal
     */
    hideDeleteModal(state) {
        return state.hideDeleteModal;
    },
    /*
     * Getter for deleteModalTextContent
     */
    getDeleteModalTextContent(state) {
        return state.deleteModalTextContent;
    },
    /*
     * Getter for actionName
     */
    getActionName(state) {
        return state.actionName;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    showDeleteModal({
        commit
    }, data) {
        commit("SHOW_DELETE_MODAL", data);
    },

    hideDeleteModal({
        commit
    }, data) {
        commit("HIDE_DELETE_MODAL", data);
    },

    resetShowDeleteModal({
        commit
    }) {
        commit("RESET_SHOW_DELETE_MODAL", {});
    },

    resetHideDeleteModal({
        commit
    }) {
        commit("RESET_HIDE_DELETE_MODAL", {});
    },

    deleteResource({
        state,
        dispatch
    }) {
        switch (state.actionName) {
            case "DELETE_SITE":
                dispatch("deleteSite", {
                    siteId: state.params.siteId
                });
                break;
            case "DELETE_BUILDING":
                dispatch("deleteBuilding", {
                    siteId: state.params.siteId,
                    buildingId: state.params.buildingId
                });
                break;
            case "DELETE_DASHBOARD":
                dispatch("deleteDashboard", {
                    siteId: state.params.siteId,
                    dashboardId: state.params.dashboardId
                });
                break;
            case "DELETE_WIDGET":
                dispatch("deleteWidget", {
                    siteId: state.params.siteId,
                    dashboardId: state.params.dashboardId,
                    widgetId: state.params.widgetId
                });
                break;
            case "DELETE_FLOOR":
                dispatch("deleteFloor", {
                    siteId: state.params.siteId,
                    buildingId: state.params.buildingId,
                    floorId: state.params.floorId
                });
                break;
            case "DELETE_TAG":
                dispatch("deleteTag", {
                    siteId: state.params.siteId,
                    tagId: state.params.tagId
                });
                break;
            case "DELETE_TAG_MODEL":
                dispatch("deleteTagModel", {
                    siteId: state.params.siteId,
                    tagTypeId: state.params.tagTypeId
                });
                break;
            case "DELETE_APPLICATION":
                dispatch("deleteApplication", {
                    appId: state.params.appId
                });
                break;
            case "DELETE_GATEWAY":
                dispatch("deleteBLEGateway", {
                    siteId: state.params.siteId,
                    gatewayId: state.params.gatewayId
                });
                break;
            case "DELETE_INDOOR_AREA":
                dispatch("deleteIndoorArea", {
                    siteId: state.params.siteId,
                    buildingId: state.params.buildingId,
                    floorId: state.params.floorId,
                    indoorAreaId: state.params.indoorAreaId
                });
                break;
            case "DELETE_GEOFENCE":
                dispatch("deleteGeofence", {
                    siteId: state.params.siteId,
                    buildingId: state.params.buildingId,
                    floorId: state.params.floorId,
                    geofenceId: state.params.geofenceId
                });
                break;
            case "DELETE_WALKWAY":
                dispatch("deleteWalkway", {
                    siteId: state.params.siteId,
                    buildingId: state.params.buildingId,
                    floorId: state.params.floorId,
                    walkwayId: state.params.walkwayId
                });
                break;
            case "DELETE_WALL":
                dispatch("deleteWall", {
                    siteId: state.params.siteId,
                    buildingId: state.params.buildingId,
                    floorId: state.params.floorId,
                    wallId: state.params.wallId
                });
                break;
            case "DELETE_AUTOCALIBRATIONTAG":
                dispatch("deleteAutoCalibrationTag", {
                    siteId: state.params.siteId,
                    autoCalibrationTagId: state.params.autoCalibrationTagId
                });
                break;
            case "DELETE_ASSET_CATEGORY":
                dispatch("deleteAssetCategory", {
                    siteId: state.params.siteId,
                    assetCategoryId: state.params.assetCategoryId
                });
                break;
            case "DELETE_ASSET":
                dispatch("deleteAsset", {
                    siteId: state.params.siteId,
                    assetId: state.params.assetId
                });
                break;
            case "REVOKE_USER_ACCESS_TO_SITE":
                dispatch("revokeUserAccessToSite", {
                    siteId: state.params.siteId,
                    userId: state.params.userId
                });
                break;
            case "DELETE_PENDING_INVITATION":
                dispatch("deletePendingInvitation", {
                    siteId: state.params.siteId,
                    invitationId: state.params.invitationId
                });
                break;
            case "DELETE_PENDING_GUEST":
                dispatch("deletePendingGuest", {
                    siteId: state.params.siteId,
                    invitationId: state.params.invitationId
                });
                break;
            case "DELETE_ROLE":
                dispatch("deleteRole", {
                    siteId: state.params.siteId,
                    roleId: state.params.roleId
                });
                break;
            case "REVOKE_GUEST_ACCESS_TO_SITE":
                dispatch("revokeGuestAccessToSite", {
                    siteId: state.params.siteId,
                    guestId: state.params.guestId
                });
                break;
            case "DELETE_NOTIFICATION":
                dispatch("deleteGeoNotification", {
                    siteId: state.params.siteId,
                    notificationId: state.params.notificationId
                });
                break;
            case "DELETE_ALARM":
                dispatch("deleteOneAlarm", {
                    siteId: state.params.siteId,
                    alarmId: state.params.alarmId,
                    limit: state.params.limit,
                    offset: state.params.offset
                });
                break;
            case "DELETE_PUSHBUTTON_ALERT":
                dispatch("deletePushButtonAlert", {
                    siteId: state.params.siteId,
                    alertId: state.params.alertId
                });
                break;
            case "DELETE_PUSHBUTTON_EVENT_TYPE":
                dispatch("deletePushButtonEventType", {
                    siteId: state.params.siteId,
                    eventTypeId: state.params.eventTypeId
                });
                break;
            case "DELETE_REPORT":
                dispatch("deleteReport", {
                    siteId: state.params.siteId,
                    reportId: state.params.reportId
                });
                break;
        }
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};