import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        currentDashboardWidgets: [],
        widgetToEdit: null,
        widgetCreationMode: false,
        widgetToDisplayDetails: null, //widgetId
        widgetTypesList: [],
        widgetCreationOrUpdateInProgess: false,
        widgetsDrawingContentData: [],
        widgetsLoadingState: {},
        analyticsWidgetHeatmapData: null,
        lastRefreshTime: null,
        isDarkMode: false,
        showAddGeonotificationModal: false,
        lastCreatedThresholdWidgetSiteId: null,
        lastCreatedThresholdWidgetThreshold: null,
        lastCreatedThresholdWidgetGeofenceId: null
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    isDarkMode(state) {
        return state.isDarkMode;
    },

    currentDashboardWidgets(state) {
        return state.currentDashboardWidgets;
    },

    widgetToEdit(state) {
        return state.widgetToEdit;
    },

    widgetCreationMode(state){
        return state.widgetCreationMode;
    },

    widgetToDisplayDetails(state) {
        return state.widgetToDisplayDetails;
    },

    widgetById(state) {
        return id => _.find(state.currentDashboardWidgets, ["id", id]);
    },

    widgetTypesList(state) {
        return state.widgetTypesList;
    },

    widgetCreationOrUpdateInProgess(state) {
        return state.widgetCreationOrUpdateInProgess;
    },

    widgetUpdateInProgess(state) {
        return state.widgetUpdateInProgess;
    },

    switchAddWidgetModalToHidden(state) {
        return state.switchAddWidgetModalToHidden;
    },

    switchUpdateWidgetModalToHidden(state) {
        return state.switchUpdateWidgetModalToHidden;
    },

    widgetsDrawingContentData(state) {
        return state.widgetsDrawingContentData;
    },

    widgetsLoadingState(state) {
        return state.widgetsLoadingState;
    },

    analyticsWidgetHeatmapData(state) {
        return state.analyticsWidgetHeatmapData;
    },

    lastRefreshTime(state) {
        return state.lastRefreshTime;
    },

    showAddGeonotificationModal(state) {
        return state.showAddGeonotificationModal;
    },

    lastCreatedThresholdWidgetSiteId(state) {
        return state.lastCreatedThresholdWidgetSiteId;
    },

    lastCreatedThresholdWidgetThreshold(state) {
        return state.lastCreatedThresholdWidgetThreshold;
    },

    lastCreatedThresholdWidgetGeofenceId(state) {
        return state.lastCreatedThresholdWidgetGeofenceId;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_DASHBOARD_WIDGETS(state, data) {
        var currentDashboardWidgets = data.widgets;
        state.currentDashboardWidgets = currentDashboardWidgets.sort(function() {
            return Math.random() - 0.5;
        });
        state.widgetsDrawingContentData = data.widgets.map(function(widgetObject) {
            return {widgetId: widgetObject.id, drawingData: null};
        });
        state.lastRefreshTime = null;
    },

    SET_WIDGET_TYPES(state, data) {
        state.widgetTypesList = data.widgetTypes || [];
    },

    UNSET_DASHBOARD_WIDGETS(state) {
        state.currentDashboardWidgets = [];
        state.widgetsDrawingContentData = [];
        state.widgetToEdit = null;
        state.widgetCreationMode = false;
    },

    REMOVE_WIDGET_BY_ID(state, widgetId) {
        // Find widget index
        let indexWidgetInfo = _.findIndex(state.currentDashboardWidgets, { id: widgetId });
        if (indexWidgetInfo >= 0) {
            state.currentDashboardWidgets.splice(indexWidgetInfo, 1);
        }
        let indexWidgetData = _.findIndex(state.widgetsDrawingContentData, { widgetId: widgetId });
        if (indexWidgetData >= 0) {
            state.widgetsDrawingContentData.splice(indexWidgetData, 1);
        }

    },

    SET_WIDGET_TO_EDIT(state, widgetToEdit) {
        // Set the current widget
        state.widgetToEdit = widgetToEdit;
        //console.log("store/widgets/mutation/ set widget to edit", state.widgetToEdit)
    },

    UPDATE_WIDGETMODAL_CREATION_MODE(state, mode) {
        state.widgetCreationMode = mode;
    },

    ADD_OR_UPDATE_WIDGET(state, data) {
        state.currentDashboardWidgets = state.currentDashboardWidgets || [];
        var indexWidgetInfo = _.findIndex(state.currentDashboardWidgets, { id: data.id });

        if (indexWidgetInfo >= 0) {
            state.currentDashboardWidgets.splice(indexWidgetInfo, 1, data);
        } else {
            state.currentDashboardWidgets.push(data);
        }
    },

    UNSET_WIDGET_TO_EDIT(state) {
        state.widgetToEdit = null;
    },

    SET_WIDGET_TO_DETAIL(state, widgetToDisplayDetails) {//widgetId
        state.widgetToDisplayDetails = widgetToDisplayDetails;
    },

    UNSET_WIDGET_TO_DETAIL(state) {
        state.widgetToDisplayDetails = null;
    },

    SET_WIDGET_CONTENT(state, data) {
        // Find widget index
        let indexWidgetInfo = _.findIndex(state.currentDashboardWidgets, { id: data.widgetId });
        let indexWidgetData = _.findIndex(state.widgetsDrawingContentData, { widgetId: data.widgetId });

        if (indexWidgetInfo >= 0) {
            if (indexWidgetData >= 0) {
                state.widgetsDrawingContentData.splice(indexWidgetData, 1, data);
            } else {
                state.widgetsDrawingContentData.push(data);
            }
            state.lastRefreshTime = new Date(Date.now());
        } else if (indexWidgetData >= 0) {
            state.widgetsDrawingContentData.splice(indexWidgetData, 1);
        }
    },

    SET_WIDGET_CREATION_OR_UPDATE_IN_PROGRESS(state, isInProgress) {
        state.widgetCreationOrUpdateInProgess = isInProgress
    },

    SET_CONTENT_DATA_IS_LOADING(state, data) {
        state.widgetsLoadingState[data.widgetId] = data.isLoading;
        state.widgetsLoadingState = Object.assign({}, state.widgetsLoadingState);
    },

    SET_HEATMAP_DATA(state, data) {
        state.analyticsWidgetHeatmapData = data;
    },

    SET_DARK_MODE(state, isDarkMode) {
        state.isDarkMode = isDarkMode
    },

    RESET_DEFAULT_WIDGETS_STATE(state) {
        Object.assign(state, getDefaultState())
    },

    SET_SHOW_ADD_GEONOTIFICATION_MODAL(state, display) {
        state.showAddGeonotificationModal = display || false;
    },

    SET_LAST_CREATED_THRESHOLD_SITE_ID(state, siteId) {
        state.lastCreatedThresholdWidgetSiteId = siteId;
    },

    SET_LAST_CREATED_THRESHOLD_VALUE(state, value) {
        state.lastCreatedThresholdWidgetThreshold = value;
    },

    SET_LAST_CREATED_THRESHOLD_GEOFENCE_ID(state, geofenceId) {
        state.lastCreatedThresholdWidgetGeofenceId = geofenceId;
    },

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Action used to create a widget on a specified dashboard
     */
    async createWidget({ commit }, payload) {
        commit("SET_WIDGET_CREATION_OR_UPDATE_IN_PROGRESS", true);
        var createWidgetURL = "/sites/" + payload.siteId + "/analytics/dashboards/" + payload.dashboardId + "/widgets";
        try {
            var resWidgetCreation = await axiosBackend.post(createWidgetURL, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            });

            var createdWidget = resWidgetCreation.data.data;

            if (!createdWidget.readyToUseURL) {
                console.error("Store/createWidget/: 'readyToUseURL' not valid");
                return;
            }

            try {
                var widgetData = await axiosBackend.get(createdWidget.readyToUseURL, {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                });

                commit("ADD_OR_UPDATE_WIDGET", createdWidget);
                commit("SET_WIDGET_CONTENT", {widgetId: createdWidget.id, drawingData: widgetData.data.data});
                commit("SET_CONTENT_DATA_IS_LOADING",  {widgetId: createdWidget.id, isLoading: false});
                commit("SET_WIDGET_CREATION_OR_UPDATE_IN_PROGRESS", false);
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("analytics_widgetSuccessfullyCreated"),
                    type: "success"
                });
                commit("UPDATE_WIDGETMODAL_CREATION_MODE", false);

                // If it is threshold which is created we must display modal to demand to user if he want to add geonotification on it
                if (createdWidget.widgetType.reference === "ASSETS_THESHOLD_IN_GEOFENCE") {
                    commit("SET_SHOW_ADD_GEONOTIFICATION_MODAL", true);
                    // Store the site id, threshold and goefence id, it will be used by modal to call newGeonotification page
                    commit("SET_LAST_CREATED_THRESHOLD_SITE_ID", createdWidget.pathParams.SITE_ID);
                    commit("SET_LAST_CREATED_THRESHOLD_GEOFENCE_ID", createdWidget.pathParams.GEOFENCE_ID);
                    commit("SET_LAST_CREATED_THRESHOLD_VALUE", createdWidget.queryParams.assetThreshold);
                } else {
                    commit("SET_SHOW_ADD_GEONOTIFICATION_MODAL", false);
                }

            } catch (error) {
                console.log(error);
                return;
            }
        } catch (error) {
            commit("UPDATE_WIDGETMODAL_CREATION_MODE", false);
            commit("SET_WIDGET_CREATION_OR_UPDATE_IN_PROGRESS", false);
            console.log(error)
        }
    },

    /**
     * Called each time search bar from asset search page is used
     * @param {object} payload
     */
    recordSearchActivity({ commit }, payload) {
        axiosBackend
            .post(
                "/sites/" + payload.siteId + "/metrics/assetmanagement/recordsearch",
                payload || {},
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                    hideSpinner: true
                }
            )
            .then(() => {
                //ok
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of all widgets for a specified dashboard
     */
    getAllWidgetsOfDashboard({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/analytics/dashboards/" + payload.dashboardId + "/widgets", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_DASHBOARD_WIDGETS", {
                    widgets: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one widget by its id
     */
    getWidgetById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/analytics/dashboards/" + payload.dashboardId + "/widgets/" + payload.widgetId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_WIDGET_TO_EDIT", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing widget
     */
    async updateWidget({ commit }, payload) {
        commit("SET_CONTENT_DATA_IS_LOADING",  {widgetId: payload.widgetId, isLoading: true}); //disable refresh feature meanwhile
        commit("SET_WIDGET_CREATION_OR_UPDATE_IN_PROGRESS", true);
        var updateWidgetURL = "/sites/" + payload.siteId + "/analytics/dashboards/" + payload.dashboardId + "/widgets/" + payload.widgetId;
        try {
            var resWidgetUpdate = await axiosBackend.put(updateWidgetURL, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            });

            var updatedWidget = resWidgetUpdate.data.data;
            if (updatedWidget.readyToUseURL) {
                var widgetData = await axiosBackend.get(updatedWidget.readyToUseURL, {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                });
                commit("SET_WIDGET_CONTENT", {widgetId: updatedWidget.id, drawingData: widgetData.data.data});
            }

            commit("ADD_OR_UPDATE_WIDGET", updatedWidget);
            commit("SET_CONTENT_DATA_IS_LOADING", {widgetId: payload.widgetId, isLoading: false}); //resume refresh
            commit("SET_WIDGET_CREATION_OR_UPDATE_IN_PROGRESS", false);
            // Display notification
            commit("SHOW_NOTIFICATION", {
                text: i18n.t("analytics_widgetSuccessfullyUpdated"),
                type: "success"
            });
            commit("UNSET_WIDGET_TO_EDIT");

        } catch (error) {
            commit("SET_CONTENT_DATA_IS_LOADING",  {widgetId: payload.widgetId, isLoading: false});
            commit("SET_WIDGET_CREATION_OR_UPDATE_IN_PROGRESS", false);
            console.log(error)
        }
    },

    /*
     * Action used to delete a widget
     */
    deleteWidget({ commit, dispatch }, data) {
        axiosBackend
            .delete("/sites/" + data.siteId + "/analytics/dashboards/" + data.dashboardId + "/widgets/" + data.widgetId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_WIDGET_BY_ID", data.widgetId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("analytics_widgetSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })

            .catch(error => console.log(error));
    },

    setWidgetToEdit({ commit, getters}, widgetId) {
        //console.log("store/widgets/action/set widgetId to " + widgetId);
        var thisWidget = getters.widgetById(widgetId);
        if (!thisWidget) {
            console.error("No widget with id '" + widgetId + "' in the store.");
            return;
        }
        commit("SET_WIDGET_TO_EDIT", thisWidget);
    },

    unsetWidgetToEdit({ commit }) {
        commit("UNSET_WIDGET_TO_EDIT");
    },

    setWidgetCreationModeTo({commit}, mode) {
        commit("UPDATE_WIDGETMODAL_CREATION_MODE", mode || false);
        commit("SET_SHOW_ADD_GEONOTIFICATION_MODAL", false);
    },

    setWidgetToDetail({ commit, getters}, widgetId) {
        //console.log("store/widgets/action/set widgetId to " + widgetId);
        var thisWidget = getters.widgetById(widgetId);
        if (!thisWidget) {
            console.error("No widget with id '" + widgetId + "' in the store.");
            return;
        }
        commit("SET_WIDGET_TO_DETAIL", widgetId);
    },

    unsetWidgetToDetail({ commit }) {
        commit("UNSET_WIDGET_TO_DETAIL");
    },
    getWidgetContent({ commit, getters }, widgetId) {
        var thisWidget = getters.widgetById(widgetId);
        if (!thisWidget) {
            console.log("No widget with id '" + widgetId + "' in the store.");
            return;
        }
        if (!thisWidget.readyToUseURL) {
            console.warn("The widget ('" + widgetId + "') configuration is not correct.");
            commit("REMOVE_WIDGET_BY_ID", widgetId)
            return;
        }
        commit("SET_CONTENT_DATA_IS_LOADING", {widgetId: widgetId, isLoading: true});
        axiosBackend
            .get(thisWidget.readyToUseURL, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                hideSpinner: true
            })
            .then(res => {
                commit("SET_WIDGET_CONTENT", {
                    widgetId: widgetId,
                    drawingData: res.data.data
                });
                commit("SET_CONTENT_DATA_IS_LOADING", {widgetId: widgetId, isLoading: false});
            })
            .catch(function(error) {
                commit("SET_CONTENT_DATA_IS_LOADING", {widgetId: widgetId, isLoading: false});
                commit("REMOVE_WIDGET_BY_ID", widgetId);
                console.log(error);
            });
    },

    setHeatmapData({commit}, data) {
        commit("SET_HEATMAP_DATA", data);
    },

    /*
     * Action used to get the list of all types of widget offered for analytics
     */
    getAllWidgetTypes({ commit }) {
        axiosBackend
            .get("/analytics/widgetTypes", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_WIDGET_TYPES", {
                    widgetTypes: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update the position of dashbord widgets
     */
    updateWidgetsPosition({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/analytics/dashboards/" + payload.dashboardId + "/widgets/position", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {})
            .catch(error => console.log(error));
    },


    setDarkMode({commit}, isDarkMode) {
        commit("SET_DARK_MODE", isDarkMode);
    },

    hideAddGeonotificationModal({ commit }) {
        commit("SET_SHOW_ADD_GEONOTIFICATION_MODAL", false);
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetDashboardsState ({ commit }) {
        commit('RESET_DEFAULT_WIDGETS_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
