import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        userApplications: [],
        currentApplication: "",
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for userApplications
     */
    userApplications(state) {
        return state.userApplications;
    },

    currentApplication(state) {
        // Find site index
        return state.currentApplication;
    },

    /*
     * Return the number of user applications
     */
    numberOfUserApplications(state) {
        return state.userApplications.length;
    },

    createAnotherApplication(state) {
        return state.createAnother;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_CURRENT_APPLICATION(state, appData) {
        // 1. Store current application
        state.currentApplication = appData.application;
        // 2. Update applications array
        // Find application index
        let index = _.findIndex(state.userApplications, { id: appData.application.id });
        if (index >= 0) {
            // Replace the site in the array of sites
            state.userApplications.splice(index, 1, appData.application);
        }
    },
    SET_USER_APPLICATIONS(state, appData) {
        state.userApplications = appData.applications;
    },
    SET_USER_APPLICATION(state, appData) {
        state.userApplications.push(appData.application);
    },
    REMOVE_APPLICATION_BY_ID(state, appId) {
        // Find application index
        let index = _.findIndex(state.userApplications, { id: appId });
        state.userApplications.splice(index, 1);
    },
    RESET_DEFAULT_APPLICATIONS_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_CREATE_ANOTHER_APPLICATION(state, createAnother) {
        state.createAnother = createAnother;
    },
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherApplication({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_APPLICATION", createAnother);
    },

    /*
     * Action used to get a user application with the appId
     */
    getCurrentApplication({ commit }, appId) {
        axiosBackend
            .get("/applications/" + appId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Update application in store
                commit("SET_CURRENT_APPLICATION", {
                    application: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to create a new application
     */
    createUserApplication({ commit }, data) {
        commit("SET_CREATE_ANOTHER_APPLICATION", data.createAnother);
        delete data.createAnother;
        axiosBackend
            .post(
                "/applications",
                {
                    name: data.name,
                    target: data.target,
                    description: data.description
                },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_USER_APPLICATION", {
                    lastApplication: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("app_newApplicationSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to applications page
                router.push({ name: "applications", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing application
     */
    updateUserApplication({ commit }, data) {
        axiosBackend
            .put(
                "/applications/" + data.id,
                {
                    name: data.name,
                    target: data.target,
                    description: data.description
                },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_APPLICATION", {
                    application: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("app_applicationSuccessfullyUpdated"),
                    type: "success"
                });

                // Creation OK => Route to applications page
                router.push({ name: "applications", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get all applications of logged user
     */
    getUserApplications({ commit }) {
        axiosBackend
            .get("/applications", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store user applications
                commit("SET_USER_APPLICATIONS", {
                    applications: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    deleteApplication({ commit, dispatch }, data) {
        axiosBackend
            .delete("/applications/" + data.appId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_APPLICATION_BY_ID", data.appId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("app_applicationSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing application state
     */
    updateUserApplicationState({ commit }, data) {
        axiosBackend
            .put(
                "/applications/" + data.id + "/deploy",
                {
                    isInProduction: data.isInProduction
                },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_APPLICATION", {
                    application: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("app_applicationSuccessfullyUpdated"),
                    type: "success"
                });

                // Creation OK => Route to applications page
                router.push({ name: "applications", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetApplicationsState ({ commit }) {
        commit('RESET_DEFAULT_APPLICATIONS_STATE');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
