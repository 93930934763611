import axiosBackend from "../../axios-backend";

const getDefaultState = () => {
    return {
        troubleshootingHeatmapData: null
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    troubleshootingHeatmapData(state) {
        return state.troubleshootingHeatmapData;
    }

};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    
    SET_TROUBLESHOOTING_HEATMAP_DATA(state, data) {
        state.troubleshootingHeatmapData = data;
    },

    RESET_DEFAULT_TROUBLESHOTINGHEATMAP_STATE(state) {
        Object.assign(state, getDefaultState())
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /*
     * Action used to get data to build trilateration heatmap
     */
    getTrilaterationHeatmap({ commit }, data) {
        var urlParam = "";
        if (data.gatewayId){
            urlParam = "?gatewayIds="+data.gatewayId
        }

        axiosBackend.get("/sites/" +data.siteId + "/health/heatmaps/trilateration/" + data.floorId + urlParam, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            commit("SET_TROUBLESHOOTING_HEATMAP_DATA", res.data.data);
        }).catch(error => console.log(error));

    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetTroubleshootingHeatmapsState ({ commit }) {
        commit('RESET_DEFAULT_TROUBLESHOTINGHEATMAP_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
