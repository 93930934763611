import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";
import moment from "moment";

/*
 * State: properties to store
 */
const state = {
    siteSubscription: null,
    hasSubscription: false
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    /*
     * Getter for userSites
     */
    siteSubscription(state) {
        return state.siteSubscription;
    },

    hasSubscription(state) {
        if (state.siteSubscription && !_.isEmpty(state.siteSubscription)) {
            return true;
        } else {
            return false;
        }
    },

    isSubscriptionTerminated(state) {
        if (state.siteSubscription) {
            var endDate = moment(state.siteSubscription.endDate);
            var now = moment();
            if (now.isAfter(endDate)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },

    getActiveLicense(state) {
        if (state.siteSubscription) {
            for (let license of state.siteSubscription.licenses) {
                if (license.status === "ACTIVE") {
                    return license;
                }
            }
        }
    },

    getWaitingForActivationAddonLicense(state) {
        if (state.siteSubscription) {
            for (let license of state.siteSubscription.licenses) {
                if (license.status === "WAITING_FOR_ACTIVATION" && license.type === "ADDON") {
                    return license;
                }
            }
        }
        return null;
    },

    getWaitingForActivationRenewalLicense(state) {
        if (state.siteSubscription) {
            for (let license of state.siteSubscription.licenses) {
                if (license.status === "WAITING_FOR_ACTIVATION" && license.type === "RENEWAL") {
                    return license;
                }
            }
        }
        return null;
    }

};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_SUBSCRIPTION(state, data) {
        state.siteSubscription = data.subscription;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /*
     * Action used to get subscription for a given siteId
     */
    getSiteSubscription({
        commit
    }, data) {
        axiosBackend
            .get("/sites/" + data.siteId + "/subscription?sync=" + data.sync, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store user sites
                commit("SET_SITE_SUBSCRIPTION", {
                    subscription: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to activate a subscription for a given siteId
     */
    activateSubscription({
        commit
    }, subscriptionData) {
        var data = {
            activationCode: subscriptionData.activationCode,
            subRef: subscriptionData.subRef
        };
        axiosBackend
            .post("/sites/" + subscriptionData.siteId + "/subscription/activate", data, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("subscription_successfullyActivated"),
                    type: "success"
                });

                // Subscription successfully activated => Route to site overview page
                router.push({
                    name: "siteOverview",
                    params: {
                        siteId: subscriptionData.siteId
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to create a trial subscription for a given siteId
     */
    createTrialSubscription({
        commit
    }, subscriptionData) {

        axiosBackend
            .post("/sites/" + subscriptionData.siteId + "/subscription/trial", {}, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("subscription_trialSuccessfullyActivated"),
                    type: "success"
                });

                // Subscription successfully activated => Route to site overview page
                router.push({
                    name: "siteOverview",
                    params: {
                        siteId: subscriptionData.siteId
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to activate addon for a Paid subscription
     */
    activateAddon({
        commit
    }, subscriptionData) {

        axiosBackend
            .post("/sites/" + subscriptionData.siteId + "/subscription/addon/activate", {}, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("subscription_addonSuccessfullyActivated"),
                    type: "success"
                });

                // Addon successfully activated => Route to site overview page
                router.push({
                    name: "siteOverview",
                    params: {
                        siteId: subscriptionData.siteId
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to activate renewal for a Paid subscription
     */
    activateRenewal({
        commit
    }, subscriptionData) {

        axiosBackend
            .post("/sites/" + subscriptionData.siteId + "/subscription/renewal/activate", {}, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("subscription_addonSuccessfullyActivated"),
                    type: "success"
                });

                // Addon successfully activated => Route to site overview page
                router.push({
                    name: "siteOverview",
                    params: {
                        siteId: subscriptionData.siteId
                    }
                });
            })
            .catch(error => console.log(error));
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};