<template>
    <div class="modal fade show" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_confirm") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="kt-margin-b-0" style=" color: #434349; font-weight: 400; ">{{ getDeleteModalTextContent }}</p>
                </div>
                <div class="modal-footer">
                    <button id="deleteModalCancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                    <button id="deleteModalConfirmButton" v-if="this.getActionName === 'REVOKE_USER_ACCESS_TO_SITE'" type="button" @click="deleteObject" class="btn btn-danger">{{ $t("common_revoke") }}</button>
                    <button id="deleteModalConfirmButton" v-else type="button" @click="deleteObject" class="btn btn-danger">{{ $t("common_delete") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {};
    },
    created: function() {
        console.log("Component(DeleteModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(DeleteModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(DeleteModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["getDeleteModalTextContent", "getActionName"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["deleteResource"]),
        deleteObject() {
            console.log("Component(DeleteModal)::deleteObject() - called");
            this.deleteResource();
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped></style>
