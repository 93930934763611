import axiosBackend from "../../axios-backend";

const getDefaultState = () => {
    return {
        contactEvents: null,
        assetHistories: null,
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    contactEvents(state) {
        return state.contactEvents;
    },

    assetHistories(state) {
        return state.assetHistories;
    },
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {

    SET_CONTACT_EVENTS(state, data) {
        state.contactEvents = data.contactEvents;
    },

    RESET_DEFAULT_CONTACT_EVENTS_STATE(state) {
        Object.assign(state, getDefaultState())
    },

    SET_ASSET_HISTORIES(state, data) {
        state.assetHistories = data.assetHistories;
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetContactEventsState ({ commit }) {
        commit('RESET_DEFAULT_CONTACT_EVENTS_STATE');
    },

    /**
     * Get all Personally Identifiable Information (PII) which can be selected for contact events
     * @param {*} param0
     * @param {*} payload
     */
    getAssetHistories({ commit }, payload) {

        axiosBackend.get("/sites/" + payload.siteId + "/piis", { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(res => {
                commit("SET_ASSET_HISTORIES", {
                    assetHistories: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    getContactEvents({ commit }, payload) {
        axiosBackend
            .post("/sites/" + payload.siteId + "/contacts", payload, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(res => {
                commit("SET_CONTACT_EVENTS", { contactEvents: res.data.data });
            })
            .catch(error => {
                console.log(error);
                commit("SET_CONTACT_EVENTS", { contactEvents: null });
            });

    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
