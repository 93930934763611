import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        guestsOfSite: [],
        pendingGuestsOfSite: [],
        currentGuest: null,
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for guest access of site
     */

    guestsOfSite(state) {
        return state.guestsOfSite;
    },

    currentGuest(state) {
        return state.currentGuest;
    },

    createAnotherGuest(state) {
        return state.createAnother;
    },

    siteGuestsByName(state) {
        let mapGuestNameToGuest = {};
        for (let i = 0; i < state.guestsOfSite.length; i++) {
            let guestName = state.guestsOfSite[i].name;
            mapGuestNameToGuest[guestName] = state.guestsOfSite[i];
        }
        return mapGuestNameToGuest;
    },

    pendingGuestsOfSite(state){
        return state.pendingGuestsOfSite;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_CURRENT_GUEST(state, guestData) {
        // 1. Store current guest
        state.currentGuest = guestData.guest;
        // 2. Update guests array
        if (guestData.guest) {
            // Find guest index
            let index = _.findIndex(state.guestsOfSite, { id: guestData.guest.id });
            if (index >= 0) {
                // Replace the guest in the array of guests
                state.guestsOfSite.splice(index, 1, guestData.guest);
            }
        }
    },

    SET_GUESTS_OF_SITE(state, data) {
        state.guestsOfSite = data.guests;
    },

    SET_PENDING_GUESTS_OF_SITE(state, data) {
        state.pendingGuestsOfSite = data.pendingGuests;
    },

    SET_CREATE_ANOTHER_GUEST(state, createAnother) {
        state.createAnother = createAnother;
    },

    REMOVE_GUEST_BY_ID(state, guestId) {
        // Find guest index
        let index = _.findIndex(state.guestsOfSite, { id: guestId });
        state.guestsOfSite.splice(index, 1);
    },

    RESET_DEFAULT_SITEGUESTS_STATE(state) {
        Object.assign(state, getDefaultState())
    },

    RESET_DEFAULT_PENDING_GUESTS_STATE(state) {
        Object.assign(state, getDefaultState())
    },
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherGuest({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_GUEST", createAnother);
    },

    /*
     * Action used to get the list of guests associated to a site
     */
    getGuestsOfSite({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/guests", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_GUESTS_OF_SITE", {
                    guests: res.data.data
                });
            })
            //.catch(error => console.log(error));
            .catch(error => console.log(error));
    },

    /**
     * Action used to get the list of pending guests associated to a site
     */
    getPendingGuestsOfSite( { commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/users/invitations?type=guest", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_PENDING_GUESTS_OF_SITE", {
                    pendingGuests: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get a site guest from a guestId
     */
    getGuestById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/guests/" + payload.guestId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Update application in store
                commit("SET_CURRENT_GUEST", {
                    guest: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to create a guest
     */
    createGuestOfSite({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_GUEST", payload.createAnother);
        delete payload.createAnother;
        axiosBackend
            .post("/sites/" + payload.siteId + "/guests", payload, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_newGuestSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to buildings list page
                router.push({ name: "listSiteGuests", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing guest
     */
    updateGuestOfSite({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/guests/" + payload.guestId, payload, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(res => {
                // Set guest Data in VueX Auth store
                commit("SET_CURRENT_GUEST", {
                    guest: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_guestSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to guests page
                router.push({ name: "listSiteGuests", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reinvite a user to site by sending an email again
     */
    reInviteToGuest({ commit }, payload) {
        axiosBackend
            .put(
                "/sites/" + payload.siteId + "/users/invitations/" + payload.invitationId + "/reinvite",
                {},
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_invitationSuccessfullySent"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to revoke a guest access
     */
    revokeGuestAccessToSite({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/guests/" + payload.guestId, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(() => {
                commit("REMOVE_GUEST_BY_ID", payload.guestId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_guestSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    deletePendingGuest({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/users/invitations/" + payload.invitationId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_pendingInvitationSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});

                dispatch("getPendingGuestsOfSite", payload.siteId);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetSiteGuestsState ({ commit }) {
        commit('RESET_DEFAULT_SITEGUESTS_STATE');
        
    },

    resetPendingGuestsState ({ commit }) {
        commit('RESET_DEFAULT_PENDING_GUESTS_STATE');
        
    },

};

export default {
    state,
    getters,
    mutations,
    actions
};
