<template>
    <div class="kt-header__topbar-item dropdown">
        <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="30px,0px" aria-expanded="true">
            <span class="kt-header__topbar-icon" :class="newAlarms && newAlarms.length > 0 ? 'kt-pulse kt-pulse--brand' : ''">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path d="M17,12 L18.5,12 C19.3284271,12 20,12.6715729 20,13.5 C20,14.3284271 19.3284271,15 18.5,15 L5.5,15 C4.67157288,15 4,14.3284271 4,13.5 C4,12.6715729 4.67157288,12 5.5,12 L7,12 L7.5582739,6.97553494 C7.80974924,4.71225688 9.72279394,3 12,3 C14.2772061,3 16.1902508,4.71225688 16.4417261,6.97553494 L17,12 Z" fill="#000000" />
                        <rect fill="#000000" opacity="0.3" x="10" y="16" width="4" height="4" rx="2" />
                    </g>
                </svg>
                <span class="kt-pulse__ring"></span>
                <span v-if="newAlarms && newAlarms.length > 0" class="kt-badge kt-badge--outline kt-badge--danger" :class="isFirefox ? 'badgeFirefox' : ''" style="position: absolute;margin-top: 13px;margin-left: -13px;font-weight: 500;width: 15px;height: 15px;">{{ newAlarms.length > 8 ? "9+" : newAlarms.length }}</span>
            </span>
        </div>
        <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-246px, 65px, 0px);" x-placement="bottom-end">
            <form>
                <!--begin: Head -->
                <div class="kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b backgroundAlarm">
                    <h3 class="kt-head__title">
                        {{ $t("alarm_alarms") }}
                        &nbsp;
                        <span v-if="newAlarms.length > 0" class="btn btn-success btn-sm btn-bold btn-font-md">{{ newAlarms.length }} {{ newAlarms.length == 1 ? $t("alarm_new") : $t("alarm_news") }}</span>
                    </h3>

                    <ul class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#topbar_notifications_newAlarms" role="tab" aria-selected="false">{{ $t("geo_notif_unacknowledged") }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#topbar_notifications_historyAlarms" role="tab" aria-selected="true">{{ $t("alarm_history") }}</a>
                        </li>
                    </ul>
                </div>
                <!--end: Head -->

                <div class="tab-content">
                    <div class="tab-pane show active" id="topbar_notifications_newAlarms" role="tabpanel">
                        <div class="kt-notification alarm-notification kt-scroll ps ps--active-y" data-scroll="true" data-height="300" data-mobile-height="200">
                            <div v-if="newAlarms && newAlarms.length == 0" style=" margin: 45px auto; text-align: center; ">
                                <img src="/assets/nodata/empty_notifications.svg" style="width: 125px;" />
                                <p style="margin-top:20px">{{ $t("alarm_emptyAlarmNew") }}</p>
                            </div>

                            <a v-for="alarm in newAlarms" :key="alarm.id" href="#" class="kt-notification__item alarmItem">
                                <div class="kt-notification__item-details">
                                    <div class="kt-notification__item-title">{{ getAlarmText(alarm) }}</div>
                                    <div class="kt-notification__item-time">{{ $t("alarm_siteName", {siteName: alarm.siteName}) }}</div>
                                    <div class="kt-notification__item-time" style="color:#5867dd;">{{ moment(alarm.issuedAt).fromNow() }}</div>
                                </div>
                                <span data-toggle="kt-tooltip" data-placement="top" @click="onAcknowledgeOneAlarm(alarm.id)" :data-original-title="$t('common_acquit')">
                                    <i class="la la-check"></i>
                                </span>
                            </a>
                        </div>

                        <div v-if="newAlarms && newAlarms.length > 0">
                            <button @click="onAcknowledgeAllAlarms()" type="button" class="btn btn-primary btn-sm alarmActionBtn">{{ $t("alarm_acknowledgeAllDisplayed") }}</button>
                        </div>
                    </div>

                    <div class="tab-pane" id="topbar_notifications_historyAlarms" role="tabpanel">
                        <div class="kt-notification alarm-notification-history kt-scroll ps" data-scroll="true" data-height="300" data-mobile-height="200">
                            <div v-if="historyAlarms && historyAlarms.length == 0" style=" margin: 45px auto; text-align: center; ">
                                <img src="/assets/nodata/empty_notifications.svg" style="width: 125px;" />
                                <p style="margin-top:20px">{{ $t("alarm_emptyAlarmHistory") }}</p>
                            </div>

                            <a v-for="alarm in historyAlarms" :key="alarm.id" href="#" class="kt-notification__item">
                                <div class="kt-notification__item-details">
                                    <div class="kt-notification__item-title">{{  getAlarmText(alarm) }}</div>
                                    <div class="kt-notification__item-time">{{ $t("alarm_siteName", {siteName: alarm.siteName}) }}</div>
                                    <div class="kt-notification__item-time" style="color:#5867dd;">{{ moment(alarm.issuedAt).fromNow() }}</div>
                                </div>
                                <span data-toggle="kt-tooltip" data-placement="top" @click="onDeleteOneAlarm(alarm.id)" :data-original-title="$t('common_delete')">
                                    <i style="color:#f9397a;" class="la la-close"></i>
                                </span>
                            </a>
                        </div>

                        <div v-if="historyAlarms && historyAlarms.length > 0">
                            <button @click="onDeleteHistoryAlarms()" type="button" class="btn btn-danger btn-sm alarmActionBtnHistory">{{ $t("alarm_deleteAllDisplayed") }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import i18n from "../../i18n";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            localAlarms: [],
            moment: moment,
            commonVueHelper: commonVueHelper,
            isFirefox: navigator.userAgent.indexOf("Firefox") != -1
        };
    },
    created: function() {
        console.log("Component(alarmdropdown)::created() - called");
        this.getAllUserSitesAlarms({ limit: 100, offset: 0 });
    },
    mounted() {
        console.log("Component(alarmdropdown)::mounted() - called");
        KTApp.init(); // Move from userSitesAlarms
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(alarmdropdown)::destroyed() - called");
        this.resetAlarmsState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        userSitesAlarms(alarms) {
            console.log("Component(alarmdropdown)::watch(userSitesAlarms) - called with ", alarms);
            if (this.userSitesAlarms && this.userSitesAlarms.length > 0) {
                this.localAlarms = this.userSitesAlarms;
            } else {
                this.localAlarms = [];
            }
        },

        user(user) {
            console.log("Component(alarmdropdown)::watch(user) - called with ", user);
            if (user) {
                // In case of language change, reload alarms to force translation of id kt-notification__item-time
                this.getAllUserSitesAlarms({limit: 50, offset: 0});
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["userSitesAlarms", "user"]),

        newAlarms() {
            console.log("Component(alarmdropdown)::computed(newAlarms) - called");
            var alarms = _.orderBy(
                this.localAlarms.filter(function(alarm) {
                    return !alarm.isAcknowledged;
                }),
                "issuedAt",
                ["desc"]
            );
            if (alarms.length == 0) {
                $("#topbar_notifications_newAlarms .ps__rail-x").remove();
                $("#topbar_notifications_newAlarms .ps__rail-y").remove();
            }
            $(".tooltip").tooltip("hide");
            $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
            console.log("Component(alarmdropdown)::computed(newAlarms) - result", alarms);
            return alarms;
        },

        historyAlarms() {
            console.log("Component(alarmdropdown)::computed(historyAlarms) - called");
            var alarms = _.orderBy(
                this.localAlarms.filter(function(alarm) {
                    return alarm.isAcknowledged;
                }),
                "issuedAt",
                ["desc"]
            );
            if (alarms.length == 0) {
                $("#topbar_notifications_historyAlarms .ps__rail-x").remove();
                $("#topbar_notifications_historyAlarms .ps__rail-y").remove();
            }
            $(".tooltip").tooltip("hide");
            $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
            console.log("Component(alarmdropdown)::computed(historyAlarms) - result", alarms);
            return alarms;
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getAllUserSitesAlarms",
            "acknowledgeAll",
            "deleteHistoryAlarms",
            "acknowledgeAlarm",
            "deleteOneAlarm",
            "resetAlarmsState"
        ]),

        onAcknowledgeAllAlarms() {
            if (this.$route.params.siteId) {
                this.acknowledgeAll({ siteId: this.$route.params.siteId, limit: 100, offset: 0 });
            } else {
                this.acknowledgeAll({ limit: 100, offset: 0 });
            }
        },

        onDeleteHistoryAlarms() {
            if (this.$route.params.siteId) {
                this.deleteHistoryAlarms({ siteId: this.$route.params.siteId, limit: 100, offset: 0 });
            } else {
                this.deleteHistoryAlarms({ limit: 100, offset: 0 });
            }
        },

        onAcknowledgeOneAlarm(alarmId) {
            if (this.$route.params.siteId) {
                this.acknowledgeAlarm({ alarmId: alarmId, siteId: this.$route.params.siteId, limit: 100, offset: 0 });
            } else {
                this.acknowledgeAlarm({ alarmId: alarmId, limit: 100, offset: 0 });
            }
        },

        onDeleteOneAlarm(alarmId) {
            if (this.$route.params.siteId) {
                this.deleteOneAlarm({ alarmId: alarmId, siteId: this.$route.params.siteId, limit: 100, offset: 0 });
            } else {
                this.deleteOneAlarm({ alarmId: alarmId, limit: 100, offset: 0 });
            }
        },

        getAlarmText(alarm) {
            let alarmText = "Unknown alarm";
            switch (alarm.value) {
                case 'IN':
                case 'OUT':
                    alarmText = alarm.assetName + " " + (alarm.value === "IN" ? i18n.t("alarm_in") : i18n.t("alarm_out")) + " " + alarm.geofenceName;
                    break;
                case 'THRESHOLD':
                    alarmText = i18n.t("alarm_threshold", { geofenceName: alarm.geofenceName, threshold: alarm.geofenceThreshold });
                    break;
                case 'PRESS':
                    const translatedPushEventType = ( alarm.pushEventTypeName && alarm.isDefaultPushEventType ? i18n.t("alert_pushEventType_"+alarm.pushEventTypeName) : alarm.pushEventTypeName );
                    alarmText = i18n.t("alarm_pushButtonDropdownFullDescription", { assetName: alarm.assetName, eventTypeName: translatedPushEventType });
                    break;
            }
            return alarmText;
        }

    }
};
</script>

<style>
.backgroundAlarm {
    background-image: url("/assets/metronic/media/misc/bg-1.jpg");
}
.alarmItem:first-child {
    border-top: 1px solid #f7f8fa !important;
}
.alarmActionBtn {
    display: inline-block;
    line-height: 1.4rem;
    width: 100%;
    border-radius: 0px !important;
}
.alarmActionBtnHistory {
    display: inline-block;
    line-height: 1.4rem;
    width: 100%;
    border-radius: 0px !important;
}
.alarm-notification .kt-notification__item:after {
    content: none;
}
.alarm-notification-history .kt-notification__item:after {
    content: none;
}
.badgeFirefox {
    margin-top: 25px !important;
    margin-left: -25px !important;
}
</style>
