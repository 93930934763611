import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        assetUnits: [],
    }
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    /**
     * Return the site type
     */
    assetUnits(state) {
        return state.assetUnits;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {

    SET_ASSET_UNITS(state, data) {
        state.assetUnits = data.assetUnits;
    },

    SET_ASSET_UNIT(state, data) {
        state.assetUnits.forEach(unit => {
            delete unit.isNewCreated;
        });
        state.assetUnits.push(data.unit);
    },

    UPDATE_ASSET_UNIT(state, data) {
        let index = _.findIndex(state.assetUnits, { isDefault: false, id: data.id });
        if (index !== -1) {
            state.assetUnits.splice(index, 1, {
                id: data.id,
                isDefault: false,
                name: data.name
            });
        }
    },

    REMOVE_ASSET_UNIT_BY_ID(state, siteId) {
        let index = _.findIndex(state.assetUnits, { isDefault: false, id: siteId });
        state.assetUnits.splice(index, 1);
    },

    RESET_DEFAULT_ASSET_UNIT_STATE(state) {
        Object.assign(state, getDefaultState())
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /**
     * Action used to create custom asset unit
     */
    createNewAssetUnit({commit}, params) {
        axiosBackend
            .post("sites/" + params.siteId + "/assetunits", {
                name: params.name
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            }).then(res => {
                // Store created user site in userSites
                commit("SET_ASSET_UNIT", {
                    unit: {
                        ...res.data.data,
                        isNewCreated: true
                    }
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_newAssetUnitSuccessfullyCreated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /**
     * Action used to edit custom asset unit
     */
    editAssetUnitById({commit}, params) {
        axiosBackend
            .put("sites/" + params.siteId + "/assetunits/" + params.id, {
                name: params.name
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            }).then(res => {
                // Store created user site in userSites
                commit("UPDATE_ASSET_UNIT", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_assetUnitSuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /**
     * Action used to delete custom asset unit
     */
    deleteAssetUnitById({commit}, params) {
        axiosBackend
            .delete("sites/" + params.siteId + "/assetunits/" + params.unitId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            }).then(res => {
                // Store created user site in userSites
                commit("REMOVE_ASSET_UNIT_BY_ID", params.unitId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_assetUnitSuccessfullyDeleted"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /**
     * Action used to get asset unit of site
     */
    getAllAssetUnitsOfSite({commit}, siteId) {
        axiosBackend.get("sites/" + siteId + "/assetunits", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // Store created site type in userSites
            commit("SET_ASSET_UNITS", {
                assetUnits: res.data.data
            });
        }).catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetAssetUnitsState ({ commit }) {
        commit('RESET_DEFAULT_ASSET_UNIT_STATE');
    },

};

export default {
    state,
    getters,
    mutations,
    actions
};