<template>
    <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
        x-placement="bottom-end"
        style="position: absolute; transform: translate3d(1255px, 64px, 0px); top: 0px; left: 0px; will-change: transform;"
    >
        <!--begin: Head -->
        <div
            class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style="background-image: url(/assets/metronic/media/misc/bg-1.jpg)"
        >
            <div class="kt-user-card__avatar">
                <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
                <span
                    v-if="user !== null"
                    class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
                >{{ user.firstName.charAt(0) }} {{ user.lastName.charAt(0) }}</span>
            </div>
            <div
                v-if="user !== null"
                class="kt-user-card__name"
            >{{ user.firstName }} {{ user.lastName }}</div>
        </div>
        <!--end: Head -->

        <!--begin: Navigation -->
        <div class="kt-notification">
            <router-link :to="{ name: 'profile' }" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-calendar-3 kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div
                        class="kt-notification__item-title kt-font-bold"
                        id="userProfile"
                    >{{ $t("user_userProfile") }}</div>
                    <div class="kt-notification__item-time">{{ $t("user_profileSubTitle") }}</div>
                </div>
            </router-link>

            <router-link :to="{ name: 'applications' }" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        class="kt-svg-icon"
                    >
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path
                                d="M17.2718029,8.68536757 C16.8932864,8.28319382 16.9124644,7.65031935 17.3146382,7.27180288 C17.7168119,6.89328641 18.3496864,6.91246442 18.7282029,7.31463817 L22.7282029,11.5646382 C23.0906029,11.9496882 23.0906029,12.5503176 22.7282029,12.9353676 L18.7282029,17.1853676 C18.3496864,17.5875413 17.7168119,17.6067193 17.3146382,17.2282029 C16.9124644,16.8496864 16.8932864,16.2168119 17.2718029,15.8146382 L20.6267538,12.2500029 L17.2718029,8.68536757 Z M6.72819712,8.6853647 L3.37324625,12.25 L6.72819712,15.8146353 C7.10671359,16.2168091 7.08753558,16.8496835 6.68536183,17.2282 C6.28318808,17.6067165 5.65031361,17.5875384 5.27179713,17.1853647 L1.27179713,12.9353647 C0.909397125,12.5503147 0.909397125,11.9496853 1.27179713,11.5646353 L5.27179713,7.3146353 C5.65031361,6.91246155 6.28318808,6.89328354 6.68536183,7.27180001 C7.08753558,7.65031648 7.10671359,8.28319095 6.72819712,8.6853647 Z"
                                fill="#000000"
                                fill-rule="nonzero"
                            />
                            <rect
                                fill="#000000"
                                opacity="0.3"
                                transform="translate(12.000000, 12.000000) rotate(-345.000000) translate(-12.000000, -12.000000) "
                                x="11"
                                y="4"
                                width="2"
                                height="16"
                                rx="1"
                            />
                        </g>
                    </svg>
                </div>
                <div class="kt-notification__item-details">
                    <div
                        class="kt-notification__item-title kt-font-bold"
                        id="userApplications"
                    >{{ $t("app_applications") }}</div>
                    <div class="kt-notification__item-time">{{ $t("app_applicationsSubTitle") }}</div>
                </div>
            </router-link>

            <router-link :to="{ name: 'faq' }" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-chat-1 kt-font-warning"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div
                        class="kt-notification__item-title kt-font-bold"
                        id="faqLink"
                    >{{ $t("main_faq") }}</div>
                    <div class="kt-notification__item-time">{{ $t("main_faqSubTitle") }}</div>
                </div>
            </router-link>

            <router-link v-if="this.releasedFor == 'ALE'" :to="{ name: 'helpvideos' }" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="la la-youtube-play kt-font-danger" style="font-size: 1.7rem;margin-left: -2px;"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div
                        class="kt-notification__item-title kt-font-bold"
                        id="videosLink"
                    >{{ $t("videos_videos") }}</div>
                    <div class="kt-notification__item-time">{{ $t("videos_subTitle") }}</div>
                </div>
            </router-link>

            <div class="kt-notification__custom">
                <a
                    @click="signout"
                    class="btn btn-label-brand btn-sm btn-bold"
                    id="logoutButton"
                >{{ $t("user_logout") }}</a>
                <a
                    v-if="this.releasedFor == 'ALE'"
                    download href="/doc/omniaccess-stellar-asset-tracking-user-guide-1.0-en.pdf"
                    class="btn btn-label-brand btn-sm btn-bold kt-margin-l-5"
                    id="userGuideButton">
                    <i class="fa fa-file-pdf kt-margin-t-1 kt-margin-r-5"></i>
                    {{ $t("user_userGuideDownload") }}</a>
                <a
                    v-else
                    download href="/doc/han/Wireless-stellar-asset-tracking-user-guide-1.0.3-en.pdf"
                    class="btn btn-label-brand btn-sm btn-bold kt-margin-l-5"
                    id="userGuideButton">
                    <i class="fa fa-file-pdf kt-margin-t-1 kt-margin-r-5"></i>
                    {{ $t("user_userGuideDownload") }}</a>
            </div>
        </div>
        <!--end: Navigation -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR
        };
    },
    computed: {
        ...mapGetters(["user"])
    },
    methods: {
        ...mapActions(["logout"]),
        signout() {
            console.log("Component(UserDropDown)::logout() - called");
            this.logout();
        }
    }
};
</script>
<style scoped>
.userDropDown {
    z-index: 101;
}
</style>
