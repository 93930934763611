<template>
    <div class="modal fade show" id="noSearchResultsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_noAssetsFound") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="fontW500">{{ $t("common_noResultsMain") }}</p>
                    <div class="alert alert-secondary marginBZero" role="alert">
                        <div class="alert-icon"><i class="flaticon-light kt-font-brand"></i></div>
                        <div class="alert-text">{{ $t("common_noResultsSub") }}</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeSearchResultButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {};
    },
    created: function() {
        console.log("Component(noSearchResultsModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(noSearchResultsModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(noSearchResultsModal)::destroyed() - called");
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([])
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.marginBZero {
    margin-bottom: 0;
}
.fontW500 {
    font-weight: 500;
}
</style>
