<template>
    <div class="modal fade show" id="browserDetectionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("main_browserNotCompatibleTitle") }}</h5>
                </div>
                <div class="modal-body">
                    <div class="row centeredBrowser">
                        <div class="col-md-12">
                            <h4 id="browserNotCompatibleModal_ReasonStopped" class="textReasonStopped">{{ getMsgNotCompatible() }}</h4>
                            <h4 id="browserNotCompatibleModal_Recommendation" class="textRecommendation">{{ $t("main_browserRecommandation") }}</h4>
                            <div class="row">
                                <div v-for="(browserType, index) in Object.keys(browsersInfoList)" :key="index" v-bind:class="{ 'col-md-3': isBrowserSupported(browserType) }">
                                    <div v-if="isBrowserSupported(browserType)">
                                        <a :href="browsersInfoList[browserType].downloadUrl" rel="noopener" target="_blank">
                                            <img class="iconSize" :src="'/assets/browsers/' + browserType + '.png'" alt="pic" />
                                        </a>
                                        <p class="kt-margin-t-10 kt-margin-b-0">{{ browsersInfoList[browserType].companyName }}</p>
                                        <p class="kt-margin-t-0 kt-margin-b-0">{{ browsersInfoList[browserType].browserName }}</p>
                                        <p class="kt-margin-t-10">{{ getBrowserVersion(browserType) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import browserDetectionHelper from "../../helpers/browserDetectionHelper";

export default {
    name: "browserdetectionmodal",
    data() {
        console.log("Component(browserDetectionModal)::data() - called");
        return {
            browsersInfoList: browserDetectionHelper.getBrowsersInfoList()
        };
    },
    created: function() {
        console.log("Component(browserDetectionModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(browserDetectionModal)::mounted() - Init metronic layout");
    },
    destroyed: function() {
        console.log("Component(browserDetectionModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {},
    methods: {
        getBrowserVersion(browserType) {
            return browserDetectionHelper.getBrowserMinimumVersionString(browserType);
        },
        isBrowserSupported(browserType) {
            return browserDetectionHelper.isBrowserSupported(browserType);
        },
        getMsgNotCompatible() {
            return browserDetectionHelper.getMsgNotCompatible();
        }
    }
};
</script>

<style scoped>
.centeredBrowser {
    text-align: center;
}

.textReasonStopped {
    font-size: 14px;
    color: #8e44ad;
    font-weight: 500;
}

.textRecommendation {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #8e44ad;
    font-weight: 500;
}

.iconSize {
    width: 50px;
    height: 50px;
    vertical-align: middle;
}
</style>
