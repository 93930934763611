module.exports = {
    list: [
        // -> UNIT_EMERGENCY: "Emergency unit"
        "UNIT_EMERGENCY",
        // -> UNIT_IMAGERY:  "Medical imagery / Radiology / Diagnostic Imaging",
        "UNIT_IMAGERY",
        // -> UNIT_AMBULATORY: /"Ambulatory unit"
        "UNIT_AMBULATORY_OPROOM",
        "UNIT_AMBULATORY_SURGERY",
        // -> UNIT_MATERNITY: "Maternity unit"
        "UNIT_MATERNITY_OBSTRETICS",
        "UNIT_MATERNITY_GYNECOLOGY",
        // -> UNIT_NEONATOLOGY:
        "UNIT_NEONATOLOGY",
        // -> UNIT_PEDIATRICS: "Pediatrics unit"
        "UNIT_PEDIATRICS",
        // -> UNIT_DAYCARE: "Hospital Day Care unit"
        "UNIT_DAYCARE",
        // -> UNIT_SURGERY: "Medicine Surgery unit"
        "UNIT_SURGERY_GENERAL",
        "UNIT_SURGERY_ONCOLOGY",
        "UNIT_SURGERY_ORTHOPEDICS",
        "UNIT_SURGERY_TRAUMATOLOGY",
        "UNIT_SURGERY_PNEUMOLOGY",
        "UNIT_SURGERY_CARDIOLOGY",
        "UNIT_SURGERY_NEUROLOGY",
        "UNIT_SURGERY_DIALYSIS",
        "UNIT_SURGERY_HEMODIALYSIS",
        "UNIT_SURGERY_SPECIALTY",
        "UNIT_SURGERY_UROLOGY",
        "UNIT_SURGERY_SPORTS",
        "UNIT_SURGERY_PSYCHOLOGY",
        "UNIT_SURGERY_PSYCHIATRY",
        "UNIT_SURGERY_STOMATOLOGY",
        "UNIT_SURGERY_GASTROENTEROLOGY",
        "UNIT_SURGERY_NEPHROLOGY",
        "UNIT_SURGERY_VISCERAL",
        // -> UNIT_INTENSIVECARE: "Intensive Care Unit"
        "UNIT_INTENSIVECARE",
        // -> UNIT_RESUSCITATION: "Resuscitation Unit"
        "UNIT_RESUSCITATION",
        // -> UNIT_LONGTERM: "Long Term Care"
        "UNIT_LONGTERM_GERIATRICS",
        "UNIT_LONGTERM_ALZHEIMER",
        "UNIT_LONGTERM_PALLIATIVE",
        "UNIT_LONGTERM_REHABILITATION",
        "UNIT_TRAIN_STATION",
        "UNIT_TECHNICAL_SERVICE",
        "UNIT_BIOMEDICAL",
        "UNIT_STERILIZATION",
        // -> UNIT_DIAGNOSTIC
        "UNIT_DIAGNOSTIC"
    ]
};
