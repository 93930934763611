import axiosBackend from "../../axios-backend";

const getDefaultState = () => {
    return {
        applicationNotificationTypes: null,
        currentNotificationType: null
    };
}

/**
 * State: properties to store
 */
const state = getDefaultState();

/**
 * Getters: Getters of properties defined in state
 */
const getters = {
    applicationNotificationTypes(state) {
        return state.applicationNotificationTypes;
    }
};

/**
 * Mutations (Setters) : Setters of properties defined in state
 */
const mutations = {
    SET_APPLICATION_NOTIFICATION_TYPES(state, data) {
        state.applicationNotificationTypes = data.notificationTypes;
    },

    RESET_DEFAULT_NOTIFICATIONTYPES_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/**
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /**
     * Action used to get the list of site geonotifications
     */
    getApplicationNotificationTypes({
        commit
    }) {
        axiosBackend
            .get("/notificationtypes", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store site geonotifications in userNotifications
                commit("SET_APPLICATION_NOTIFICATION_TYPES", {
                    notificationTypes: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetNotificationTypesState ({ commit }) {
        commit('RESET_DEFAULT_NOTIFICATIONTYPES_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};