const getDefaultState = () => {
    return {
        showSiteTypeModal: false,
        hideSiteTypeModal: false,
        actionSiteType: null,
        siteType: null,
    };
};

/*
 * State: properties to store
 */
const state = getDefaultState();

const getters = {
    showSiteTypeModal(state) {
        return state.showSiteTypeModal;
    },

    hideSiteTypeModal(state) {
        return state.hideSiteTypeModal;
    },

    actionSiteType(state) {
        return state.actionSiteType;
    },

    siteType(state) {
        return state.siteType;
    }

};

const mutations = {

    SHOW_SITE_TYPE_MODAL(state, data) {
        state.showSiteTypeModal = true;
        state.actionSiteType = data.type;
        state.siteType = data.data
    },

    HIDE_SITE_TYPE_MODAL(state) {
        state.hideSiteTypeModal = true;
        state.actionSiteType = null;
    },

    RESET_SHOW_SITE_TYPE_MODAL(state) {
        state.showSiteTypeModal = false;
    },

    RESET_HIDE_SITE_TYPE_MODAL(state) {
        state.hideSiteTypeModal = false;
    },

    RESET_SITE_TYPE_MODAL(state) {
        Object.assign(state, getDefaultState())
    }
};

const actions = {

    showSiteTypeModal({ commit }, data) {
        commit("SHOW_SITE_TYPE_MODAL", data);
    },

    hideSiteTypeModal({ commit }) {
        commit("HIDE_SITE_TYPE_MODAL", {});
    },

    resetShowSiteTypeModal({ commit }) {
        commit("RESET_SHOW_SITE_TYPE_MODAL", {});
    },

    resetHideSiteTypeModal({ commit }) {
        commit("RESET_HIDE_SITE_TYPE_MODAL", {});
    },

    resetSiteTypeModal({commit}) {
        commit("RESET_SITE_TYPE_MODAL", {});
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};
