import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        userSites: [],
        currentSite: null,
        createAnother: false,
        showFingerprintInProgressModal: false,
        showSiteGatewaysEmissionModal: false,
        siteGatewaysWhichEmit: [],
        latestFingerprintStatus: "",
        latestFingerprintStartDate: null,
        showNeuralNetworkInProgressModal: false,
        latestNeuralNetworkStatus: "",
        latestNeuralNetworkStartDate: null,
        IQMServerIsVerify: false
    }
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    /*
    * Getter for latestFingerprintStatus
    */
    latestFingerprintStatus(state) {
        return state.latestFingerprintStatus;
    },

    /*
    * Getter for latestFingerprintStartDate
    */
    latestFingerprintStartDate(state) {
        return state.latestFingerprintStartDate;
    },

    /*
    * Getter for showFingerprintInProgressModal
    */
    showFingerprintInProgressModal(state) {
        return state.showFingerprintInProgressModal;
    },

    /*
    * Getter for latestNeuralNetworkStatus
    */
    latestNeuralNetworkStatus(state) {
        return state.latestNeuralNetworkStatus;
    },

    /*
    * Getter for latestNeuralNetworkStartDate
    */
    latestNeuralNetworkStartDate(state) {
        return state.latestNeuralNetworkStartDate;
    },

    /*
    * Getter for showNeuralNetworkInProgressModal
    */
    showNeuralNetworkInProgressModal(state) {
        return state.showNeuralNetworkInProgressModal;
    },

    /*
    * Getter for showSiteGatewaysEmissionModal
    */
    showSiteGatewaysEmissionModal(state) {
        return state.showSiteGatewaysEmissionModal;
    },

    /**
     * Getter for siteGatewaysWhichEmit
     */
    siteGatewaysWhichEmit(state) {
        return state.siteGatewaysWhichEmit;
    },

    /*
    * Getter for userSites
    */
    userSites(state) {
        return state.userSites;
    },

    currentSite(state) {
        return state.currentSite;
    },

    /*
   * Return the number of user sites
   */
    numberOfUserSites(state) {
        return state.userSites.length;
    },

    /*
    * Return the number of user sites in Production
    */
    numberOfUserSitesInProduction(state) {
        return _.filter(state.userSites, function(o) {
            return o.isInProduction;
        }).length;
    },

    createAnotherSite(state) {
        return state.createAnother;
    },

    /**
     * Return the state of IQMServer verification
     */
    IQMServerIsVerify(state) {
        return state.IQMServerIsVerify;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_USER_SITES(state, userData) {
        state.userSites = userData.sites;
    },

    SET_USER_SITE(state, userData) {
        state.userSites.push(userData.sites);
    },

    SET_CURRENT_SITE(state, siteData) {
        // Set the currentSite
        state.currentSite = siteData.currentSite;
        // Find site index
        let index = _.findIndex(state.userSites, {
            id: siteData.currentSite.id
        });
        if (index >= 0) {
            // Replace the site in the array of sites
            state.userSites.splice(index, 1, siteData.currentSite);
        } else {
            state.userSites.push(siteData.currentSite);
        }
    },

    SET_CURRENT_SITE_PRODMODE(state, siteData) {
        // Set the currentSite
        state.currentSite.isInProduction = siteData.currentSite.isInProduction;
        // Find site index
        let index = _.findIndex(state.userSites, {
            id: siteData.currentSite.id
        });
        if (index >= 0) {
            // Replace the site in the array of sites
            state.userSites.splice(index, 1, siteData.currentSite);
        } else {
            // Should not occur....
            state.userSites.push(siteData.currentSite);
        }
    },

    REMOVE_SITE_BY_ID(state, siteId) {
        // Find site index
        let index = _.findIndex(state.userSites, {
            id: siteId
        });
        state.userSites.splice(index, 1);
    },

    SET_CREATE_ANOTHER_SITE(state, createAnother) {
        state.createAnother = createAnother;
    },

    SHOW_FINGERPRINT_IN_PROGRESS_MODAL(state, data) {
        state.showFingerprintInProgressModal = true;
        if (data && data.startDate) {
            state.latestFingerprintStartDate = data.startDate;
        }
    },

    SET_FINGERPRINT_STATUS(state, data) {
        state.latestFingerprintStatus = data.status;
        state.latestFingerprintStartDate = data.ts;
    },

    SHOW_NEURAL_NETWORK_IN_PROGRESS_MODAL(state, data) {
        state.showNeuralNetworkInProgressModal = true;
        if (data && data.startDate) {
            state.latestNeuralNetworkStartDate = data.startDate;
        }
    },

    SET_NEURAL_NETWORK_STATUS(state, data) {
        state.latestNeuralNetworkStatus = data.status;
        state.latestNeuralNetworkStartDate = data.ts;
    },


    SET_SITE_GATEWAYS_EMISSION(state, data) {
        state.showSiteGatewaysEmissionModal = true;
        state.siteGatewaysWhichEmit = data.gateways;
    },

    SET_IQM_SERVER_VERIFY_STATE(state, value) {
        state.IQMServerIsVerify = value;
    },

    RESET_SITE_GATEWAYS_EMISSION(state) {
        state.showSiteGatewaysEmissionModal = false;
        state.siteGatewaysWhichEmit = [];
    },

    RESET_DEFAULT_SITES_STATE(state) {
        Object.assign(state, getDefaultState())
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherSite({
        commit
    }, createAnother) {
        commit("SET_CREATE_ANOTHER_SITE", createAnother);
    },

    /*
     * Action used to get the list of user sites
     */
    getUserSites({
        commit
    }, data) {
        var urlParam = '';
        if (data.hasOwnProperty("includeImage")){
            urlParam = '?includeImage='+data.includeImage;
        }
        axiosBackend
            .get("/sites" + urlParam, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_USER_SITES", {
                    sites: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get a user site with id iteId
     */
    getSiteById({
        commit
    }, siteId) {
        axiosBackend
            .get("/sites/" + siteId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store user sites
                commit("SET_CURRENT_SITE", {
                    currentSite: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the fingerprint status for a given site
     */
    getFingerprintStatusForSite({
        commit, state
    }, siteId) {

        state.latestFingerprintStartDate = null;

        axiosBackend
            .get("/sites/" + siteId + "/fingerprint/status", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                hideSpinner: true
            })
            .then(res => {
                // Store user sites
                commit("SET_FINGERPRINT_STATUS", {
                    status: res.data.data.value,
                    ts: res.data.data.ts
                });
            })
            .catch(error => console.log(error));
    },


    /*
     * Action used to get the fingerprint status for a given site
     */
    getNeuralNetworkStatusForSite({
        commit, state
    }, siteId) {

        state.latestNeuralNetworkStartDate = null;

        axiosBackend
            .get("/sites/" + siteId + "/neuralnetwork/status", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                hideSpinner: true
            })
            .then(res => {
                // Store user sites
                commit("SET_NEURAL_NETWORK_STATUS", {
                    status: res.data.data.value,
                    ts: res.data.data.ts
                });
            })
            .catch(error => console.log(error));
    },


    /*
     * Action used to create a user site
     */
    createUserSite({
        commit
    }, siteData) {
        commit("SET_CREATE_ANOTHER_SITE", siteData.createAnother);
        delete siteData.createAnother;
        axiosBackend
            .post("/sites", siteData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_USER_SITE", {
                    sites: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("site_newSiteSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to login page
                router.push({
                    name: "home",
                    params: {
                        fromAction: "createAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing site
     */
    updateSite({
        commit
    }, data) {
        axiosBackend
            .put("/sites/" + data.id, data, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_SITE", {
                    currentSite: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("site_siteSuccessfullyUpdated"),
                    type: "success"
                });

                // Creation OK => Route to applications page
                router.push({
                    name: "siteOverview",
                    params: {
                        siteId: res.data.data.id,
                        fromAction: "updateAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing site
     */
    updateSiteProductionMode({ commit, dispatch }, payload) {
        axiosBackend
            .put("/sites/" + payload.id + "/productionmode", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_SITE_PRODMODE", {
                    currentSite: res.data.data
                });

                // Display notification
                if (res.data.data.isInProduction) {
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("site_siteSetInProduction"),
                        type: "success"
                    });
                } else {
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("site_siteUnsetFromProduction"),
                        type: "success"
                    });
                }
                if (payload.metadata && payload.metadata.engineMode) {
                    if (payload.nextAction === "EDIT_SITE") {
                        dispatch("updateSite", payload.metadata);
                    }
                }
            })
            .catch(error => console.log(error));
    },

    cancelSiteProductionMode({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.id + "/productionmode/cancel", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Display notification
                if (res.data.data.isInProduction === false) {
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("site_siteUnsetFromProduction"),
                        type: "success"
                    });
                }

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete an existing site
     */
    deleteSite({
        commit,
        dispatch
    }, data) {
        axiosBackend
            .delete("/sites/" + data.siteId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(() => {
                commit("REMOVE_SITE_BY_ID", data.siteId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("site_siteSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});

                // Force update list of geonotification alarms for all user sites
                dispatch("getAllUserSitesAlarms", data);

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to upload asset image to server
     */
    uploadSiteImage({ dispatch }, payload) {
        var formData = new FormData();
        formData.append("uploadedImage", payload.siteImage);
        formData.append("entity", "sites");
        axiosBackend
            .post("/upload/image", formData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(res => {
                // When received a 204, get location URL in header and set into filename
                payload.metadata.image = res.headers.location;
                if (payload.nextAction === "NEW_SITE") {
                    dispatch("createUserSite", payload.metadata);
                } else if (payload.nextAction === "EDIT_SITE") {
                    dispatch("updateSite", payload.metadata);
                }
            })
            .catch(error => console.log(error));
    },

    /**
     * Action use to set to true the value of IQM Server is verify
     */
    validateIQMServer({ commit }) {
        commit('SET_IQM_SERVER_VERIFY_STATE', true);
    },

    /**
     * Action use to set to false the value of IQM Server is verify
     */
    invalidateIQMServer({ commit }) {
        commit('SET_IQM_SERVER_VERIFY_STATE', false);
    },

    /**
     * Action use to check if the server is available
     * @param {Object} payload
     */
    checkIQMServer({ commit, dispatch }, payload) {
        axiosBackend
            .post("/iqm/verify", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                hideSpinner: false
            })
            .then(res => {
                dispatch("validateIQMServer");

                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("site_IQMSuccessfullyTested"),
                    type: "success"
                });
            })
            .catch(error => {
                dispatch("invalidateIQMServer");
                console.log(error);
            });
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetSitesState ({ commit }) {
        commit('RESET_DEFAULT_SITES_STATE');
    },

    /*
     * Action used to reset show gateways emission modal
     */
    resetShowGatewaysEmissionModal({ commit }) {
        commit('RESET_SITE_GATEWAYS_EMISSION');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};