import axiosBackend from "../../axios-backend";

const getDefaultState = () => {
    return {
        siteGatewayChecking : [],
        siteBeaconsChecking : []
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for siteIndoorAreas
     */
    siteGatewayChecking(state){
        return state.siteGatewayChecking;
    },

    siteBeaconsChecking(state){
        return state.siteBeaconsChecking;
    }

};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {

    SET_SITE_GATEWAY_CHECKING_STATE(state, data) {
        state.siteGatewayChecking = [...data];
    },

    SET_SITE_BEACONS_CHECKING_STATE(state, data) {
        state.siteBeaconsChecking = [...data];
    },

    RESET_DEFAULT_SITE_CHECKING_STATE(state){
        Object.assign(state, getDefaultState());
    },

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Action used to get info on deployment checking
     */

    getSiteGatewayChecking({commit}, siteId){

        const URL =  "/sites/"+siteId+"/troubleshooting/check/gateways";
        axiosBackend
            .get(URL,{
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store site buildings in userBuildings
                commit("SET_SITE_GATEWAY_CHECKING_STATE", res.data.data);
            })
            .catch(error => console.log(error));

    },

    getSiteBeaconsChecking({commit}, siteId){

        const URL =  "/sites/"+siteId+"/troubleshooting/check/autocalibrationtags";
        axiosBackend
            .get(URL,{
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store site buildings in userBuildings
                commit("SET_SITE_BEACONS_CHECKING_STATE", res.data.data);
            })
            .catch(error => console.log(error));

    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetSiteCheckingState ({ commit }) {
        commit('RESET_DEFAULT_SITE_CHECKING_STATE');
    }



};

export default {
    state,
    getters,
    mutations,
    actions
};
