<template>
    <div class="modal fade show" id="assetSearchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("asset_assetSearchResult", { categoryName: categoryName }) }}</h5>
                    <button type="button" class="close" @click="onCancelAssetSearch()" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body kt-padding-b-0">
                    <p class="kt-font-bold">{{ $tc("asset_assetSearchItems", foundItems, {countResult: foundItems, countFloor: floorsOnAllBuilding.length} ) }}</p>
                    <p class="kt-font-bold">{{ $t("asset_selectedFloorOnResults") }}</p>
                    <div class="row kt-margin-t-15 kt-margin-l-5 kt-margin-b-10">
                        <div v-for="floor in floorsOnAllBuilding" :key="floor.id" class="col-12 kt-margin-t-5 kt-padding-t-5 kt-padding-b-5 cursorPointer asset-results">
                            <p @click="onChangeFloor(floor.id)" ><span class="kt-font-bold">{{ floor.buildingName }}</span> - {{floor.name}} - {{ $tc("common_itemAssets", floor.assets.length, { number: floor.assets.length }) }}</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="margin-top: -1px">
                    <button id="assetSearchModalCancelButton" @click="onCancelAssetSearch()" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
export default {
    data() {
        return {
            foundItems: 0,
            categoryName: "",
            floorsOnAllBuilding: []
        };
    },
    created: function() {
        console.log("Component(assetSearchModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(assetSearchModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(assetSearchModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteAssetsSearch: function() {
            if (this.siteAssetsSearch && this.siteAssetsSearch.length) {
                this.foundItems = this.siteAssetsSearch.length;
                if (this.siteAssetsSearch[0]["assetcategory"]["isDefault"]) {
                    this.categoryName = i18n.t(this.siteAssetsSearch[0]["assetcategory"]["name"]);
                } else {
                    this.categoryName = this.siteAssetsSearch[0]["assetcategory"]["name"];
                }
                for(let building of this.buildingsAndTheirFloorsArray) {
                    building.floors.forEach(floor => {
                        floor.buildingName = building.name;
                    })
                    this.floorsOnAllBuilding.push(...building.floors);
                }
                this.floorsOnAllBuilding = this.floorsOnAllBuilding.filter(floor => {
                    return this.siteAssetsSearch.find(asset => asset.tag.floorId === floor.id);
                });
                _.forEach(this.floorsOnAllBuilding, floor => {
                    floor.assets = this.siteAssetsSearch.filter(asset => { return floor.id === asset.tag.floorId });
                });
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteAssetsSearch", "buildingsAndTheirFloorsArray"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["resetSearchedItems", "hideAssetSearchModal"]),
        onCancelAssetSearch() {
            this.floorsOnAllBuilding = [];
            this.resetSearchedItems();
            this.hideAssetSearchModal();
        },

        onChangeFloor(floorId) {
            if (typeof localStorage != "undefined") {
                localStorage.setItem(
                    this.siteId + "_" + this.mode + "_lastDisplayedFloorId",
                    floorId
                );
            }
            $(".floorSelectorLink[data-floorid='" + floorId + "']").click();

            this.floorsOnAllBuilding = [];
            this.hideAssetSearchModal();
        }

    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.asset-results {
    background: #f7f7f7;
}
.asset-results:hover {
    background: #efeef0;
}

</style>
