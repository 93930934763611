<template>
    <!--begin::Aside-->
    <div
        class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
        style="background-image: url(/assets/metronic/media/bg/bg-4.jpg);"
        :style="{ backgroundImage: 'url(' + getBackgroundImage() + ')' }"
    >
        <div class="kt-grid__item">
            <div v-if="this.releasedFor == 'HAN'" class="kt-login__logo">
                <img src="/assets/han/company_logo_white.png" class="width140" />
            </div>
            <a v-else-if="this.releasedFor == 'ALE'" href="https://www.al-enterprise.com/en" target="_blank" class="kt-login__logo">
                <img src="/assets/ale/al_enterprise_wh.png" />
            </a>
        </div>
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
            <div class="kt-grid__item kt-grid__item--middle">
                <h3 v-if="this.releasedFor == 'ALE'" class="kt-login__title" style="font-size: 20px;">{{ $t("main_joinStellarLBS") }}</h3>
                <h3 v-else class="kt-login__title" style="font-size: 20px;">{{ $t("main_joinStellarLBSHan") }}</h3>
                <h4 class="kt-login__subtitle customTitle">{{ $t("main_joinStellarLBSSub") }}</h4>
                <ol class="subListWelcome customTitle">
                    <li>{{ $t("main_joinStellarSub1") }}</li>
                    <li>{{ $t("main_joinStellarSub2") }}</li>
                    <li>{{ $t("main_joinStellarSub3") }}</li>
                </ol>
                <h4 v-if="this.releasedFor == 'ALE'" class="kt-login__subtitle customTitle">{{ $t("main_joinStellarSub4") }}</h4>
                <h4 v-else class="kt-login__subtitle customTitle">{{ $t("main_joinStellarSub4Han") }}</h4>
            </div>
        </div>
        <div class="kt-grid__item">
            <div class="kt-login__info">
                <div class="kt-login__copyright">© {{ currentYear }} {{ this.releasedFor == "HAN" ? "HAN Networks" : "Alcatel-Lucent Enterprise" }}</div>
                <div class="kt-login__menu">
                    <a
                        v-if="this.releasedFor == 'ALE'"
                        href="https://www.al-enterprise.com/en/legal/privacy"
                        target="_blank"
                        class="kt-link"
                    >{{ $t("main_privacy") }}</a>
                    <a
                        v-if="this.releasedFor == 'ALE'"
                        href="https://www.al-enterprise.com/en/legal"
                        target="_blank"
                        class="kt-link"
                    >{{ $t("main_legal") }}</a>
                    <a
                        v-else
                        href="http://www.han-networks.com/low-u.php"
                        target="_blank"
                        class="kt-link"
                    >{{ $t("main_legal") }}</a>

                </div>
            </div>
        </div>
    </div>

    <!--begin::Aside-->
</template>

<script>
export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR,
            currentYear: new Date().getFullYear()
        };
    },
    mounted: function() {
        console.log("Component(Welcome)::mounted() - called");
        KTLayout.init();
    },
    methods: {
        getBackgroundImage() {
            return this.releasedFor == "HAN" ? "/assets/metronic/media/bg/bg-4-han.jpg" : "/assets/metronic/media/bg/bg-4.jpg";
        }
    }
};
</script>

<style scoped>
.logo_ale {
    width: 170px;
}
.width140 {
    width: 140px;
}
.subListWelcome {
    margin-top: -20px;
    margin-bottom: -10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6rem;
    color: rgba(255, 255, 255, 0.7);
}
.subListWelcome li {
    margin-top: 10px;
}
.customTitle {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 1.6rem !important;
}
</style>
