import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        tagTypeObjsByName: {},
        tagTypeObjsById: {},
        autocalibrationTagTypeObjsByName: {},
        autocalibrationTagTypeObjsById: {}
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for siteTags
     */
    tagTypeObjsByName(state) {
        return state.tagTypeObjsByName;
    },

    tagTypeObjsById(state) {
        return state.tagTypeObjsById;
    },

    autocalibrationTagTypeObjsByName(state) {
        return state.autocalibrationTagTypeObjsByName;
    },

    autocalibrationTagTypeObjsById(state) {
        return state.autocalibrationTagTypeObjsById;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_TAG_TYPES(state, data) {
        state.tagTypeObjsByName = {};
        state.tagTypeObjsById = {};
        state.autocalibrationTagTypeObjsByName = {};
        state.autocalibrationTagTypeObjsById = {};
        if (data.tagTypes) {
            for (let i = 0; i < data.tagTypes.length; i++) {
                let tagType = data.tagTypes[i];
                if (tagType && tagType.name) {
                    if (tagType.isUsedAsTag || tagType.isUsedAsAutocalibrationTag) {
                        let nameLC = tagType.name.toLowerCase();
                        let itemType = {
                            id: tagType.id,
                            name: tagType.name,
                            batteryLifetime: tagType.batteryLifetime,
                            rssiOneMeter: tagType.rssiOneMeter,
                            ref: tagType.reference,
                            imgClass: "img" + nameLC.charAt(0).toUpperCase() + nameLC.slice(1) + "Tag",
                            isDefault: tagType.isDefault
                        };
                        if (tagType.isDefault) {
                            itemType.label = i18n.t("tag_" + nameLC + "TagLabel");
                            itemType.fullRef = i18n.t("tag_" + nameLC + "TagFullRef");
                            itemType.desc = i18n.t("tag_" + nameLC + "TagDesc");
                            itemType.img = "/assets/tags/" + nameLC + ".svg";
                        } else {
                            itemType.fullRef = tagType.name + " (" + tagType.reference + ")";
                            itemType.label = tagType.name;
                            itemType.img = tagType.image;
                            itemType.desc = nameLC;

                        }
                        if (tagType.isUsedAsTag) {
                            state.tagTypeObjsByName[tagType.name] = itemType;
                            state.tagTypeObjsById[tagType.id] = itemType;
                        }
                        if (tagType.isUsedAsAutocalibrationTag) {
                            state.autocalibrationTagTypeObjsByName[tagType.name] = itemType;
                            state.autocalibrationTagTypeObjsById[tagType.id] = itemType;
                        }
                    }
                }
            }
        }
    },

    RESET_DEFAULT_TAGTYPES_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Action used to get the 2 list of tag types:  tag types and autocalibration tag types
     */
    getTagTypes({ commit }, data) {
        axiosBackend
            .get(`/sites/${data.siteId}/tagtypes`, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_SITE_TAG_TYPES", {
                    tagTypes: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetTagTypesState ({ commit }) {
        commit('RESET_DEFAULT_TAGTYPES_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
