import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        currentShippedTag: null
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for shippedTag
     */
    currentShippedTag(state) {
        return state.currentShippedTag;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_CURRENT_SHIPPEDTAG(state, data) {
        // Set the currentAutoCalibrationTag
        state.currentShippedTag = data.currentShippedTag;
    },

    RESET_CURRENT_SHIPPEDTAG(state) {
        state.currentShippedTag = null;
    },

    RESET_DEFAULT_SHIPPEDTAGS_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Action used to get a shipped tag by its label
     */
    getShippedTagByLabel({ commit }, tagLabel) {
        axiosBackend
            .get("/shippedtags/search?tagLabel=" + tagLabel, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_CURRENT_SHIPPEDTAG", {
                    currentShippedTag: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("act_searchAutoCalibrationTagByLabelSuccess", { macAddress: res.data.data.macAddress }),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetShippedTagsState ({ commit }) {
        commit('RESET_DEFAULT_SHIPPEDTAGS_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
