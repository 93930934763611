import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";


const getDefaultState = () => {
    return {
        siteBLEGateways: null,
        currentBLEGateway: null,
        currentBLEGatewayLocation: {
            id: "",
            bleMacAddress: "",
            state: "",
            isHighlightFromMap: false
        },
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for siteBLEGateways
     */
    siteBLEGateways(state) {
        return state.siteBLEGateways;
    },

    currentBLEGateway(state) {
        return state.currentBLEGateway;
    },

    currentBLEGatewayLocation(state) {
        return state.currentBLEGatewayLocation;
    },

    getGatewayById: state => gatewayId => {
        return state.siteBLEGateways.find(gateway => gateway.id == gatewayId);
    },

    createAnotherGateway(state) {
        return state.createAnother;
    },

    allGatewaysKeywords(state) {
        let keywordsArray = [];
        for (let item in state.siteBLEGateways) {
            if (state.siteBLEGateways[item].keywords !== null) {
                keywordsArray.push.apply(keywordsArray, state.siteBLEGateways[item].keywords.list);
            }
        }
        return _.uniq(keywordsArray);
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_GATEWAYS(state, data) {
        if (!state.siteBLEGateways) {
            state.siteBLEGateways = [];
        }
        state.siteBLEGateways = data.gateways;
    },

    PUSH_SITE_GATEWAY(state, data) {
        if (!state.siteBLEGateways) {
            state.siteBLEGateways = [];
        }
        state.siteBLEGateways = state.siteBLEGateways.concat(data.gateways);
    },

    SET_SITE_GATEWAY(state, userData) {
        if (!state.siteBLEGateways) {
            state.siteBLEGateways = [];
        }
        state.siteBLEGateways.push(userData.gateways);
    },

    REMOVE_GATEWAY_BY_ID(state, gatewayId) {
        // Find gateway index
        let index = _.findIndex(state.siteBLEGateways, {
            id: gatewayId
        });
        state.siteBLEGateways.splice(index, 1);
    },

    SET_CURRENT_GATEWAY(state, gatewayData) {
        // Set the currentBLEGateway
        state.currentBLEGateway = gatewayData.currentBLEGateway;
        if (state.siteBLEGateways) {
            // Find gateway index
            let index = _.findIndex(state.siteBLEGateways, {
                id: gatewayData.currentBLEGateway.id
            });
            if (index >= 0) {
                // Replace the gateway in the array of gateways
                state.siteBLEGateways.splice(index, 1, gatewayData.currentBLEGateway);
            } else {
                state.siteBLEGateways.push(gatewayData.currentBLEGateway);
            }
        } else {
            state.siteBLEGateways = [];
            state.siteBLEGateways.push(gatewayData.currentBLEGateway);
        }
    },

    SET_CURRENT_GATEWAY_LOCATION(state, gatewayData) {
        state.currentBLEGatewayLocation = gatewayData;
    },

    SET_CREATE_ANOTHER_GATEWAY(state, createAnother) {
        state.createAnother = createAnother;
    },

    RESET_DEFAULT_GATEWAYS_STATE(state) {
        Object.assign(state, getDefaultState())
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherGateway({
        commit
    }, createAnother) {
        commit("SET_CREATE_ANOTHER_GATEWAY", createAnother);
    },

    /*
     * Action used to create a gateway
     */
    createBLEGateway({
        commit
    }, payload) {
        commit("SET_CREATE_ANOTHER_GATEWAY", payload.createAnother);
        delete payload.createAnother;
        axiosBackend
            .post("/sites/" + payload.siteId + "/gateways", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_SITE_GATEWAY", {
                    gateways: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("gtw_bleGatewaySuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to gateways list page
                router.push({
                    name: "gateways",
                    params: {
                        fromAction: "createAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to create multiple gateways
     */
    massImportGateways({
        commit
    }, payload) {
        // Inform store that upload is in progress
        commit("SET_UPLOAD_GATEWAYS_IN_PROGRESS", true);

        axiosBackend
            .post("/sites/" + payload.siteId + "/gateways/massimport", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                commit("SET_UPLOAD_GATEWAYS_IN_PROGRESS", false);

                // Add new gateways to the list
                commit("PUSH_SITE_GATEWAY", {
                    gateways: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("gtw_newBleGatewaySuccessfullyCreated"),
                    type: "success"
                });

                // Ask to hide import modal now
                commit("HIDE_IMPORT_GATEWAYS_MODAL", {});
                commit("HIDE_IMPORT_GATEWAYS_FROM_OV_MODAL", {});
            })
            .catch(error => {
                commit("SET_UPLOAD_GATEWAYS_IN_PROGRESS", false);
                console.log(error);
            });
    },

    /*
     * Action used to get the list of gateways of site
     */
    getSiteBLEGateways({
        commit
    }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/gateways", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_SITE_GATEWAYS", {
                    gateways: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one gateway by its id
     */
    getBLEGatewayById({
        commit
    }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/gateways/" + payload.gatewayId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                commit("SET_CURRENT_GATEWAY", {
                    currentBLEGateway: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing gateway
     */
    updateBLEGateway({
        commit
    }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/gateways/" + payload.gatewayId, payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_GATEWAY", {
                    currentBLEGateway: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("gtw_bleGatewaySuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to gateways page
                router.push({
                    name: "gateways",
                    params: {
                        fromAction: "updateAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a gateway
     */
    deleteBLEGateway({
        commit,
        dispatch
    }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/gateways/" + payload.gatewayId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(() => {
                commit("REMOVE_GATEWAY_BY_ID", payload.gatewayId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("gtw_bleGatewaySuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    setCurrentGatewayLocation({
        commit
    }, payload) {
        commit("SET_CURRENT_GATEWAY_LOCATION", payload);
    },

    /**
     * Set location of gateways
     * @param commit
     * @param payload
     */
    setGatewayLocation({
        commit, rootState
    }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/gateways/" + payload.id + "/location", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                let updatedBLEGatewayLocation = res.data.data;

                commit("SET_CURRENT_GATEWAY", {
                    currentBLEGateway: updatedBLEGatewayLocation
                });

                // Reset current gateway location
                commit("SET_CURRENT_GATEWAY_LOCATION", {
                    id: "",
                    bleMacAddress: "",
                    state: ""
                });

                // Check if the removed gateway is the last one added to the dashboard. If it is the case reset variable to compute distance.
                if (!payload.location && rootState.map.lastAddedObjectId && rootState.map.lastAddedObjectId == payload.id){
                    // Reset current gateway location
                    commit("SET_LAST_ADDED_OBJECT_LOCATION", {
                        lastAddedObjectLocation: null,
                        lastAddedObjectType: null,
                        lastAddedObjectId: null
                    });
                }

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetGatewaysState ({ commit }) {
        commit('RESET_DEFAULT_GATEWAYS_STATE');
    }
    
};

export default {
    state,
    getters,
    mutations,
    actions
};