/*
 * State: properties to store
 */
const state = {
    /*
     * Indicate if the delete modal has to be displayed
     */
    showImportTagsModal: false,

    /*
     * Indicate if the modal has to be hide
     */
    hideImportTagsModal: false,

    /*
     * Indicate if an upload of tags is in progress or not
     */
    isUploadTagsInProgress: false
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    /*
     * Toggle showImportTagsModal property
     */
    SHOW_IMPORT_TAGS_MODAL(state) {
        state.showImportTagsModal = true;
    },

    /*
     * Toggle hideImportTagsModal property
     */
    HIDE_IMPORT_TAGS_MODAL(state) {
        state.hideImportTagsModal = true;
    },

    /*
     * Reset showImportTagsModal property
     */
    RESET_SHOW_IMPORT_TAGS_MODAL(state) {
        state.showImportTagsModal = false;
    },

    /*
     * Reset hideImportTagsModal property
     */
    RESET_HIDE_IMPORT_TAGS_MODAL(state) {
        state.hideImportTagsModal = false;
    },

    /*
     * Set isUploadTagsInProgress property
     */
    SET_UPLOAD_TAGS_IN_PROGRESS(state, value) {
        state.isUploadTagsInProgress = value;
    }
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for showImportTagsModal
     */
    showImportTagsModal(state) {
        return state.showImportTagsModal;
    },
    /*
     * Getter for hideImportTagsModal
     */
    hideImportTagsModal(state) {
        return state.hideImportTagsModal;
    },
    /*
     * Getter for isUploadTagsInProgress
     */
    isUploadTagsInProgress(state) {
        return state.isUploadTagsInProgress;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    showImportTagsModal({ commit }, data) {
        commit("SHOW_IMPORT_TAGS_MODAL", data);
    },

    hideImportTagsModal({ commit }, data) {
        commit("HIDE_IMPORT_TAGS_MODAL", data);
    },

    resetShowImportTagsModal({ commit }) {
        commit("RESET_SHOW_IMPORT_TAGS_MODAL", {});
    },

    resetHideImportTagsModal({ commit }) {
        commit("RESET_HIDE_IMPORT_TAGS_MODAL", {});
    },

    setIsUploadTagsInProgress({ commit }, value) {
        commit("SET_UPLOAD_TAGS_IN_PROGRESS", value);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
