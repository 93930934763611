<template>
    <div id="assetDetailBar" class="kt-demo-panel" style="opacity: 1;background:#1e1e2d;">
        <div class="kt-demo-panel__head" kt-hidden-height="49" style="">
            <h3 class="kt-demo-panel__title colorWhite">
                <span id="assetDetailBarCurrentName">{{ name }}</span><br />
                <span class="unitLabel">{{ $t("asset_assetUnit") }}: <span id="assetDetailBarCurrentUnit">{{ unit }}</span></span>
            </h3>
            <a id="assetDetailBarCloseButton" aria-label="Close Asset Detail Bar" ref="#" @click="toggleAssetDetailBar()" class="kt-demo-panel__close closeIconLink">
                <i class="flaticon2-delete"></i>
            </a>
        </div>

        <div class="kt-demo-panel__body">
            <div 
                v-if="this.image && this.image.includes('data:', 0) && this.image.includes('base64')"
                v-bind:style="{ backgroundImage:  'url(' + this.image + ')' }"
                class="assetImage assetDetailBarSectionMargin assetDetailBarImage"    
            ></div>

            <!--begin: AssetCategory -->
            <div class="assetSection assetDetailBarSectionMargin">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <path d="M12.4644661,14.5355339 L9.46446609,14.5355339 C8.91218134,14.5355339 8.46446609,14.9832492 8.46446609,15.5355339 C8.46446609,16.0878187 8.91218134,16.5355339 9.46446609,16.5355339 L12.4644661,16.5355339 L12.4644661,17.5355339 C12.4644661,18.6401034 11.5690356,19.5355339 10.4644661,19.5355339 L6.46446609,19.5355339 C5.35989659,19.5355339 4.46446609,18.6401034 4.46446609,17.5355339 L4.46446609,13.5355339 C4.46446609,12.4309644 5.35989659,11.5355339 6.46446609,11.5355339 L10.4644661,11.5355339 C11.5690356,11.5355339 12.4644661,12.4309644 12.4644661,13.5355339 L12.4644661,14.5355339 Z" fill="#000000" opacity="0.3" transform="translate(8.464466, 15.535534) rotate(-45.000000) translate(-8.464466, -15.535534) " />
                        <path d="M11.5355339,9.46446609 L14.5355339,9.46446609 C15.0878187,9.46446609 15.5355339,9.01675084 15.5355339,8.46446609 C15.5355339,7.91218134 15.0878187,7.46446609 14.5355339,7.46446609 L11.5355339,7.46446609 L11.5355339,6.46446609 C11.5355339,5.35989659 12.4309644,4.46446609 13.5355339,4.46446609 L17.5355339,4.46446609 C18.6401034,4.46446609 19.5355339,5.35989659 19.5355339,6.46446609 L19.5355339,10.4644661 C19.5355339,11.5690356 18.6401034,12.4644661 17.5355339,12.4644661 L13.5355339,12.4644661 C12.4309644,12.4644661 11.5355339,11.5690356 11.5355339,10.4644661 L11.5355339,9.46446609 Z" fill="#000000" transform="translate(15.535534, 8.464466) rotate(-45.000000) translate(-15.535534, -8.464466) " />
                    </g>
                </svg>
                {{ $t("asset_category") }}
            </div>

            <div class="assetDetailBarSectionMargin">
                <form class="kt-form kt-form--label-right sectionForm colorWhite">
                    <div class="kt-portlet__body">
                        <div class="row">
                            <div class="col-lg-12">
                                <label class="fontW400">{{ $t("common_name") }}</label>
                                <div v-if="categoryIsDefault == false" class="customAssetCategoryIcon marginT5 fontW500" v-bind:style="{ backgroundImage: 'url(' + categoryIconUrl + ')' }">
                                    <div class="categoryNameForCustomCat">{{ categoryName }}</div>
                                </div>
                                <div v-if="categoryIsDefault == true" class="marginT5 fontW500"><img class="categoryIcon marginR5" :src="categoryIconUrl" /> {{ categoryName }}</div>
                            </div>
                        </div>
                        <div v-if="subcategoryName" class="row marginT10">
                            <div class="col-lg-12">
                                <label v-if="categoryOriginalName == 'ASSET_C_PATIENTS' && isFrench" class="fontW400">{{ $t("asset_subcategoriesListPatient") }}</label>
                                <label v-else class="fontW400">{{ $t("asset_subCategoryName") }}</label>
                                <p id="assetDetailBarSubCategoryName" class="marginB0 fontW500">{{ subcategoryName }}</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <!--end: AssetCategory -->

            <!--begin: CustomFields -->
            <div class="kt-margin-t-20 assetSection assetDetailBarSectionMargin" v-show="showCustomFieldsDetail()">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) " />
                        <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1" />
                    </g>
                </svg>
                {{ $t("asset_customFields") }}
            </div>

            <!-- Display this when we are on show asset on map open view (not protect) -->
            <div v-show="isShowFloorAssetOnMapComponent && showCustomFieldsDetailOnShowAssetOnMap()" class="assetDetailBarSectionMargin">
                <form class="kt-form kt-form--label-right sectionForm">
                    <div class="kt-portlet__body">
                        <div class="row">
                            <div class="col-sm-5">
                                <div class="kt-form__label fontW400">
                                    <label>{{ $t("common_name") }}</label>
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="kt-form__label fontW400">
                                    <label>{{ $t("common_value") }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-for="customField in getCustomFields()" v-bind:key="customField.id">

                            <div class="form-group row align-items-center customFieldItem" v-if="customField && customField.value">
                                <div class="col-sm-5">
                                    <p class="fontW500 customFieldName">{{ customField.isDefault ? $t(customField.name) : customField.name }}</p>
                                </div>
                                <div class="col-sm-7">
                                    <p v-if="customField.typec === 'String'" type="text" class="fontW500 customFieldName"> {{ customField.value }}</p>
                                    <p v-else-if="customField.typec === 'Enum'" type="text" class="fontW500 customFieldName"> {{ customField.isDefault ? $t(customField.value) : customField.value }}</p>
                                    <p v-else-if="customField.typec === 'Bool'" type="text" class="fontW500 customFieldName"><input type="checkbox" :value="customField.value" disabled="true" /></p>
                                    <p v-else-if="customField.typec === 'Date'" type="text" class="fontW500 customFieldName">{{ moment(customField.value).format("LL") }}</p>
                                    <p v-else-if="customField.isStatic" type="text" class="fontW500 customFieldName">{{ customField.staticValue }}</p>
                                </div>
                            </div>

                            <!-- Add also admission field if entry date is field -->
                            <div v-if="customField.name === 'CUSTOMFIELD_DATE_ENTRY' && customField.value" class="form-group row align-items-center customFieldItem">
                                <div class="col-sm-5">
                                    <p class="fontW500 customFieldName">{{ $t("asset_customField_admission") }}</p>
                                </div>
                                <div class="col-sm-7">
                                    <p v-if="now.isSame(customField.value, 'day')" style=" color: white; font-weight: 500; margin-top: 9px; ">{{ $t("asset_customField_today") }}</p>
                                    <p v-else style=" color: white; font-weight: 500; margin-top: 9px; ">{{ moment(customField.value).fromNow() }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>

            <div v-show="!isShowFloorAssetOnMapComponent && showCustomFieldsDetail()" class="assetDetailBarSectionMargin">
                <form class="kt-form kt-form--label-right sectionForm">
                    <div class="kt-portlet__body">
                        <div class="row">
                            <div class="col-sm-5">
                                <div class="kt-form__label fontW400">
                                    <label>{{ $t("common_name") }}</label>
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <div class="kt-form__label fontW400">
                                    <label>{{ $t("common_value") }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-for="customField in getCustomFields()" v-bind:key="customField.id">

                            <div class="form-group row align-items-center customFieldItem">
                                <div class="col-sm-5">
                                    <p class="fontW500 customFieldName">{{ customField.isDefault ? $t(customField.name) : customField.name }}</p>
                                </div>
                                <div class="col-sm-7">
                                    <div v-if="customField.typec === 'String'" class="input-group">
                                        <input :id="getCustomFieldSelectorId(customField)" type="text" class="paddingCustomFieldInput form-control form-control-sm" v-on:keyup.enter="onChangeString(getCustomFieldSelectorId(customField))" :placeholder="$t('common_enterValue')" :name="getCustomFieldSelectorId(customField) + '-string'" />
                                        <div class="input-group-append">
                                            <span :id="getCustomFieldSelectorId(customField) + '-btn'" class="btn btn-sm btn-secondary btn-icon cursorPointer backgroundViolet" @click="onChangeString(getCustomFieldSelectorId(customField))"><i class="la la-check colorWhite"></i></span>
                                        </div>
                                    </div>
                                    <select v-else-if="customField.typec === 'Enum'" :id="getCustomFieldSelectorId(customField)" class="form-control kt-select2">
                                        <option></option>
                                    </select>
                                    <label v-else-if="customField.typec === 'Bool'" class="kt-switch kt-switch--md kt-switch--icon">
                                        <input type="checkbox" value="" :id="getCustomFieldSelectorId(customField)" />
                                        <span></span>
                                    </label>
                                    <input v-else-if="customField.typec === 'Date'" :id="getCustomFieldSelectorId(customField)" type="text" class="paddingCustomFieldInput form-control form-control-sm" readonly="" :placeholder="$t('common_selectDate')" />
                                    <div v-else-if="customField.isStatic && customField.typec === 'URL'">
                                        <a :href="customField.url" target="_blank" rel="noopener" class="kt-link kt-font-bold">{{ customField.staticValue}}</a>
                                    </div>
                                </div>
                            </div>

                            <!-- Add also admission field if entry date is field -->
                            <div v-if="customField.name === 'CUSTOMFIELD_DATE_ENTRY' && customField.value" class="form-group row align-items-center customFieldItem">
                                <div class="col-sm-5">
                                    <p class="fontW500 customFieldName">{{ $t("asset_customField_admission") }}</p>
                                </div>
                                <div class="col-sm-7">
                                    <p v-if="now.isSame(customField.value, 'day')" style=" color: white; font-weight: 500; margin-top: 9px; ">{{ $t("asset_customField_today") }}</p>
                                    <p v-else style=" color: white; font-weight: 500; margin-top: 9px; ">{{ moment(customField.value).fromNow() }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
            <!--end: CustomFields -->

            <!--begin: Tag -->
            <div class="kt-margin-t-20 assetSection assetDetailBarSectionMargin">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <path d="M12,13.8453624 L12,18.758963 L15.3125397,16.8266482 L12,13.8453624 Z M10,12.0453624 L6.33103527,8.74329415 C5.92052525,8.37383513 5.88724683,7.74154529 6.25670585,7.33103527 C6.62616487,6.92052525 7.25845471,6.88724683 7.66896473,7.25670585 L17.6689647,16.2567059 C18.172608,16.7099848 18.0891527,17.5223646 17.503871,17.8637789 L11.503871,21.3637789 C10.8372144,21.7526619 10,21.2717908 10,20.5 L10,12.0453624 Z" id="Path-20-Copy" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                        <path d="M12,10.4375595 L15.3984747,7.54885603 L12,5 L12,10.4375595 Z M10,3 C10,2.17595468 10.9407637,1.70557281 11.6,2.2 L17.6,6.7 C18.1131546,7.08486597 18.1363893,7.84650959 17.6476484,8.26193932 L7.64764842,16.7619393 C7.22684095,17.1196257 6.59574703,17.0684559 6.23806068,16.6476484 C5.88037434,16.226841 5.93154411,15.595747 6.35235158,15.2380607 L10,12.1375595 L10,3 Z" id="Path-20" fill="#000000" fill-rule="nonzero" />
                    </g>
                </svg>
                {{ $t("asset_tagInfo") }}
            </div>

            <div class="assetDetailBarSectionMargin">
                <form class="kt-form kt-form--label-right sectionForm colorWhite">
                    <div class="kt-portlet__body">
                        <div class="row">
                            <div class="col-lg-6 pr-1">
                                <label class="fontW400">{{ $t("tag_tagTypeLabel") }}</label>
                                <p class="fontW500">
                                    <span>
                                        <img style="width:20px" :src="'/assets/tags/' + tagType.toLowerCase() + '.svg'" />
                                        <span id="assetDetailBarTagTypeName" class="kt-margin-l-10">{{ $t("tag_" + tagType.toLowerCase() + "TagLabel") }}</span>
                                    </span>
                                </p>
                            </div>
                            <div class="col-lg-6">
                                <label class="fontW400">{{ $t("tag_serialNumberLabel") }}</label>
                                <p id="assetDetailBarTagSerialNumber" class="fontW500">{{ tagSerialNumber }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <label class="fontW400">{{ $t("tag_EstimatedBatteryStatusLabel") }}</label>
                                <p class="fontW500 inlineFlex" v-if="tagRealBatteryStatusValue !== -1">
                                    <span v-if="tagRealBatteryStatusValue < 30">
                                        <div class="progress" style="width: 30px;margin-top: 2px;">
                                            <div class="progress-bar bg-danger" role="progressbar" :style="'width:' + tagRealBatteryStatusValue + '%'" :aria-valuenow="tagRealBatteryStatusValue" aria-valuemin="0" aria-valuemax="10">
                                                <img style="width: 17px;margin: 0 auto;margin-left: 6px;" alt="Battery" src="/assets/battery/battery-charging-thin.svg" />
                                            </div>
                                        </div>
                                    </span>
                                    <span v-else-if="tagRealBatteryStatusValue >= 30 && tagRealBatteryStatusValue < 70">
                                        <div class="progress" style="width: 30px;margin-top: 2px;">
                                            <div class="progress-bar bg-warning" role="progressbar" :style="'width:' + tagRealBatteryStatusValue + '%'" :aria-valuenow="tagRealBatteryStatusValue" aria-valuemin="0" aria-valuemax="50">
                                                <img style="width: 17px;margin: 0 auto;margin-left: 6px;" alt="Battery" src="/assets/battery/battery-charging-thin.svg" />
                                            </div>
                                        </div>
                                    </span>
                                    <span v-else>
                                        <div class="progress" style="width: 30px;margin-top: 2px;">
                                            <div class="progress-bar bg-success" role="progressbar" :style="'width:' + tagRealBatteryStatusValue + '%'" :aria-valuenow="tagRealBatteryStatusValue" aria-valuemin="0" aria-valuemax="100">
                                                <img style="width: 17px; margin: 0 auto;" alt="Battery" src="/assets/battery/battery-charging-thin.svg" />
                                            </div>
                                        </div>
                                    </span>
                                    <span id="assetDetailBarTagBatteryStatus" class="batteryStatusIndicatorTxt">{{  tagRealBatteryStatusValue + "%" }}</span>
                                </p>
                                <p class="fontW500 inlineFlex" v-else>
                                    <span v-if="now.isAfter(estimatedBatteryLifetimeEndDate) || now.isAfter(estimatedBatteryLifetimeEndDateMinus6Months)">
                                        <div class="progress" style="width: 30px;margin-top: 2px;">
                                            <div class="progress-bar bg-danger" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="10">
                                                <img style="width: 17px;margin: 0 auto;margin-left: 6px;" alt="Battery" src="/assets/battery/battery-charging-thin.svg" />
                                            </div>
                                        </div>
                                    </span>
                                    <span v-else-if="now.isAfter(estimateBatteryMiddleLifetimeDate) && now.isBefore(estimatedBatteryLifetimeEndDateMinus6Months)">
                                        <div class="progress" style="width: 30px;margin-top: 2px;">
                                            <div class="progress-bar bg-warning" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="50">
                                                <img style="width: 17px;margin: 0 auto;margin-left: 6px;" alt="Battery" src="/assets/battery/battery-charging-thin.svg" />
                                            </div>
                                        </div>
                                    </span>
                                    <span v-else>
                                        <div class="progress" style="width: 30px;margin-top: 2px;">
                                            <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                                                <img style="width: 17px; margin: 0 auto;" alt="Battery" src="/assets/battery/battery-charging-thin.svg" />
                                            </div>
                                        </div>
                                    </span>
                                    <span id="assetDetailBarTagBatteryStatus" class="batteryStatusIndicatorTxt">{{  $t("tag_EstimatedBatteryLabel") + "*" }}</span>
                                </p>
                            </div>
                            <div class="col-lg-6 kt-hidden">
                                <label class="fontW400">{{ $t("tag_macAddressLabel") }}</label>
                                <p id="assetDetailBarTagMacAddress" class="fontW500">{{ tagMacAddress }}</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <!--end: Tag -->

            <div class="kt-margin-t-10 assetSection assetDetailBarSectionMargin">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24" />
                        <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fill-rule="nonzero" />
                    </g>
                </svg>
                {{ $t("common_currentLocation") }}
            </div>

            <div class="assetDetailBarSectionMargin">
                <form class="kt-form kt-form--label-right sectionForm colorWhite">
                    <div class="kt-portlet__body">
                        <div class="row kt-hidden">
                            <div class="col-lg-6">
                                <label class="fontW400">{{ $t("common_latitude") }}</label>
                                <p id="assetDetailBarCurrentLatitude" class="fontW500">{{ currentLatitude }}</p>
                            </div>
                            <div class="col-lg-6">
                                <label class="fontW400">{{ $t("common_longitude") }}</label>
                                <p id="assetDetailBarCurrentLongitude" class="fontW500">{{ currentLongitude }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <label v-if="mapMode !== 'assetlocationhistory'" class="fontW400">{{ $t("common_lastKnownLocation") }}</label>
                                <label v-else class="fontW400">{{ $t("common_lastKnownLocationAssetHistory") }}</label>
                                <p id="assetDetailBarCurrentDate" class="fontW500">{{ currentLocationDate }}</p>
                            </div>
                        </div>
                        <div class="row kt-hidden">
                            <div class="col-lg-12">
                                <label class="fontW400">{{ $t("common_lastUpdateTimestamp") }}</label>
                                <p id="assetDetailBarCurrentTimestamp" class="fontW500">{{ currentLocationTimestamp / 1000 }}</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import i18n from "../../i18n";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            baseUrl: process.env.VUE_APP_BACKEND_URL,
            name: "",
            image: "",
            unit: "",
            isFrench: this.$i18n.locale === "fr" ? true : false,

            tagMacAddress: "",
            tagSerialNumber: "",
            tagType: "OTHER",
            tagBatteryInstallationDate: "",
            tagBatteryLifetime: "",
            tagRealBatteryStatusValue: -1,

            categoryName: "",
            categoryOriginalName: "",
            categoryIsDefault: false,
            categoryIconUrl: "",
            subcategoryName: "",

            displayedAsset: {}, // The asset currently displayed
            isCustomFieldToReload: false, // Used to reinit custom field HTML tags after the view render (v-for ended)

            currentLatitude: "",
            currentLongitude: "",
            currentLocationTimestamp: "",
            currentLocationDate: "",

            now: moment(),
            estimatedBatteryLifetimeEndDate: moment(),
            estimatedBatteryLifetimeEndDateMinus6Months: moment(),
            estimateBatteryMiddleLifetimeDate: moment(),
            moment: moment,

            playbackAssetTimestamp: [],
            playbackAssetCoordinates: [],
            isShowFloorAssetOnMapComponent: false
        };
    },
    created: function() {
        console.log("Component(AssetDetails)::created() - called");
    },
    mounted: function() {
        console.log("Component(AssetDetails)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    updated: function() {
        //console.log("Component(AssetDetails)::updated() - called");
        this.$nextTick(function() {
            // Code that will run only after the
            // entire view has been re-rendered
            if (this.isCustomFieldToReload) {
                // Initialization of custom fields display after rendered the v-for on displayedAsset.customfields
                this.initCustomFieldsInput(this.displayedAsset);
                this.isCustomFieldToReload = false;
            }
        });
    },
    destroyed: function() {
        console.log("Component(AssetDetails)::destroyed() - called");
        this.setCurrentOpenedAssetIdInDashboard("");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        // Monitor floorsAsset for realtime displaying
        floorsAssets: {
            handler() {
                console.log("Component(AssetDetails)::watch(floorsAssets) handler() - called");
                if (this.currentOpenedAssetIdInDashboard !== "") {
                    this.displayCurrentAssetData();
                }
            },
            deep: true
        },

        // Monitor the current asset (if empty this component is not displayed)
        currentOpenedAssetIdInDashboard(assetId) {
            console.log("Component(AssetDetails)::watch(currentOpenedAssetIdInDashboard) called with : ", assetId);
            this.isShowFloorAssetOnMapComponent = document.getElementById("showfloorassetsonmap") ? true : false;
            if (this.currentOpenedAssetIdInDashboard !== "") {
                // Finally, open lateral bar if not yet opened
                if (!$("#assetDetailBar").hasClass("kt-demo-panel--on")) {
                    this.toggleAssetDetailBar();
                }
                this.isCustomFieldToReload = true;
                // Display the data of the current asset
                this.displayCurrentAssetData();
            } else {
                // Finally, close lateral bar if is opened
                if ($("#assetDetailBar").hasClass("kt-demo-panel--on")) {
                    this.toggleAssetDetailBar();
                }
            }
        },

        user: function(user) {
            console.log("Component(AssetDetails)::watch(user) called with :", user);
            if (user) {
                this.isFrench = this.user.preferredLanguage === "fr" ? true : false;
            }
        },

        currentPlaybackTimeStamp(timestamp) {
            console.log("Component(AssetDetails)::watch(currentPlaybackTimeStamp) called with : ", timestamp);
            const localeFormat = this.isFrench ? "ddd, Do MMM YYYY, H:mm:ss [GMT]ZZ" : "ddd, MMM Do YYYY, H:mm:ss [GMT]ZZ"
            this.currentLocationDate = moment(timestamp).format(localeFormat);
            // alert(JSON.stringify(this.displayedAsset));
            if (this.playbackAssetTimestamp
                && this.playbackAssetTimestamp.length > 0
                && this.playbackAssetCoordinates
                && this.playbackAssetCoordinates.length > 0
            ) {
                // Get timestamp index
                let timestampInd = null;
                for (let ind = 0; ind < this.playbackAssetTimestamp.length; ind++) {
                    if (this.playbackAssetTimestamp[ind] === timestamp) {
                        timestampInd = ind;
                        break;
                    } else if (this.playbackAssetTimestamp[ind] > timestamp) {
                        // Not necessary to continue
                        break;
                    }
                }

                if (timestampInd) {
                    this.currentLatitude = this.playbackAssetCoordinates[timestampInd][1];
                    this.currentLongitude = this.playbackAssetCoordinates[timestampInd][1];
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentOpenedAssetIdInDashboard", "floorsAssets", "mapMode", "currentPlaybackTimeStamp", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["setCurrentOpenedAssetIdInDashboard", "updateCustomFieldValueForAssetById"]),

        // Manage show/hide of asset detail bar
        toggleAssetDetailBar() {
            $("#assetDetailBar").toggleClass("kt-demo-panel--on");

            // asset detail bar has been closed so we set the current asset id to empty
            if (!$("#assetDetailBar").hasClass("kt-demo-panel--on")) {
                this.setCurrentOpenedAssetIdInDashboard("");
            }
        },

        /**
         * Display detail of the asset in this component
         */
        displayCurrentAssetData() {
            console.log("Component(AssetDetails)::displayCurrentAssetData() - called with currentOpenedAssetIdInDashboard:", this.currentOpenedAssetIdInDashboard);
            if (this.currentOpenedAssetIdInDashboard) {
                const oldAsset = this.displayedAsset;
                if (this.mapMode !== "assetlocationhistory") {
                    // Get the asset to display
                    const asset = this.$store.getters.getFloorAssetById(this.currentOpenedAssetIdInDashboard);
                    console.log("Component(AssetDetails)::displayCurrentAssetData() asset:", asset, " - oldAsset:", oldAsset);
                    this.displayAssetTrackingAssetData(asset, oldAsset);
                } else {
                    // Get the asset to display
                    const asset = this.$store.getters.getHistoryAssetById(this.currentOpenedAssetIdInDashboard);
                    console.log("Component(AssetDetails)::displayCurrentAssetData() asset:", asset);
                    this.displayAssetLocationHistoryAssetData(asset);
                }
            }
        },

        // Display asset details on asset tracking page
        displayAssetTrackingAssetData(asset, oldAsset) {
            if (asset) {
                // Save current displayed asset values
                this.displayedAsset = asset;

                // Is it an asset attribute (except location) to refresh
                if (commonVueHelper.isChangedAsset(asset, oldAsset)) {
                    console.log("Component(AssetDetails)::displayAssetTrackingAssetData() - An asset attribute has changed => Refresh asset");

                    this.name = asset.name || asset.assetName;
                    this.image = asset.image || asset.assetImage;
                    this.unit = asset.assetUnit ? asset.assetUnit.isDefault ? i18n.t(asset.assetUnit.name) : asset.assetUnit.name : "";

                    this.tagMacAddress = asset.tag.macAddress ? asset.tag.macAddress : "-";
                    this.tagSerialNumber = asset.tag.serialNumber ? asset.tag.serialNumber : "-";
                    if (asset.tag.tagType) {
                        // In asset the tagType is an id
                        this.tagType = asset.tag.tagType.name;
                        this.tagBatteryLifetime = asset.tag.tagType.batteryLifetime;
                    }

                    this.tagBatteryInstallationDate = asset.tag.batteryInstallationDate;

                    // Set the right value of tag real battery status
                    if ((asset.tag.realBatteryStatusValue && asset.tag.realBatteryStatusValue < 0) || !asset.tag.realBatteryStatusValue) {
                        // Set to -1 not value is lower than 0
                        this.tagRealBatteryStatusValue = -1;
                    } else if (asset.tag.realBatteryStatusValue && asset.tag.realBatteryStatusValue >= 100) {
                        // Set to 0 not value is greater or equal to 100
                        this.tagRealBatteryStatusValue = 100;
                    } else {
                        // Set the realt atg value
                        this.tagRealBatteryStatusValue = asset.tag.realBatteryStatusValue;
                    }

                    this.estimatedBatteryLifetimeEndDate = moment(this.tagBatteryInstallationDate).add(this.tagBatteryLifetime, "months");
                    this.estimatedBatteryLifetimeEndDateMinus6Months = moment(this.estimatedBatteryLifetimeEndDate).subtract(6, "months");
                    this.estimateBatteryMiddleLifetimeDate = moment(this.estimatedBatteryLifetimeEndDate).subtract(this.tagBatteryLifetime / 2, "months");

                    this.categoryName = asset.assetcategory && asset.assetcategory.name ? asset.assetcategory.name : asset.categoryName ? asset.categoryName : "-";
                    this.categoryOriginalName = asset.assetcategory && asset.assetcategory.name ? asset.assetcategory.name : asset.categoryName ? asset.categoryName : "-";

                    this.categoryIsDefault = asset.isDefaultCategory || (asset.assetcategory && asset.assetcategory.isDefault);

                    if (asset.assetsubcategory || asset.subcategoryName) {
                        this.subcategoryName = asset.assetsubcategory && asset.assetsubcategory.name ? asset.assetsubcategory.name : asset.subcategoryName ? asset.subcategoryName : "-";
                        if (asset.isDefaultSubcategory || (asset.assetsubcategory && asset.assetsubcategory.isDefault)) {
                            if ((asset.assetsubcategory && asset.assetsubcategory.name !== "-") && asset.subcategoryName !== '-') {
                                this.subcategoryName = asset.assetsubcategory && asset.assetsubcategory.name ? i18n.t(asset.assetsubcategory.name) : asset.subcategoryName ? i18n.t(asset.subcategoryName) : "-";
                            }
                        }
                    } else {
                        this.subcategoryName = "";
                    }

                    if ((asset.assetcategory && asset.assetcategory.isDefault) || asset.isDefaultCategory) {
                        // If the asset category is a default category, use png image saved in public folder,
                        // for performance reasons.
                        const icon = asset.assetcategory ? asset.assetcategory.name : asset.categoryName 
                        this.categoryIconUrl = "/assets/categories/" + icon + "/Icon.png";
                        // Translate default category name
                        if (this.categoryName !== "-") {
                            this.categoryName = i18n.t(this.categoryName);
                        }
                    } else {
                        if (asset.isDefaultCategory) {
                            this.categoryName = i18n.t(this.categoryName);
                        }
                        // If the category is a custom category, we will use the customicon base64 image
                        this.categoryIconUrl = asset.assetcategory ? asset.assetcategory.iconImg : asset.categoryImage;
                    }
                    // Initialization of custom fields display is made after rendered vue (see update())
                    // Update of custom fields
                    if (!this.isCustomFieldToReload && oldAsset && commonVueHelper.isChangeCustomFields(asset.customfields, oldAsset.customfields)) {
                        // Changed custom field of current category => Update value of custom fields
                        this.updateCustomFields(asset.customfields, oldAsset.customfields);
                    }
                }

                // Refresh battery progress bar status
                this.now = moment();

                console.log("Component(AssetDetails)::displayAssetTrackingAssetData() - Refresh location");
                this.currentLocationTimestamp = asset.tag.location_timestamp;
                const localeFormat = this.isFrench ? "ddd, Do MMM YYYY, H:mm:ss [GMT]ZZ" : "ddd, MMM Do YYYY, H:mm:ss [GMT]ZZ";
                this.currentLocationDate = moment(this.currentLocationTimestamp / 1000).format(localeFormat);

                const location = asset.tag.lastLocation || asset.tag.location;
                this.currentLatitude = location.coordinates[0];
                this.currentLongitude = location.coordinates[1];
            } else {
                console.log("Component(AssetDetails)::displayAssetTrackingAssetData() - Asset " + this.currentOpenedAssetIdInDashboard + " not found in floorsAssets");
            }
        },


        // Display asset details on asset location history page
        displayAssetLocationHistoryAssetData(targetAsset) {
            if (targetAsset && targetAsset.properties && targetAsset.properties.asset) {
                const asset = targetAsset.properties.asset;
                this.playbackAssetTimestamp = targetAsset.properties.time;
                this.playbackAssetCoordinates = targetAsset.geometry.coordinates;
                // Save current displayed asset values
                this.displayedAsset = asset;
                this.name = asset.assetName;
                this.image = asset.assetImage;
                this.unit = asset.assetUnit ? asset.assetUnit.isDefault ? i18n.t(asset.assetUnit.name): asset.assetUnit.name : "";
                this.tagMacAddress = asset.tagMacAddress ? asset.tagMacAddress : "-";
                this.tagSerialNumber = asset.tagSerialNumber ? asset.tagSerialNumber : "-";
                if (asset.tagTypeName) {
                    // In asset the tagType is an id
                    this.tagType = asset.tagTypeName;
                    this.tagBatteryLifetime = asset.tagTypeBatteryLifetime;
                }
                this.tagBatteryInstallationDate = asset.tagBatteryInstallationDate;

                this.estimatedBatteryLifetimeEndDate = moment(this.tagBatteryInstallationDate).add(this.tagBatteryLifetime, "months");
                this.estimatedBatteryLifetimeEndDateMinus6Months = moment(this.estimatedBatteryLifetimeEndDate).subtract(6, "months");
                this.estimateBatteryMiddleLifetimeDate = moment(this.estimatedBatteryLifetimeEndDate).subtract(this.tagBatteryLifetime / 2, "months");

                if (asset.categoryName) {
                    this.categoryName = asset.isDefaultCategory ? i18n.t(asset.categoryName) : asset.categoryName;
                } else {
                    this.categoryName = "-";
                }
                this.categoryOriginalName = this.categoryName;
                this.categoryIsDefault = asset.isDefaultCategory;

                if (asset.subcategoryName) {
                    this.subcategoryName = asset.isDefaultSubcategory ? i18n.t(asset.subcategoryName) : asset.subcategoryName;
                } else {
                    this.subcategoryName = "";
                }

                if (asset.isDefaultCategory) {
                    // If the asset category is a default category, use png image saved in public folder,
                    this.categoryIconUrl = "/assets/categories/" + asset.categoryName + "/Icon.png";
                } else {
                    // If the category is a custom category, we will use the customicon base64 image
                    this.categoryIconUrl = asset.categoryImage && asset.categoryImage.trim() !== "" ? asset.categoryImage : "/assets/categories/ASSET_C_CUSTOM/Icon_marker.png";
                }
            } else {
                this.playbackAssetTimestamp = [];
                this.playbackAssetCoordinates = [];
            }
        },

        resetCustomFieldsInput(asset) {
            console.log("Component(AssetDetails)::resetCustomFieldsInput() - called");
            if (asset && asset.customfields && asset.customfields.length > 0) {
                // Reset displayed custom fields (to avoid bug change category type Enum to other type which doesn't destroy completely the select2)
                for (let i = 0; i < asset.customfields.length; i++) {
                    let customfield = asset.customfields[i];
                    let selectorId = this.getCustomFieldSelectorId(customfield);
                    let selector = $("#" + selectorId);
                    commonVueHelper.destroyTag(selector);
                }
            }
        },

        initCustomFieldsInput(asset) {
            console.log("Component(AssetDetails)::initCustomFieldsInput() - called");
            if (asset && asset.customfields && asset.customfields.length > 0) {
                let mySelf = this;
                for (let i = 0; i < asset.customfields.length; i++) {
                    let customfield = asset.customfields[i];
                    let selectorId = this.getCustomFieldSelectorId(customfield);
                    let selector = $("#" + selectorId);
                    if (selector[0]) {
                        switch (customfield.typec) {
                            case "Bool":
                                commonVueHelper.setCheckBoxVal(selector, customfield.value);
                                break;
                            case "String":
                                selector.val(customfield.value);
                                break;
                            case "Date":
                                commonVueHelper.initDatePicker(selector, customfield.value);
                                break;
                            case "Enum":
                                commonVueHelper.initSelect2CustomField(selector, customfield);
                                break;
                        }
                        if (customfield.typec !== "String") {
                            selector.on("change", event => {
                                if (!event.params || event.params.initialInit === undefined || event.params.initialInit === null) {
                                    mySelf.onChangeCustomField(event);
                                }
                            });
                        } // else string change is managed by the click event linked to onChangeString()
                    } else {
                        console.log("Component(AssetDetails)::initCustomFieldsInput() - Bad selector " + selectorId + " for type " + customfield.typec);
                    }
                }
            }
        },

        updateCustomFields(newCustomFields, oldCustomFields) {
            console.log("Component(AssetDetails)::updateCustomFields() - called");
            if (newCustomFields && oldCustomFields && newCustomFields.length === oldCustomFields.length) {
                for (let i = 0; i < newCustomFields.length; i++) {
                    if (newCustomFields[i].id !== oldCustomFields[i].id || newCustomFields[i].typec !== oldCustomFields[i].typec) {
                        // Other attribute than value which has been in custom field
                        // To force reload initCustomFieldsInput
                        this.resetCustomFieldsInput(this.displayedAsset);
                        this.isCustomFieldToReload = true;
                        break;
                    } else {
                        // Update custom field value
                        this.updateCustomField(newCustomFields[i]);
                    }
                }
            } else {
                this.resetCustomFieldsInput(this.displayedAsset);
                this.isCustomFieldToReload = true;
            }
        },

        updateCustomField(customField) {
            let selector = $("#" + this.getCustomFieldSelectorId(customField));
            switch (customField.typec) {
                case "Bool":
                    if (customField.value === "true") {
                        selector.prop("checked", true);
                    } else {
                        selector.prop("checked", false);
                    }
                    break;
                case "String":
                    selector.val(customField.value);
                    break;
                case "Date":
                    commonVueHelper.setDatePickerVal(selector, customField.value);
                    break;
                case "Enum":
                    commonVueHelper.setSelect2Val(selector, customField.value, { initialInit: false });
                    break;
            }
        },

        getCustomFields() {
            if (this.displayedAsset && this.displayedAsset.customfields) {
                const customfields = _.sortBy(this.displayedAsset.customfields, [(obj) => obj.typec == 'URL', 'typec'])
                return _.map(customfields, customField => {
                    if (customField.isStatic && customField.typec === "URL") {
                        if (!/^https?:\/\//i.test(customField.staticValue)) {
                            customField.url = "https://" + customField.staticValue;
                        }
                        else {
                            customField.url = customField.staticValue;
                        }
                    }
                    return customField;
                });
            }
            return [];
        },

        getCustomFieldSelectorId(customField) {
            let name = "cf-" + customField.id;
            return name;
        },

        getCustomFieldIdFromSelectorId(selectorId) {
            let customFieldId = "";
            if (selectorId) {
                let splitName = selectorId.split("-");
                if (splitName && splitName.length > 0) {
                    customFieldId = splitName[splitName.length - 1];
                }
            }
            return customFieldId;
        },

        onChangeCustomField(event) {
            console.log("Component(AssetDetails)::onChangeCustomField() - (event:", event, ", displayedAsset:", this.displayedAsset, ")");
            if (!event.currentTarget) {
                // No target => no change to take into account
                return;
            }
            if (event.currentTarget.name.endsWith("-string")) {
                // It's a string modification, the change is taken with the click on text button
                return;
            }
            if (event.currentTarget.id) {
                let customFieldId = this.getCustomFieldIdFromSelectorId(event.currentTarget.id);
                this.changeCustomField(customFieldId, event.currentTarget);
            }
        },

        onChangeString(selectorId) {
            console.log("Component(AssetDetails)::onChangeString() - called with selectorId: ", selectorId);
            let customFieldId = this.getCustomFieldIdFromSelectorId(selectorId);
            let customFieldValue = $("#" + selectorId).val();
            this.changeCustomField(customFieldId, { value: customFieldValue });
        },

        changeCustomField(customFieldId, target) {
            if (customFieldId) {
                let value = target.value;
                let toUpdate = false;
                // Check if the custom field target has been updated
                for (let i = 0; i < this.displayedAsset.customfields.length; i++) {
                    if (this.displayedAsset.customfields[i].id === customFieldId) {
                        switch (this.displayedAsset.customfields[i].typec) {
                            case "Bool":
                                value = target.checked ? "true" : "false";
                                break;
                            case "Date":
                                value = commonVueHelper.getDatePickerISOString($("#" + target.id));
                                break;
                        }
                        // Case of value is defined for updated customfield or for previous customfield
                        if (this.displayedAsset.customfields[i].value || value) {
                            if (this.displayedAsset.customfields[i].value !== value) {
                                toUpdate = true;
                            }
                        }
                        break;
                    }
                }
                if (toUpdate) {
                    let customFieldToUpdateData = {
                        siteId: this.$route.params.siteId,
                        assetId: this.displayedAsset.id,
                        customFieldId: customFieldId,
                        customFieldValue: value
                    };
                    console.log("Component(AssetDetails)::changeCustomField() - customFieldToUpdateData:", customFieldToUpdateData);
                    this.updateCustomFieldValueForAssetById(customFieldToUpdateData);
                }
            }
        },

        closeAssetDetailBar() {
            console.log("Component(AssetDetails)::closeAssetDetailBar()");
            // The asset detail bar is open
            if ($("#assetDetailBar").hasClass("kt-demo-panel--on")) {
                // Close the asset detail bar
                this.toggleAssetDetailBar();
            }
        },

        showCustomFieldsDetail() {
            if (this.displayedAsset && this.displayedAsset.customfields && this.displayedAsset.customfields.length > 0) {
                return true;
            }
            return false;
        },

        showCustomFieldsDetailOnShowAssetOnMap() {
            let showCustom = false;
            if (this.displayedAsset && this.displayedAsset.customfields && this.displayedAsset.customfields.length > 0) {
                for (let custom of this.displayedAsset.customfields) {
                    if(custom.value !== null) {
                        showCustom = true;
                        break;
                    }
                }
            }
            return showCustom;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.colorWhite {
    color: white !important;
}
.backgroundViolet {
    background: #594495 !important;
}
.backgroundViolet:hover {
    background: #402982 !important;
}
.assetDetailBarSectionMargin:first-child {
    margin-top: -10px;
}
.assetDetailBarSectionMargin {
    margin-left: -32px;
    margin-right: -32px;
}
.assetDetailBarImage {
    background: transparent;
    height: 210px;
}
.assetImage {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.assetSection {
    background: #594495;
    color: white;
    height: 40px;
    font-weight: 500;
    padding-top: 10px;
    padding-left: 10px;
}
.kt-svg-icon g [fill] {
    fill: white !important;
}
.tagTypeIcon {
    width: 20px;
}
.sectionForm {
    margin-left: 15px;
    margin-top: 15px;
    margin-right: 15px;
}
.fontW400 {
    font-weight: 400;
    color: #888888;
}
.fontW500 {
    font-weight: 500;
}
.paddingCustomFieldInput {
    font-weight: 400 !important;
    padding-top: 7px !important;
}
.marginT5 {
    margin-top: 5px;
}
.categoryIcon {
    width: 35px;
}
.unitLabel {
    font-size: 12px;
    font-style: italic;
}
.closeIconLink {
    cursor: pointer;
    background: #ffffff !important;
}
.closeIconLink i {
    color: #594495 !important;
    padding-top: 1px !important;
    padding-left: 1px !important;
}
.inlineFlex {
    display: flex;
}
.batteryStatusIndicatorTxt {
    margin-left: 5px;
    margin-top: -1px;
}
.customAssetCategoryIcon {
    height: 35px;
    width: 35px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 600px;
    vertical-align: middle;
}
.categoryNameForCustomCat {
    margin-left: 42px;
    padding-top: 7px;
    width: 260px;
}
.customFieldItem {
    margin-top: 5px;
    margin-bottom: 5px;
}
.customFieldName {
    color: white;
    margin-top: 10px;
}
</style>
