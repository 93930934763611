<template>
    <div class="modal fade show" id="bulkDeleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common_confirm") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body kt-padding-b-0">
                    <p class="kt-margin-b-0" style=" color: #434349; font-weight: 400; ">{{ getBulkDeleteModalTextContent }}</p>
                    <div class="row kt-margin-t-15 list-bulk-delete">
                        <div class="col-12 border-bottom kt-padding-t-5" v-for="item in dataBulkDelete" v-bind:key="item.id">
                            <p>{{ item.name || item.serialNumber }}</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="margin-top: -1px">
                    <button id="bulkDeleteModalCancelButton" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                    <button id="bulkDeleteModalConfirmButton" type="button" @click="deleteObject" class="btn btn-danger">{{ $t("common_delete") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {};
    },
    created: function() {
        console.log("Component(BulkDeleteModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(BulkDeleteModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(BulkDeleteModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["getBulkDeleteModalTextContent", "getActionName", "dataBulkDelete"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["bulkDeleteResource"]),
        deleteObject() {
            console.log("Component(BulkDeleteModal)::deleteObject() - called");
            this.bulkDeleteResource();
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.list-bulk-delete {
    max-height: 250px;
    overflow-y: auto;
}

.list-bulk-delete::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.list-bulk-delete::-webkit-scrollbar-thumb {
    background-color: #d8dce6;
    border-radius: 4px;
}

.list-bulk-delete::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

</style>
