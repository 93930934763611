import axios from "axios";
import i18n from "./i18n";
import store from "./store";
import router from "./route/router";
import authHelper from "./helpers/authHelper";

// Create global Axios instance for CTC Backends API calls
const instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL
});

let ongoingRequestsNumber = 0;

// Define interceptor for request to log all requests
instance.interceptors.request.use(config => {
    let urlInfo = "";
    if (config) {
        urlInfo = config.method + " " + config.url;
    }
    console.log("axiosBackend::RequestInterceptor() - " + urlInfo + " - ", config);

    // Set is Loading
    if (!config.hideSpinner) {
        store.commit("SET_IS_LOADING", true);
    }

    ongoingRequestsNumber++;

    return config;
});

// Define interceptor for responses to handle certain API responses
instance.interceptors.response.use(
    res => {
        ongoingRequestsNumber--;

        // Reset is Loading
        if (res && res.config && res.config.url && res.config.url !== "/user/token/refresh" && ongoingRequestsNumber === 0) {
            store.commit("SET_IS_LOADING", false);
        }

        // Get API URL
        let urlInfo = "";
        if (res && res.config && res.config.url) {
            let splitUrl = res.config.url.split(res.config.baseURL);
            if (splitUrl.length === 2) {
                urlInfo = res.config.method + " " + splitUrl[1];
            }
        }
        console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - ", res);

        // Check if the url is the assets fetching. In this case no loading is necessary
        let isFetchingAssets = (res ? res.config.url.indexOf("/assets/search") !== -1 : false);
        if (isFetchingAssets && res.data && res.data.data && res.data.data.error) {
            console.error("axiosBackend::ResponseInterceptor() - " + urlInfo + " -- " + JSON.stringify(res.data.data.error, null, 2));
        }

        return res;
    },
    error => {
        ongoingRequestsNumber--;

        if (ongoingRequestsNumber === 0) {
            // Reset is Loading
            store.commit("SET_IS_LOADING", false);
        }

        // Get API URL
        let urlInfo = "";
        if (error && error.config && error.config.url) {
            let splitUrl = error.config.url.split(error.config.baseURL);
            if (splitUrl.length === 2) {
                urlInfo = error.config.method + " " + splitUrl[1];
            }
        }
        if (!error.response) {
            console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Service unreachable.", error);

            // Check if the url is the assets fetching. In this case, no logout
            let isFetchingAssets = (error && error.config && error.config.url ? error.config.url.indexOf("/assets/search") !== -1 : false);
            if (!isFetchingAssets) {
                // If there is no response, it means that the service is temporary unavailable
                // Possible reasons: Server is down
                store.commit("SHOW_NOTIFICATION", {
                    text: i18n.t("error_serverError"),
                    type: "error"
                });
                // Now redirect to the signin page
                if(_.isBoolean(authHelper.isLocalStorageTokenExpired()) && authHelper.isLocalStorageTokenExpired()){
                    store.commit("SET_SESSION_EXPIRED", false);
                    authHelper.clearLocalStorage();
                    router.push({
                        name: "signin"
                    });
                }
            }

        } else {
            console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Error message:", error.response);
            // In case of error with a delete modal, hide the modal to only display the alert toast
            if (error.config && error.config.method && error.config.method.toUpperCase() === "DELETE") {
                store.commit("HIDE_DELETE_MODAL", {});
            }

            let isFetchingAssets = (error && error.config && error.config.url ? error.config.url.indexOf("/assets/search") !== -1 : false);

            if (isFetchingAssets) {
                if( error.response.data && error.response.data.errorDetailsCode && error.response.data.errorDetailsCode === "invalid_token"){
                    store.commit("SHOW_NOTIFICATION", {
                        text: i18n.t("error_invalidToken"),
                        type: "error"
                    });
                    router.push({
                        name: "signin"
                    });
                }
                if( error.response.data && error.response.data.errorDetailsCode && error.response.data.errorDetailsCode === "invalid_visualization_token"){
                    store.commit("SHOW_NOTIFICATION", {
                        text: i18n.t("error_invalidLinkToken"),
                        type: "error"
                    });
                }
                if (error.response.data && error.response.data.errorDetailsCode && error.response.data.errorDetailsCode === "action_not_allowed_no_subscription") {
                    store.commit("SHOW_NOTIFICATION", {
                        text: i18n.t("error_action_not_allowed_no_subscription"),
                        type: "error"
                    });
                }
                if (error.response.data && error.response.data.errorDetailsCode && error.response.data.errorDetailsCode === "action_not_allowed_no_subscription") {
                    store.commit("SHOW_NOTIFICATION", {
                        text: i18n.t("error_action_not_allowed_no_subscription"),
                        type: "error"
                    });
                } else {
                    if (router && router.currentRoute && router.currentRoute.name == "assetsTrackingDashboard") {
                        store.commit("SHOW_NOTIFICATION", {
                            text: i18n.t("error_fetchAssetsNotAvailable"),
                            type: "error"
                        });
                    }
                }
            } else {
                // Show the alert toast
                switch (error.response.status) {
                    case 400:
                        console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 400", error.response);
                        store.commit("SHOW_NOTIFICATION", {
                            text: i18n.t("error_invalidArgument"),
                            type: "error"
                        });
                        break;
                    case 401:
                        switch (error.response.data.errorDetailsCode) {
                            case "invalid_credentials":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - invalidCredentials", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_invalidCredentials"),
                                    type: "error"
                                });
                                if (router.currentRoute.name !== "signin") {
                                    store.commit("SET_SESSION_EXPIRED", false);
                                    authHelper.clearLocalStorage();
                                    router.push({
                                        name: "signin"
                                    });
                                }
                                break;
                            case "invalid_token":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - invalidToken", error.response);
                                if (error.config.url.indexOf("activities") === -1) {
                                    store.commit("SET_SESSION_EXPIRED", false);
                                    authHelper.clearLocalStorage();

                                    // Display a notification for all requests except for activities
                                    setTimeout(function () {
                                        store.commit("SHOW_NOTIFICATION", {
                                            text: i18n.t("error_invalidToken"),
                                            type: "error"
                                        });
                                    }, 500);
                                }
                                // Now redirect to the signin page
                                router.push({
                                    name: "signin"
                                });
                                break;

                            case "expired_resetpasswordtoken":
                            case "invalid_resetpasswordtoken":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - " + error.response.data.errorDetailsCode, error.response);
                                setTimeout(function () {
                                    store.commit("SHOW_NOTIFICATION", {
                                        text: i18n.t(error.response.data.errorDetailsCode),
                                        type: "error"
                                    });
                                }, 500);
                                // Now redirect to the signin page
                                router.push({
                                    name: "signin"
                                });
                                break;

                            case "sameas_resetpasswordtoken":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - sameas_resetpasswordtoken", error.response);
                                setTimeout(function () {
                                    store.commit("SHOW_NOTIFICATION", {
                                        text: i18n.t("sameas_resetpasswordtoken"),
                                        type: "error"
                                    });
                                }, 500);
                                break;
                            case "notsameas_updateuserpassword":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - notsameas_updateuserpassword", error.response);
                                setTimeout(function () {
                                    store.commit("SHOW_NOTIFICATION", {
                                        text: i18n.t("notsameas_updateuserpassword"),
                                        type: "error"
                                    });
                                }, 500);
                                break;
                            case "site_in_production":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_siteInProduction"),
                                    type: "error"
                                });
                                break;
                            case "asset_associatedToPeopleFlowWidget":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_asset_associatedToPeopleFlowWidget"),
                                    type: "error"
                                });
                                break;
                            case "unauthorized_actionOnDefaultRole":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_unauthorized_actionOnDefaultRole"),
                                    type: "error"
                                });
                                break;
                            case "iqm_server_connexion_failed":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_iqm_server_connexion_failed"),
                                    type: "error"
                                });
                                break;
                            case "iqm_device_code_creation_failed":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_iqm_device_code_creation_failed"),
                                    type: "error"
                                });
                                break;
                            case "iqm_device_code_updated_failed":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_iqm_device_code_updated_failed"),
                                    type: "error"
                                });
                                break;
                            case "iqm_server_version_not_valid":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_iqm_server_version_not_valid"),
                                    type: "error"
                                });
                                break;
                            case "unauthorized_default_site_type":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_unauthorized_actionOnDefaultSiteType"),
                                    type: "error"
                                });
                                break;
                            case "invalid_device_token":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                break;
                            default:
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 401 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_unauthorizedAccess"),
                                    type: "error"
                                });
                                break;
                        }
                        break;
                    case 403:
                        switch (error.response.data.errorDetailsCode) {
                            case "user_is_not_authorized":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 403 - guest trying to delete a site", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_userIsNotAuthorizedToDeleteSite"),
                                    type: "error"
                                });
                                break;
                            case "calibration_in_progress_for_site":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 403 - Calibration is in progress for site");
                                if (error.response.data.data) {
                                    switch (error.response.data.data.engineMode) {
                                        case "NEURAL_NETWORK":
                                            store.commit("SHOW_NEURAL_NETWORK_IN_PROGRESS_MODAL", {
                                                startDate: error.response.data.data.startDate
                                            });
                                            break;
                                        case "HAN":
                                            store.commit("SHOW_FINGERPRINT_IN_PROGRESS_MODAL", {
                                                startDate: error.response.data.data.startDate
                                            });
                                            break;
                                        default:
                                            break;
                                    }
                                } else {
                                    console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - No error.response.data.data");
                                }
                                break;
                            case "gateways_not_emit":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 403 - Gateways emission is not satisfied");
                                store.commit("SET_SITE_GATEWAYS_EMISSION", {
                                    gateways: error.response.data.data
                                });
                                break;
                            case "role_associated_to_user":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 403 - Role associated to user profile");
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_roleIsAssociatedToUserProfile"),
                                    type: "error"
                                });
                                break;
                            case "role_associated_to_user_invite":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 403 - Role associated to user invitation");
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_roleIsAssociatedToUserInvitation"),
                                    type: "error"
                                });
                                break;
                            case "action_not_allowed_expired_subscription":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 403 - There is a subscription for this site but it has expired");
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_action_not_allowed_expired_subscription"),
                                    type: "error"
                                });
                                break;
                            case "action_not_allowed_no_subscription":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 403 - There is no subscription yet for this site");
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_action_not_allowed_no_subscription"),
                                    type: "error"
                                });
                                break;
                            case "action_not_allowed_expired_site_guest":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 403 -Guest associated with this site has expired");
                                console.log(" asdfasdf0 ", error.response.data.data.siteName)
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_action_not_allowed_expired_site_guest", {siteName: error.response.data.data.siteName}),
                                    type: "error"
                                });
                                break;
                            default:
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 403 - " + error.response.data.errorDetailsCode, error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_" + error.response.data.errorDetailsCode),
                                    type: "error"
                                });
                                break;
                        }
                        break;
                    case 404:
                        console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 404", error.response);
                        var errCode = "error_notFound";
                        switch (error.response.data.errorResourceName) {
                            case "ShippedTag":
                                errCode = "error_notFound_" + error.response.data.errorPropertyName;
                                break;
                        }
                        store.commit("SHOW_NOTIFICATION", {
                            text: i18n.t(errCode),
                            type: "error"
                        });
                        break;
                    case 409:
                        console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409", error.response);
                        switch (error.response.data.errorLabel) {
                            case "user_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_userAlreadyExists"),
                                    type: "error"
                                });
                                break;
                            case "gateway_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - gateway already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_gatewayAlreadyExists") + " (" + error.response.data.conflictValues.join(", ") + ")",
                                    type: "error"
                                });
                                break;
                            case "gateways_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - gateways already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_gatewaysAlreadyExists") + " (" + error.response.data.conflictValues.join(", ") + ")",
                                    type: "error"
                                });
                                break;
                            case "tag_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - tag already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_tagAlreadyExists") + " (" + error.response.data.conflictValues.join(", ") + ")",
                                    type: "error"
                                });
                                break;
                            case "tags_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - tags already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_tagsAlreadyExists") + " (" + error.response.data.conflictValues.join(", ") + ")",
                                    type: "error"
                                });
                                break;
                            case "autocalibration_tag_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - autocalibrationtag already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_autocalibrationtagAlreadyExists") + " (" + error.response.data.conflictValues.join(", ") + ")",
                                    type: "error"
                                });
                                break;
                            case "autocalibration_tags_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - autocalibrationtags already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_autocalibrationtagsAlreadyExists") + " (" + error.response.data.conflictValues.join(", ") + ")",
                                    type: "error"
                                });
                                break;
                            case "floor_number_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - floor number already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_floorNumberAlreadyExists"),
                                    type: "error"
                                });
                                break;
                            case "assetcategory_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - asset category already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_assetCategoryAlreadyExists") + " (" + error.response.data.conflictValues.join(", ") + ")",
                                    type: "error"
                                });
                                break;
                            case "role_name_already_exists":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - role name already exists", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_roleNameAlreadyExists"),
                                    type: "error"
                                });
                                break;

                            case "del_assetcategory_nofree":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - del_assetcategory_nofree", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_del_assetcategory_nofree") + "<br> - " + error.response.data.conflictValues.join(",<br> - "),
                                    type: "error"
                                });
                                break;
                            case "del_tag_associated_asset":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - tag_associated_asset", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_del_tag_associated_asset") + "<br> - " + error.response.data.conflictValues.join(",<br> - "),
                                    type: "error"
                                });
                                break;
                            case "update_asset_nofree_tag":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - tag_associated_asset", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_update_asset_nofree_tag") + "<br> - " + error.response.data.conflictValues.join(",<br> - "),
                                    type: "error"
                                });
                                break;

                            case "user_already_associated_to_site":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - userhas already access to this site", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_userAlreadyAssociatedToSite"),
                                    type: "error"
                                });
                                break;
                            case "user_already_invited_to_site":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user already invited to join this site", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_userAlreadyInvitedToSite"),
                                    type: "error"
                                });
                                break;
                            case "users_already_invited_to_site":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - users already invited to join this site", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_usersAlreadyInvitedToSite"),
                                    type: "error"
                                });
                                break;
                            case "user_trying_to_revoke_himself_from_site":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to revoke his own access to this site", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_userRevokeHimselfFromSite"),
                                    type: "error"
                                });
                                break;
                            case "asset_associated_to_geonotification":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a asset associated to a geonotification", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_assetAssociatedToGeonotification"),
                                    type: "error"
                                });
                                break;
                            case "asset_category_associated_to_geonotification":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a asset category associated to a geonotification", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_assetCategoryAssociatedToGeonotification"),
                                    type: "error"
                                });
                                break;
                            case "user_associated_to_geonotification":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a user is associated to a geonotification", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_userAssociatedToGeonotification", {geonotifcationNames: error.response.data.conflictValues.join(', ')}),
                                    type: "error"
                                });
                                break;
                            case "geofence_associated_to_geonotification":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a geofence associated to a geonotification", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_geofenceAssociatedToGeonotification"),
                                    type: "error"
                                });
                                break;
                            case "role_associated_to_geonotification":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a role associated to a geonotification", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_roleAssociatedToGeonotification"),
                                    type: "error"
                                });
                                break;
                            case "asset_associated_to_pushButtonAlert":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete an asset is associated to a push button alert", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_assetAssociatedToPushButtonAlert", {pushButtonAlertNames: error.response.data.conflictValues.join(', ')}),
                                    type: "error"
                                });
                                break;
                            case "assetCategory_associated_to_pushButtonAlert":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete an asset category is associated to a push button alert", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_assetCategoryAssociatedToPushButtonAlert", {pushButtonAlertNames: error.response.data.conflictValues.join(', ')}),
                                    type: "error"
                                });
                                break;
                            case "tag_associated_to_pushButtonAlert":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a tag is associated to a push button alert", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_tagAssociatedToPushButtonAlert", {pushButtonAlertNames: error.response.data.conflictValues.join(', ')}),
                                    type: "error"
                                });
                                break;
                            case "role_associated_to_pushButtonAlert":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a role is associated to a push button alert", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_roleAssociatedToPushButtonAlert", {pushButtonAlertNames: error.response.data.conflictValues.join(', ')}),
                                    type: "error"
                                });
                                break;
                            case "pushEventType_associated_to_pushButtonAlert":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a role is associated to a push button alert", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_pushEventTypeAssociatedToPushButtonAlert", {pushButtonAlertNames: error.response.data.conflictValues.join(', ')}),
                                    type: "error"
                                });
                                break;
                            case "building_associated_to_widget":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a building associated to a widget", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_buidingAssociatedToWidget"),
                                    type: "error"
                                });
                                break;
                            case "floor_associated_to_widget":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a floor associated to a widget", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_floorAssociatedToWidget"),
                                    type: "error"
                                });
                                break;
                            case "geofence_associated_to_widget":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a geofence associated to a widget", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_geofenceAssociatedToWidget"),
                                    type: "error"
                                });
                                break;
                            case "buildingFloor_associated_to_widget":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a building which has a floor associated to a widget", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_buidingFloorAssociatedToWidget"),
                                    type: "error"
                                });
                                break;
                            case "pushEventType_already_exist":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a building which has a floor associated to a widget", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_pushEventTypeAlreadyExist"),
                                    type: "error"
                                });
                                break;
                            case "iqmServer_associated_to_geonotification":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a IQM Server configuration is associated to a geonotification", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_IQMServerAssociatedToGeonotification", {geonotificationNames: error.response.data.conflictValues.join(', ')}),
                                    type: "error"
                                });
                                break;
                            case "iqmServer_associated_to_pushButtonAlert":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a IQM Server configuration is associated to a push button alerts", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_IQMServerAssociatedToPushButtonAlert", {pushButtonAlertNames: error.response.data.conflictValues.join(', ')}),
                                    type: "error"
                                });
                                break;
                            case "tagType_reference_already_exist":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - tag type reference already exist", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_tagTypeReferenceAlreadyExists"),
                                    type: "error"
                                });
                                break;
                            case "delete_associated_tagType":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a tag type is associated to one or more tags", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t('tag_tagModelCannotDeleteTagInUse', { name: error.response.data.conflictValues.join(', ') }),
                                    type: "error"
                                });
                                break;
                            case "delete_associated_siteType":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a site type is associated to the site", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t('site_siteTypeCannotDeleteSiteInUse', { name: error.response.data.conflictValues.join(', ') }),
                                    type: "error"
                                });
                                break;
                            case "delete_associated_assetUnit":
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - user trying to delete a asset unit is associated to the asset", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t('asset_assetUnitCannotDeleteAssetInUse', { name: error.response.data.conflictValues.join(', ') }),
                                    type: "error"
                                });
                                break;
                            default:
                                console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 409 - default", error.response);
                                store.commit("SHOW_NOTIFICATION", {
                                    text: i18n.t("error_conflict"),
                                    type: "error"
                                });
                                break;
                        }
                        break;
                    case 500:
                        console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case 500", error.response);
                        store.commit("SHOW_NOTIFICATION", {
                            text: i18n.t("error_serverError"),
                            type: "error"
                        });
                        break;
                    default:
                        console.log("axiosBackend::ResponseInterceptor() - " + urlInfo + " - Case default", error.response);
                        store.commit("SHOW_NOTIFICATION", {
                            text: i18n.t("error_serverError"),
                            type: "error"
                        });
                        break;
                }
            }
        }

        return Promise.reject(error.response);
    }
);

export default instance;