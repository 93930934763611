import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteAssets: null,
        isSearchRequestInProgress: false,
        currentAsset: null,
        createAnother: false,
        floorsAssets: [],
        floorsAsset: null,
        assetsPosition: [],
        siteAssetsInside: [],
        visualizationLink: null,
        siteAssetsSearch: null,
        showAssetSearchModal: false,
        hideAssetSearchModal: false,

        /*
         * This is the id of the current asset opened in assets dashboard
         */
        currentOpenedAssetIdInDashboard: ""
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    siteAssets(state) {
        return state.siteAssets;
    },

    siteAssetsWithTag(state) {
        let result = (state.siteAssets ? state.siteAssets.filter(asset => asset.tag) : null);
        return result;
    },

    currentAsset(state) {
        return state.currentAsset;
    },

    createAnotherAsset(state) {
        return state.createAnother;
    },

    floorsAssets(state) {
        return state.floorsAssets;
    },

    floorsAsset(state) {
        return state.floorsAsset;
    },

    getSiteAssetById: state => assetId => {
        let result = state.siteAssets.find(asset => asset.id == assetId);
        return result;
    },

    getFloorAssetById: state => assetId => {
        let result = state.floorsAssets.find(asset => asset.id == assetId);
        if(!result) {
            result = state.floorsAsset.id === assetId ? state.floorsAsset : null;
        }
        if (!result) {
            console.log("NO RESULT getFloorAssetById(" + assetId + ") with state.floorsAssets: ", state.floorsAssets);
        }
        return result;
    },

    getFloorAssetByTagMacAddress: state => macAddress => {
        let result = state.floorsAssets.find(function(asset) {
            if (asset.tag && asset.tag.macAddress && asset.tag.macAddress == macAddress) {
                return asset;
            }
        });
        return result;
    },

    currentOpenedAssetIdInDashboard(state) {
        return state.currentOpenedAssetIdInDashboard;
    },

    isSearchRequestInProgress(state) {
        return state.isSearchRequestInProgress;
    },

    assetsPosition(state) {
        return state.assetsPosition;
    },

    siteAssetsInside(state) {
        return state.siteAssetsInside;
    },

    visualizationLink(state) {
        return state.visualizationLink;
    },

    siteAssetsSearch(state) {
        return state.siteAssetsSearch;
    },

    showAssetSearchModal(state) {
        return state.showAssetSearchModal;
    },

    hideAssetSearchModal(state) {
        return state.hideAssetSearchModal;
    },
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_ASSETS(state, data) {
        state.siteAssets = data.assets;
    },

    REMOVE_ASSET_BY_ID(state, assetId) {
        // Find asset index
        let index = _.findIndex(state.siteAssets, { id: assetId });
        state.siteAssets.splice(index, 1);
    },

    SET_CURRENT_ASSET(state, data) {
        if (!state.siteAssets) {
            state.siteAssets = [];
        }
        // Set the current asset
        state.currentAsset = data.currentAsset;
        // Find asset index
        let index = -1;
        if (data.currentAsset) {
            index = _.findIndex(state.siteAssets, { id: data.currentAsset.id });
        }
        if (index >= 0) {
            // Replace the asset in the array of assets
            state.siteAssets.splice(index, 1, data.currentAsset);
        } else {
            if (data.currentAsset) {
                state.siteAssets.push(data.currentAsset);
            }
        }
    },

    SET_CREATE_ANOTHER_ASSET(state, createAnother) {
        state.createAnother = createAnother;
    },

    SET_ASSETS_INSIDE_CURRENT_FLOOR(state, data) {
        state.floorsAssets = data.floorsAssets;
    },

    SET_ASSET_INSIDE_CURRENT_FLOOR(state, data) {
        state.floorsAsset = data.floorsAsset;
    },

    RESET_FLOOR_ASSETS(state) {
        state.floorsAssets = [];
    },

    SET_CURRENT_OPENED_ASSET_ID_IN_DASHBOARD(state, assetId) {
        state.currentOpenedAssetIdInDashboard = assetId;
    },

    SET_CURRENT_OPENED_ASSET_IN_FLOORS(state, data) {
        // Find asset index
        let index = -1;
        if (state.floorsAssets) {
            index = _.findIndex(state.floorsAssets, { id: data.asset.id });
        }
        if (index >= 0) {
            let floorAssetToUpdate = data.asset;
            if (state.floorsAssets[index] && state.floorsAssets[index].tag && floorAssetToUpdate && floorAssetToUpdate.tag && floorAssetToUpdate.tag.id === state.floorsAssets[index].tag.id) {
                // Add current tag location to the floor asset to update
                floorAssetToUpdate.tag = state.floorsAssets[index].tag;
            }
            // Replace the asset in the array of assets
            state.floorsAssets.splice(index, 1, floorAssetToUpdate);
        } else {
            console.log("SET_CURRENT_OPENED_ASSET_IN_FLOORS: Asset not in floors => no update of the floorsAssets array with:", data.asset);
        }
    },

    SET_ASSETS_CURRENT_FLOOR_POSITION(state, data) {
        state.assetsPosition = data.assetsPosition;
    },

    SET_ASSET_SEARCH_REQUEST_INPROGRESS(state, value) {
        state.isSearchRequestInProgress = value;
    },

    SET_ASSETS_INSIDE_CURRENT_SITE(state, data) {
        state.siteAssetsInside = data.siteAssetsInside;
    },

    RESET_DEFAULT_ASSETS_STATE(state) {
        Object.assign(state, getDefaultState())
    },

    SET_VISUALIZATION_LINK(state, data) {
        state.visualizationLink = data.visualizationLink;
    },

    SHOW_ASSET_SEARCH_MODAL(state, data) {
        state.showAssetSearchModal = true;
        state.siteAssetsSearch = data.siteAssets;
    },

    HIDE_ASSET_SEARCH_MODAL() {
        state.siteAssetsSearch = null;
        state.hideAssetSearchModal = true;
    },

    RESET_SHOW_ASSET_SEARCH_MODAL() {
        state.showAssetSearchModal = false;
    },

    RESET_HIDE_ASSET_SEARCH_MODAL() {

        state.siteAssetsSearch = null;
        state.hideAssetSearchModal = false;
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherAsset({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_ASSET", createAnother);
    },

    /*
     * Action used to create an asset
     */
    createAsset({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_ASSET", payload.createAnother);
        delete payload.createAnother;
        axiosBackend
            .post("/sites/" + payload.siteId + "/assets", payload, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(res => {
                // Store created asset site in userSites
                commit("SET_CURRENT_ASSET", {
                    currentAsset: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_assetSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to assets list page
                router.push({ name: "assets", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to upload asset image to server
     */
    uploadAssetImage({ dispatch }, payload) {
        var formData = new FormData();
        formData.append("uploadedImage", payload.assetImage);

        axiosBackend
            .post("/sites/" + payload.metadata.siteId + "/assets/upload", formData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(res => {
                // When received a 204, get location URL in header and set into filename
                payload.metadata.image = res.headers.location;
                if (payload.nextAction === "NEW_ASSET") {
                    dispatch("createAsset", payload.metadata);
                } else if (payload.nextAction === "EDIT_ASSET") {
                    dispatch("updateAsset", payload.metadata);
                }
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of site assets
     */
    getSiteAssets({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/assets", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store site assets in userAssets
                commit("SET_SITE_ASSETS", {
                    assets: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one asset by its id
     */
    getAssetById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/assets/" + payload.assetId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_CURRENT_ASSET", {
                    currentAsset: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Search all assets inside dedicated floor id and matching with filters
     */
    searchAssetsInFloor({ commit }, payload) {
        // Update boolean to know if request is in progress or not
        commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", true);
        commit("SET_ASSETS_INSIDE_CURRENT_SITE", { siteAssetsInside: [] });
        axiosBackend
            .post("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/assets/search", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                hideSpinner: true
            })
            .then(res => {
                commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", false);
                // Store assets data only if an error is not detected
                if (!res.data.data.error) {
                    commit("SET_ASSETS_INSIDE_CURRENT_FLOOR", {
                        floorsAssets: res.data.data
                    });
                }
            })
            .catch(error => {
                commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", false);
                console.log(error)
            });
    },

    //Search all assets apply filters, and enable searching across multiple floors
    searchAssetsInSite({ commit }, payload) {
        commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", true);
        commit("SET_ASSETS_INSIDE_CURRENT_SITE", { siteAssetsInside: [] });

        axiosBackend
            .post("/sites/" + payload.siteId + "/assets/search", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                hideSpinner: true
            })
            .then(res => {
                commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", false);
                // Store assets data only if an error is not detected
                if (!res.data.data.error) {
                    const siteAssets = res.data.data;
                    const floorIds = _.map(siteAssets, asset => asset.tag.floorId);
                    if (_.every(floorIds, item => item === floorIds[0])){
                        commit("SET_ASSETS_INSIDE_CURRENT_SITE", {
                            siteAssetsInside: res.data.data
                        });
    
                        setTimeout(() => {
                            commit("SET_ASSETS_INSIDE_CURRENT_FLOOR", {
                                floorsAssets: res.data.data
                            });
                        }, 500);
                    } else {
                        commit("SHOW_ASSET_SEARCH_MODAL", { siteAssets: siteAssets });
                    }
                }
            })
            .catch(error => {
                commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", false);
                console.log(error);
            });
    },

    /*
     * Action used to update an existing asset
     */
    updateAsset({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/assets/" + payload.assetId, payload, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_ASSET", {
                    currentAsset: res.data.data
                });
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_assetSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to assets page
                router.push({ name: "assets", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to change the asset state
     */
    updateAssetState({
        commit, dispatch
    }, payload) {
        axiosBackend
            .put(
                "/sites/" +
                payload.siteId +
                "/assets/" +
                payload.assetId + "/status",
                payload, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }
            )
            .then(res => {

                // Reload up-to-date list of assets
                dispatch("getSiteAssets", payload.siteId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_assetStatusSuccessfullyUpdated"),
                    type: "success"
                });

            })
            .catch(error => {
                // Reload up-to-date list of assets
                dispatch("getSiteAssets", payload.siteId);
                console.log(error)
            });
    },

    /*
     * Action used to delete an asset
     */
    deleteAsset({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/assets/" + payload.assetId, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(() => {
                commit("REMOVE_ASSET_BY_ID", payload.assetId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_assetSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetAssetsState ({ commit }) {
        commit('RESET_DEFAULT_ASSETS_STATE');
    },

    setCurrentOpenedAssetIdInDashboard({ commit }, assetId) {
        commit("SET_CURRENT_OPENED_ASSET_ID_IN_DASHBOARD", assetId);
    },

    updateCustomFieldValueForAssetById({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/assets/" + payload.assetId + "/customfields/" + payload.customFieldId, payload, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_OPENED_ASSET_IN_FLOORS", {
                    asset: res.data.data
                });
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_assetCustomFieldValueSuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    searchAssetsInFloorByIds({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/floors/" + payload.floorId + "/search?assets=" + payload.assets.join(','))
            .then((res) => {
                // Store assets data only if an error is not detected
                if (!res.data.data.error) {
                    if (res.data.data) {
                        commit("SET_ASSETS_CURRENT_FLOOR_POSITION", {
                            assetsPosition: res.data.data
                        });
                        if (res.data.data.assets && res.data.data.assets.length > 0) {
                            commit("SET_ASSETS_INSIDE_CURRENT_FLOOR", {
                                floorsAssets: res.data.data.assets
                            });
                        }
                    } else {
                        // Display notification
                        commit("SHOW_NOTIFICATION", {
                            text: i18n.t("asset_nonePositionFound"),
                            type: "error"
                        });
                        router.push({
                            name: "signin"
                        });
                    }
                }
            })
            .catch(
                error => {
                    console.log(error);
                    router.push({
                        name: "signin"
                    });
                }
            );
    },

    async getAssetLastPositionByTagId({ commit }, payload) {
        try {
            const hours = new Date().getHours();
            const lastPositionData = localStorage.getItem("tag_" + payload.tagId + "_" + payload.locale + "_" + hours + "_lastPosition");

            let response = null;
            if (lastPositionData) {
                response = JSON.parse(lastPositionData);
            } else {
                const keys = Object.keys(localStorage);
                keys.forEach(key => {
                    if (key.startsWith("tag_")) {
                        localStorage.removeItem(key);
                    }
                });
                response = await axiosBackend
                    .get("/tags/" + payload.tagId + "/lastposition",
                        { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
                    );
                localStorage.setItem("tag_" + payload.tagId + "_" + payload.locale + "_" + hours + "_lastPosition", JSON.stringify(response));
            }

            if (response.data.data.asset) {
                commit("SET_ASSET_INSIDE_CURRENT_FLOOR", {
                    floorsAsset: response.data.data.asset
                });
            }
            return commit("SET_ASSETS_CURRENT_FLOOR_POSITION", {
                assetsPosition: response.data.data
            });

        } catch (err) {
            console.log(err);
        }
    },

    generateVisualizationLink({commit}, payload) {
        axiosBackend
            .post("/sites/"+ payload.siteId +"/floors/" + payload.floorId + "/link",
                { assetIds: payload.assetIds },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                    hideSpinner: true
                }
            )
            .then((res) => {
                if (res.data.data) {
                    commit("SET_VISUALIZATION_LINK", {
                        visualizationLink: res.data.data
                    });
                }
            })
            .catch(
                error => {
                    console.log(error);
                    router.push({
                        name: "signin"
                    });
                }
            );
    },

    getAssetVisualizationByToken({commit}, token) {
        commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", true);
        axiosBackend
            .get("/assets/search?token=" + token, {
                hideSpinner: true
            })
            .then((res) => {
                commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", false);
                if (res.data.data) {
                    const expirationDate =  moment(res.data.data.expiredAt);
                    if (expirationDate.isBefore(moment())) {
                        // Display notification
                        commit("SHOW_NOTIFICATION", {
                            text: i18n.t("asset_linkExpired"),
                            type: "error"
                        });

                        return router.push({
                            name: "signin"
                        });
                    }

                    commit("SET_ASSETS_CURRENT_FLOOR_POSITION", {
                        assetsPosition: res.data.data
                    });

                    if (res.data.data.assets && res.data.data.assets.length > 0) {
                        commit("SET_ASSETS_INSIDE_CURRENT_FLOOR", {
                            floorsAssets: res.data.data.assets
                        });
                    }
                } else {
                    // Display notification
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("asset_nonePositionFound"),
                        type: "error"
                    });
                    router.push({
                        name: "signin"
                    });
                }
            })
            .catch(
                error => {
                    commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", false);
                    console.log(error);
                    router.push({
                        name: "signin"
                    });
                }
            );
    },

    /*
     * Action used to reset the floor assets
     */
    resetFloorAssets({ commit }) {
        commit("RESET_FLOOR_ASSETS", {});
        commit("SET_ASSET_SEARCH_REQUEST_INPROGRESS", false);
    },

    /**
     * Action used to create a new IQM device code for asset identified by it id.
     *
     * @param {*} param0
     * @param {*} payload
     */
    createAssetIQMDeviceCode({ commit, dispatch }, payload) {
        axiosBackend
            .post("/sites/" + payload.siteId + "/iqm/" + payload.iqmId + "/devices", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store asset
                // commit("SET_CURRENT_ASSET_STATUS", {
                //     currentAssetStatus: res.data.data
                // });
                if (res && res.data && res.data.data && res.data.data.assetId) {
                    // Load current asset
                    dispatch("getAssetById", {
                        siteId: payload.siteId,
                        assetId: res.data.data.assetId
                    });
                } else {
                    // Load all assets
                    dispatch("getSiteAssets", {
                        siteId: payload.siteId
                    });
                }

                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_IQM_SuccessfullyCreateDevice"),
                    type: "success"
                });
            })
            .catch(error => {
                console.log(error);
            });
    },

    /**
     * Action used to update an IQM device code for asset identified by it id.
     *
     * @param {*} param0
     * @param {*} payload
     */
    updateAssetIQMDeviceCode({ commit, dispatch }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/iqm/" + payload.iqmId + "/devices", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                if (res && res.data && res.data.data && res.data.data.assetId) {
                    // Load current asset
                    dispatch("getAssetById", {
                        siteId: payload.siteId,
                        assetId: res.data.data.assetId
                    });
                } else {
                    // Load all assets
                    dispatch("getSiteAssets", {
                        siteId: payload.siteId
                    })
                }

                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_IQM_SuccessfullyUpdateDevice"),
                    type: "success"
                });
            })
            .catch(error => {
                console.log(error);
            });
    },

    hideAssetSearchModal({ commit }) {
        commit("HIDE_ASSET_SEARCH_MODAL", {});
    },

    resetShowAssetSearchModal({ commit }) {
        commit("RESET_SHOW_ASSET_SEARCH_MODAL", {});
    },

    resetHideAssetSearchModal({ commit }) {
        commit("RESET_HIDE_ASSET_SEARCH_MODAL", {});
    },

};

export default {
    state,
    getters,
    mutations,
    actions
};
