<template>
    <!-- begin:: Header -->
    <div id="kt_header" class="kt-header kt-grid__item kt-header--fixed">
        <!-- begin:: Header Menu -->
        <button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn">
            <i class="la la-close"></i>
        </button>

        <div class="kt-header-menu-wrapper" id="kt_header_menu_wrapper">
            <div
                id="kt_header_menu"
                class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default"
            >
                <autocomplete
                    v-if="mapMode == 'assetsTracking'"
                    :get-result-value="getResultValue"
                    :search="onSearchItem"
                    @submit="handleSubmit"
                    base-class="autocomplete"
                    :placeholder="$t('common_searchByAsset')"
                    :aria-label="$t('common_searchByAsset')"
                    ref="autocompleteRef"
                >
                    <template v-slot:result="{ result, props }">
                        <li v-bind="props" class="autocomplete-result wiki-result">
                            <div
                                v-if="result.hasOwnProperty('isDefault') && result.isDefault == true"
                                class="kt-margin-b-10"
                            >
                                <span>{{ $t(result.name) }}</span>
                                <span class="assetCategoryResult">{{ $t("asset_resultAssetCat") }}</span>
                            </div>
                            <div
                                v-else-if="result.hasOwnProperty('isDefault') && result.isDefault == false"
                                class="kt-margin-b-10"
                            >
                                <span>{{ result.name }}</span>
                                <span class="assetCategoryResult">{{ $t("asset_resultAssetCat") }}</span>
                            </div>
                            <div v-else-if="result.hasOwnProperty('assetcategory')" class="kt-margin-b-10">
                                <span>{{ result.name }}</span>
                                <span class="assetNameResult">{{ $t("asset_resultAssetName") }}</span>
                            </div>
                            <div v-else-if="result.hasOwnProperty('tagSerial') && result.tagSerial == true" class="kt-margin-b-10">
                                <span>{{ result.name }}</span>
                                <span class="tagNameResult">{{ $t("tag_serialNumberLabel") }}</span>
                            </div>
                            <div v-else class="kt-margin-b-10">
                                <span>{{ result.name }}</span>
                            </div>
                        </li>
                    </template>
                </autocomplete>
            </div>
        </div>
        <!-- end:: Header Menu -->

        <!-- begin:: Header Topbar -->
        <div class="kt-header__topbar">
            <app-alarmdropdown></app-alarmdropdown>

            <div class="kt-header__topbar-item kt-header__topbar-item--langs">
                <div
                    class="kt-header__topbar-wrapper"
                    data-toggle="dropdown"
                    data-offset="0px,0px"
                    aria-expanded="false"
                >
                    <span class="kt-header__topbar-icon">
                        <img
                            v-if="this.$i18n.locale === 'fr'"
                            class
                            src="/assets/metronic/media/flags/019-france.svg"
                            alt
                        />
                        <img
                            v-else
                            class
                            src="/assets/metronic/media/flags/226-united-states.svg"
                            alt
                        />
                    </span>
                </div>
                <div
                    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
                    style
                >
                    <ul class="kt-nav kt-margin-t-10 kt-margin-b-10">
                        <li
                            @click.prevent="setLangAsEn()"
                            class="kt-nav__item"
                            :class="this.$i18n.locale == 'en' ? 'kt-nav__item--active' : ''"
                        >
                            <a href="#" class="kt-nav__link">
                                <span class="kt-nav__link-icon">
                                    <img
                                        src="/assets/metronic/media/flags/226-united-states.svg"
                                        alt
                                    />
                                </span>
                                <span class="kt-nav__link-text">{{ $t("common_langEn") }}</span>
                            </a>
                        </li>
                        <li
                            @click.prevent="setLangAsFr()"
                            class="kt-nav__item"
                            :class="this.$i18n.locale === 'fr' ? 'kt-nav__item--active' : ''"
                        >
                            <a href="#" class="kt-nav__link">
                                <span class="kt-nav__link-icon">
                                    <img src="/assets/metronic/media/flags/019-france.svg" alt />
                                </span>
                                <span class="kt-nav__link-text">{{ $t("common_langFr") }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="kt-header__topbar-item kt-header__topbar-item--user">
                <div
                    class="kt-header__topbar-wrapper"
                    data-toggle="dropdown"
                    data-offset="0px,0px"
                    aria-expanded="false"
                >
                    <div class="kt-header__topbar-user">
                        <span
                            v-if="user !== null"
                            class="kt-header__topbar-username kt-hidden-mobile"
                        >{{ user.firstName }} {{ user.lastName }}</span>
                        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
                        <span
                            v-if="user !== null"
                            class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
                        >{{ user.firstName.charAt(0) }} {{ user.lastName.charAt(0) }}</span>
                    </div>
                </div>
                <app-userdropdown></app-userdropdown>
            </div>
        </div>
        <!-- end:: Header Topbar -->
    </div>
    <!-- end:: Header -->
</template>

<script>
import UserDropDown from "./user/userdropdown.vue";
import AlarmDropDown from "./alarm/alarmdropdown.vue";
import { mapGetters, mapActions } from "vuex";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";
import i18n from "../i18n";

export default {
    data() {
        return {
            isAssetsCategoriesLoaded: false,
            isAssetsLoaded: false,
            isLastSearchLoaded: false,
            isTagLoaded: false,
            assets: [],
            tags: [],
            assetCategories: [],
            searchedItems: [],
            lastAssetSearch: []
        };
    },
    created: function() {
        console.log("Component(Header)::created() - called");
    },
    mounted: function () {
        let self = this;
        setTimeout(() => {
            const actionName = this.$route.name;
            if (actionName === "assetsTrackingDashboard") {
                $(".autocomplete-result-list").hide();
                $("body").off().on("click", function(event) {
                    const autocompleteElement = self.$refs && self.$refs.autocompleteRef ? self.$refs.autocompleteRef.$el : null;
                    if (
                        autocompleteElement &&
                        !autocompleteElement.contains(event.target) &&
                        $(".autocomplete-result-list").css("display") === "block"
                    ) {
                        $(".autocomplete-result-list").hide();
                    }
                });
            }
        }, 50);
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteAssetCategories() {
            // Change attribute to add the x button in input
            $(".autocomplete input").attr("type", "search");

            // Listen to focus out
            $(".autocomplete input").focusout(() => {
                if (!$(".autocomplete input").val()) {
                    this.resetSearchedItems();
                }
            });

            if (this.mapMode === "assetsTracking" && this.siteAssetCategories) {
                this.assetCategories = _.uniqBy([...this.assetCategories, ...this.siteAssetCategories], 'id');

                this.isAssetsCategoriesLoaded = true;
                if (this.isAssetsLoaded || this.isTagLoaded || this.isLastSearchLoaded) {
                    // Put list in correct order lastSearch=>assetCategoreis and asset
                    if (this.isAssetsLoaded && this.isAssetsCategoriesLoaded) {
                        this.searchedItems = [];
                        let sortAssetCategories = this.assetCategories.sort(this.sortAssetOrCategoryByName);
                        let sortAssets = this.assets.sort(this.sortAssetOrCategoryByName);
                        let sortTags = _.sortBy(this.tags, 'name');
                        this.searchedItems = this.searchedItems.concat(sortAssets);
                        this.searchedItems = this.searchedItems.concat(sortAssetCategories);
                        this.searchedItems = this.searchedItems.concat(sortTags);
                    } else {
                        let sortAssetCategories = this.assetCategories.sort(this.sortAssetOrCategoryByName);
                        if (this.isAssetsLoaded) {
                            this.searchedItems = sortAssetCategories.concat(this.searchedItems);
                        }
                        if (this.isTagLoaded) {
                            let sortTags = _.sortBy(this.tags, 'name');
                            this.searchedItems = this.searchedItems.concat(sortTags);
                        }
                        else {
                            this.searchedItems = this.searchedItems.concat(sortAssetCategories);
                        }
                    }
                } else {
                    let sortAssetCategories = this.assetCategories.sort(this.sortAssetOrCategoryByName);
                    this.searchedItems = sortAssetCategories;
                }
            }
        },

        siteAssets() {
            // Change attribute to add the x button in input
            $(".autocomplete input").attr("type", "search");

            // Listen to focus out
            $(".autocomplete input").focusout(() => {
                if (!$(".autocomplete input").val()) {
                    this.resetSearchedItems();
                }
            });

            if (this.mapMode === "assetsTracking" && this.siteAssets) {
                this.assets = _.uniqBy([...this.assets, ...this.siteAssets], 'id');
                this.isAssetsLoaded = true;
                let sortAssets = this.assets.sort(this.sortAssetOrCategoryByName);
                if (this.isAssetsCategoriesLoaded || this.isTagLoaded || this.isLastSearchLoaded) {
                    this.searchedItems = this.searchedItems.concat(sortAssets);
                    if (this.isAssetsCategoriesLoaded) {
                        let sortAssetCategories = this.assetCategories.sort(this.sortAssetOrCategoryByName);
                        this.searchedItems = this.searchedItems.concat(sortAssetCategories);
                    }
                    if (this.isTagLoaded) {
                        let sortTags = _.sortBy(this.tags, 'name');
                        this.searchedItems = this.searchedItems.concat(sortTags);
                    }
                } else {
                    this.searchedItems = sortAssets;
                }
            }
        },

        siteTags(tags) {
            // Change attribute to add the x button in input
            $(".autocomplete input").attr("type", "search");

            // Listen to focus out
            $(".autocomplete input").focusout(() => {
                if (!$(".autocomplete input").val()) {
                    this.resetSearchedItems();
                }
            });
            if (this.mapMode === "assetsTracking" && tags) {
                for (let tag of tags) {
                    tag.name = tag.asset ? tag.serialNumber + " (" + tag.asset.name + ")" : tag.serialNumber;
                    tag.tagSerial = true;
                    if(!_.some(this.tags, tag)) {
                        this.tags.push(tag);
                    }
                }
                this.isTagLoaded = true;
                let sortTags = _.sortBy(this.tags, 'name');
                if (this.isAssetsLoaded || this.isAssetsCategoriesLoaded || this.isLastSearchLoaded) {
                    if (this.isAssetsLoaded) {
                        let sortAssets = this.assets.sort(this.sortAssetOrCategoryByName);
                        this.searchedItems = this.searchedItems.concat(sortAssets);
                    }
                    if (this.isAssetsCategoriesLoaded) {
                        let sortAssetCategories = this.assetCategories.sort(this.sortAssetOrCategoryByName);
                        this.searchedItems = this.searchedItems.concat(sortAssetCategories);
                    }
                    this.searchedItems = this.searchedItems.concat(sortTags);
                }
                else {
                    this.searchedItems = this.tags;
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["user", "mapMode", "siteAssetCategories", "siteAssets", "siteTags"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["resetSearchedItems", "addSearchedItem", "updateUserLanguage", "recordSearchActivity"]),

        setLangAsFr() {
            this.$i18n.locale = "fr";
            let userLanguage = {preferredLanguage: "fr"}
            this.updateUserLanguage(userLanguage);
        },

        setLangAsEn() {
            this.$i18n.locale = "en";
            let userLanguage = {preferredLanguage: "en"}
            this.updateUserLanguage(userLanguage);
        },

        /*
         * This function is called each time user will start to search something in search area
         */
        onSearchItem(input) {
            if (input.length < 1) {
                this.resetSearchedItems();
                return [];
            }

            setTimeout(() => {
                if ($(".autocomplete-result-list").css("display") === "none") {
                    $(".autocomplete-result-list").show();
                }
            }, 200);

            return _.uniqBy(this.searchedItems, 'id').filter(item => {
                if (item.isDefault) {
                    return (
                        i18n
                            .t(item.name)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) !== -1
                    );
                } else {
                    return (
                        item.name.toLowerCase().indexOf(input.toLowerCase()) !==
                        -1
                    );
                }
            });
        },

        /*
         * This function is called by plugin to know which value to dsiplay during rendering
         */
        getResultValue(item) {
            if (item.hasOwnProperty("isDefault")) {
                if (item.isDefault) {
                    return i18n.t(item.name);
                } else {
                    return item.name;
                }
            } else {
                return item.name;
            }
        },

        /*
         * This function is when the user submits their result by either selecting a result from the list, or pressing enter/return
         */
        handleSubmit(valueObj) {
            $(".autocomplete-result-list").hide();
            let searchedItemName = "";
            let metricsInfo = {};

            if (valueObj) {

                // valueObj is defined only if user has selected an autocompleete suggested field
                if (valueObj.hasOwnProperty("isDefault")) {

                    metricsInfo.name = i18n.t(valueObj.name);
                    metricsInfo.itemType = "category";
                    metricsInfo.isFromSearchHistory = false;
                    metricsInfo.isFromSuggestions = true;
                    metricsInfo.itemFound = true;

                    // ==> User selected a category that really exist. (Not an history one)
                    console.log("Component(Header)::handleSubmit() - Search for category " +valueObj.name);
                    this.addSearchedItem({
                        type: "CATEGORY",
                        value: valueObj.name
                    });
                    // Verify is it is a default asset category
                    if (valueObj.isDefault) {
                        // Add the new research in local Storage
                        this.updateLocalStorage(i18n.t(valueObj.name));
                    } else {
                        // Add the new research in local Storage
                        this.updateLocalStorage(valueObj.name);
                    }

                } else {

                    metricsInfo.name = valueObj.name;
                    metricsInfo.isFromSuggestions = false;

                    // User selected an asset name or select an item which has been set in history
                    console.log("Component(Header)::handleSubmit() - Search for name " + valueObj.name);
                    this.resetSearchedItems();
                    let itemIndex = this.lastAssetSearch.findIndex(item => item.name == valueObj.name);
                    if (itemIndex != -1) {
                        metricsInfo.isFromSearchHistory = true;
                        let itemFound = false;
                        // For assets
                        for (let asset of this.siteAssets) {
                            if (asset.name.toLowerCase().indexOf(valueObj.name.toLowerCase()) !== -1) {
                                this.addSearchedItem({
                                    type: "ASSET_NAME",
                                    value: asset.name
                                });
                                itemFound = true;
                            }
                        }

                        metricsInfo.itemType = itemFound ? "asset" : null;

                        // For assetCategories
                        for (let cat of this.siteAssetCategories) {
                            if (cat.isDefault) {
                                if (i18n.t(cat.name).toLowerCase().indexOf(valueObj.name.toLowerCase()) !== -1) {
                                    this.addSearchedItem({
                                        type: "CATEGORY",
                                        value: cat.name
                                    });
                                    itemFound = true;
                                }
                            } else {
                                if (cat.name.toLowerCase().indexOf(valueObj.name.toLowerCase()) !== -1) {
                                    this.addSearchedItem({
                                        type: "CATEGORY",
                                        value: cat.name
                                    });
                                    itemFound = true;
                                }
                            }
                        }

                        metricsInfo.itemType = itemFound ? "category" : null;

                        // For tags
                        for (let tag of this.tags) {
                            if (tag.name.toLowerCase().indexOf(valueObj.name.toLowerCase()) !== -1) {
                                this.addSearchedItem({
                                    type: "TAG_SERIAL",
                                    value: tag.serialNumber,
                                    asset: tag.asset.name
                                });
                                itemFound = true;
                            }
                        }

                        metricsInfo.itemType = itemFound ? "tag" : null;

                        if (!itemFound) {
                            // Display modal only if user has entered text and we don't have found any result
                            $("#noSearchResultsModal").modal("show");
                        }
                        metricsInfo.itemFound = itemFound;

                    } else if (valueObj.hasOwnProperty("tagSerial") && valueObj.tagSerial) {
                        this.addSearchedItem({
                            type: "TAG_SERIAL",
                            value: valueObj.serialNumber,
                            asset: valueObj.asset.name
                        });
                        metricsInfo.isFromSearchHistory = false;
                        metricsInfo.itemType = "tag";
                        metricsInfo.itemFound = null;
                    } else {
                        this.addSearchedItem({
                            type: "ASSET_NAME",
                            value: valueObj.name
                        });
                        metricsInfo.isFromSearchHistory = false;
                        metricsInfo.itemType = "asset";
                        metricsInfo.itemFound = null;
                    }

                    // Add the new research in local Storage
                    this.updateLocalStorage(valueObj.name);

                }

            } else {

                metricsInfo.isFromSearchHistory = false;
                metricsInfo.isFromSuggestions = false;

                if ($(".autocomplete input").val()) {

                    // Retrieve input val
                    let inputVal = $(".autocomplete input").val();
                    // Add the new research in local Storage
                    this.updateLocalStorage(inputVal);
                    metricsInfo.name = inputVal;
                    let itemFound = false;

                    this.resetSearchedItems();

                    // User has entered something and tap Enter on his keyboard.
                    // We need to check if there is a category or an asset name matching with entered character.
                    for (let cat of this.siteAssetCategories) {
                        if (cat.isDefault) {
                            if (
                                i18n
                                    .t(cat.name)
                                    .toLowerCase()
                                    .indexOf(inputVal.toLowerCase()) !== -1
                            ) {
                                this.addSearchedItem({
                                    type: "CATEGORY",
                                    value: cat.name
                                });
                                itemFound = true;
                            }
                        } else {
                            if (
                                cat.name
                                    .toLowerCase()
                                    .indexOf(inputVal.toLowerCase()) !== -1
                            ) {
                                this.addSearchedItem({
                                    type: "CATEGORY",
                                    value: cat.name
                                });
                                itemFound = true;
                            }
                        }
                    }
                    metricsInfo.itemType = itemFound ? "category" : null;

                    // For assets
                    for (let asset of this.siteAssets) {
                        if (
                            asset.name
                                .toLowerCase()
                                .indexOf(inputVal.toLowerCase()) !== -1
                        ) {
                            this.addSearchedItem({
                                type: "ASSET_NAME",
                                value: asset.name
                            });
                            itemFound = true;
                        }
                    }

                    metricsInfo.itemType = itemFound ? "asset" : null;

                    // For tags
                    for (let tag of this.tags) {
                        if (
                            tag.name
                                .toLowerCase()
                                .indexOf(inputVal.toLowerCase()) !== -1
                        ) {
                            this.addSearchedItem({
                                type: "TAG_SERIAL",
                                value: tag.serialNumber,
                                asset: tag.asset.name

                            });
                            itemFound = true;
                        }
                    }

                    metricsInfo.itemType = itemFound ? "tag" : null;

                    if (!itemFound) {
                        // Display modal only if user has entered text and we don't have found any result
                        $("#noSearchResultsModal").modal("show");
                    }
                    metricsInfo.itemFound = itemFound;

                } else {

                    // Empty search. ==> Reset search data to empty and force making the request
                    console.log(
                        "Component(Header)::handleSubmit() - Empty Search, will reset the search "
                    );
                    this.resetSearchedItems();

                }

            }

            if (metricsInfo.name && metricsInfo.itemType) {
                this.recordSearchActivity({info: metricsInfo, siteId: this.$route.params.siteId});
            }

        },
        /**
         * This function updates the local storage which contains the last user asset research
         * */
        updateLocalStorage() {
            if (typeof localStorage != "undefined") {
                //Check if a new research is in last reasearch
                // let itemIndex = this.lastAssetSearch.findIndex(item => item.name == lastSearch);
                // if (itemIndex != -1) {
                //     this.lastAssetSearch.splice(itemIndex, 1);
                // }
                // this.lastAssetSearch.unshift({name: lastSearch});
                // localStorage.setItem(this.$route.params.siteId + "_lastAssetSearch", JSON.stringify(this.lastAssetSearch));
                // Update the search input
                // this.searchedItems = this.lastAssetSearch;
                this.searchedItems = [];
                let sortAssetCategories = this.assetCategories.sort(this.sortAssetOrCategoryByName);
                let sortAssets = this.assets.sort(this.sortAssetOrCategoryByName);
                let sortTags = _.sortBy(this.tags, 'name');
                this.searchedItems = this.searchedItems.concat(sortAssets);
                this.searchedItems = this.searchedItems.concat(sortAssetCategories);
                this.searchedItems = this.searchedItems.concat(sortTags);
            }
        },
        /**
         * This function sorts the assetCategories array or assets list by name
         */
        sortAssetOrCategoryByName(obj1, obj2) {
            let name1 = obj1.name;
            let name2 = obj2.name;
            // Verify if it is not a key of asset category
            if (obj1.hasOwnProperty('isDefault') && obj2.hasOwnProperty('isDefault')) {
                if (obj1.isDefault == true) {
                    name1 = i18n.t(obj1.name);
                }

                if (obj2.isDefault == true) {
                    name2 = i18n.t(obj2.name);
                }
            }
            // Do a comparison
            if (name1 > name2) {
                return 1;
            } else if (name1 == name2) {
                return 0
            } else {
                return -1;
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-userdropdown": UserDropDown,
        autocomplete: Autocomplete,
        "app-alarmdropdown": AlarmDropDown
    }
};
</script>

<style>
.autocomplete {
    width: 425px;
    margin-top: 12px;
    margin-left: -12px;
}
@media (max-width: 768px) {
    .autocomplete {
        width: 380px;
    }
    .autocomplete[data-expanded="true"] {
        width: 425px;
    }
}
@media (max-width: 575px) {
    .autocomplete {
        width: 318px;
    }
    .autocomplete[data-expanded="true"] {
        width: 425px;
    }
}
@media (max-width: 500px) {
    .autocomplete {
        width: auto;
    }
}
@media (max-width: 480px) {
    .autocomplete[data-expanded="true"] {
        width: 360px;
    }
}
@media (max-width: 410px) {
    .autocomplete {
        width: auto;
    }
    .autocomplete-input {
        width: 46px;
        padding: 12px 12px 12px 34px;
    }
    .autocomplete[data-expanded="true"] {
        width: 310px;
    }
}
@media (max-width: 360px) {
    .autocomplete[data-expanded="true"] {
        width: 100%;
    }
}
.autocomplete-input {
    font-size: 14px !important;
    margin-top: -3px;
}
.autocomplete-input:focus,
.autocomplete-input[aria-expanded="true"] {
    border-color: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    width: 100%;
}
.assetCategoryResult {
    width: 100px;
    display: block;
    float: right;
    text-align: center;
    padding: 5px 0px 5px 0px;
    background-color: #5d78ff;
    color: white;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 400;
}

.assetNameResult {
    width: 100px;
    display: block;
    float: right;
    text-align: center;
    padding: 5px 0px 5px 0px;
    background-color: #594495;
    color: white;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 400;
}

.tagNameResult {
    width: 100px;
    display: block;
    float: right;
    text-align: center;
    padding: 5px 0px 5px 0px;
    background-color: #594495;
    color: white;
    border-radius: 3px;
    font-size: 10px;
    font-weight: 400;
}
.autocomplete-result{
    cursor: pointer !important;
}
</style>
