/*
 * State: properties to store
 */
const state = {
    /*
     * Indicate if the delete modal has to be displayed
     */
    showImportUsersModal: false,

    /*
     * Indicate if the modal has to be hide
     */
    hideImportUsersModal: false,

    /*
     * Indicate if an upload of users is in progress or not
     */
    isUploadUsersInProgress: false
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    /*
     * Toggle showImportUsersModal property
     */
    SHOW_IMPORT_USERS_MODAL(state) {
        state.showImportUsersModal = true;
    },

    /*
     * Toggle hideImportUsersModal property
     */
    HIDE_IMPORT_USERS_MODAL(state) {
        state.hideImportUsersModal = true;
    },

    /*
     * Reset showImportUsersModal property
     */
    RESET_SHOW_IMPORT_USERS_MODAL(state) {
        state.showImportUsersModal = false;
    },

    /*
     * Reset hideImportUsersModal property
     */
    RESET_HIDE_IMPORT_USERS_MODAL(state) {
        state.hideImportUsersModal = false;
    },

    /*
     * Set isUploadUsersInProgress property
     */
    SET_UPLOAD_USERS_IN_PROGRESS(state, value) {
        state.isUploadUsersInProgress = value;
    }
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for showImportUsersModal
     */
    showImportUsersModal(state) {
        return state.showImportUsersModal;
    },
    /*
     * Getter for hideImportUsersModal
     */
    hideImportUsersModal(state) {
        return state.hideImportUsersModal;
    },
    /*
     * Getter for isUploadUsersInProgress
     */
    isUploadUsersInProgress(state) {
        return state.isUploadUsersInProgress;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    showImportUsersModal({ commit }, data) {
        commit("SHOW_IMPORT_USERS_MODAL", data);
    },

    hideImportUsersModal({ commit }, data) {
        commit("HIDE_IMPORT_USERS_MODAL", data);
    },

    resetShowImportUsersModal({ commit }) {
        commit("RESET_SHOW_IMPORT_USERS_MODAL", {});
    },

    resetHideImportUsersModal({ commit }) {
        commit("RESET_HIDE_IMPORT_USERS_MODAL", {});
    },

    setIsUploadUsersInProgress({ commit }, value) {
        commit("SET_UPLOAD_USERS_IN_PROGRESS", value);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
