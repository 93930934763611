<template>
    <div class="modal fade show" id="neuralNetworkTrainingStatusModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog" style="max-width: 565px;" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("fingerprint_in_progress") }}</h5>
                </div>
                <div class="modal-body">
                    <div class="kt-section kt-section--first">
                        <div class="kt-section__body">
                            <div v-if="this.neuralNetworkTrainingStatus === 'START'">
                                <div class="loader"></div>
                                <p style=" text-align: center; margin-top: 13px; color: black; ">{{ $t("fingerprint_unavailable") }}</p>
                                <p style=" text-align: center; color: #5578eb; font-weight: 400; margin: -5px 10px; ">{{ $t("fingerprint_unavailable_details") }}</p>
                                <p style="text-align: center;margin-top: 20px;color: black;">{{ $t("fingerprint_startedDate") }} {{ this.localNeuralNetworkStartDate }} ({{ localNeuralNetworkStartDateAgo }})</p>
                                <router-link :to="{ name: 'home' }" id="neuralNetworkModal_goHomeButton" class="btn btn-brand btn-bold btn-sm btn-icon-h" style="margin: 25px auto;display: table;">
                                    {{ $t("fingerprint_backToHomePage") }}
                                </router-link>
                            </div>

                            <div v-else-if="this.neuralNetworkTrainingStatus === 'SUCCESS'">
                                <div style=" margin: 0 auto; text-align: center; ">
                                    <i class="la la-check" style=" color: #0abb87; font-size: 7rem; margin-bottom: 24px; "></i>
                                </div>
                                <p style=" text-align: center; color: rgb(10, 187, 135); font-weight: 400; margin: -5px 10px; ">{{ $t("fingerprint_success") }}</p>
                            </div>

                            <div v-else-if="this.neuralNetworkTrainingStatus === 'INTERRUPTION'">
                                <div style=" margin: 0 auto; text-align: center; ">
                                    <i class="la la-exclamation-circle" style=" color: #fd397a; font-size: 7rem; margin-bottom: 24px; "></i>
                                </div>
                                <p style=" text-align: center; color: #fd397a; font-weight: 400; margin: -5px 10px; ">{{ $t("fingerprint_interruption") }}</p>
                            </div>

                            <div v-else-if="this.neuralNetworkTrainingStatus === 'FAILURE'">
                                <div style=" margin: 0 auto; text-align: center; ">
                                    <i class="la la-close" style=" color: #fd397a; font-size: 7rem; margin-bottom: 24px; "></i>
                                </div>
                                <p style=" text-align: center; color: #fd397a; font-weight: 400; margin: -5px 10px; ">{{ $t("fingerprint_failure") }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="this.neuralNetworkTrainingStatus === 'SUCCESS' || this.neuralNetworkTrainingStatus === 'FAILURE' || this.neuralNetworkTrainingStatus === 'INTERRUPTION'" class="modal-footer">
                    <button id="closeNeuralNetworkTrainingStatusModal" type="button" class="btn btn-brand" @click="reloadPage()">{{ $t("common_close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import commonVueHelper from "../../helpers/commonVueHelper";
import moment from "moment";

export default {
    data() {
        return {
            scheduleFetchNeuralNetworkStatus: null,
            neuralNetworkTrainingStatus: "START",
            localNeuralNetworkStartDate: null,
            localNeuralNetworkStartDateAgo: null
        };
    },
    created: function() {
        console.log("Component(NeuralNetworkTrainingStatusModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(NeuralNetworkTrainingStatusModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(NeuralNetworkTrainingStatusModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        showNeuralNetworkInProgressModal() {
            console.log("Component(NeuralNetworkTrainingStatusModal)::watch(showNeuralNetworkInProgressModal) called");
            if (this.showNeuralNetworkInProgressModal) {
                console.log(
                    "Component(NeuralNetworkTrainingStatusModal)::showNeuralNetworkInProgressModal() - show modal"
                );
                if (this.$route.params.siteId) {
                    $("#neuralNetworkTrainingStatusModal").modal("show");
                    this.getNeuralNetworkStatusForSite(this.$route.params.siteId);
                    this.startFetchNeuralNetworkStatusInterval();
                }
            } else {
                this.stopFetchNeuralNetworkStatusInterval();
                $("#neuralNetworkTrainingStatusModal").modal("hide");
            }
        },

        latestNeuralNetworkStartDate() {
            if (this.latestNeuralNetworkStartDate) {
                this.localNeuralNetworkStartDate = moment( this.latestNeuralNetworkStartDate ).format( commonVueHelper.getDateStringFormatFromLocale( moment().locale() ) );
                this.localNeuralNetworkStartDateAgo = moment( this.latestNeuralNetworkStartDate ).fromNow();
            }
        },

        latestNeuralNetworkStatus() {
            console.log("Component(NeuralNetworkTrainingStatusModal)::watch(latestNeuralNetworkStatus) called");
            this.neuralNetworkTrainingStatus = this.latestNeuralNetworkStatus;
            if (
                this.neuralNetworkTrainingStatus === "SUCCESS" ||
                this.neuralNetworkTrainingStatus === "INTERRUPTION" ||
                this.neuralNetworkTrainingStatus === "FAILURE"
            ) {
                this.stopFetchNeuralNetworkStatusInterval();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "showNeuralNetworkInProgressModal",
            "latestNeuralNetworkStatus",
            "latestNeuralNetworkStartDate"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getNeuralNetworkStatusForSite"
        ]),

        reloadPage() {
            window.location.reload();
        },

        startFetchNeuralNetworkStatusInterval() {
            this.stopFetchNeuralNetworkStatusInterval();
            var self = this;
            this.scheduleFetchNeuralNetworkStatus = setInterval(function() {
                self.getNeuralNetworkStatusForSite(self.$route.params.siteId);
            }, 10000);
        },

        stopFetchNeuralNetworkStatusInterval() {
            if (this.scheduleFetchNeuralNetworkStatus) {
                clearInterval(this.scheduleFetchNeuralNetworkStatus);
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.cursorPointer {
    cursor: pointer;
}
</style>
<style>
.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #5578eb;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: 20px auto;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
