<template>
    <div id="app">
        <router-view></router-view>
        <app-loader v-if="isLoading()"></app-loader>
        <app-deletemodal></app-deletemodal>
        <app-assetdetail></app-assetdetail>
        <app-browserdetectionmodal ref="browserDetectionModal"></app-browserdetectionmodal>
        <app-aboutmodal ref="aboutModal"></app-aboutmodal>
        <app-fingerprintstatusmodal ref="fingerprintstatusmodal"></app-fingerprintstatusmodal>
        <app-neuralnetworktrainingstatusmodal ref="neuralnetworktrainingstatusmodal"></app-neuralnetworktrainingstatusmodal>
        <app-nosearchresults></app-nosearchresults>
        <app-betaversionmodal></app-betaversionmodal>
        <app-textmmodal></app-textmmodal>
        <app-reportmodal></app-reportmodal>
        <app-sitetypemodal></app-sitetypemodal>
        <app-assetunitmodal></app-assetunitmodal>
        <app-genericbulkdeletemodal></app-genericbulkdeletemodal>
        <app-genericassetsearchmodal></app-genericassetsearchmodal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "./components/loader.vue";
import DeleteModal from "./components/modals/genericdeletemodal.vue";
import AssetDetail from "./components/dashboard/assetdetails";
import BrowserDetectionModal from "./components/modals/browserdetectionmodal";
import AboutModal from "./components/modals/aboutmodal";
import BetaVersionModal from "./components/modals/betaversionmodal";
import NoSearchResults from "./components/modals/noresultsmodal";
import commonVueHelper from "../src/helpers/commonVueHelper";
import FingerprintStatusModal from "./components/modals/fingerprintstatusmodal";
import NeuralNetworkTrainingStatusModal from "./components/modals/neuralnetworktrainingstatusmodal"
import TextModal from "./components/modals/genericTextModal.vue";
import ReportModal from "./components/modals/genericreportmodal.vue";
import SiteTypeModal from "./components/modals/genericsitetypemodal.vue";
import GenericAssetUnitModal from "./components/modals/genericassetunitmodal.vue";
import GenericBulkDeleteModal from "./components/modals/genericbulkdeletemodal.vue";
import Genericassetsearchmodal from "./components/modals/genericassetsearchmodal.vue";
import i18n from "./i18n";
// eslint-disable-next-line
import _ from "lodash";

export default {
    created() {
        console.log("Component(App)::created() - called");
        // if (window.BroadcastChannel) {
        //     const alarmsListener = new BroadcastChannel("alarmsListener");
        //     alarmsListener.onmessage = e => {
        //         console.log("Component(App)::created() - New event received from service worker !", e.data);
        //         if (e.data === "NEW_ALARM_FIRED") {
        //             // this.$store.dispatch("getAllUserSitesAlarms");
        //         }
        //     };
        // }

        // Configure default options for all toastr
        toastr.options.closeButton = true;
        toastr.options.positionClass = "toast-top-center";
        toastr.options.timeOut = 6000;

        // We cannot use this.$route.query here because vue-router is not available yet
        if (window.location.search.indexOf("inviteeId") == -1) {
            // Try auto login only if we are not coming from email to invite user to join a site
            this.$store.dispatch("tryAutoLogin");
        } else {
            // If url contains an inviteeId, we need to logout the user and redirect to signin/signup page
            let hasAccount = commonVueHelper.getUrlParameter("hasAccount");
            if (hasAccount === "true") {
                // Display message asking the user to sign in directly to the app because user has already an account
                // Silently, call API to create access for the user because the user has already an account.
                let inviteeId = commonVueHelper.getUrlParameter("inviteeId");
                this.confirmInvitationToJoinSite(inviteeId);
                this.sendNotification({
                    text: i18n.t("common_finalize_signin_after_invitation"),
                    type: "success"
                });
                this.logout();
            } else {
                // Display message asking the user to signup first because the user doesn't have any account yet
                this.sendNotification({
                    text: i18n.t("common_finalize_signup_after_invitation"),
                    type: "success"
                });
                let inviteeEmail = commonVueHelper.getUrlParameter("inviteeEmail");
                this.logoutAndSignUp({ inviteeEmail: inviteeEmail });
            }
        }

        // Init UI language using user preferredLanguage
        if (this.user) {
            this.$i18n.locale = this.user.preferredLanguage;
            this.$moment.locale(this.user.preferredLanguage);
        }

        // datepicker support i18n
        $.fn.datepicker.dates["fr"] = {
            days: [
                "dimanche",
                "lundi",
                "mardi",
                "mercredi",
                "jeudi",
                "vendredi",
                "samedi"
            ],
            daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
            daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
            months: [
                "janvier",
                "février",
                "mars",
                "avril",
                "mai",
                "juin",
                "juillet",
                "août",
                "septembre",
                "octobre",
                "novembre",
                "décembre"
            ],
            monthsShort: [
                "janv.",
                "févr.",
                "mars",
                "avril",
                "mai",
                "juin",
                "juil.",
                "août",
                "sept.",
                "oct.",
                "nov.",
                "déc."
            ],
            today: "Aujourd'hui",
            monthsTitle: "Mois",
            clear: "Effacer",
            format: "dd/mm/yyyy",
            weekStart: 1
        };
        $.fn.datetimepicker.dates["fr"] = {
            days: [
                "dimanche",
                "lundi",
                "mardi",
                "mercredi",
                "jeudi",
                "vendredi",
                "samedi",
                "dimanche"
            ],
            daysShort: [
                "dim.",
                "lun.",
                "mar.",
                "mer.",
                "jeu.",
                "ven.",
                "sam.",
                "dim."
            ],
            daysMin: ["d", "l", "ma", "me", "j", "v", "s", "d"],
            months: [
                "janvier",
                "février",
                "mars",
                "avril",
                "mai",
                "juin",
                "juillet",
                "août",
                "septembre",
                "octobre",
                "novembre",
                "décembre"
            ],
            monthsShort: [
                "janv.",
                "févr.",
                "mars",
                "avril",
                "mai",
                "juin",
                "juil.",
                "août",
                "sept.",
                "oct.",
                "nov.",
                "déc."
            ],
            today: "Aujourd'hui",
            clear: "Effacer",
            meridiem: ["", ""],
            suffix: ["", "", "", ""]
        };
    },
    data() {
        return {};
    },
    mounted: function() {
        console.log("Component(App)::mounted() - Init metronic layout");
    },
    destroyed: function() {
        console.log("Component(App)::destroyed() - called");
    },
    computed: {
        ...mapGetters([
            "user",
            "notificationDisplayed",
            "getNotificationText",
            "getNotificationType",
            "showDeleteModal",
            "hideDeleteModal",
            "showTextModal",
            "hideTextModal",
            "showReportModal",
            "hideReportModal",
            "showSiteTypeModal",
            "hideSiteTypeModal",
            "showAssetUnitModal",
            "hideAssetUnitModal",
            "showBulkDeleteModal",
            "hideBulkDeleteModal",
            "showAssetSearchModal",
            "hideAssetSearchModal",
        ])
    },
    watch: {
        user() {
            console.log("Component(App)::user() - called");
            if (this.user) {
                this.$i18n.locale = this.user.preferredLanguage;
                this.$moment.locale(this.user.preferredLanguage);
            }
        },
        notificationDisplayed() {
            console.log("Component(App)::notificationDisplayed() - called");
            if (this.notificationDisplayed) {
                // Don't display the same toast again...
                let toastrContainer = toastr.getContainer();
                if (toastrContainer.length !== 0) {
                    // There is already at least one toast displayed in web page
                    let notificationType = this.getNotificationType;
                    notificationType =
            notificationType === "info" ? "success" : notificationType;
                    let notificationText = this.getNotificationText;
                    // Get toasts of the same type
                    let displayedToasts = toastrContainer[0].getElementsByClassName(
                        "toast-" + notificationType
                    );
                    for (let toast of displayedToasts) {
                        let toastMessages = toast.getElementsByClassName("toast-message");
                        if (toastMessages[0].innerHTML === notificationText) {
                            // Same toast is already displayed, no need to display it again
                            this.resetNotification();
                            return;
                        }
                    }
                }

                switch (this.getNotificationType) {
                    case "success":
                        toastr
                            .success(this.getNotificationText)
                            .css({ width: "auto", "max-width": "35%" });
                        break;
                    case "info":
                        toastr
                            .success(this.getNotificationText)
                            .css({ width: "auto", "max-width": "35%" });
                        break;
                    case "warning":
                        toastr
                            .warning(this.getNotificationText)
                            .css({ width: "auto", "max-width": "35%" });
                        break;
                    case "error":
                        toastr
                            .error(this.getNotificationText)
                            .css({ width: "auto", "max-width": "35%" });
                        break;
                }
            }
            this.resetNotification();
        },
        showDeleteModal() {
            if (this.showDeleteModal) {
                console.log("Component(App)::showDeleteModal() - show modal");
                $("#deleteModal").modal("show");
            }
            this.resetShowDeleteModal();
        },
        hideDeleteModal() {
            if (this.hideDeleteModal) {
                console.log("Component(App)::hideDeleteModal() - hide modal");
                setTimeout(() => {
                    $("#deleteModal").modal("hide");
                }, 500);
            }
            this.resetHideDeleteModal();
        },
        showBulkDeleteModal() {
            if (this.showBulkDeleteModal) {
                console.log("Component(App)::showBulkDeleteModal() - show modal");
                $("#bulkDeleteModal").modal("show");
            }
            this.resetShowBulkDeleteModal();
        },
        hideBulkDeleteModal() {
            if (this.hideBulkDeleteModal) {
                console.log("Component(App)::hideBulkDeleteModal() - hide modal");
                setTimeout(() => {
                    $("#bulkDeleteModal").modal("hide");
                }, 500);
            }
            this.resetHideBulkDeleteModal();
        },
        showReportModal() {
            if (this.showReportModal) {
                console.log("Component(App)::showReportModal() - show modal");
                $("#reportModal").modal("show");
            }
            this.resetShowReportModal();
        },
        hideReportModal() {
            if (this.hideReportModal) {
                console.log("Component(App)::hideReportModal() - hide modal");
                setTimeout(() => {
                    $("#reportModal").modal("hide");
                }, 500);
            }
            this.resetHideReportModal();
        },
        showSiteTypeModal() {
            if (this.showSiteTypeModal) {
                console.log("Component(App)::showSiteTypeModal() - show modal");
                $("#unitModal").modal("show");
            }
            this.resetShowSiteTypeModal();
        },
        hideSiteTypeModal() {
            if (this.hideSiteTypeModal) {
                console.log("Component(App)::hideSiteTypeModal() - hide modal");
                $("#unitModal").modal("hide");
            }
            this.resetHideSiteTypeModal();
        },
        showAssetUnitModal() {
            if (this.showAssetUnitModal) {
                console.log("Component(App)::showAssetUnitModal() - show modal");
                $("#assetUnitModal").modal("show");
            }
            this.resetShowAssetUnitModal();
        },
        hideAssetUnitModal() {
            if (this.hideAssetUnitModal) {
                console.log("Component(App)::hideAssetUnitModal() - hide modal");
                $("#assetUnitModal").modal("hide");
            }
            this.resetHideAssetUnitModal();
        },
        showAssetSearchModal() {
            if (this.showAssetSearchModal) {
                console.log("Component(App)::showAssetSearchModal() - show modal");
                $("#assetSearchModal").modal("show");
            }
            this.resetShowAssetSearchModal();
        },
        hideAssetSearchModal() {
            if (this.hideAssetSearchModal) {
                console.log("Component(App)::hideAssetSearchModal() - hide modal");
                $("#assetSearchModal").modal("hide");
            }
            this.resetHideAssetSearchModal();
        },
        showTextModal() {
            if (this.showTextModal) {
                console.log("Component(App)::showTextModal() - show modal");
                $("#textModal").modal("show");
            }
            this.resetShowTextModal();
        },
        hideTextModal() {
            if (this.hideTextModal) {
                console.log("Component(App)::hideTextModal() - hide modal");
                setTimeout(() => {
                    $("#textModal").modal("hide");
                }, 500);
            }
            this.resetHideTextModal();
        }
    },
    methods: {
        ...mapGetters(["isLoading"]),
        ...mapActions([
            "sendNotification",
            "resetNotification",
            "resetShowDeleteModal",
            "resetHideDeleteModal",
            "resetShowTextModal",
            "resetHideTextModal",
            "logout",
            "logoutAndSignUp",
            "confirmInvitationToJoinSite",
            "resetShowReportModal",
            "resetHideReportModal",
            "resetShowSiteTypeModal",
            "resetHideSiteTypeModal",
            "resetShowAssetUnitModal",
            "resetHideAssetUnitModal",
            "resetShowBulkDeleteModal",
            "resetHideBulkDeleteModal",
            "resetShowAssetSearchModal",
            "resetHideAssetSearchModal",
        ])
    },
    components: {
        "app-loader": Loader,
        "app-deletemodal": DeleteModal,
        "app-assetdetail": AssetDetail,
        "app-browserdetectionmodal": BrowserDetectionModal,
        "app-aboutmodal": AboutModal,
        "app-nosearchresults": NoSearchResults,
        "app-fingerprintstatusmodal": FingerprintStatusModal,
        "app-neuralnetworktrainingstatusmodal": NeuralNetworkTrainingStatusModal,
        "app-betaversionmodal": BetaVersionModal,
        "app-textmmodal": TextModal,
        "app-reportmodal": ReportModal,
        "app-sitetypemodal": SiteTypeModal,
        "app-assetunitmodal": GenericAssetUnitModal,
        "app-genericbulkdeletemodal": GenericBulkDeleteModal,
        "app-genericassetsearchmodal": Genericassetsearchmodal,
    }
};
</script>

<style>
#app {
  display: contents;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: #42b983;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-text {
  color: #cfd7ff;
}

.kt-login.kt-login--v1 .kt-login__wrapper {
  padding: 4rem 3rem !important;
}

body {
  background: #e5e6ea;
}

.blinkAnimation {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.v-step {
  background: #ffffff !important;
  color: #1e1e2d !important;
  font-weight: 500 !important;
  text-align: left !important;
  max-width: 350px !important;
}

.v-step__header {
  color: white !important;
}

.v-step__header strong {
  margin-left: 10px !important;
}

.v-step__arrow--dark:before {
  background: #ffffff !important;
}

.v-step__arrow:before {
  margin-left: 0px !important;
}

.v-step__button-skip {
  font-weight: 500 !important;
  background: #454d5d !important;
  border: .05rem solid #fff;
  border-radius: .1rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: .8rem;
  height: 1.8rem;
  line-height: 1rem;
  outline: none;
  margin: 0 .2rem;
  padding: .35rem .4rem;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  vertical-align: middle;
  white-space: nowrap;
}

.v-step__button {
  background: #5d78ff !important;
  font-weight: 500 !important;
}

.v-step__button:hover {
  background-color: #4a66f5 !important;
  color: white !important;
}

.warningRequireAttention {
  font-size: 20px;
  margin-left: 5px;
  vertical-align: middle;
}

.select2-selection__rendered {
  color: #495057 !important;
  font-weight: 400 !important;
  padding-top: 7px !important;
}

select[id^="cf-"] + span.select2 .select2-selection {
  height: calc(1.5em + 1rem + 2px) !important;
  font-size: 0.875rem !important;
  border-radius: 0.2rem !important;
  padding: 0px 0px !important;
}

#toast-container > div {
  opacity: 1;
  font-weight: 600;
  margin: 10px 0 6px;
}

.openResourceLink {
  margin: 0 auto;
  cursor: pointer;
}

.openResourceLinkTag {
  margin: 0;
  cursor: pointer;
}

.centerResource {
  margin: 0 auto;
}

.modalHelpPlacement {
  max-width: 650px;
}

.modalTagsHealth {
  max-width: 750px;
}

.centerMapIcon {
  background-image: url("/assets/map/center_map.png");
}

.showLabelIcon {
  background-image: url("/assets/map/show.png");
}

.hideLabelIcon {
  background-image: url("/assets/map/hide.png");
}

.cursorPointer {
  cursor: pointer;
}

.width270 {
  width: 270px;
}

.leaflet-range-control {
  background-color: #fff;
}

.leaflet-range-control.vertical {
  width: 34px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
}

.leaflet-range-control .leaflet-range-icon {
  display: inline-block;
  float: left;
  width: 18px;
  height: 18px;
  margin: 2px 6px 7px 6px;
  background-size: 18px;
  background-image: url("/assets/map/opacity_selector.svg");
}

.leaflet-range-control input[type="range"] {
  display: block;
  cursor: pointer;
  width: 100%;
  margin: 0px;
}

.leaflet-range-control input[type="range"][orient="vertical"] {
  height: 150px;
  writing-mode: vertical-lr;
  -webkit-appearance: slider-vertical;
}

/* Hack to target IE 10 & 11 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .leaflet-range-control.vertical {
    padding-bottom: 15px;
  }
  .leaflet-range-control input[type="range"][orient="vertical"] {
    writing-mode: vertical-lr;
    width: 45px;
    margin-left: -11px;
  }
}

.kt-separator.kt-separator--border-dashed {
  border-bottom: 1px dashed #d2d4d8;
}

.separator {
  display: none;
}

.leaflet-tooltip {
  pointer-events: all;
  cursor: pointer;
}

.floorSelectorDropdownBtn {
  border: 1px solid #ccc;
  width: 160px !important;
  font-size: 13px;
  height: 35px !important;
  color: black;
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 2px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: white;
}

.floorSelectorDropdownBtn:hover {
  background: #f4f4f4 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: 2px solid rgba(0, 0, 0, 0.2) !important;
}

.floorSelectorDropdownColor:active {
  color: #ffffff !important;
}

.floorSelectorDropdownHeaderColor {
  color: #5f74e8 !important;
}

.floorSelectorDropdown a {
  color: #707280;
}

.floorSelectorDropdown a:active {
  color: white;
}

.floorSelectorDropdownList {
  overflow-y: auto;
  max-height: 450px;
}

.selectedFloorsList {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto -10px;
}

.selectedFloorsList li {
  padding: 3px 20px;
  border: 1px solid #ccc;
  color: black;
  font-weight: 500;
  border-radius: 15px;
  font-size: 16px;
}

.selectedFloorsList li:not(:last-child) {
  margin-bottom: 2px !important;
}

.leaflet-gateway-location-mouse-marker {
  cursor: crosshair;
}

/* style for tags input*/
/* style the background and the text color of the input ... */
.vue-tags-input .ti-input {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ebedf2 !important;
  border-radius: 4px;
}

.vue-tags-input.ti-focus .ti-input {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 4px;
  color: #495057;
  background-color: #fff;
  border-color: #9aabff !important;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag {
  background: transparent !important;
  border: 1px solid #5d78ff !important;
  color: #5d78ff !important;
  margin-right: 4px !important;
  border-radius: 0px !important;
}

.vue-tags-input ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a7abc3 !important;
  font-size: 13px;
}

.ti-tags {
  font-size: 15px;
}

.ti-new-tag-input {
  color: #495057;
}

.assetTrackingTooltip {
  background: #ffffff;
  border: none;
  color: black;
  padding: 0px 5px 0px 5px;
  font-size: 10px;
  text-align: left;
  font-weight: 400;
}

.assetLocationHistoryTooltip {
  background: #ffffff;
  border: none;
  color: black;
  padding: 0px 5px 0px 5px;
  font-size: 10px;
  text-align: left;
  font-weight: 400;
}

.assetTrackingTooltipContainer {
  display: inline-grid;
}

.assetTrackingTooltipContainer span:first-child {
  font-weight: 500;
  font-size: 11px;
}

.assetLocationHistoryTooltipContainer {
  display: inline-grid;
}

.assetLocationHistoryTooltipContainer span:first-child {
  font-weight: 500;
  font-size: 11px;
}


.leaflet-tooltip-right:before {
  display: none;
}

.toUppercase {
  text-transform: uppercase;
}

.marginTM4 {
  margin-top: -4px;
}

.marginTM30 {
  margin-top: -30px;
}

.noDataForHeatmapDiv {
  position: absolute;
  text-align: center;
  background: white;
  margin-top: 20px;
  max-width: 500px;
  border-radius: 7px;
  padding: 10px;
  color: #d82a2a;
  font-weight: 500;
  font-size: 12px;
  top: 4%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 99999999;
}

@media (display-mode: fullscreen) {
  .noDataForHeatmapDiv {
    top: 7%;
  }
  @media (max-width: 1024px) {
    .noDataForHeatmapDiv {
      top: 11%;
    }
  }
  @media (max-width: 768px) {
    .noDataForHeatmapDiv {
      top: 14%;
    }

    .asset-link, .asset-link .short-cup-status {
      width: 570px;
    }
  }
}

.select2-container--default .select2-results__option .select2-results__group {
  color: #5d77fc;
}

.toUppercase::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  text-transform: none !important;
}

.lowBattery {
  color: #e60505;
  font-weight: 500;
}

.blockui > span {
  color: #2e2e33;
}

.mediumBattery {
  color: #e65c05;
  font-weight: 500;
}

.highBattery {
  color: #2d980d;
  font-weight: 500;
}

.customTooltipGatewayClass {
  background: #ffffff;
  border: none;
  color: black;
  padding: 0px 10px 0px 10px;
  font-size: 11px;
  text-align: center;
}

.customTooltipWalkwayClass {
  background: #ffffff;
  border: none;
  color: black;
  padding: 0px 10px 0px 10px;
  font-size: 11px;
  text-align: center;
}

.customTooltipWallClass {
  background: #ffffff;
  border: none;
  color: black;
  padding: 0px 10px 0px 10px;
  font-size: 11px;
  text-align: center;
}

.customTooltipGeofenceClass {
  background: #ffffff;
  border: none;
  color: black;
  padding: 0px 10px 0px 10px;
  font-size: 11px;
  text-align: center;
}

.leaflet-tooltip-left:before {
  display: none;
}

.customTooltipIndoorAreaClass {
  background: #ffffff;
  border: none;
  color: black;
  padding: 0px 10px 0px 10px;
  font-size: 11px;
  text-align: center;
}

.customSmButtonPadding {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 6px;
    padding-right: 6px;
}

.highlightedName {
  color: #734abd !important;
}

.colorWhite {
  color: white !important;
}

.marginBM20 {
  margin-bottom: -20px !important;
}

.marginB20 {
    margin-bottom: 20px;
}

.marginB0 {
    margin-bottom: 0;
}

.popover {
  min-width: 720px;
  max-width: 720px;
}

.paddingLAR0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.paddingL0 {
  padding-left: 0px !important;
}

.width100P {
  width: 100% !important;
}

.width380P {
  width: 380px !important;
}

.width425P {
  width: 425px !important;
}

.maxWidth378 {
  max-width: 378px;
}

.bubbleGreen {
  width: 10px;
  height: 10px;
  background-color: #8bc63f;
  display: inline-block;
  border-radius: 300px;
  margin-right: 5px;
  position: absolute;
  margin-top: 5px;
}

.displayInlineBlock {
  display: inline-block !important;
}

.paddingTop8 {
  padding-top: 8px;
}

.ovIcon {
  width: 19px;
  height: 12px;
  margin-right: 9px;
  margin-top: 2px;
}

.markerLocationPopup {
  text-align: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-weight: 600;
}

.kt-widget17 .kt-widget17__stats {
  margin: -8.3rem auto 0 auto !important;
}

.bubbleOrange {
  width: 10px;
  height: 10px;
  background-color: #f7931f;
  display: inline-block;
  border-radius: 300px;
  margin-right: 5px;
  position: absolute;
  margin-top: 5px;
}

.bubbleBlack {
  width: 10px;
  height: 10px;
  background-color: #000;
  display: inline-block;
  border-radius: 300px;
  margin-right: 5px;
  position: absolute;
  margin-top: 5px;
}

.bubbleGrey {
  width: 10px;
  height: 10px;
  background-color: #979797;
  display: inline-block;
  border-radius: 300px;
  margin-right: 5px;
  position: absolute;
  margin-top: 5px;
}

.bubbleRed {
  width: 10px;
  height: 10px;
  background-color: #ed1d24;
  display: inline-block;
  border-radius: 300px;
  margin-right: 5px;
  position: absolute;
  margin-top: 5px;
}

.marginL5 {
    margin-left: 5px;
}

.marginL15 {
  margin-left: 15px;
}

.highlightedItemList {
  border-left: 3px solid #734abd;
  padding-left: 5px;
}

.customTooltipActClass {
  background: #ffffff;
  border: none;
  color: black;
  padding: 0px 10px 0px 10px;
  font-size: 11px;
}

.customTooltipActClassCenter {
  background: #ffffff;
  border: none;
  color: black;
  padding: 0px 10px 0px 10px;
  font-size: 11px;
  text-align: center;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__section {
  margin: 10px 0 0 0 !important;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__section:first-child {
  margin: -25px 0 0 0 !important;
}

.leaflet-tooltip-left:before {
  display: none;
}

.trackingDashboardCustomIconCatLarge {
  height: 38px;
  width: 38px;
  background: white;
  background-position: center center;
  background-size: cover;
  border-radius: 600px;
  margin-left: 6px;
  margin-top: 4px;
}

.trackingDashboardCustomIconCatSmall {
  height: 26px;
  width: 27px;
  background: white;
  background-position: center center;
  background-size: cover;
  border-radius: 600px;
  margin-left: 6px;
  margin-top: 4px;
}

.trackingDashboardCustomIconCatWithAvailabilitySmall {
  height: 38px;
  width: 38px;
  background: white;
  background-position: center center;
  background-size: cover;
  border-radius: 600px;
  margin-left: 8px;
  margin-top: 4px;
}

.trackingDashboardCustomIconCatWithAvailabilityLarge {
  height: 38px;
  width: 38px;
  background: white;
  background-position: center center;
  background-size: cover;
  border-radius: 600px;
  margin-left: 8px;
  margin-top: 4px;
}

.text-muted {
  color: #797b88 !important;
  font-weight: 400;
}

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  border: 1px solid #d2d5dc;
}

.form-control {
  border: 1px solid #d2d5dc;
}

.input-group-text {
  border: 1px solid #d2d5dc;
}

.ps > .ps__rail-x > .ps__thumb-x {
  background: #343135;
}

.ps > .ps__rail-y > .ps__thumb-y {
  background: #343135;
}

.ps > .ps__rail-x > .ps__thumb-x:hover,
.ps > .ps__rail-x > .ps__thumb-x:focus {
  background: #343135;
}

.ps > .ps__rail-y > .ps__thumb-y:hover,
.ps > .ps__rail-y > .ps__thumb-y:focus {
  background: #343135;
}

.kt-login.kt-login--v1
  .kt-login__wrapper
  .kt-login__body
  .kt-login__form
  .kt-login__actions
  .kt-login__link-forgot {
  color: #5e2aac;
  font-weight: 400;
}

.select2-selection__clear {
  margin-top: -16px !important;
}

.labelNew {
  background: #5578eb;
  color: white;
  border-radius: 2px;
  padding: 1px 6px;
  margin-left: 7px;
  font-size: 0.78rem;
}

.word-break {
    word-break: break-word;
    line-height: initial;
}

.custom-file-label {
  border: 1px solid #d2d5dc;
}

.select2-results__option {
  color: #495057;
  font-weight: 400;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background: #e1e1e4;
  color: #495057;
}

.select2-container--default
  .select2-results__option.select2-results__option--highlighted {
  background: #e1e1e4;
  color: #495057;
}

.select2-container--default .select2-dropdown {
  border: 1px solid #caccd0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #ced0d6;
}

.marginR5 {
  margin-right: 5px;
}

.marginB0 {
  margin-bottom: 0px;
}

.marginT10 {
  margin-top: 10px;
}

.gatewayMarkerFormat {
  height: 35px;
  width: 35px;
}

.autocalibrationMarkerFormat {
  height: 35px;
  width: 35px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-left: 10px !important;
  margin-bottom: 13px !important;
}

.emptyDefaultMarkerModelLarge {
  background-image: url("/assets/categories/ASSET_C_CUSTOM/Icon_marker.png");
}
.emptyDefaultMarkerModelGreenLarge {
  background-image: url("/assets/categories/ASSET_C_CUSTOM/Icon_marker_vert.png");
}
.emptyDefaultMarkerModelOrangeLarge {
  background-image: url("/assets/categories/ASSET_C_CUSTOM/Icon_marker_orange.png");
}
.emptyDefaultMarkerModelRedLarge {
  background-image: url("/assets/categories/ASSET_C_CUSTOM/Icon_marker_rouge.png");
}

.emptyDefaultMarkerModelSmall {
  background-image: url("/assets/categories/ASSET_C_CUSTOM/Icon_marker_45.png");
}
.emptyDefaultMarkerModelGreenSmall {
  background-image: url("/assets/categories/ASSET_C_CUSTOM/Icon_marker_45_vert.png");
}
.emptyDefaultMarkerModelOrangeSmall {
  background-image: url("/assets/categories/ASSET_C_CUSTOM/Icon_marker_45_orange.png");
}
.emptyDefaultMarkerModelRedSmall {
  background-image: url("/assets/categories/ASSET_C_CUSTOM/Icon_marker_45_rouge.png");
}

.iconPreviewCustomCat {
  height: 30px;
  width: 30px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 300px;
}

.numberOfAssetsDisplayed {
  text-align: center;
  font-size: 12px;
}

.numberOfAssetsDisplayed span {
  color: #5f74e8 !important;
}

.kt-widget14__chart text {
  font-family: Poppins !important;
}

.genericWidgetHeader {
  display: flex;
  justify-content: space-between;
}

.genericWidgetTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.genericWidgetTitle:hover {
  white-space: normal;
}

.genericWidgetContentText {
  font-size: 100px;
  margin-top: -15px;
  font-weight: 500;
}

.genericWidgetContentChart {
  height: 250px;
  justify-content: space-around;
}

.addNewFilterDiv {
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: left;
}

.addNewFilterLabel {
  color: #f8c023;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
}

#toast-container .toast-message {
  font-size: 12px !important;
}

.kt-header
  .kt-header__topbar
  .kt-header__topbar-item.kt-header__topbar-item--user
  .kt-header__topbar-user
  .kt-badge.kt-badge--username {
  font-size: 1.2rem !important;
}

.kt-user-card .kt-user-card__avatar .kt-badge {
  font-size: 1.5rem !important;
}

.marginRowLeftAndRightZero {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/* Class for tagType image */
.imgCardTag {
  width: 23px;
  margin: 10px auto -5px;
  display: block;
}
.imgWristbandTag {
  width: 27px;
  margin: 10px auto -5px;
  display: block;
}
.imgUniversalTag {
  width: 50px;
  margin: 10px auto -5px;
  display: block;
}
.imgBeaconTag {
  width: 46px;
  margin: 10px auto -5px;
  display: block;
}
.imgBeacon2Tag {
  width: 46px;
  margin: 10px auto -5px;
  display: block;
}
.imgAssetTag {
  width: 37px;
  margin: 10px auto -5px;
  display: block;
}
.imgCoinTag {
  width: 37px;
  margin: 10px auto -5px;
  display: block;
}
.tagTypeEl {
  align-items: center;
  display: block;
}
.tagsIconPreview {
  height: 30px;
  width: 30px;
}
.customTagType {
  width: 100%;
  height: 90%;
  margin: 10px auto -5px;
  display: block;
}

.jstree-default .jstree-hovered {
  background: none !important;
}

.jstree-default .jstree-wholerow-clicked {
  background: none !important;
}

.jstree-default .jstree-context {
  background: none !important;
}

.jstree-default-responsive .jstree-wholerow-hovered {
  background: none !important;
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
  background: none !important;
}

.jstree-default .jstree-wholerow-hovered {
  background: none !important;
}

.jstree-default.jstree-checkbox-no-clicked
  > .jstree-wholerow-ul
  .jstree-wholerow-clicked.jstree-wholerow-hovered {
  background: none !important;
}

.leaflet-control-measure .startprompt h3 {
  color: black;
  font-size: 14px;
}

.leaflet-control-measure h3, .leaflet-measure-resultpopup h3 {
    color: black;
    font-size: 14px;
}

/* Class for tagtype label and border if selected or not */
.tagBorderNotSelected {
  border: 1px solid #ebedf2;
}
.tagBorderSelected {
  border: 1px solid #5d78ff;
}
.tagLabelNotSelected {
  color: #6c7293 !important;
}
.tagLabelSelected {
  color: #5d78ff !important;
}

/* Class for walkwaymode label and border if selected or not */
.walkwayBorderNotSelected {
  border: 1px solid #ebedf2;
}
.walkwayBorderSelected {
  border: 1px solid #5d78ff;
}
.walkwayLabelNotSelected {
  color: #6c7293 !important;
}
.walkwayLabelSelected {
  color: #5d78ff !important;
}

.kt-login.kt-login--v1
  .kt-login__wrapper
  .kt-login__body
  .kt-login__form
  .kt-form
  .form-group
  .form-control {
  background-color: rgba(232, 232, 236, 0.7);
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse" !important;
}

.custom-file-input:lang(fr) ~ .custom-file-label::after {
  content: "Parcourir" !important;
}

.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item {
  border: 1px solid #c9c9d4;
}

.kt-widget17 .kt-widget17__stats .kt-widget17__items .kt-widget17__item:hover {
  background: #e9ebf3 !important;
}

.kt-widget17
  .kt-widget17__stats
  .kt-widget17__items
  .kt-widget17__item
  .kt-widget17__desc {
  color: #43454c !important;
  font-weight: 500 !important;
}

.kt-badge.kt-badge--gray {
  color: #ffffff;
  background: #6d7290;
}

.kt-widget.kt-widget--project-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100% + 20px);
}

.kt-widget.kt-widget--project-1 .kt-widget__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 25px;
}

.batteryStatusStr {
  font-size: 11px;
  font-weight: 400;
  color: #312e2e;
}

.marginT4BatStatus {
  margin-top: 4px;
}

.kt-widget.kt-widget--project-1 .kt-widget__head .kt-widget__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.kt-widget.kt-widget--project-1
  .kt-widget__head
  .kt-widget__label
  .kt-widget__media
  .kt-media
  .kt-widget__icon {
  max-width: 62px;
  height: 62px;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
}

.kt-widget.kt-widget--project-1
  .kt-widget__head
  .kt-widget__label
  .kt-widget__media
  .kt-media
  img {
  width: 65px;
}

.kt-widget.kt-widget--project-1
  .kt-widget__head
  .kt-widget__label
  .kt-widget__media.kt-widget__media--m {
  margin-top: 1.5px;
}

.kt-widget.kt-widget--project-1
  .kt-widget__head
  .kt-widget__label
  .kt-widget__info {
  padding: 0.25rem 0 0 1rem;
}

.kt-widget.kt-widget--project-1
  .kt-widget__head
  .kt-widget__label
  .kt-widget__info
  .kt-widget__title {
  font-size: 1.3rem;
  color: #48465b;
  font-weight: 600;
}

.kt-widget.kt-widget--project-1
  .kt-widget__head
  .kt-widget__label
  .kt-widget__info
  .kt-widget__title:hover {
  color: #5d78ff;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.kt-widget.kt-widget--project-1
  .kt-widget__head
  .kt-widget__label
  .kt-widget__info
  .kt-widget__desc {
  padding-top: 0.4rem;
  color: #595d6e;
  font-weight: 500;
  display: block;
}

.kt-widget.kt-widget--project-1 .kt-widget__head .kt-widget__toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.kt-widget.kt-widget--project-1 .kt-widget__body {
  padding: 25px;
  height: 100%;
  padding-top: 0 !important;
}

.kt-widget.kt-widget--project-1 .kt-widget__body .kt-widget__stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__stats
  .kt-widget__item {
  padding-top: 1rem;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__stats
  .kt-widget__item
  .kt-widget__date {
  color: #595d6e;
  font-weight: 500;
  padding-bottom: 1rem;
  display: block;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__stats
  .kt-widget__item
  .kt-widget__subtitel {
  color: #595d6e;
  font-weight: 500;
  display: block;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__stats
  .kt-widget__item
  .kt-widget__progress {
  width: 100%;
  margin: 1.4rem 0 0.5rem 0;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__stats
  .kt-widget__item
  .kt-widget__progress
  .kt-widget__stat {
  padding-left: 0.7rem;
  color: #48465b;
  font-weight: 600;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__stats
  .kt-widget__item:not(:first-child):not(:last-child) {
  margin: 0 2.2rem;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__stats
  .kt-widget__item:last-child {
  padding-left: 1rem;
}

.kt-widget.kt-widget--project-1 .kt-widget__body .kt-widget__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 2.7rem 0 1.2rem 0;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__container
  .kt-widget__subtitel {
  color: #595d6e;
  font-weight: 500;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__container
  .kt-widget__progress {
  width: 100%;
  margin: 0 1rem;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__container
  .kt-widget__progress
  .kt-widget__stat {
  padding-left: 0.7rem;
  color: #48465b;
  font-weight: 600;
}

.kt-widget.kt-widget--project-1 .kt-widget__body .kt-widget__text {
  color: #595d6e;
  font-weight: 500;
  margin-top: 2.7rem;
  display: block;
}

.kt-widget.kt-widget--project-1 .kt-widget__body .kt-widget__content {
  padding: 1rem 0 1.3rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__content
  .kt-widget__details {
  margin-right: 3.7rem;
  padding-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__content
  .kt-widget__details
  .kt-widget__subtitle {
  color: #595d6e;
  font-weight: 600;
  padding-bottom: 1.1rem;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__content
  .kt-widget__details
  .kt-widget__value {
  color: #48465b;
  font-weight: 600;
  font-size: 1.2rem;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__content
  .kt-widget__details
  .kt-widget__value
  span {
  color: #74788d;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__content
  .kt-widget__details
  .kt-badge {
  margin: 0.3rem 0 0 7px;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__content
  .kt-widget__details:last-child {
  margin-right: 0;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__content
  .kt-widget__details:last-child
  .kt-widget__subtitle {
  margin-top: 0;
}

.kt-widget.kt-widget--project-1
  .kt-widget__body
  .kt-widget__content
  .kt-widget__details
  .kt-media-group {
  margin-top: -0.5rem;
}

.kt-widget.kt-widget--project-1 .kt-widget__footer {
  border-top: 1px solid #ebedf2;
  width: 100%;
}

.kt-widget.kt-widget--project-1 .kt-widget__footer .kt-widget__wrapper {
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.kt-widget.kt-widget--project-1
  .kt-widget__footer
  .kt-widget__wrapper
  .kt-widget__section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.kt-widget.kt-widget--project-1
  .kt-widget__footer
  .kt-widget__wrapper
  .kt-widget__section
  .kt-widget__blog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.kt-widget.kt-widget--project-1
  .kt-widget__footer
  .kt-widget__wrapper
  .kt-widget__section
  .kt-widget__blog
  i {
  font-size: 1.3rem;
  color: #d2d8e8;
}

.kt-widget.kt-widget--project-1
  .kt-widget__footer
  .kt-widget__wrapper
  .kt-widget__section
  .kt-widget__blog
  .kt-widget__value {
  font-weight: 700;
  padding-left: 0.6rem;
}

.kt-widget.kt-widget--project-1
  .kt-widget__footer
  .kt-widget__wrapper
  .kt-widget__section
  .kt-widget__blog:last-child {
  padding-left: 1.7rem;
}

.custom-field-url {
  text-decoration: none;
  color: #6c7293;
  transition: color 0.3s ease;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.custom-field-url:hover {
  transition: color 0.3s ease;
  color: #5d78ff;
}

@media (max-width: 1024px) {
  .kt-widget.kt-widget--project-1 .kt-widget__head {
    padding-bottom: 1rem;
    padding: 15px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media
    img {
    width: 100%;
    max-width: 50px;
    height: 50px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media
    span {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media.kt-media--fixed {
    width: 50px;
    height: 50px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media.kt-media--fixed
    img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media
    img {
    width: 50px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media.kt-media--md
    img {
    width: 100%;
    max-width: 45px;
    height: 45px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media.kt-media--md
    span {
    width: 45px;
    height: 45px;
    font-size: 1rem;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media.kt-media--md.kt-media--fixed {
    width: 45px;
    height: 45px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media.kt-media--md.kt-media--fixed
    img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media.kt-media--md
    img {
    width: 47px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media
    .kt-media
    .kt-widget__icon {
    margin-top: -0.7rem;
    width: 62px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__head
    .kt-widget__media.kt-widget__media--m {
    margin-top: 1px;
  }

  .kt-widget.kt-widget--project-1 .kt-widget__head .kt-widget__info {
    padding: 0.2rem 0 0 1rem;
  }

  .kt-widget.kt-widget--project-1 .kt-widget__body {
    padding: 15px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__body
    .kt-widget__stats
    .kt-widget__item:not(:first-child):not(:last-child) {
    margin: 0 1.5rem;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__body
    .kt-widget__stats
    .kt-widget__item:last-child {
    padding-left: 0;
  }

  .kt-widget.kt-widget--project-1 .kt-widget__body .kt-widget__text {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .kt-widget.kt-widget--project-1 .kt-widget__body .kt-widget__content {
    padding: 0.5rem 0 1rem 0;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__body
    .kt-widget__content
    .kt-widget__details {
    margin-right: 1.2rem;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__body
    .kt-widget__content
    .kt-widget__details:last-child {
    margin-right: 0;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__body
    .kt-widget__content
    .kt-widget__details:last-child
    .kt-widget__subtitle {
    margin-top: 0;
  }

  .kt-widget.kt-widget--project-1 .kt-widget__footer .kt-widget__wrapper {
    padding: 15px;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__footer
    .kt-widget__wrapper
    .kt-widget__section {
    padding-right: 1rem;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__footer
    .kt-widget__wrapper
    .kt-widget__section
    .kt-widget__blog
    .kt-widget__value {
    padding-left: 0.4rem;
  }

  .kt-widget.kt-widget--project-1
    .kt-widget__footer
    .kt-widget__wrapper
    .kt-widget__section
    .kt-widget__blog:last-child {
    padding-left: 1.2rem;
  }
}

@media (max-width: 410px) {
  #sidebaritem_config_link {
    display: none;
  }
  #sidebaritem_layers_link {
    display: none;
  }
  #sidebaritem_filters_link {
    display: none;
  }
}

@media (max-width: 1024px) {
  .kt-demo-panel {
      padding: 32px !important;
  }
  .searchInputDatatable {
      margin-top: 15px;
  }
  .exportXLSXButton {
      margin-top: -4px;
  }
  .kt-demo-panel {
    padding: 32px !important;
  }
  .searchInputDatatable {
    margin-top: 15px;
  }
}

@media (max-width: 360px) {
  .selectedFloorsList {
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .selectedFloorsList {
    margin-bottom: 35px;
  }
}

.geonotifSelect2 {
  margin-left: 10px;
  margin-top: -9px;
}

.colorGeonotif {
  color: #353131;
}

.select2-container .select2-search__field {
  width: 100% !important;
}

.select2-container .select2-search--inline {
  width: 100% !important;
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  border: 2px solid #000000 !important;
  top: 9px !important;
  left: 9px !important;
  height: 10px !important;
  width: 10px !important;
}

.leaflet-control-geosearch a.leaflet-bar-part:before {
  border-top: 2px solid #000000 !important;
  top: 18px !important;
  left: 16px !important;
  width: 6px !important;
}

.leaflet-control-geosearch form input{
  height: 30px !important;
}

.kt-widget14__chart svg text tspan {
  font-family: "Poppins";
  font-weight: 500;
}

#gatewaysHeatmapSelector + span {
  width: 265px !important;
}

.fontMonospace {
    font-family: monospace
}

.legendScaleBar {
  display: flex;
  /* background: rgba(255,255,255,0.8); */
  line-height: 18px;
  color: #555;
}
.legendScaleBar i {
  width: 5.8rem;
  height: 13px;
  float: left;
}
.legendScaleBar i:first-child {
  border-left: 1px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.legendScaleBar i:last-child {
  border: 1px solid black;
}
.legendScaleBar i:not(:first-child):not(:last-child){
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
}
.legendScaleBar span {
  width: 7rem;
  float: left;
  color: black;
  margin-top: 4px;
}
.legendScaleBar span:first-child {
  width: 5rem;
}
.legendWrapper {
  width: 40rem;
}
.marginLeft60Heatmap {
  margin-left: 60px !important;
}
.marginLeft100Heatmap {
  margin-left: 100px;
}
.heatmapControl {
  padding: 10px 5px;
  width: calc(100% - 80px);
  background-color: #ffffff;
}
.select2OptionHeatmap {
  margin-top: 10px;
  margin-right: 20px;
  z-index: 9999;
  pointer-events: auto;
  padding: 7px 0px;
}
.heatmapControl, .select2OptionHeatmap, .select2-container {
  font-size: 13px;
  font-family: 'Poppins';
}
.kt-header-full-screen {
  margin-left: 60px;
  width: 425px !important;
  clear: both;
  background: none;
  border: none;
  top: 0px !important;
  left: 0px !important;
}

.apOrBeaconDown {
  background: white;
  padding: 15px;
  text-align: center;
  color: #f10000;
  font-weight: 600;
  float: right;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 5px;
  z-index: 1000;
  position: absolute;
}

.apOrBeaconDown.marginT60 {
  margin-top: 60px;
}

.asset-visualizationLink{
  font-weight: bold;
}

.asset-link {
  font-family: 'Poppins';
  color: #6c7293;
  font-weight: 500;
  text-decoration: none;
  position: absolute;
  bottom: calc(100% + 0.1rem);
  left: 50%;
  width: 720px;
  transform: translateX(-50%);
  background: white;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  text-align: left;
  border: 1px solid white;
  box-shadow: 0px 20px 20px 0px rgb(0 0 0 / 11%);
}
.asset-link .arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid white;
    margin: 0 0.3rem;
}

.asset-link-header {
  font-size: 1rem;
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  color: #6c7293 !important;
  background-color: #ffffff;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  text-align: left;
  position: relative;
}

.asset-link-body {
    padding: 1rem 1.25rem;
    color: #a7abc3;
    position: relative;
}

.asset-link-body span {
  color: #6c7293 !important;
}

.asset-link-header h3 {
  font-size: 1rem;
}
.asset-link-header span {
  color: #6c7293 !important;
}

.asset-link .asset-close {
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  color: #6c7293;
}

.asset-link .short-cup-status {
  position: absolute;
  top: 90%;
  left: -1px;
  padding: 0 0.5rem;
  padding-bottom: 0.5rem;
  width: 720px;
  background: white;
  margin: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid white;
}

.asset-link .short-cup-status span {
  color: #6c7293 !important;

}

@media (min-width: 1025px) and (max-width: 1125px) {
  .asset-link, .asset-link .short-cup-status {
    width: 620px;
  }
}

@media (max-width: 870px) and (min-width: 330px) {
  .asset-link {
    left: 5%;
  }
}

@media(max-width: 575px) {
  .asset-link, .asset-link .short-cup-status {
    width: 100vw;
  }
}

.hidden-select-search {
  display: none !important;
}

@media (max-width: 1024px) {
  .apOrBeaconDown {
    margin-top: 115px;
    margin-left: 60px;
  }

  .asset-link, .asset-link .short-cup-status {
    width: 720px;
  }
}

.kt-aside-menu-han {
  background-color: #17bbb3;
}

.kt-aside-han {
  background-color: #17bbb3;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-text-han {
  color: white;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__heading, .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link-han {
  background-color: #23a29b;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__heading, .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__link-han {
  background-color: #23a29b;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__heading, .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__link-han {
  background-color: #23a29b;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__heading, .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link-han {
    background-color: #23a29b;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__heading, .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link-han {
  background-color: #23a29b;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item>.kt-menu__heading .kt-menu__link-text-han, .kt-aside-menu .kt-menu__nav>.kt-menu__item>.kt-menu__link .kt-menu__link-text-han {
  color: white;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item>.kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item>.kt-menu__link .kt-menu__link-text-han {
  color: white;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item>.kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot>span, .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item>.kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot-han>span {
  background-color: white;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot>span, .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot-han>span {
  background-color: white;
}

.kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot>span, .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot-han>span {
  background-color: white;
}

@media (max-width: 1024px) {
  .kt-header-mobile-han {
    background-color: #17bbb3;
  }
  .header-assets-search {
    position: absolute;
    top: 55px;
    left: 0px;
    margin-left: 60px;
    z-index: 999;
  }
  .kt-header-menu-mobile {
    background: none;
  }
  .header-assets-search .autocomplete {
    margin-top: 0px;
  }
  .header-assets-search .autocomplete.marginT12 {
    margin-top: 12px;
  }
  .kt-header__topbar {
    top: 50px;
  }
  .leaflet-control-container .leaflet-top.fiexdTop0p:first-child, #kt_header_menu_wrapper.fiexdTop0p {
    top: 0px !important;
  }
  .heatmapControl {
    margin-top: 45px !important;
  }
  .select2OptionHeatmap{
    margin-top: 0px;
  }
}

@media (min-width: 1025px){
  .kt-aside__brand-han {
    background-color: #17bbb3;
  }

  .marginLeft380p {
    margin-left: 380px !important;
  }

  .paddingAll0 {
    padding: 0 !important;
  }

  .marginAll0 {
    overflow: hidden;
    margin: 0 !important;
  }
}
</style>
