/*
 * State: properties to store
 */
const state = {

    /*
     * Indicate if the text modal has to be displayed
     */
    showTextModal: false,

    /*
     * Indicate if the text modal has to be hide
     */
    hideTextModal: false,

    /*
     * Body Text content currently displayed in the text modal
     */
    textModalTextContent: ""

};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {

    /*
     * Toggle showTextModal property
     */
    SHOW_TEXT_MODAL(state, data) {
        state.showTextModal = true;
        state.textModalTextContent = data.textContent;
    },

    /*
     * Toggle hideTextModal property
     */
    HIDE_TEXT_MODAL(state) {
        state.hideTextModal = true;
    },

    /*
     * Reset showTextModal property
     */
    RESET_SHOW_TEXT_MODAL(state) {
        state.showTextModal = false;
    },

    /*
     * Reset hideTextModal property
     */
    RESET_HIDE_TEXT_MODAL(state) {
        state.hideTextModal = false;
    }

};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    /*
     * Getter for showTextModal
     */
    showTextModal(state) {
        return state.showTextModal;
    },

    /*
     * Getter for hideTextModal
     */
    hideTextModal(state) {
        return state.hideTextModal;
    },

    /*
     * Getter for textModalTextContent
     */
    getTextModalTextContent(state) {
        return state.textModalTextContent;
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    showTextModal({
        commit
    }, data) {
        commit("SHOW_TEXT_MODAL", data);
    },

    hideTextModal({
        commit
    }, data) {
        commit("HIDE_TEXT_MODAL", data);
    },

    resetShowTextModal({
        commit
    }) {
        commit("RESET_SHOW_TEXT_MODAL", {});
    },

    resetHideTextModal({
        commit
    }) {
        commit("RESET_HIDE_TEXT_MODAL", {});
    }
    
};

export default {
    state,
    getters,
    actions,
    mutations
};