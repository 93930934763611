import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteTypes: [],
    }
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    /**
     * Return the site type
     */
    siteTypes(state) {
        const siteTypesCustom = [];
        const siteTypesDefault = [];
        _.forEach(state.siteTypes, siteType => {
            if(siteType.isDefault) {
                siteTypesDefault.push(siteType);
            } else {
                siteTypesCustom.push(siteType);
            }
        });

        const sortedSiteTypesDefault = _.orderBy(siteTypesDefault, [
            item => item.name === 'SITE_OTHER',
        ]);

        return sortedSiteTypesDefault.concat(siteTypesCustom)
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {

    SET_SITE_TYPES(state, data) {
        state.siteTypes = data.siteTypes;
    },

    SET_SITE_TYPE(state, data) {
        state.siteTypes.forEach(siteType => {
            delete siteType.isNewCreated;
        });
        state.siteTypes.push(data.siteType);
    },

    UPDATE_SITE_TYPE(state, data) {
        let index = _.findIndex(state.siteTypes, { isDefault: false, id: data.id });
        if (index !== -1) {
            state.siteTypes.splice(index, 1, {
                id: data.id,
                isDefault: false,
                name: data.name
            });
        }
    },

    REMOVE_SITE_TYPE_BY_ID(state, siteId) {
        let index = _.findIndex(state.siteTypes, { isDefault: false, id: siteId });
        state.siteTypes.splice(index, 1);
    },

    RESET_SITE_GATEWAYS_EMISSION(state) {
        state.showSiteGatewaysEmissionModal = false;
        state.siteGatewaysWhichEmit = [];
    },

    RESET_DEFAULT_SITE_TYPE_STATE(state) {
        Object.assign(state, getDefaultState())
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /**
     * Action used to get default site types
     */
    getAllSiteType({commit}) {
        axiosBackend.get("sitetypes", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // Store created site type in userSites
            commit("SET_SITE_TYPES", {
                siteTypes: res.data.data
            });
        }).catch(error => console.log(error));
    },

    /**
     * Action used to create custom site type
     */
    createNewSiteType({commit}, params) {
        if (!params.siteId) {
            commit("SET_SITE_TYPE", {
                siteType: {
                    id: params.id,
                    isDefault: params.isDefault,
                    name: params.name,
                    isNewCreated: true
                }
            });
            // Display notification
            commit("SHOW_NOTIFICATION", {
                text: i18n.t("site_newSiteTypeSuccessfullyCreated"),
                type: "success"
            });
        } else {
            axiosBackend
                .post("sites/" + params.siteId + "/sitetypes", {
                    name: params.name
                }, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }).then(res => {
                    // Store created user site in userSites
                    commit("SET_SITE_TYPE", {
                        siteType: {
                            ...res.data.data,
                            isNewCreated: true
                        }
                    });
    
                    // Display notification
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("site_newSiteTypeSuccessfullyCreated"),
                        type: "success"
                    });
                })
                .catch(error => console.log(error));
        }
    },

    /**
     * Action used to edit custom site type
     */
    editSiteTypeById({commit}, params) {
        if (!params.siteId) {
            commit("UPDATE_SITE_TYPE", params);
            // Display notification
            commit("SHOW_NOTIFICATION", {
                text: i18n.t("site_siteTypeSuccessfullyUpdated"),
                type: "success"
            });
        } else {
            axiosBackend
                .put("sites/" + params.siteId + "/sitetypes/" + params.id, {
                    name: params.name
                }, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }).then(res => {
                    // Store created user site in userSites
                    commit("UPDATE_SITE_TYPE", res.data.data);

                    // Display notification
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("site_siteTypeSuccessfullyUpdated"),
                        type: "success"
                    });
                })
                .catch(error => console.log(error));
        }
    },

    /**
     * Action used to delete custom site type
     */
    deleteSiteTypeById({commit}, params) {
        if (!params.siteId) {
            commit("REMOVE_SITE_TYPE_BY_ID", params.siteTypeId);
            // Display notification
            commit("SHOW_NOTIFICATION", {
                text: i18n.t("site_siteTypeSuccessfullyDeleted"),
                type: "success"
            });
        } else {
            axiosBackend
                .delete("sites/" + params.siteId + "/sitetypes/" + params.siteTypeId, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }).then(res => {
                    // Store created user site in userSites
                    commit("REMOVE_SITE_TYPE_BY_ID", params.siteTypeId);

                    // Display notification
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("site_siteTypeSuccessfullyDeleted"),
                        type: "success"
                    });
                })
                .catch(error => console.log(error));
        }
    },

    /**
     * Action used to get site types of site
     */
    getAllSiteTypeOfSite({commit}, siteId) {
        axiosBackend.get("sites/"+ siteId +"/sitetypes", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            // Store created site type in userSites
            commit("SET_SITE_TYPES", {
                siteTypes: res.data.data
            });
        }).catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetSiteTypesState ({ commit }) {
        commit('RESET_DEFAULT_SITE_TYPE_STATE');
    },

};

export default {
    state,
    getters,
    mutations,
    actions
};