/*
 * State: properties to store
 */
const state = {
    /*
     * Indicate if the delete modal has to be displayed
     */
    showImportGatewaysModal: false,

    /*
     * Indicate if the modal has to be hide
     */
    hideImportGatewaysModal: false,

    /*
     * Indicate if an upload of gateways is in progress or not
     */
    isUploadGatewaysInProgress: false
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    /*
     * Toggle showImportGatewaysModal property
     */
    SHOW_IMPORT_GATEWAYS_MODAL(state) {
        state.showImportGatewaysModal = true;
    },

    /*
     * Toggle hideImportGatewaysModal property
     */
    HIDE_IMPORT_GATEWAYS_MODAL(state) {
        state.hideImportGatewaysModal = true;
    },

    /*
     * Reset showImportGatewaysModal property
     */
    RESET_SHOW_IMPORT_GATEWAYS_MODAL(state) {
        state.showImportGatewaysModal = false;
    },

    /*
     * Reset hideImportGatewaysModal property
     */
    RESET_HIDE_IMPORT_GATEWAYS_MODAL(state) {
        state.hideImportGatewaysModal = false;
    },

    /*
     * Set isUploadGatewaysInProgress property
     */
    SET_UPLOAD_GATEWAYS_IN_PROGRESS(state, value) {
        state.isUploadGatewaysInProgress = value;
    }
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for showImportGatewaysModal
     */
    showImportGatewaysModal(state) {
        return state.showImportGatewaysModal;
    },
    /*
     * Getter for hideImportGatewaysModal
     */
    hideImportGatewaysModal(state) {
        return state.hideImportGatewaysModal;
    },
    /*
     * Getter for isUploadGatewaysInProgress
     */
    isUploadGatewaysInProgress(state) {
        return state.isUploadGatewaysInProgress;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    showImportGatewaysModal({ commit }, data) {
        commit("SHOW_IMPORT_GATEWAYS_MODAL", data);
    },

    hideImportGatewaysModal({ commit }, data) {
        commit("HIDE_IMPORT_GATEWAYS_MODAL", data);
    },

    resetShowImportGatewaysModal({ commit }) {
        commit("RESET_SHOW_IMPORT_GATEWAYS_MODAL", {});
    },

    resetHideImportGatewaysModal({ commit }) {
        commit("RESET_HIDE_IMPORT_GATEWAYS_MODAL", {});
    },

    setIsUploadGatewaysInProgress({ commit }, value) {
        commit("SET_UPLOAD_GATEWAYS_IN_PROGRESS", value);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
