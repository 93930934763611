<template>
    <div id="kt_header_mobile" class="kt-header-mobile--fixed" v-bind:class="[this.releasedFor == 'HAN' ? 'kt-header-mobile-han' : '', 'kt-header-mobile']">
        <div class="kt-header-mobile__logo">
            <router-link :to="{ name: 'home' }">
                <img v-if="this.releasedFor == 'HAN'" alt="Logo" class="hanLogo" src="/assets/han/company_logo_white.png" />
                <img v-else alt="Logo" class="imgAlu" src="/assets/ale/al_enterprise_wh.png" />
            </router-link>
        </div>
        <div class="kt-header-mobile__toolbar">
            <button class="kt-header-mobile__toggler kt-header-mobile__toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
            <button class="kt-header-mobile__topbar-toggler" id="kt_header_mobile_topbar_toggler"><i class="flaticon-more"></i></button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR
        };
    },
    mounted: function() {
        console.log("Component(MobileMenu)::mounted() - called");
        KTLayout.init();
    }
};
</script>

<style scoped>
.imgAlu {
    width: 110px;
}
.hanLogo {
    width: 80px;
}
</style>
