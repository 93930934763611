<template>
    <div class="modal fade show report-model" id="reportModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-modal="true" style="display: none">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("reporting_additionalInformation") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="resetReport()" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="report">
                    <div class="row">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_created") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.createdAt }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_updated") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.updatedAt }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("common_name") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.name }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_templateReport") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.reportTemplate }}</p>
                        </div>
                    </div>
                    <div class="row" v-if="report.dateRange">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_dateRange") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.dateRange }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_fileType") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.fileType }}</p>
                        </div>
                    </div>
                    <div class="row" v-if="report.scheduler">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_periodicity") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.scheduler.repeat }}</p>
                        </div>
                    </div>
                    <div class="row" v-if="report.scheduler && report.scheduler.weekDays && report.scheduler.weekDays.length">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_weekday") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.scheduler.weekDays }}</p>
                        </div>
                    </div>
                    <div class="row" v-if="report.scheduler && report.scheduler.monthDays && report.scheduler.monthDays.length">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_monthDay") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.scheduler.monthDays }}</p>
                        </div>
                    </div>
                    <div class="row" v-if="report.scheduler">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_time") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.scheduler.time }}</p>
                        </div>
                    </div>
                    <div class="row" v-if="report.hasOwnProperty('status')">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_statusReport") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.status }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_recipientsReport") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.recipients }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <p class="text-left">{{ $t("reporting_emails") }}:</p>
                        </div>
                        <div class="col-lg-8">
                            <p class="text-center">{{ report.emails }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import commonVueHelper from "../../helpers/commonVueHelper";
import moment from 'moment-timezone';
import i18n from "../../i18n";

import listReportWeekdays from "../../constants/reportWeekdays";

export default {
    data() {
        return {
            report: {}
        };
    },
    created: function() {
        console.log("Component(reportModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(reportModal)::mounted() - called");
    },
    destroyed: function() {
        console.log("Component(reportModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentReport(currentReport) {
            console.log("Component(reportModal)::watch(currentReport) called with : ", currentReport);
            if (currentReport && this.$route.name === "reports") {
                this.formatReportData(currentReport);
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentReport"]),
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["resetCurrentReportState"]),
        formatReportData(currentReport) {
            if (currentReport) {
                this.report = {};
                this.report.name = currentReport.name;
                let formatTime = commonVueHelper.getDateStringFormatFromLocale(this.$i18n.locale);
                this.report.createdAt = moment(currentReport.createdAt).format(formatTime);
                this.report.updatedAt = moment(currentReport.updatedAt).format(formatTime);

                this.report.reportTemplate = commonVueHelper.getPropFromReport("template", currentReport);

                if (currentReport.from && currentReport.to) {
                    formatTime = commonVueHelper.getShortDateStringFormatFromLocale(this.$i18n.locale);
                    this.report.dateRange = moment(currentReport.from.split("T")[0]).format(formatTime)+ " - " + moment(currentReport.to.split("T")[0]).format(formatTime);
                }
                this.report.fileType = currentReport.fileType === "CSV" ? "CSV" : currentReport.fileType === "XLS" ? "Excel" : currentReport.fileType;

                if (currentReport.scheduler) {
                    this.report.scheduler = {};
                    this.report.scheduler.repeat = commonVueHelper.getPropFromReport("scheduler", currentReport);
                    if(currentReport.scheduler.weekDays && currentReport.scheduler.weekDays.length) {
                        this.report.scheduler.weekDays = currentReport.scheduler.weekDays.map(report => {
                            return parseInt(report)
                        });
                        const weekDays = listReportWeekdays.list.filter(day => {
                            return this.report.scheduler.weekDays.includes(day.index)
                        })
                            .map(day => { return commonVueHelper.getDayOfWeek(day.value) });
                        this.report.scheduler.weekDays = weekDays.join(", ");
                    }
                    if (currentReport.scheduler.monthDays && currentReport.scheduler.monthDays.length) {
                        this.report.scheduler.monthDays = currentReport.scheduler.monthDays.join(", ")
                    }
                    const hour = currentReport.scheduler.hour;
                    const minute = currentReport.scheduler.minute;
                    this.report.scheduler.time = commonVueHelper.formatTimePicker(hour, minute, null, this.$i18n.locale);
                }

                if (currentReport.hasOwnProperty('isActive')) {
                    if (currentReport.isActive) {
                        this.report.status = i18n.t("reporting_reportStatusActive");
                    } else {
                        this.report.status = i18n.t("reporting_reportStatusInactive");
                    }
                }

                this.report.recipients = currentReport.emails.length;
                this.report.emails = currentReport.emails.join("; ");
            }
        },

        resetReport() {
            this.resetCurrentReportState();
        }

    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
@media (min-width: 768px) {
    .modal-dialog{
        max-width: 700px;
    }
}
</style>
