import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        userSitesGeonotificationAlarms: null,
        userSitesPushButtonAlarms: null,
        currentSiteGeonotificationAlarms: null,
        currentSitePushButtonAlarms     : null,
        userSitesAcknowledgeAlarms: null,
        userSitesUnAcknowledgeAlarms: null,
        siteAlarms: null,
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    userSitesGeonotificationAlarms(state) {
        return state.userSitesGeonotificationAlarms;
    },

    currentSiteGeonotificationAlarms(state) {
        return state.currentSiteGeonotificationAlarms;
    },

    currentSitePushButtonAlarms(state) {
        return state.currentSitePushButtonAlarms;
    },

    userSitesPushButtonAlarms(state) {
        return state.userSitesPushButtonAlarms;
    },

    userSitesAlarms(state) {
        let result = null;
        if (state.userSitesGeonotificationAlarms && state.userSitesPushButtonAlarms) {
            result = state.userSitesGeonotificationAlarms.concat(state.userSitesPushButtonAlarms);
        } else if (state.userSitesAcknowledgeAlarms && state.userSitesUnAcknowledgeAlarms) {
            result = state.userSitesAcknowledgeAlarms.concat(state.userSitesUnAcknowledgeAlarms);
        }
        return result;
    }

};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {

    SET_ALL_SITES_GEONOTIFICATION_ALARMS(state, data) {
        state.userSitesGeonotificationAlarms = data.alarms;
    },

    SET_SITE_GEONOTIFICATION_ALARMS(state, data) {
        state.currentSiteGeonotificationAlarms = data.alarms;
    },

    SET_SITE_PUSHBUTTON_ALARMS(state, data) {
        state.currentSitePushButtonAlarms = data.alarms;
    },

    SET_ALL_SITES_PUSHBUTTON_ALARMS(state, data) {
        state.userSitesPushButtonAlarms = data.alarms;
    },

    SET_ALL_SITES_UN_ACKNOWLEDGE_ALARMS(state, data) {
        state.userSitesUnAcknowledgeAlarms = data.alarms;
    },

    SET_ALL_SITES_ACKNOWLEDGE_ALARMS(state, data) {
        state.userSitesAcknowledgeAlarms = data.alarms;
    },

    SET_SITE_ALARMS(state, data) {
        state.siteAlarms = data.alarms;
    },

    RESET_DEFAULT_ALARMS_STATE(state) {
        Object.assign(state, getDefaultState())
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /*
     * Action used to get the list of all geonotifications alarms for a given site
     */
    getGeonotificationAlarmsForSite({ commit }, data) {
        const params = {};
        if (data.limit) {
            params.limit = data.limit;
            params.offset = data.offset || 0;
            params.sort = data.sort || "DESC";
        }
        axiosBackend
            .get(`/sites/${data.siteId}/alarms/geonotifications`, {
                params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                hideSpinner: true
            })
            .then(res => {
                commit("SET_SITE_GEONOTIFICATION_ALARMS", {
                    alarms: res.data.data.list || res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of all push button alarms for a given site
     */
    getPushButtonAlarmsForSite({ commit }, data) {
        const params = {};
        if (data.limit) {
            params.limit = data.limit;
            params.offset = data.offset || 0;
            params.sort = data.sort || "DESC";
        }

        axiosBackend
            .get(`/sites/${data.siteId}/alarms/pushbuttonalerts`, {
                params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                hideSpinner: true
            })
            .then(res => {
                commit("SET_SITE_PUSHBUTTON_ALARMS", {
                    alarms: res.data.data.list || res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of all geonotifications alarms for all user sites
     */
    getAllUserSitesGeonotificationAlarms({ commit }, data) {
        commit("SET_ALL_SITES_GEONOTIFICATION_ALARMS", {
            alarms: []
        });
        let url = "/sites/alarms/geonotifications";

        if (data.limit) {
            const offset = data.offset || 0;
            const sort = data.sort || "DESC";
            url = `/sites/alarms/geonotifications?limit=${data.limit}&offset=${offset}&sort=${sort}`;
        }

        axiosBackend
            .get(url, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                hideSpinner: true
            })
            .then(res => {
                commit("SET_ALL_SITES_GEONOTIFICATION_ALARMS", {
                    alarms: res.data.data.list || res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of all geonotifications alarms for aluser sites
     */
    getAllUserSitesPushButtonAlarms({ commit }, data) {
        commit("SET_ALL_SITES_PUSHBUTTON_ALARMS", {
            alarms: []
        });
        let url = "/sites/alarms/pushbuttonalerts";

        if (data.limit) {
            const offset = data.offset || 0;
            const sort = data.sort || "DESC";
            url = `/sites/alarms/pushbuttonalerts?limit=${data.limit}&offset=${offset}&sort=${sort}`;
        }

        axiosBackend
            .get(url, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                hideSpinner: true
            })
            .then(res => {
                commit("SET_ALL_SITES_PUSHBUTTON_ALARMS", {
                    alarms: res.data.data.list || res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    getAllUserSiteAcknowledgeAlarms({commit}, data) {
        commit("SET_ALL_SITES_ACKNOWLEDGE_ALARMS", {
            alarms: []
        });

        let params = { filters: { isAcknowledged: true } };

        if (data && data.limit) {
            let offset = data.offset || 0;
            params.limit = data.limit;
            params.offset = offset;
        }

        axiosBackend
            .get('/alarms', {
                params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                hideSpinner: true
            })
            .then(res => {
                commit("SET_ALL_SITES_ACKNOWLEDGE_ALARMS", {
                    alarms: res.data.data.list || res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    getAllUserSiteUnAcknowledgeAlarms({commit}, data) {
        commit("SET_ALL_SITES_UN_ACKNOWLEDGE_ALARMS", {
            alarms: []
        });

        let params = { filters: { isAcknowledged: false } };

        if (data && data.limit) {
            let offset = data.offset || 0;
            params.limit = data.limit;
            params.offset = offset;
        }

        axiosBackend
            .get('/alarms', {
                params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                hideSpinner: true
            })
            .then(res => {
                commit("SET_ALL_SITES_UN_ACKNOWLEDGE_ALARMS", {
                    alarms: res.data.data.list || res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    getAllUserSitesAlarms({ dispatch }, data) {
        // dispatch("getAllUserSitesGeonotificationAlarms", data);
        // dispatch("getAllUserSitesPushButtonAlarms", data);
        dispatch("getAllUserSiteUnAcknowledgeAlarms", data);
        dispatch("getAllUserSiteAcknowledgeAlarms", data);

        // if (router && router.currentRoute && router.currentRoute.params && router.currentRoute.params.siteId) {
        //    dispatch("getGeonotificationAlarmsForSite", { siteId: router.currentRoute.params.siteId, limit: data.limit || 50, offset: data.offset });
        //    dispatch("getPushButtonAlarmsForSite", { siteId: router.currentRoute.params.siteId, limit: data.limit || 50, offset: data.offset });
        // }
    },

    /*
     * Action used to acknowledge all geonotification alarms
     */
    acknowledgeAllGeonotificationAlarms({ dispatch }, data) {

        let newAlarms = [];

        if (data.userSitesGeonotificationAlarms) {
            newAlarms = data.userSitesGeonotificationAlarms.filter( alarm => !alarm.isAcknowledged );
        } else if(state.userSitesGeonotificationAlarms) {
            newAlarms = state.userSitesGeonotificationAlarms.filter( alarm => !alarm.isAcknowledged );
        }

        var params = {
            alarms: _.map(newAlarms, 'id')
        }

        axiosBackend
            .put("/sites/alarms/geonotifications/acknowledge", params, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // dispatch("getAllUserSitesGeonotificationAlarms", { limit: data.limit || 50, offset: data.offset});
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                if (data && data.siteId) {
                    dispatch("getGeonotificationAlarmsForSite", { siteId: data.siteId, limit: data.limit || 50, offset: data.offset });
                }

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to acknowledge all push button alarms
     */
    acknowledgeAllPushButtonAlarms({ dispatch }, data) {

        let newAlarms = [];

        if (data.userSitesPushButtonAlarms) {
            newAlarms = data.userSitesPushButtonAlarms.filter( alarm => !alarm.isAcknowledged );
        } else if(state.userSitesPushButtonAlarms) {
            newAlarms = state.userSitesPushButtonAlarms.filter( alarm => !alarm.isAcknowledged );
        }

        const params = {
            alarms: _.map(newAlarms, 'id')
        }

        axiosBackend
            .put("sites/alarms/pushbuttonalerts/acknowledge", params, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // dispatch("getAllUserSitesPushButtonAlarms", { limit: data.limit, offset: data.offset });
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                if (data && data.siteId) {
                    dispatch("getPushButtonAlarmsForSite", { siteId: data.siteId, limit: data.limit || 50, offset: data.offset });
                }
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to acknowledge all push button alarms
     */
    acknowledgeAll({ dispatch }, data) {
        // Is there a geonotification alarm to acknowledge
        const newGeonotificationAlarms = state.userSitesUnAcknowledgeAlarms ? state.userSitesUnAcknowledgeAlarms.filter( alarm => !alarm.isAcknowledged && !alarm.pushEventTypeName) : [];

        if (newGeonotificationAlarms.length > 0) {
            // Acknowledge geonotification alarms
            data.userSitesGeonotificationAlarms = newGeonotificationAlarms;
            dispatch("acknowledgeAllGeonotificationAlarms", data);
        }

        const newPushButtonAlarms = state.userSitesUnAcknowledgeAlarms ? state.userSitesUnAcknowledgeAlarms.filter( alarm => !alarm.isAcknowledged && alarm.pushEventTypeName) : [];

        if (newPushButtonAlarms.length > 0) {
            delete data.userSitesGeonotificationAlarms;
            // Acknowledge PushButton alarms only if there are alarms to acknowledge (to avoid message error_notFound when no alarm to acknowledge)
            data.userSitesPushButtonAlarms = newPushButtonAlarms;
            dispatch("acknowledgeAllPushButtonAlarms", data);
        }
    },

    /*
     * Action used to acknowledge multiple geonotification alarms
     */
    async acknowledgeGeonotificationAlarms({ dispatch, commit }, data) {

        let alarmIdsArray = data.alarms || [];

        if (alarmIdsArray.length === 0 && data.isAcknowledgeAllForSite) {
            try {
                const response = await axiosBackend
                    .get("/sites/" + data.siteId + "/alarms/geonotifications", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                    });
                const geonotificationsAlarm = response.data.data.list || response.data.data 
                alarmIdsArray = geonotificationsAlarm.filter(alarm => alarm.isAcknowledged === false).map(alarm => alarm.id);
            } catch (error) {
                console.log(error);
            }
        }

        let params = {
            alarms: alarmIdsArray
        }

        axiosBackend
            .put("sites/alarms/geonotifications/acknowledge", params, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // dispatch("getAllUserSitesGeonotificationAlarms", { limit: data.limit || 50, offset: data.offset });
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                if (data && data.siteId) {
                    dispatch("getGeonotificationAlarmsForSite", { siteId: data.siteId, limit: data.limit || 50, offset: data.offset });
                }

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: ((res && res.data && res.data.data && res.data.data.alarms && res.data.data.alarms.length === 1) ? i18n.t("alarm_notifSuccessfullyAcknowledged") : i18n.t("alarm_notifsSuccessfullyAcknowledged")),
                    type: "success"
                });

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to acknowledge multiple push button alarms
     */
    async acknowledgePushButtonAlarms({ dispatch, commit }, data) {

        let alarmIdsArray = data.alarms || [];

        if ((!alarmIdsArray || alarmIdsArray.length === 0) && data.isAcknowledgeAllForSite) {
            try {
                const response = await axiosBackend
                    .get("/sites/" + data.siteId + "/alarms/pushbuttonalerts", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                    });

                const pushbuttonalertsAlarm = response.data.data.list || response.data.data;
                alarmIdsArray = pushbuttonalertsAlarm.filter(alarm => alarm.isAcknowledged === false).map(alarm => alarm.id);
            } catch (error) {
                console.log(error);
            }
        }

        const params = {
            alarms: alarmIdsArray
        }

        axiosBackend
            .put("sites/alarms/pushbuttonalerts/acknowledge", params, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // dispatch("getAllUserSitesPushButtonAlarms", { limit: data.limit, offset: data.offset });
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                if (data && data.siteId) {
                    dispatch("getPushButtonAlarmsForSite", { siteId: data.siteId, limit: data.limit || 50, offset: data.offset });
                }

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: ((res && res.data && res.data.data && res.data.data.alarms && res.data.data.alarms.length === 1) ? i18n.t("alarm_notifSuccessfullyAcknowledged") : i18n.t("alarm_notifsSuccessfullyAcknowledged")),
                    type: "success"
                });

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to acknowledge one alarm
     */
    acknowledgeAlarm({ dispatch, commit }, data) {

        let siteAlarm = state.userSitesUnAcknowledgeAlarms ? state.userSitesUnAcknowledgeAlarms.find(alarm => alarm.id === data.alarmId) : false;

        if (!siteAlarm) {
            siteAlarm = state.siteAlarms ? state.siteAlarms.find(alarm => alarm.id === data.alarmId) : false; 
        }

        if (siteAlarm) {
            const params = {
                alarms: [data.alarmId]
            }

            if (data.siteId) {
                params.siteId = data.siteId;
            }

            if (siteAlarm.pushEventTypeName) {
                return dispatch("acknowledgePushButtonAlarms", params);
            }

            return dispatch("acknowledgeGeonotificationAlarms", params);
        }

        // Not alarm found
        console.error("acknowledgeAlarm: No alarm " + data.alarmId + " found");
        commit("SHOW_NOTIFICATION", {
            text: i18n.t("error_notFound"),
            type: "error"
        });

    },

    /*
     * Action used to acknowledge all geonotification alarms for site
     */
    acknowledgeAllGeonotificationAlarmsForSite({ dispatch, commit }, siteId) {

        let params = {
            alarms: ["*"]
        };

        axiosBackend
            .put("sites/" + siteId + "/alarms/geonotifications/acknowledge", params, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                dispatch("getGeonotificationAlarmsForSite", { siteId: siteId, limit: 50, offset: 0 });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alarm_notifsSuccessfullyAcknowledged"),
                    type: "success"
                });

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to acknowledge all push button alarms for site
     */
    acknowledgeAllPushButtonAlarmsForSite({ dispatch, commit }, siteId) {

        const params = {
            alarms: ["*"]
        };

        axiosBackend
            .put("sites/" + siteId + "/alarms/pushbuttonalerts/acknowledge", params, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                dispatch("getPushButtonAlarmsForSite", { siteId: siteId, limit: 50, offset: 0 });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alarm_notifsSuccessfullyAcknowledged"),
                    type: "success"
                });

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete one geonotification alarm
     */
    deleteOneGeonotificationAlarm({ dispatch, commit }, data) {

        var params = {
            alarms: [data.alarmId]
        };

        axiosBackend
            .delete("sites/alarms/geonotifications", {
                data: params
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // dispatch("getAllUserSitesGeonotificationAlarms", { limit: data.limit || 50, offset: data.offset });
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                if (data && data.siteId) {
                    dispatch("getGeonotificationAlarmsForSite", { siteId: data.siteId, limit: data.limit || 50, offset: data.offset });
                }

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alarm_notifSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete one push button alarm
     */
    deleteOnePushButtonAlarm({ dispatch, commit }, data) {

        var params = {
            alarms: [data.alarmId]
        };

        axiosBackend
            .delete("sites/alarms/pushbuttonalerts", {
                data: params
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // dispatch("getAllUserSitesPushButtonAlarms", { limit: data.limit || 50, offset: data.offset });
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                if (data && data.siteId) {
                    dispatch("getPushButtonAlarmsForSite", { siteId: data.siteId, limit: data.limit || 50, offset: data.offset });
                }

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alarm_notifSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete one alarm
     */
    deleteOneAlarm({ dispatch, commit }, data) {

        let siteAlarm = state.userSitesAcknowledgeAlarms ? state.userSitesAcknowledgeAlarms.find(alarm => alarm.id === data.alarmId) : false;

        if (!siteAlarm) {
            siteAlarm = state.siteAlarms ? state.siteAlarms.find(alarm => alarm.id === data.alarmId) : false; 
        }

        if (siteAlarm) {
            if (siteAlarm.pushEventTypeName) {
                return dispatch("deleteOnePushButtonAlarm", data);
            }

            return dispatch("deleteOneGeonotificationAlarm", data);
        }

        // Not alarm found
        console.error("deleteOneAlarm: No alarm " + data.alarmId + " found");
        return commit("SHOW_NOTIFICATION", {
            text: i18n.t("error_notFound"),
            type: "error"
        });
    },

    /*
     * Action used to delete multiple geonotification alarms
     */
    async deleteGeonotificationAlarms({ dispatch, commit }, data) {

        const params = {
            silentMode: data.silentMode
        };

        let alarmIdsArray = data.alarms || [];
        if (alarmIdsArray.length === 0 && data.isDeleteAllForSite) {
            try {
                const response = await axiosBackend
                    .get("/sites/" + data.siteId + "/alarms/geonotifications", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                    });
                const geonotificationsAlarm = response.data.data.list || response.data.data;
                alarmIdsArray = geonotificationsAlarm.map(alarm => alarm.id)
            } catch (error) {
                console.log(error);
            }
        }
        
        params.alarms = alarmIdsArray;

        axiosBackend
            .delete("sites/alarms/geonotifications", {
                data: params
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // dispatch("getAllUserSitesGeonotificationAlarms", { limit: data.limit || 50, offset: data.offset });
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                if (data && data.siteId) {
                    dispatch("getGeonotificationAlarmsForSite", { siteId: data.siteId });
                }
                // Display notification
                if (!params.silentMode) {
                    commit("SHOW_NOTIFICATION", {
                        text: ((res && res.data && res.data.data && res.data.data.alarms && res.data.data.alarms.length === 1) ? i18n.t("alarm_notifSuccessfullyDeleted") : i18n.t("alarm_notifsSuccessfullyDeleted")),
                        type: "success"
                    });
                }
                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete multiple push button alarms
     */
    async deletePushButtonAlarms({ dispatch, commit }, data) {

        var params = {
            silentMode: data.silentMode
        };

        let alarmIdsArray = data.alarms || [];
        if(alarmIdsArray.length === 0 && data.isDeleteAllForSite) {
            try {
                const response = await axiosBackend
                    .get("/sites/" + data.siteId + "/alarms/pushbuttonalerts", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        hideSpinner: true
                    })
                const pushbuttonalertsAlarm = response.data.data.list || response.data.data; 
                alarmIdsArray = pushbuttonalertsAlarm.map(alarm => alarm.id);
            } catch (error) {
                console.log(error);
            }
        }

        params.alarms = alarmIdsArray;

        axiosBackend
            .delete("sites/alarms/pushbuttonalerts", {
                data: params
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // dispatch("getAllUserSitesPushButtonAlarms", {});
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                if (data && data.siteId) {
                    dispatch("getPushButtonAlarmsForSite", { siteId: data.siteId, limit: data.limit || 50, offset: data.offset });
                }
                // Display notification
                if (!params.silentMode) {
                    commit("SHOW_NOTIFICATION", {
                        text: ((res && res.data && res.data.data && res.data.data.alarms && res.data.data.alarms.length === 1) ? i18n.t("alarm_notifSuccessfullyDeleted") : i18n.t("alarm_notifsSuccessfullyDeleted")),
                        type: "success"
                    });
                }
                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete all geonotification alarms for site
     */

    async deleteAllGeonotificationAlarmsForSite({ dispatch, commit }, siteId) {
        const params = { alarms: ["*"] }

        axiosBackend
            .delete("sites/" + siteId + "/alarms/geonotifications", {
                data: params
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                dispatch("getGeonotificationAlarmsForSite", { siteId: siteId });
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alarm_notifsSuccessfullyDeleted"),
                    type: "success"
                });
                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete multiple push button alarms
     */
    async deleteAllPushButtonAlarmsForSite({ dispatch, commit }, siteId) {

        var params = { alarms: ["*"] };

        axiosBackend
            .delete("sites/" + siteId + "/alarms/pushbuttonalerts", {
                data: params
            }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                dispatch("getAllUserSitesAlarms", { limit: 100, offset: 0 });
                dispatch("getPushButtonAlarmsForSite", { siteId: siteId, limit: 50, offset: 0 });
                // Display notification
                if (!params.silentMode) {
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("alarm_notifsSuccessfullyDeleted"),
                        type: "success"
                    });
                }
                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete history alarms
     */
    deleteHistoryAlarms({ dispatch }, data) {

        // Delete geonotification history
        const historyGeonotificationAlarms = state.userSitesAcknowledgeAlarms ? state.userSitesAcknowledgeAlarms.filter( alarm => alarm.isAcknowledged && !alarm.pushEventTypeName) : [];

        const geonotificationsParams = {
            alarms: _.map(historyGeonotificationAlarms, 'id'),
            silentMode: true,
        };

        if (data.siteId) {
            geonotificationsParams.siteId = data.siteId;
            geonotificationsParams.limit = data.limit;
            geonotificationsParams.offset = data.offset;
        }

        if (geonotificationsParams.alarms.length > 0) {
            dispatch("deleteGeonotificationAlarms", geonotificationsParams);
        }

        // Delete push button history
        const historyPushButtonAlarms = state.userSitesAcknowledgeAlarms ? state.userSitesAcknowledgeAlarms.filter( alarm => alarm.isAcknowledged && alarm.pushEventTypeName) : [];

        const pushButtonParams = {
            alarms: _.map(historyPushButtonAlarms, 'id'),
            silentMode: true
        }

        if (data.siteId) {
            pushButtonParams.siteId = data.siteId;
            geonotificationsParams.limit = data.limit;
            geonotificationsParams.offset = data.offset;
        }

        if (pushButtonParams.alarms.length > 0) {
            dispatch("deletePushButtonAlarms", pushButtonParams);
        }

    },

    setSiteAlarm({commit}, data) {
        commit("SET_SITE_ALARMS", { alarms: data.alarms });
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetAlarmsState ({ commit }) {
        commit('RESET_DEFAULT_ALARMS_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};