import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteAssetCategories: null,
        siteAssetCategoriesById: null,
        currentAssetCategory: null,
        createAnotherAssetCategory: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    siteAssetCategories(state) {
        return state.siteAssetCategories;
    },

    siteAssetCategoriesById(state) {
        return state.siteAssetCategoriesById;
    },

    currentAssetCategory(state) {
        return state.currentAssetCategory;
    },

    createAnotherAssetCategory(state) {
        return state.createAnotherAssetCategory;
    },

    getAssetCategoryById: state => id => {
        let result = state.siteAssetCategories.find(function (assetCategory) {
            if (assetCategory.id && assetCategory.id == id) {
                return assetCategory;
            }
        });
        return result;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_ASSET_CATEGORIES(state, data) {
        // siteAssetCategories is the array of al categories
        state.siteAssetCategories = data.assetCategories;
        // siteAssetCategoriesById is a map indexed by category id
        state.siteAssetCategoriesById = _.keyBy(data.assetCategories, "id");
    },

    REMOVE_ASSET_CATEGORY_BY_ID(state, assetCategoryId) {
        // Find asset category index
        let index = _.findIndex(state.siteAssetCategories, {
            id: assetCategoryId
        });
        state.siteAssetCategories.splice(index, 1);
    },

    SET_CURRENT_ASSET_CATEGORY(state, data) {
        if (!state.siteAssetCategories) {
            state.siteAssetCategories = [];
        }
        // Set the current asset category
        state.currentAssetCategory = data.currentAssetCategory;
        // Find asset category index
        let index = -1;
        if (data.currentAssetCategory) {
            index = _.findIndex(state.siteAssetCategories, {
                id: data.currentAssetCategory.id
            });
        }
        if (index >= 0) {
            // Replace the asset category in the array of assetcategories
            state.siteAssetCategories.splice(index, 1, data.currentAssetCategory);
        } else {
            if (data.currentAssetCategory) {
                state.siteAssetCategories.push(data.currentAssetCategory);
            }
        }
    },

    SET_CREATE_ANOTHER_ASSET_CATEGORY(state, createAnother) {
        state.createAnotherAssetCategory = createAnother;
    },

    RESET_DEFAULT_CATEGORIES_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherAssetCategory({
        commit
    }, createAnother) {
        commit("SET_CREATE_ANOTHER_ASSET_CATEGORY", createAnother);
    },

    /*
     * Action used to create an asset category
     */
    createAssetCategory({
        commit
    }, payload) {
        commit("SET_CREATE_ANOTHER_ASSET_CATEGORY", payload.createAnother);
        delete payload.createAnother;
        axiosBackend
            .post("/sites/" + payload.siteId + "/assetcategories", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store created asset category site in userSites
                commit("SET_CURRENT_ASSET_CATEGORY", {
                    currentAssetCategory: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_categorySuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to assetcategories list page
                router.push({
                    name: "assetCategories",
                    params: {
                        fromAction: "createAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of site assetcategories
     */
    getSiteAssetCategories({
        commit
    }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/assetcategories", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store site assetcategories in userAssetCategories
                commit("SET_SITE_ASSET_CATEGORIES", {
                    assetCategories: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one asset category by its id
     */
    getAssetCategoryById({
        commit
    }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/assetcategories/" + payload.assetCategoryId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                commit("SET_CURRENT_ASSET_CATEGORY", {
                    currentAssetCategory: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing asset category
     */
    updateAssetCategory({
        commit
    }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/assetcategories/" + payload.assetCategoryId, payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_ASSET_CATEGORY", {
                    currentAssetCategory: res.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_categorySuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to assetcategories page
                router.push({
                    name: "assetCategories",
                    params: {
                        fromAction: "updateAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete an asset category
     */
    deleteAssetCategory({
        commit,
        dispatch
    }, data) {
        axiosBackend
            .delete("/sites/" + data.siteId + "/assetcategories/" + data.assetCategoryId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(() => {
                commit("REMOVE_ASSET_CATEGORY_BY_ID", data.assetCategoryId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("asset_categorySuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetCategoriesState ({ commit }) {
        commit('RESET_DEFAULT_CATEGORIES_STATE');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};