import axiosBackend from "../../axios-backend";

const getDefaultState = () => {
    return {
        siteGatewaysHealth: [],
        siteTagsHealth: [],
        siteFingerprintsHealth: [],
        siteNeuralNetworksHealth: []
    };
}

/**
 * State: properties to Store
 */
const state = getDefaultState();

/**
 * Getters: Getters of properties defined in state
 */
const getters = {
    /**
     * Getter for gateways health
     */
    siteGatewaysHealth(state) {
        return state.siteGatewaysHealth;
    },

    /**
     * Getter for tags health
     */
    siteTagsHealth(state) {
        return state.siteTagsHealth;
    },

    /**
     * Getter for fingerprints health
     */
    siteFingerprintsHealth(state) {
        return state.siteFingerprintsHealth;
    },

    /**
     * Getter for neural networks health
     */
    siteNeuralNetworksHealth(state) {
        return state.siteNeuralNetworksHealth;
    },

    /**
     * Getter for gateway health by gateway id
     */
    getGatewayHealthById: state => gatewayId => {
        return state.siteGatewaysHealth.find(gateway => gateway.id == gatewayId);
    },

    /**
     * Getter for beacon health by autocalibration tag id
     */
    getBeaconHealthById: state => beaconId => {
        return state.siteTagsHealth.find(beacon => beacon.id == beaconId);
    }
};

/**
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_GATEWAYS_HEALTH(state, data) {
        state.siteGatewaysHealth = data.gateways;
    },

    SET_TAGS_HEALTH(state, data) {
        state.siteTagsHealth = data.tags;
    },

    SET_FINGERPRINTS_HEALTH(state, data) {
        state.siteFingerprintsHealth = data.fingerprints;
    },

    SET_NEURAL_NETWORKS_HEALTH(state, data) {
        state.siteNeuralNetworksHealth = data.neuralNetworks;
    },

    RESET_DEFAULT_SITE_HEALTH_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/**
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /**
     * Action used to get gateways status
     */
    getSiteGatewaysHealth({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/health/gateways",{
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store the gateways status in gatewaysHealth
                commit("SET_GATEWAYS_HEALTH", {
                    gateways: res.data.data.gateways
                });
            })
            .catch(error => console.log(error));
    },

    /**
     * Mook for test must be deleted.
     */
    //  getSiteGatewaysHealth({ commit, rootGetters }, siteId) {
    //     axiosBackend
    //         .get("/sites/" + siteId + "/gateways", {
    //             headers: {
    //                 Authorization: "Bearer " + localStorage.getItem("token")
    //             }
    //         })
    //         .then(res => {
    //             // Store created user site in userSites
    //             const gateways = res.data.data;
    //             // Create now data
    //             var gatewaysHealth = [];
    //             const generateData = [
    //                 {
    //                     lastEmission: 100,
    //                     lastKonSPReceived: null,
    //                     lastKonLocReceived: null
    //                 },
    //                 {
    //                     lastEmission: -1,
    //                     lastKonSPReceived: null,
    //                     lastKonLocReceived: null
    //                 },
    //                 {
    //                     lastEmission: null,
    //                     lastKonSPReceived: null,
    //                     lastKonLocReceived: null
    //                 }
    //             ]
    //             for (let gateway of gateways) {
    //                 if (gateway.building && gateway.floor) {
    //                     const random = Math.floor(Math.random() * (3 - 0) + 0);
    //                     gateway.building = gateway.building.id;
    //                     gateway.floor = gateway.floor.id;
    //                     gateway.lastEmission = generateData[random].lastEmission;
    //                     gateway.lastKonSPReceived = generateData[random].lastKonSPReceived;
    //                     gateway.lastKonLocReceived = generateData[random].lastKonLocReceived;
    //                     gatewaysHealth.push(gateway);
    //                 }
    //             }
    //             commit("SET_GATEWAYS_HEALTH", {
    //                 gateways: gatewaysHealth
    //             })
    //         })
    //         .catch(error => console.log(error));
    // },

    /**
     * Action used to get tags status
     */
    getSiteTagsHealth({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/health/tags", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store the tags status in tagsHealth
                commit("SET_TAGS_HEALTH", {
                    tags: res.data.data.tags
                });
            })
            .catch(error => console.log(error));
    },

    // getSiteTagsHealth({ commit }, siteId) {
    //     axiosBackend
    //         .get("/sites/" + siteId + "/autocalibrationtags", {
    //             headers: { Authorization: "Bearer " + localStorage.getItem("token") }
    //         })
    //         .then(res => {
    //             const autotags = res.data.data;
    //             // Create now data
    //             var tagsHealth = [];
    //             for (let tag of autotags) {
    //                 if (tag.building && tag.floor) {
    //                     tag.coverage = [];
    //                     tagsHealth.push(tag);
    //                 }
    //             }
    //             // Store the tags status in tagsHealth
    //             commit("SET_TAGS_HEALTH", {
    //                 tags: tagsHealth
    //             });
    //         })
    //         .catch(error => console.log(error));
    // },

    /**
     * Action used to get site's fingerprints status
     */
    getSiteFingerprintsHealth({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/health/fingerprint", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store the fingerprints status in fingerprintsHealth
                commit("SET_FINGERPRINTS_HEALTH", {
                    fingerprints: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /**
     * Action used to get site's neural networks status
     */
    getSiteNeuralNetworksHealth({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/health/neuralnetwork", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store the fingerprints status in fingerprintsHealth
                commit("SET_NEURAL_NETWORKS_HEALTH", {
                    neuralNetworks: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetSiteHealthState ({ commit }) {
        commit('RESET_DEFAULT_SITE_HEALTH_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};