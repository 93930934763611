import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteTags: null,
        freeTags: [], // Array of unassigned tags of the site
        currentTag: null,
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for siteTags
     */
    siteTags(state) {
        return state.siteTags;
    },

    freeTags(state) {
        return state.freeTags;
    },

    currentTag(state) {
        return state.currentTag;
    },

    createAnotherTag(state) {
        return state.createAnother;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_TAGS(state, data) {
        if (!state.siteTags) {
            state.siteTags = [];
        }
        state.siteTags = data.tags;
        // Set the list of unassigned tags
        state.freeTags = [];
        if (data.tags) {
            for (let i = 0; i < data.tags.length; i++) {
                let tag = data.tags[i];
                if (tag && tag.asset) {
                    continue; // It's an assigned tag => skip to next tag
                }
                state.freeTags.push(tag);
            }
        }
    },

    PUSH_SITE_TAGS(state, data) {
        if (!state.siteTags) {
            state.siteTags = [];
        }
        state.siteTags = state.siteTags.concat(data.tags);
        // Add the list of unassigned tags
        if (data.tags) {
            let newfreeTags = [];
            for (let i = 0; i < data.tags.length; i++) {
                let tag = data.tags[i];
                if (tag && tag.asset) {
                    continue; // It's an assigned tag => skip to next tag
                }
                newfreeTags.push(tag);
            }
            state.freeTags = state.freeTags.concat(newfreeTags);
        }
    },

    REMOVE_TAG_BY_ID(state, tagId) {
        // Find tag index
        let index = _.findIndex(state.siteTags, { id: tagId });
        state.siteTags.splice(index, 1);
        // Remove the tag from the unassigned tags if it exists
        let indexFree = _.findIndex(state.freeTags, { id: tagId });
        if (indexFree >= 0) {
            state.freeTags.splice(indexFree, 1);
        }
    },

    SET_CURRENT_TAG(state, tagData) {
        if (!state.siteTags) {
            state.siteTags = [];
        }
        // Set the currentTag
        state.currentTag = tagData.currentTag;
        // Find tag index
        let index = -1;
        if (tagData.currentTag) {
            index = _.findIndex(state.siteTags, { id: tagData.currentTag.id });
        }
        if (index >= 0) {
            // Replace the tag in the array of tags
            state.siteTags.splice(index, 1, tagData.currentTag);
        } else {
            if (tagData.currentTag) {
                state.siteTags.push(tagData.currentTag);
            }
        }
        // Fing the unassigned tag index
        if (!state.freeTags) {
            state.freeTags = [];
        }
        let indexFree = -1;
        if (tagData.currentTag) {
            indexFree = _.findIndex(state.freeTags, { id: tagData.currentTag.id });
        }
        if (indexFree >= 0) {
            // Replace the tag in the array of unassigned tags
            state.freeTags.splice(indexFree, 1, tagData.currentTag);
        } else {
            if (tagData.currentTag) {
                state.freeTags.push(tagData.currentTag);
            }
        }
    },

    SET_CREATE_ANOTHER_TAG(state, createAnother) {
        state.createAnother = createAnother;
    },

    RESET_DEFAULT_TAGS_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherTag({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_TAG", createAnother);
    },

    /*
     * Action used to create a tag
     */
    createTag({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_TAG", payload.createAnother);
        axiosBackend
            .post(
                "/sites/" + payload.siteId + "/tags",
                {
                    serialNumber: payload.serialNumber,
                    macAddress: payload.macAddress,
                    batteryInstallationDate: payload.batteryInstallationDate,
                    tagTypeId: payload.tagTypeId
                },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("tag_newTagSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to tags list page
                router.push({ name: "tags", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to create multiple tags
     */
    massImportTags({ commit }, payload) {
        commit("SET_UPLOAD_TAGS_IN_PROGRESS", true);

        axiosBackend
            .post("/sites/" + payload.siteId + "/tags/massimport", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_UPLOAD_TAGS_IN_PROGRESS", false);

                // Add new tags to the list
                commit("PUSH_SITE_TAGS", {
                    tags: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("tags_tagsSuccessfullyCreated"),
                    type: "success"
                });

                // Ask to hide import modal now
                commit("HIDE_IMPORT_TAGS_MODAL", {});
            })
            .catch(error => {
                commit("SET_UPLOAD_TAGS_IN_PROGRESS", false);
                console.log(error);
            });
    },

    /*
     * Action used to get the list of sites tags
     */
    getSiteTags({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/tags", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_SITE_TAGS", {
                    tags: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one tag by its id
     */
    getTagById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/tags/" + payload.tagId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_CURRENT_TAG", {
                    currentTag: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing tag
     */
    updateTag({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/tags/" + payload.tagId, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_TAG", {
                    currentTag: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("tag_tagSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to tags page
                router.push({ name: "tags", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a tag
     */
    deleteTag({ commit, dispatch }, data) {
        axiosBackend
            .delete("/sites/" + data.siteId + "/tags/" + data.tagId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_TAG_BY_ID", data.tagId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("tag_tagSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetTagsState ({ commit }) {
        commit('RESET_DEFAULT_TAGS_STATE');
    }
    
};

export default {
    state,
    getters,
    mutations,
    actions
};
