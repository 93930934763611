/*
 * State: properties to store
 */
const state = {
    /*
     * Indicate if the delete modal has to be displayed
     */
    showImportGatewaysFromOVModal: false,

    /*
     * Indicate if the modal has to be hide
     */
    hideImportGatewaysFromOVModal: false,

    /*
     * Indicate if an upload of gateways is in progress or not
     */
    isUploadGatewaysFromOVInProgress: false
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    /*
     * Toggle showImportGatewaysFromOVModal property
     */
    SHOW_IMPORT_GATEWAYS_FROM_OV_MODAL(state) {
        state.showImportGatewaysFromOVModal = true;
    },

    /*
     * Toggle hideImportGatewaysModal property
     */
    HIDE_IMPORT_GATEWAYS_FROM_OV_MODAL(state) {
        state.hideImportGatewaysFromOVModal = true;
    },

    /*
     * Reset showImportGatewaysFromOVModal property
     */
    RESET_SHOW_IMPORT_GATEWAYS_FROM_OV_MODAL(state) {
        state.showImportGatewaysFromOVModal = false;
    },

    /*
     * Reset hideImportGatewaysFromOVModal property
     */
    RESET_HIDE_IMPORT_GATEWAYS_FROM_OV_MODAL(state) {
        state.hideImportGatewaysFromOVModal = false;
    },

    /*
     * Set isUploadGatewaysFromOVInProgress property
     */
    SET_UPLOAD_GATEWAYS_FROM_OV_IN_PROGRESS(state, value) {
        state.isUploadGatewaysFromOVInProgress = value;
    }
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for showImportGatewaysFromOVModal
     */
    showImportGatewaysFromOVModal(state) {
        return state.showImportGatewaysFromOVModal;
    },

    /*
     * Getter for hideImportGatewaysModal
     */
    hideImportGatewaysFromOVModal(state) {
        return state.hideImportGatewaysFromOVModal;
    },

    /*
     * Getter for isUploadGatewaysInProgress
     */
    isUploadGatewaysFromOVInProgress(state) {
        return state.isUploadGatewaysFromOVInProgress;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    showImportGatewaysFromOVModal({
        commit
    }, data) {
        commit("SHOW_IMPORT_GATEWAYS_FROM_OV_MODAL", data);
    },

    hideImportGatewaysFromOVModal({
        commit
    }, data) {
        commit("HIDE_IMPORT_GATEWAYS_FROM_OV_MODAL", data);
    },

    resetShowImportGatewaysFromOVModal({
        commit
    }) {
        commit("RESET_SHOW_IMPORT_GATEWAYS_FROM_OV_MODAL", {});
    },

    resetHideImportGatewaysFromOVModal({
        commit
    }) {
        commit("RESET_HIDE_IMPORT_GATEWAYS_FROM_OV_MODAL", {});
    },

    setIsUploadGatewaysFromOVInProgress({
        commit
    }, value) {
        commit("SET_UPLOAD_GATEWAYS_FROM_OV_IN_PROGRESS", value);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};