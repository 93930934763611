import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        sitePushButtonEventTypes: null,
        currentPushButtonEventType: null,
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/**
 * Getters: Getters of properties defined in state
 */
const getters = {
    sitePushButtonEventTypes(state) {
        return state.sitePushButtonEventTypes;
    },

    currentPushButtonEventType(state) {
        return state.currentPushButtonEventType;
    },

    createAnotherPushButtonEventType(state) {
        return state.createAnother;
    }
};

/**
 * Mutations (Setters) : Setters of properties defined in state
 */
const mutations = {
    SET_SITE_PUSHBUTTON_EVENT_TYPES(state, data) {
        state.sitePushButtonEventTypes = data.sitePushButtonEventTypes;
    },

    REMOVE_PUSHBUTTON_EVENT_TYPE_BY_ID(state, alertId) {
        // Find notification index
        let index = _.findIndex(state.sitePushButtonEventTypes, {
            id: alertId
        });
        state.sitePushButtonEventTypes.splice(index, 1);
    },

    SET_CURRENT_PUSHBUTTON_EVENT_TYPE(state, data) {
        if (!state.sitePushButtonEventTypes) {
            state.sitePushButtonEventTypes = [];
        }
        // Set the current notification
        state.currentPushButtonEventType = data.currentPushButtonEventType;
        let index = -1;
        if (data.currentPushButtonEventType) {
            index = _.findIndex(state.sitePushButtonEventTypes, { id: data.currentPushButtonEventType.id });
        }
        if (index >= 0) {
            state.sitePushButtonEventTypes.splice(index, 1, data.currentPushButtonEventType);
        } else {
            if (data.currentPushButtonEventType) {
                state.sitePushButtonEventTypes.push(data.currentPushButtonEventType);
            }
        }
    },

    SET_CREATE_ANOTHER_PUSHBUTTON_EVENT_TYPE(state, createAnother) {
        state.createAnother = createAnother;
    },

    RESET_DEFAULT_PUSHBUTTON_EVENT_TYPE_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/**
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /**
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherPushButtonEventType({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_PUSHBUTTON_EVENT_TYPE", createAnother);
    },

    /*
     * Action used to create a push button alert
     */
    createPushButtonEventType({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_PUSHBUTTON_EVENT_TYPE", payload.createAnother);
        axiosBackend
            .post(
                "/sites/" + payload.siteId + "/pusheventtypes",
                {
                    name: payload.name,
                    isDefault: false,
                    site: payload.site
                },
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then((res) => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alert_newPushButtonEventTypeSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to pushButtonAlerts list page
                router.push({
                    name: "pushButtonEventTypes",
                    params: {
                        fromAction: "createAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },



    /**
     * Action used to get the list of push button event types
     */
    getSitePushButtonEventTypes({ commit }, siteId) {
        axiosBackend
            .get(
                "/sites/" + siteId + "/pusheventtypes",
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(res => {
                commit("SET_SITE_PUSHBUTTON_EVENT_TYPES", {
                    sitePushButtonEventTypes: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one push button event type by its id
     */
    getPushButtonEventTypeById({ commit }, payload) {
        axiosBackend
            .get(
                "/sites/" + payload.siteId + "/pusheventtypes/" + payload.eventTypeId,
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(res => {
                commit("SET_CURRENT_PUSHBUTTON_EVENT_TYPE", {
                    currentPushButtonEventType: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing push button event type
     */
    updatePushButtonEventType({ commit }, payload) {
        axiosBackend
            .put(
                "/sites/" + payload.siteId + "/pusheventtypes/" + payload.eventTypeId,
                {
                    name: payload.name,
                    isDefault: payload.isDefault,
                    site: payload.site
                },
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_PUSHBUTTON_EVENT_TYPE", {
                    currentPushButtonAlert: res.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alert_pushButtonEventTypeSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to pushButtonAlerts page
                router.push({
                    name: "pushButtonEventTypes",
                    params: {
                        fromAction: "updateAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a push button alert
     */
    deletePushButtonEventType({ commit, dispatch }, data) {
        axiosBackend
            .delete(
                "/sites/" + data.siteId + "/pusheventtypes/" + data.eventTypeId,
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(() => {
                commit("REMOVE_PUSHBUTTON_EVENT_TYPE_BY_ID", data.eventTypeId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alert_pushButtonEventTypeSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetPushButtonEventTypeState({ commit }) {
        commit('RESET_DEFAULT_PUSHBUTTON_EVENT_TYPE_STATE');
    },

};

export default {
    state,
    getters,
    mutations,
    actions
};