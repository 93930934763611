module.exports = {
    list: [
        { index: 0, value: "MONDAY" },
        { index: 1, value: "TUESDAY" },
        { index: 2, value: "WEDNESDAY" },
        { index: 3, value: "THURSDAY" },
        { index: 4, value: "FRIDAY" },
        { index: 5, value: "SATURDAY" },
        { index: 6, value: "SUNDAY" }
    ]
}