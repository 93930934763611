import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteIndoorAreas: null,
        currentIndoorArea: { id: "", state: "", isHighlightFromMap: false }
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for siteIndoorAreas
     */
    siteIndoorAreas(state) {
        return state.siteIndoorAreas;
    },

    currentIndoorArea(state) {
        return state.currentIndoorArea;
    },

    getIndoorAreaById(state) {
        return id => _.find(state.siteIndoorAreas, ["id", id]);
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_INDOOR_AREAS(state, data) {
        if (!state.siteIndoorAreas) {
            state.siteIndoorAreas = [];
        }
        state.siteIndoorAreas = data;
    },

    REMOVE_INDOOR_AREA_BY_ID(state, indoorAreaId) {
        // Find indoorArea index
        let index = _.findIndex(state.siteIndoorAreas, { id: indoorAreaId });
        state.siteIndoorAreas.splice(index, 1);
    },

    SET_INDOOR_AREA(state, indoorAreaData) {
        if (state.siteIndoorAreas) {
            // Find indoor area index
            let index = _.findIndex(state.siteIndoorAreas, { id: indoorAreaData.id });
            if (index >= 0) {
                // Update - replace the indoor area in the array of indoorAreas
                state.siteIndoorAreas.splice(index, 1, indoorAreaData);
            } else {
                // Creation
                state.siteIndoorAreas.push(indoorAreaData);
            }
        } else {
            state.siteIndoorAreas = [];
            state.siteIndoorAreas.push(indoorAreaData);
        }
    },

    SET_CURRENT_INDOOR_AREA(state, indoorAreaData) {
        state.currentIndoorArea = indoorAreaData;
    },

    RESET_DEFAULT_INDOOR_AREAS_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Action used to create an indoor area
     */
    createIndoorArea({ commit }, payload) {
        axiosBackend
            .post("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/indoorareas", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current indoor area in store
                commit("SET_CURRENT_INDOOR_AREA", { id: "", state: "" });

                // Store created indoor area in store
                commit("SET_INDOOR_AREA", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("area_indoorAreaSuccessfullyCreated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of site indoor areas
     */
    getSiteIndoorAreas({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/indoorareas/", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store indoor areas
                commit("SET_INDOOR_AREAS", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one indoor area by its id
     */
    getIndoorAreaById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/indoorareas/" + payload.indoorAreaId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_INDOOR_AREA", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing indoor area
     */
    updateIndoorArea({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/indoorareas/" + payload.id, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current indoor area in store
                commit("SET_CURRENT_INDOOR_AREA", { id: "", state: "" });

                // Set user Data in VueX Auth store
                commit("SET_INDOOR_AREA", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("area_indoorAreaSuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing indoor area polygon
     */
    updateIndoorAreaPolygon({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/indoorareas/" + payload.id + "/polygon", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current indoor area in store
                commit("SET_CURRENT_INDOOR_AREA", { id: "", state: "" });

                // Set user Data in VueX Auth store
                commit("SET_INDOOR_AREA", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("area_indoorAreaSuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete an indoor area
     */
    deleteIndoorArea({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/indoorareas/" + payload.indoorAreaId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_INDOOR_AREA_BY_ID", payload.indoorAreaId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("area_indoorAreaSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetIndoorAreasState ({ commit }) {
        commit('RESET_DEFAULT_INDOOR_AREAS_STATE');
    },

    setCurrentIndoorArea({ commit }, payload) {
        commit("SET_CURRENT_INDOOR_AREA", payload);
    }
    
};

export default {
    state,
    getters,
    mutations,
    actions
};
