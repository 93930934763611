import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        broadcastMessages: null,
        bannerMessage: null
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /**
     * Getter for
     */
    broadcastMessages(state) {
        return state.broadcastMessages;
    },

    /**
     * Getter for diplayMessagesOfBanner
     */
    diplayMessagesOfBanner(state) {
        return state.bannerMessage;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_BROADCAST_MESSAGES(state, data) {
        state.broadcastMessages = data;
    },

    SET_BANNER_MESSAGES(state, data) {
        if (data.messageEN && data.messageFR) {
            // Message has been found set the message
            state.bannerMessage = {
                french: data.messageFR,
                english: data.messageEN,
                startDate: data.startDate,
                endDate: data.endDate
            };
        } else {
            // Set the banner message to null
            state.bannerMessage = null;
        }
    },

    RESET_DEFAULT_BROADCAST_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /**
     * Action to get all broadcast messages.
     */
    getAllBroadcastMessages({ commit }) {
        axiosBackend
            .get("/broadcastmessages", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store the banner message
                commit("SET_BROADCAST_MESSAGES", res.data.data);
            })
            .catch(error =>  console.log(error))
    },

    /**
     * Action to get the banner message which will be displayed.
     */
    getBannerMessage({ commit }) {
        axiosBackend
            .get("/broadcastmessages/banner", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store the banner message
                commit("SET_BANNER_MESSAGES", res.data.data);
            })
            .catch(error =>  console.log(error))
    },

    /**
     * Action to get the banner message which will be displayed.
     */
    spreadBannerBroadcast({ commit }, payload) {
        axiosBackend
            .post("/broadcastmessages/banner", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("broadcast_bannerSuccessfullySpread"),
                    type: "success"
                });

                // Creation OK => Route to home page
                router.push({
                    name: "home",
                    params: {
                        fromAction: "createAPI"
                    }
                });
            })
            .catch(error =>  console.log(error))
    },
    /**
     * Action to get the banner message which will be displayed.
     */
    spreadEmailBroadcast({ commit }, payload) {
        axiosBackend
            .post("/broadcastmessages/email", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("broadcast_emailSuccessfullySpread"),
                    type: "success"
                });

                // Creation OK => Route to home page
                router.push({
                    name: "home",
                    params: {
                        fromAction: "createAPI"
                    }
                });
            })
            .catch(error =>  console.log(error))
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
    */
    resetBroadcastState ({ commit }) {
        commit('RESET_DEFAULT_BROADCAST_STATE');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};