import i18n from "../i18n";
import authHelper from "./authHelper";
import moment from "moment";
import { Workbook } from "exceljs";
import * as fs from "file-saver";

const _iconSizes = require("../constants/iconSizes");
const _customfieldTypes = require("../constants/customfieldTypes");
const _assetUnit = require("../constants/assetUnit");

/**
 * Class containing global methods about the authentication
 */
export default class commonVueHelper {

    /**
     * This function is used to extract one url query parameter identified by its name
     */
    static getUrlParameter(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
        var results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    /**
     * Display modal confirmation if a component is modified but user decide to leave the component
     * @param componentName string to set the component name (ony used for console log)
     * @param componentType string to define component action (create or update)
     * @param isConfirmModalMustBeDisplayed bool to define if we must to display a confirm modal
     * @param to string to url
     * @param from string from url
     * @param next iterator next url
     */
    static displayConfirmModalOnLeavePage(vuecomponent, componentName, componentType, isConfirmModalMustBeDisplayed, to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        let fromAction = "";
        if (to.params.fromAction) {
            fromAction = to.params.fromAction;
        }
        console.log("Component(" + componentName + ")::beforeRouteLeave() - called from " + fromAction);

        let isLeavePageOnSessionExpired = !authHelper.isLocalStorageAuthenticated() && to.name === "signin";

        if (fromAction.indexOf(componentType) >= 0) {
            console.log("Component" + componentName + "::beforeRouteLeave() - " + componentType + " Button has been clicked");
            next();
        } else if (isConfirmModalMustBeDisplayed && !isLeavePageOnSessionExpired) {
            let modalVue = vuecomponent.$refs.confirmleavepagemodalref;
            modalVue.display();

            modalVue.$once("confirmleavepageyes", () => {
                console.log("Component(" + componentName + ")::beforeRouteLeave() - confirm leave page");
                next();
            });
            modalVue.$once("confirmleavepageno", () => {
                // User clicked cancel or the modal 'x' close button
                console.log("Component(" + componentName + ")::beforeRouteLeave() - abort leave page");
                next(false);
            });
        } else {
            console.log("Component(" + componentName + ")::beforeRouteLeave() - No data / normal route");
            return next();
        }
    }

    /**
     * This function allows to destroy correctly a select2 element.
     * @param {Object} selector
     */
    static destroySelect2(selector) {
        if (selector && selector.data("select2")) {
            // Force empty select2
            selector.empty();
            // Destroy select2
            selector.select2("destroy");
            // Unbind the event
            selector.off();
        }
    }

    /**
     * This function allows to return an HTML string defining the availability UI into a select2
     * @param {Object} item
     */
    static formatAvailabilityTemplateResult(item, container) {
        console.log("commonVueHelper::formatAvailabilityTemplateResult() - item: " + JSON.stringify(item));
        if (!item.id) {
            return item.text;
        }
        if (item.id === "ENUM_AVAILABILITY_UNAVAILABLE") {
            //$(container).addClass('testNatResultRed');
            // Red bubble
            return "<div class='bubbleRed'></div><span class='marginL15'>" + item.text + "</span>";
        } else if (item.id === "ENUM_AVAILABILITY_AVAILABLE") {
            //$(container).addClass('testNatResultGreen');
            // Green bubble
            return "<div class='bubbleGreen'></div><span class='marginL15'>" + item.text + "</span>";
        } else {
            //$(container).addClass('testNatResultNoBubble');
            // No bubble
            return item.text;
        }
    }

    /**
     * This function allows to return an HTML string defining the availability UI into a select2
     * @param {Object} item
     */
    static formatAvailabilityTemplateSelection(item, container) {
        console.log("commonVueHelper::formatAvailabilityTemplateSelection() - item: " + JSON.stringify(item));
        if (!item.id) {
            return item.text;
        }
        if (item.id === "ENUM_AVAILABILITY_UNAVAILABLE") {
            //$(container).addClass('testNatSelectionRed');
            // Red bubble
            return "<div class='bubbleRed'></div><span class='marginL15'>" + item.text + "</span>";
        } else if (item.id === "ENUM_AVAILABILITY_AVAILABLE") {
            //$(container).addClass('testNatSelectionGreen');
            // Green bubble
            return "<div class='bubbleGreen'></div><span class='marginL15'>" + item.text + "</span>";
        } else {
            //$(container).addClass('testNatSelectionNoBubble');
            // No bubble
            return item.text;
        }
    }

    /**
     * This function allows to return an HTML string defining the priority UI into a select2
     * @param {Object} item
     */
    static formatPriority(item) {
        if (!item.id) {
            return item.text;
        }
        if (item.id === "ENUM_PRIORITY_LOW") {
            // Red bubble
            return "<div class='bubbleGrey'></div><span class='marginL15'>" + item.text + "</span>";
        } else if (item.id === "ENUM_PRIORITY_MEDIUM") {
            // Green bubble
            return "<div class='bubbleOrange'></div><span class='marginL15'>" + item.text + "</span>";
        } else if (item.id === "ENUM_PRIORITY_HIGH") {
            // Green bubble
            return "<div class='bubbleRed'></div><span class='marginL15'>" + item.text + "</span>";
        } else if (item.id === "ENUM_PRIORITY_VITAL") {
            // Green bubble
            return "<div class='bubbleBlack'></div><span class='marginL15'>" + item.text + "</span>";
        } else {
            // No bubble
            return item.text;
        }
    }

    /**
     * This function allows to initialize a select2 element.
     * @param {Object} selector
     * @param {String} placeholderKey
     * @param {Array} data
     * @param {String} value
     */
    static initSelect2(selector, placeholderKey, data, value) {
        if (selector) {
            // Define default json select2 config
            var jsonSelect2Config = {
                placeholder: i18n.t(placeholderKey),
                width: "100%",
                data: data,
                minimumResultsForSearch: -1
            };

            // Check if the select2 is for availability custom field
            // If it is the case, we will add a small bubble inside select2
            for (var i = 0; i < data.length; i++) {
                if (data[i].id && data[i].id.indexOf("ENUM_AVAILABILITY_") !== -1) {
                    jsonSelect2Config.templateResult = this.formatAvailabilityTemplateResult;
                    jsonSelect2Config.templateSelection = this.formatAvailabilityTemplateSelection;
                    jsonSelect2Config.escapeMarkup = function(m) {
                        return m;
                    };
                    break;
                } else if (data[i].id && data[i].id.indexOf("ENUM_PRIORITY_") !== -1) {
                    jsonSelect2Config.templateResult = this.formatPriority;
                    jsonSelect2Config.templateSelection = this.formatPriority;
                    jsonSelect2Config.escapeMarkup = function(m) {
                        return m;
                    };
                    break;
                }
            }

            // Init unit Select2
            this.destroySelect2(selector);
            //selector.next('.select2-container').remove(); //https://stackoverflow.com/questions/41255897/jquery-form-repeater-and-select2-dont-work-together
            //
            selector.select2(jsonSelect2Config);
            this.setSelect2Val(selector, value, {
                initialInit: true
            });
            /*
            if (!selector.hasClass("select2-hidden-accessible")) {
                selector.addClass("select2-hidden-accessible");
            }
            */
        }
    }

    /**
     * Sets the value of the select2 element.
     * @param {Object} selector
     * @param {String} value
     * @param {Object} jsonParams
     */
    static setSelect2Val(selector, value, jsonParams) {
        if (selector) {
            if (value === undefined) {
                selector.val(null);
            } else {
                selector.val(value);
            }
            if (jsonParams) {
                selector.trigger({
                    type: "change",
                    params: jsonParams
                });
            } else {
                selector.trigger("change");
            }
        }
    }

    /**
     * This function allows to initialize a select2 custom field element.
     * @param {Object} selector
     * @param {Object} customField
     */
    static initSelect2CustomField(selector, customField) {
        if (selector) {
            let enumData = [];
            let value = undefined;
            if (customField && customField.enumc) {
                for (let l = 0; l < customField.enumc.length; l++) {
                    let translatedText = customField.isDefault ? i18n.t(customField.enumc[l]) : customField.enumc[l];
                    let item = {
                        text: translatedText, // Displayed text in select2
                        id: customField.enumc[l] // Dico key of text if it exists
                    };
                    enumData.push(item);
                }
                if (customField.name === "CUSTOMFIELD_AVAILABILITY" && !customField.value) {
                    // A value must be defined in case of availabilty field for dashboard asset display
                    value = "ENUM_AVAILABILITY_AVAILABLE";
                } else {
                    value = customField.value;
                }
            }
            this.initSelect2(selector, "common_selectValue", enumData, value);
        }
    }

    /**
     * This function allows to destroy correctly a datepicker element.
     * @param {Object} selector
     */
    static destroyDatePicker(selector) {
        if (selector && selector.data("datepicker")) {
            // Force empty select2
            selector.empty();
            // Destroy select2
            selector.datepicker("destroy");
        }
    }

    /**
     * This function allows to initialize a datepicker element with given JSON options.
     * @param {Object} selector
     * @param {Object} options
     * @param {String|Date} date
     */
    static initDatePickerAllOptions(selector, options, date) {
        if (selector) {
            this.destroyDatePicker(selector);

            // Date format
            let dateFormat;
            const momentLocale = moment().locale();
            switch (momentLocale) {
                case "en":
                    dateFormat = "MM d, yyyy";
                    break;
                case "fr":
                    dateFormat = "d MM yyyy";
                    break;
                default:
                    dateFormat = "MM d, yyyy";
            }

            // Init datepicker
            let optionsDatepicker = {
                language: momentLocale,
                format: dateFormat,
                todayHighlight: options.todayHighlight,
                datepickerOrientation: options.datepickerOrientation,
                todayBtn: options.todayBtn,
                clearBtn: options.clearBtn,
                autoclose: options.autoclose
            }

            // option put end date selection (maximun date to select)
            if (options.endDate) {
                optionsDatepicker['endDate'] = options.endDate;
            }

            // Init datepicker
            selector.datepicker(optionsDatepicker);

            // Init datepicker to the date
            this.setDatePickerVal(selector, date);
        }
    }

    /**
     * This function allows to initialize a datepicker element with common default options.
     * @param {Object} selector
     * @param {String} orientation
     * @param {String|Date} date
     */
    static initDatePicker(selector, date) {
        // Common default datepicker options
        let options = {
            todayHighlight: true,
            datepickerOrientation: "top left",
            todayBtn: "linked",
            clearBtn: true,
            autoclose: true,
            endDate: null
        };
        this.initDatePickerAllOptions(selector, options, date);
    }

    /**
     * This function allows to initialize a datepicker element without clear button.
     * @param {Object} selector
     * @param {String|Date} date
     * @param {String|Date} maxDay
     */
    static initDatePickerWithoutClear(selector, date, maxDay) {
        // Common default datepicker options
        let maxDate = this.endDay(maxDay);
        let options = {
            todayHighlight: true,
            datepickerOrientation: "top left",
            todayBtn: "linked",
            clearBtn: false,
            autoclose: true,
            endDate: maxDate
        };
        this.initDatePickerAllOptions(selector, options, date);

    }

    /**
     * This function allows to initialize a datepicker element with common default options.
     * @param {Object} selector
     * @param {String|Date} date
     */
    static initDatePickerTag(selector, date) {
        // Common default datepicker options
        let options = {
            todayHighlight: true,
            datepickerOrientation: "top left",
            todayBtn: false,
            clearBtn: false,
            autoclose: true,
            endDate: null
        };
        this.initDatePickerAllOptions(selector, options, date);
    }

    /**
     * Sets the date for the datepicker element.
     * @param {Object} selector
     * @param {String|Date} value
     */
    static setDatePickerVal(selector, value) {
        if (selector) {
            if (value) {
                let dt = new Date(value);
                selector.datepicker("setDate", moment(dt).toDate());
            } else {
                selector.datepicker("setDate", null);
            }
        }
    }

    /**
     * Get the string ISOString date for the datepicker element.
     */
    static getDatePickerISOString(selector) {
        let dateToISOString = null;
        if (selector) {
            let date = selector.datepicker("getDate");
            dateToISOString = this.dateToISOString(date);
        }
        return dateToISOString;
    }

    /**
     * This function allows to destroy correctly a datetimepicker element.
     * @param {Object} selector
     */
    static destroyDateTimePicker(selector) {
        if (selector && selector.data("datetimepicker")) {
            // Force empty datetimepicker
            selector.empty();
            // Destroy datetimepicker
            selector.datetimepicker("destroy");
        }
    }

    /**
     * This function allows to initialize a datetimepicker element.
     * @param {Object} selector
     * @param {String} position
     * @param {String|Date} currentDate
     * @param {String|Date} startDate
     * @param {String|Date} endDate
     */
    static initDateTimePicker(selector, position, currentDate, startDate, endDate) {
        if (selector) {
            this.destroyDateTimePicker(selector);
            let dtpPosition = position;
            if (!position) {
                dtpPosition = "top-right";
            }
            // Date format
            let dateFormat;
            const momentLocale = moment().locale();
            switch (momentLocale) {
                case "en":
                    dateFormat = "MM d, yyyy, hh:ii";
                    break;
                case "fr":
                    dateFormat = "d MM yyyy, hh:ii";
                    break;
                default:
                    dateFormat = "MM d, yyyy, hh:ii";
            }
            // Init datepicker
            let options = {
                language: momentLocale,
                locale: momentLocale,
                format: dateFormat,
                todayHighlight: true,
                pickerPosition: dtpPosition,
                todayBtn: "linked",
                autoclose: true
            };
            // if (currentDate) {
            //     options["initialDate"] = new Date(currentDate);
            // }
            if (startDate) {
                options["startDate"] = new Date(startDate);
            }
            if (endDate) {
                options["endDate"] = new Date(endDate);
            }
            selector.datetimepicker(options);
            // Init datepicker to the date
            this.setDateTimePickerVal(selector, currentDate);
        }
    }

    /**
     * Sets the date for the datetimepicker element.
     * @param {Object} selector
     * @param {String} value
     */
    static setDateTimePickerVal(selector, value) {
        if (selector && value) {
            selector.datetimepicker("update", new Date(value));
        }
    }

    /**
     * Get the string ISOString date for the datepicker element.
     */
    static getDateTimePickerISOString(selector) {
        let dateToISOString = null;
        if (selector) {
            let date = selector.datepicker("getDate");
            dateToISOString = moment(date).toISOString();
        }
        return dateToISOString;
    }

    /**
     * Sets checked/unchecked a checkbox element.
     * @param {Object} selector
     * @param {String|Boolean} value
     */
    static setCheckBoxVal(selector, value) {
        if (selector) {
            if (value === "true" || value === true || value === 1) {
                selector.prop("checked", true);
            } else {
                selector.prop("checked", false);
            }
        }
    }

    static setURLVal(selector, value) {
        let url = value; 
        if (!/^https?:\/\//i.test(url)) {
            url = "https://" + url;
        }
        selector.attr('href', url);
        selector.html(value)
    }

    /**
     * This function allows to destroy correctly a tag element.
     * @param {Object} selector
     */
    static destroyTag(selector) {
        if (selector) {
            if (selector.data("select2")) {
                this.destroySelect2(selector);
            } else if (selector.data("datepicker")) {
                this.destroyDatePicker(selector);
            } else if (selector.data("datetimepicker")) {
                this.destroyDateTimePicker(selector);
            }
        }
    }

    /**
     * This function allows to add a tooltip to a given DOM element
     * @param domEl DOM HTML element
     * @param tooltipText The text to display
     */
    static addTooltipToElement(domEl, tooltipText) {
        $(domEl).attr("data-toggle", "kt-tooltip");
        $(domEl).attr("data-placement", "right");
        $(domEl).attr("data-original-title", tooltipText);
    }

    /**
     * This function allows to get the date string format to be used for display according moment locale
     * @param date Date string
     * @param momentLocale Moment locale: could be fr or en
     */
    static getDateStringFormatFromLocale(momentLocale) {
        if (momentLocale === "en") {
            return "MMM. D, YYYY, h:mm:ss a";
        } else if (momentLocale === "fr") {
            return "D MMM YYYY, H:mm:ss";
        } else {
            return "MMM. D, YYYY, h:mm:ss a";
        }
    }

    /**
     * This function allows to get the date string format to be used for display according moment locale
     * @param date Date string
     * @param momentLocale Moment locale: could be fr or en
     */
    static getShortDateStringFormatFromLocale(momentLocale) {
        if (momentLocale === "en") {
            return "MMM. DD, YYYY";
        } else if (momentLocale === "fr") {
            return "DD MMM YYYY";
        } else {
            return "MMM. DD, YYYY";
        }
    }

    /**
     * Get translated tag type information from tagType object.
     * @param {Object} tagType
     * @returns JSON object
     */
    static getTagTypeItem(tagType) {
        if (!tagType) {
            return {};
        }
        let itemType = {
            id: tagType.id,
            name: tagType.name,
            batteryLifetime: tagType.batteryLifetime,
            rssiOneMeter: tagType.rssiOneMeter,
            ref: (tagType.reference ? tagType.reference : tagType.ref),
            label: this.getPropFromTagType("label", tagType),
            fullRef: this.getPropFromTagType("fullRef", tagType),
            desc: this.getPropFromTagType("desc", tagType),
            imgClass: this.getPropFromTagType("imgClass", tagType),
            isDefault: tagType.isDefault,
        };
        if (tagType.image) {
            itemType.img = this.getPropFromTagType("img", tagType);
        } else {
            itemType.img = "/assets/tags/universal.svg";
        }
        return itemType;
    }

    static getPropFromTagType(prop, tagType) {
        let value = "";

        if (tagType && tagType.name) {
            let nameLC = tagType.name.toLowerCase();
            switch (prop) {
                case "name":
                    value = tagType.name;
                    break;
                case "label":
                    if (tagType.isDefault) {
                        value = i18n.t("tag_" + nameLC + "TagLabel");
                    } else {
                        value = tagType.name;
                    }
                    break;
                case "fullRef":
                    if (tagType.isDefault) {
                        value = i18n.t("tag_" + nameLC + "TagFullRef");
                    } else {
                        value = tagType.name + (tagType.reference ? " (" + tagType.reference+ ")" : " (" + tagType.ref + ")");
                    }
                    break;
                case "desc":
                    if (tagType.isDefault) {
                        value = i18n.t("tag_" + nameLC + "TagDesc");
                    } else {
                        value = nameLC;
                    }
                    break;
                case "img":
                    if (tagType.isDefault) {
                        value = "/assets/tags/" + nameLC + ".svg";
                    } else {
                        value = tagType.image || tagType.img;
                    }
                    break;
                case "imgClass":
                    if (tagType.isDefault) {
                        value = "img" + nameLC.charAt(0).toUpperCase() + nameLC.slice(1) + "Tag";
                    } else {
                        value = "customTagType"
                    }
                    break;
            }
        }
        return value;
    }

    static getPropFromReport(prop, report) {
        let value;

        if(report) {
            switch (prop) {
                case "template":
                    value = i18n.t(report.reportTemplate);
                    break;
                case "reportType":
                    const reportTypeLC = report.reportType.toLowerCase();
                    value = i18n.t("reporting_" + reportTypeLC + "Type");
                    break;
                case "scheduler":
                    const schedulerLC = report.scheduler.repeat.toLowerCase();
                    value = i18n.t("reporting_" + schedulerLC + "Scheduler");
                    break;
                default:
                    value = "-";
                    break;
            }
        }

        return value;
    }

    static getDayOfWeek(day) {
        let value = "-";
        if (day) {
            const dayLC = day.toLowerCase();
            value = i18n.t("common_" + dayLC);
        }
        return value;
    }

    /**
     * Compare 2 JSON object on { "id": idVal, "updatedAt": dateVal } and returns true if JSON object has been updated (different id or updateAt ).
     * @params {array} jsonObj1 First JSON object
     * @params {array} jsonObj2 Other JSON object to compare
     * @returns True if "id" or "updatedAt" property has changed
     */
    static isChangedIdOrUpdatedAt(jsonObj1, jsonObj2) {
        let isChanged = false;
        if (jsonObj1 && jsonObj2) {
            if (jsonObj1.id !== jsonObj2.id || jsonObj1.updatedAt !== jsonObj2.updatedAt) {
                // JSON object with different properties "id" or "updatedAt"
                console.log("commonVueHelper::isChangedIdOrUpdatedAt() - TRUE on (" + jsonObj1.id + ", " + jsonObj1.updatedAt + ") / (" + jsonObj2.id + ", " + jsonObj2.updatedAt + ")");
                isChanged = true;
            }
        } else {
            if (jsonObj1 != jsonObj2) {
                // JSON object with different properties "id" or "updatedAt"
                console.log("commonVueHelper::isChangedIdOrUpdatedAt() - TRUE with one jsonObj null");
                isChanged = true;
            }
        }
        //console.log("commonVueHelper::isChangedIdOrUpdatedAtInArray() - returns", isChanged);
        return isChanged;
    }

    /**
     * Compare 2 assets on { "id": idVal, "updatedAt": dateVal }
     * @params {Object} asset1 First asset
     * @params {Object} asset2 Other asset to compare
     * @returns True if "id" or "updatedAt" property has changed on asset
     */
    static isChangedAsset(asset1, asset2) {
        let isChangedAsset = false;
        if (asset1 && asset2) {
            if (this.isChangedIdOrUpdatedAt(asset1, asset2)) {
                isChangedAsset = true;
            } else if (this.isChangedIdOrUpdatedAt(asset1.tag, asset2.tag)) {
                isChangedAsset = true;
            } else if (this.isChangedIdOrUpdatedAt(asset1.assetcategory, asset2.assetcategory)) {
                isChangedAsset = true;
            } else if (this.isChangedIdOrUpdatedAt(asset1.assetsubcategory, asset2.assetsubcategory)) {
                isChangedAsset = true;
            } else if (this.isChangeCustomFields(asset1.customfields, asset2.customfields)) {
                isChangedAsset = true;
            }
        } else if (asset1 !== asset2) {
            // One asset null and other asset defined
            isChangedAsset = true;
        }
        // The 2 assets are null
        return isChangedAsset;
    }

    /**
     * Compare 2 arrays of custom fields on { "id": idVal, "updatedAt": dateVal }
     * @params {Object} customFields1 First custom fields array
     * @params {Object} customFields2 Other custom fields array to compare
     * @returns True if "id" or "updatedAt" property has changed on custom fields
     */
    static isChangeCustomFields(customFields1, customFields2) {
        let isChanged = false;
        if (customFields1 && customFields2) {
            if (customFields1.length === customFields2.length) {
                for (let i = 0; i < customFields1.length; i++) {
                    if (this.isChangedIdOrUpdatedAt(customFields1[i], customFields2[i])) {
                        isChanged = true;
                        break;
                    }
                }
            } else {
                // Not same number of custom fields
                isChanged = true;
            }
        } else if (customFields1 !== customFields2) {
            // One custom field null and other custom field defined
            isChanged = true;
        }
        return isChanged;
    }

    /**
     * Get the current date plus X minutes
     * @param {Integer} x
     * @returns Date
     */
    static getToDayPlusXmin(x) {
        let toDay = new Date();
        let toDayPlusXmin = new Date(toDay);
        toDayPlusXmin.setMinutes(toDay.getMinutes() + x);
        return toDayPlusXmin;
    }

    /**
     * Get the current date plus X minutes
     * @param {Integer} x
     * @returns Date
     */
    static getToDayLessXday(x) {
        let toDay = new Date();
        let toDayPlusXmin = new Date(toDay);
        toDayPlusXmin.setHours(toDay.getHours() - (24 * x));
        return toDayPlusXmin;
    }

    /**
     * date initialized at the start of the day
     * @param {Date} date
     * @returns Date
     */
    static beginDay(date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    /**
     * date initialized at the end of the day
     * @param {Date} date
     * @returns Date
     */
    static endDay(date) {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(999);
        return date;
    }

    /**
     * Initialize a date in simplified extended ISO format (ISO 8601).
     * In case of date at midnight, take into account the timezone to set the date with the good day.
     * @params {Date} date
     * @returns String ISO 8601
     */
    static dateToISOString(date) {
        let dateToISOString = null;
        if (date) {
            let dt = date;
            if (dt.getHours() === 0 && dt.getMinutes() === 0) {
                // At 0h, take into account timezone to store the date with 0h in the backend
                let offset = 0;
                if (dt) {
                    offset = dt.getTimezoneOffset() / 60;
                }
                dt = new Date(dt.setHours(dt.getHours() - offset));
            }
            dateToISOString = moment(dt).toISOString();
        }
        return dateToISOString;
    }

    /**
     * Initialize a date  and time in simplified extended ISO format (ISO 8601).
     * @params {Date} date
     * @returns String ISO 8601
     */
    static datetimeToISOString(date) {
        let dateToISOString = null;
        if (date) {
            dateToISOString = moment(date).toISOString();
        }
        return dateToISOString;
    }

    /**
     * Verify the status of subscription
     * @param {Object} subscription
     * @return String
     */
    static getSubscriptionStatus(subscription) {
        if (!subscription) {
            // No subscription
            return "NO_SUBSCRIPTION";
        } else {
            var endDate = moment(subscription.endDate);
            var now = moment();
            var isTerminated = true;
            var subscriptionType = "";
            if (now.isAfter(endDate)) {
                isTerminated = false;
            }
            if (subscription.type == "TRIAL") {
                subscriptionType = "TRIAL_";
            } else {
                subscriptionType = "PAID_";
            }

            subscriptionType += isTerminated == false ? "EXPIRED" : "ACTIVE";
            return subscriptionType;
        }
    }

    /**
     * Export data to excel file
     * @param {Array} data
     * @param {Array} header
     * @param {String} fileName
     * @param {String} worksheetName
     * @param {String} title
     * @param {Array} infoData
     * @param {Array} infoHeader
     * @param {String} infoWorksheetName
     */
    static exportsDataToExcel(data, header, fileName, worksheetName, title, infoData = null, infoHeader = null, infoWorksheetName = null) {
        let workbook = new Workbook();

        this.exportsDataToExcelWorksheet(fileName, workbook, worksheetName, data, header, title);

        if (infoData && infoHeader && infoWorksheetName) {
            this.exportsDataToExcelWorksheet(fileName, workbook, infoWorksheetName, infoData, infoHeader, title);
        }

        // Save file
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            fs.saveAs(blob, fileName + ".xlsx");
        });
    }

    /**
     * Add new worksheet with data in a workbook
     * @param {String} fileName
     * @param {Workbook} workbook
     * @param {String} worksheetName
     * @param {Array} data
     * @param {Array} header
     * @param {String} title
     */
    static exportsDataToExcelWorksheet(fileName, workbook, worksheetName, data, header, title) {
        let worksheet = workbook.addWorksheet(worksheetName);

        if (title) {
            // Set Header with given title (default centered)
            worksheet.headerFooter.oddHeader = title;
            // Set the footer (default centered) to bold, resulting in: "Page 2 of 16"
            worksheet.headerFooter.oddFooter = "&L"+fileName+"&C&D&RPage &P of &N"
        }

        // Define sheet columns
        var sheetColumns = [];
        header.forEach((item) => {
            if (item.type == "DATE") {
                sheetColumns.push({ header: item.name, key: item.name, width: 40, style: { numFmt: "yyyy-mm-dd" } });
            } else if (item.type == "DATETIME") {
                sheetColumns.push({ header: item.name, key: item.name, width: 40, style: { numFmt: "yyyy-mm-dd hh:mm:ss" } });
            } else if (item.type == "DATETIMEZONE") {
                // Excel format "Z" for +0200 (Basic ISO 8601 timezone)
                // Excel format "XXX" +02:00 (Extended ISO 8601 time zone)
                sheetColumns.push({ header: item.name, key: item.name, width: 40, style: { numFmt: "yyyy-mm-dd hh:mm:ss \"GMT\"Z" } });
            } else if (item.type == "TIME") {
                sheetColumns.push({ header: item.name, key: item.name, width: 40, style: { numFmt: "hh:mm:ss" } });
            } else {
                sheetColumns.push({ header: item.name, key: item.name, width: 40 });
            }
        });
        worksheet.columns = sheetColumns;

        // Define header style
        let headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
            cell.font = {
                name: "Calibri",
                size: 12,
                bold: true
            };
            cell.alignment = {
                vertical: "center",
                horizontal: "center"
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "BFBFBF" },
                bgColor: { argb: "BFBFBF" }
            };
            cell.border = { top: { style: "thin" }, left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } };
        });

        // Add row and define style
        data.forEach((item) => {
            let row = worksheet.addRow(item);
            row.eachCell((cell) => {
                cell.alignment = {
                    vertical: "center",
                    horizontal: "center"
                };
                cell.font = {
                    name: "Calibri",
                    size: 12
                };
                cell.border = { top: { style: "thin" }, left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } };
            });
        });

        // Set the correct type of column
        header.forEach((item) => {
            switch (item.type) {
                case "DATE":
                case "DATETIME":
                    let dateColumnValues = worksheet.getColumn(item.name);
                    dateColumnValues.eachCell((cell) => {
                        if (cell.value && cell.value != item.name) {
                            // Fix to set Excel date in local time
                            let date = cell.value;
                            if (! (cell.value instanceof Date) ) {
                                date = new Date(cell.value);
                            }
                            const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
                            cell.value = localDate;
                        }
                    });
                    break;
                case "NUMBER":
                    let numberColumnValues = worksheet.getColumn(item.name);
                    numberColumnValues.eachCell((cell) => {
                        if (cell.value != item.name && cell.value != null) {
                            cell.value = parseInt(cell.value);
                        }
                    });
                    break;
                case "FLOAT":
                    let floatColumnValues = worksheet.getColumn(item.name);
                    floatColumnValues.eachCell((cell) => {
                        if (cell.value != item.name && cell.value != null) {
                            cell.value = parseFloat(cell.value);
                        }
                    });
                    break;
            }
        });
    }

    /**
     * @name getLayoutOptions
     * Give the layout configuration of KtDataTable
     */
    static getLayoutOptions() {
        return {
            theme: "default",
            class: "",
            scroll: !1,
            footer: !1
        };
    }
    /**
     * @name getTranslateOptions
     * Give the translate configuration of KtDataTable
     */
    static getTranslateOptions() {
        return  {
            records: {
                processing: i18n.t("common_datatable_processing"),
                noRecords: i18n.t("common_datatable_noRecords")
            },
            toolbar: {
                pagination: {
                    items: {
                        default: {
                            first: i18n.t("common_datatable_pagination_first"),
                            prev: i18n.t("common_datatable_pagination_prev"),
                            next: i18n.t("common_datatable_pagination_next"),
                            last: i18n.t("common_datatable_pagination_last"),
                            more: i18n.t("common_datatable_pagination_morepages"),
                            input: i18n.t("common_datatable_pagination_input"),
                            select: i18n.t("common_datatable_pagination_select")
                        },
                        info: i18n.t("common_datatable_pagination_label")
                    }
                }
            }
        };
    }

    /**
     * @name getToolbarOptions
     * Give the toolbar configuration of KtDataTable
     */
    static getToolbarOptions() {
        return  {
            // toolbar placement can be at top or bottom or both top and bottom repeated
            placement: ["bottom"],
            layout: ["info", "pagination"],
            // toolbar items
            items: {
                // pagination
                pagination: {
                    // page size select
                    pageSizeSelect: [5, 10, 20, 30, 50] // display dropdown to select pagination size. -1 is used for "ALl" option
                }
            }
        }
    }

    /**
     * is the string value is an email valid
     * @param {String} value
     * @returns Bool
     */
    static isValidEmail(value) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    }

    /**
     * This function permits the renitialisation of tooltip
     */
    static initToolTips() {
        // Renitialisation of tooltip
        setTimeout(() => {
            KTApp.initTooltips()
            $('[data-toggle="kt-tooltip"]')
                .tooltip({ trigger: "hover" })
                .on("click", function() {
                    $(this).tooltip("hide");
                });
        }, 100);
    }


    /**
     * Reset the image path of the file displayed on the page in case of no image loaded.
     *
     * @param {bool} isImageLoaded
     */
    static resetImageInputLabel(isImageLoaded=false) {
        if (!isImageLoaded) {
            // Use the innerHTML property to change the text inside the label (to replace file path by <common_chooseFile> translated string)
            $(".custom-file-label").html(i18n.t("common_chooseFile"));
        }
    }


    /**
     * Get array of translated icon sizes with key and tranlated key.
     *
     * @returns {array}
     */
    static getIconSizesData() {
        let iconSizesData = [];
        if (_iconSizes && _iconSizes.list) {
            for (let i = 0; i < _iconSizes.list.length; i++) {
                let item = { id: _iconSizes.list[i], text: i18n.t(_iconSizes.list[i]) };
                iconSizesData.push(item);
            }
        }
        return iconSizesData;
    }

    /**
     * Get array of translated custom field types with key and tranlated key.
     *
     * @returns {array}
     */
    static getCustomFieldTypesData() {
        let typesData = [];
        if (_customfieldTypes && _customfieldTypes.list) {
            const customfieldTypes = _customfieldTypes.list.sort();
            for (let i = 0; i < customfieldTypes.length; i++) {
                typesData.push({
                    id: customfieldTypes[i], // Will be the value key
                    text: i18n.t("common_" + customfieldTypes[i].toLowerCase()) // Will be the text displayed
                });
            }
        }
        return typesData;
    }

    /**
     * Get array of units with key and tranlated key sorted in ascending order.
     *
     * @returns {array}
     */
    static getUnitsData() {
        let unitsData = [];
        if (_assetUnit && _assetUnit.list) {
            _.forEach(_assetUnit.list, key => {
                unitsData.push({
                    id: key, // Will be the value
                    text: i18n.t(key) // Will be the text displayed
                });
            });
            // Ascending sort order Select2 data of units
            unitsData = _.orderBy(unitsData, ["text"], ["asc"]);
        }
        return unitsData;
    }

    /**
     * Get the moment format for date and time with timezone.
     *
     * @returns {string}
     */
    static getXlDateTimezoneFormat() {
        const contactDateFormat = "YYYY-MM-DD HH:mm:ss";
        const timezoneBasicFormat = "[GMT]ZZ"; // GMT+0200 (Basic ISO 8601 timezone)
        const timezoneExtendFormat = "[GMT]Z"; // GMT+02:00 (Extended ISO 8601 time zone)
        return contactDateFormat + " " + timezoneBasicFormat;
    }

    /**
     * Protection against XSS attacks
     * Treat a string or json/array in order to escape specific characters such as < >
     *
     * @param {string|array|json} obj
     * @param {int} depth int
     */
    static preventHtmlInjection(obj, depth = 0) {

        if (obj && (obj.constructor === Object || obj.constructor === Array) && depth < 10) {
            // obj is a json or array
            for (const [key, value] of Object.entries(obj)) {
                obj[key] = this.preventHtmlInjection(value, depth + 1);
            }
        }
        else {
            if (typeof obj === 'string' || obj instanceof String) {
                // Obj is a string, let's escape it
                obj = $('<div />').text(obj).html();
            }
        }
        return obj;
    }

    /**
     * Get the ISOString start date of day (Timezone UTC+0)
     *
     * @param {Date|integer} date
     * @returns {string} as yyyy-mm-ddT00:00:00.000Z
     */
    static getStartOfDayToISOString(date) {
        const startOfDay = new Date(date);
        const startOfDayTimestamp = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
        return new Date(startOfDayTimestamp).toISOString();
    }

    /**
     * Get the ISOString end date of day (Timezone UTC+0)
     *
     * @param {Date|integer} date
     * @returns {string} as yyyy-mm-ddT23:59:59.999Z
     */
    static getEndOfDayToISOString(date) {
        const endOfDay = new Date(date);
        const endOfDayTimestamp = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
        return new Date(endOfDayTimestamp).toISOString();
    }

    /**
     * format time picker HH:mm or HH:mm A
     * @param {string} hour 
     * @param {string} minute
     * @param {string} timezone
     * @param {string} locale
     */
    static formatTimePicker(hour, minute, timezone, locale) {
        const formatTime = locale === "fr" ? "HH:mm" : "hh:mm A";
        if(timezone){
            return moment({ hour, minute })
                .tz(timezone)
                .locale(locale)
                .format(formatTime);
        }
        return moment({ hour, minute })
            .locale(locale)
            .format(formatTime);
        

    }

    /**
     * Convert image links to base64 images
     * @param {string} imageUrl 
     */
    static convertImageLinkToBase64(imageUrl) {
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onload = () => {
                    return reader.result;
                };
                reader.readAsDataURL(blob);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }



}