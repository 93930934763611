import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import routes from "./routes";
import i18n from "../i18n";

import authHelper from "../helpers/authHelper";

Vue.use(Router);

// Function responsible to check if user is authenticated or not
function guardRoute(to, from, next) {
    // There is no jwt token, user is not logged
    if (!authHelper.isLocalStorageAuthenticated()) {
        console.log("auth::guardRoute() NOT authenticated for route:" + to);

        // Check if the session is expired to display notification
        if ((store.state.auth.isSessionExpired === true || (localStorage.getItem("expirationDate") && authHelper.isLocalStorageTokenExpired() === true)) && to.name !== "signin") {
            console.log("auth::guardRoute() session expired");

            store.commit("SET_SESSION_EXPIRED", false);
            authHelper.clearLocalStorage();

            setTimeout(function () {
                store.commit("SHOW_NOTIFICATION", {
                    text: i18n.t("error_invalidToken"),
                    type: "error"
                });
            }, 500);
        }

        // user is not logged so signin and signup routes are availables
        if (to.name === "signin" || to.name === "signup" || to.name === "forgot" || to.name === "reset" || to.name === "showFloorAssetsOnMap") {
            next();
        }
        // all others routes are redirected to signin
        else {
            next("/signin");
        }
        // There is a jwt token, user is logged
    } else {
        console.log("auth::guardRoute() authenticated");

        //user is logged so signin and signup routes are not availables and redirected to home page
        if (to.name === "signin" || to.name === "signup" || to.name === "forgot" || to.name === "reset") {
            next("/home");
        }
        // all others routes are availables
        else {
            if (to.meta.requiresPermission) {
                // Ensure that logged user has permission to access this route (depending on his role on the selected site)
                if (!to.params.siteId) {
                    // Can't get route permissions without a siteId
                    next("/home");
                }
                let payload = {
                    siteId: to.params.siteId,
                    callback: getPermissionsCallback,
                    to: to, // need to pass to + next functions for later routing process in callback
                    next: next,
                    from: from
                };
                store.dispatch("getPermissions", payload).then(
                    () => {},
                    () => {
                        next("/home");
                    }
                );
            } else {
                next();
            }
        }
    }
}

function getPermissionsCallback(from, to, next, siteId) {
    if (store.getters.hasNoConfigAccess(siteId) && to.name !== "assetsTrackingDashboard" && from.name == "home") {
        // Force route directly to assets dashboard
        next("/site/" + siteId + "/dashboard/assets");
    } else {
        if (!to.meta.requiresPermission.feature) {
            // overview
            next();
        } else {
            let permission = to.meta.requiresPermission.feature;
            if (to.meta.requiresPermission.subFeature) {
                permission = permission + "." + to.meta.requiresPermission.subFeature;
            }
            if (!store.getters.hasAccess(siteId, permission)) {
                // User tries to access directly to an unauthorized page, redirect to signin
                console.log("Access to '" + to.name + "' page denied ! Redirection in progress...");
                next("/signin");
            } else {
                if (to.query) {
                    next({
                        query: to.query
                    });
                } else {
                    next();
                }
            }
        }
    }
}

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes.map(route => ({
        name: route.name,
        path: route.path,
        component: route.component,
        children: route.children,
        meta: route.meta
    })),
    // savedPosition managed scrool position when using of back/next button of the navigator
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                x: 0,
                y: 0
            };
        }
    }
});

router.beforeEach((to, from, next) => {
    // Set title of the page according current route title
    if (process.env.VUE_APP_RELEASED_FOR == "ALE") {
        document.title = to.meta.title;
    } else {
        if (to && to.meta && to.meta.title){
            document.title = to.meta.title.replaceAll("OmniAccess Stellar Asset Tracking", "Wireless Asset Tracking");
        }
    }

    if (from.params.siteId && (store.getters.showFingerprintInProgressModal || store.getters.showNeuralNetworkInProgressModal)) {
        store.dispatch("resetSitesState");
    }
    if (!to.meta.isPublic) {
        // If route is not public, check if user is well authenticated
        return guardRoute(to, from, next);
    }
    next();
});

// Avoid chunk failures. See here: https://medium.com/thinkspecial/chunkloaderror-loading-chunk-failed-gopi-k-kancharla-413efbc21db2
router.onError(error => {
    console.error('Failure Reason: ', error);
    /*
    // If you are certain the chunk is on your web server
    // You can try reloading the page, but be careful of RECURSION
    // In case the chunk really is not available
    if (/ChunkLoadError:.*failed./i.test(error.message)) {
        window.location.reload();
    }
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
        console.log('Reload the current URL');
        window.location.reload();
    }
    */
});

export default router;