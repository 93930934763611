/*
* State: properties to store
*/
import axiosBackend from "../../axios-backend";

const getDefaultState = () => {
    return {
        assetLocationHistory: [],
        currentPlaybackTimeStamp: null,
    };
};

const state = getDefaultState();

/*
* Getters
*/
const getters = {
    assetLocationHistory(state) {
        return state.assetLocationHistory;
    },

    getHistoryAssetById: state => assetId => {
        let result = state.assetLocationHistory.find(data => {
            const asset = data.properties.asset;
            if (asset && asset.id == assetId) {
                return data;
            }
        });
        if (!result) {
            console.log("NO RESULT getHistoryAssetById(" + assetId + ") with state.assetLocationHistory: ", state.assetLocationHistory);
        }
        return result;
    },

    currentPlaybackTimeStamp(state) {
        return state.currentPlaybackTimeStamp;
    }

};

/*
* Mutations
*/
const mutations = {
    SET_ASSET_LOCATION_HISTORY(state, data) {
        state.assetLocationHistory = data.history;
    },

    SET_CURRENT_PLAYBACK_TIMESTAMP(state, timestamp) {
        state.currentPlaybackTimeStamp = timestamp;
    }
};

/*
* Actions: List of methods to fetch, update or delete data
*/
const actions = {
    /**
     * Fecth the asset location history on given floor, site, building during periods.
     * @param {Object} context
     * @param {Object} payload {startDate: timestamp, endDate: timeStamp, flooId: String, siteId: String, buidingId: String}
     * @description
     *      Get the asset location history between startDate and endDate.
     */
    getAssetLocationHistory({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/asset/history?startDate=" + payload.startDate + "&endDate=" + payload.endDate, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                const data = [...res.data.data];

                //Allows assets to appear for another 30 seconds before disappearing
                for (let assetLocation of data) {
                    let lateCoordinates;
                    let lateTime;
                    if (assetLocation.geometry.coordinates && assetLocation.geometry.coordinates.length > 0) {
                        lateCoordinates = assetLocation.geometry.coordinates[assetLocation.geometry.coordinates.length - 1];
                    }
                    if (assetLocation.properties.time && assetLocation.properties.time.length > 0) {
                        lateTime = assetLocation.properties.time[assetLocation.properties.time.length - 1];
                    }
                    if (lateCoordinates && lateTime && lateTime < payload.endDate) {
                        assetLocation.geometry.coordinates.push(lateCoordinates);
                        assetLocation.properties.time.push(parseInt(lateTime) + 29000); // Milliseconds
                    }
                }

                commit("SET_ASSET_LOCATION_HISTORY", {
                    history: data
                });
            })
            .catch(error => console.log(error));
    },


    /**
     * Set the current timestamp of playback
     * @param {long} timestamp
     * @description
     *      Set the current timestamp of playback.
     */
    setCurrentPlaybackTimestamp({ commit }, timestamp) {
        commit("SET_CURRENT_PLAYBACK_TIMESTAMP", timestamp);
    }


};

export default {
    state,
    getters,
    mutations,
    actions
};
