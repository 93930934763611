const routes = [
    {
        path: "/signin",
        name: "signin",
        component: () => import("@/components/auth/signin.vue"),
        meta: {
            title: "Sign In - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/signup",
        name: "signup",
        component: () => import("@/components/auth/signup.vue"),
        meta: {
            title: "Sign Up - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/password/reset/:token",
        name: "reset",
        component: () => import("@/components/auth/reset.vue"),
        meta: {
            title: "Reset Password - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/password/reset",
        name: "forgot",
        component: () => import("@/components/auth/forgot.vue"),
        meta: {
            title: "Reset Password - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/home",
        name: "home",
        component: () => import("@/components/home/home.vue"),
        meta: {
            title: "Home - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/broadcast",
        name: "broadcast",
        component: () => import("@/components/broadcast/broadcast.vue"),
        meta: {
            title: "Broadcast - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/user/profile",
        name: "profile",
        component: () => import("@/components/user/profile.vue"),
        meta: {
            title: "User Profile - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/user/applications",
        name: "applications",
        component: () => import("@/components/user/applications.vue"),
        meta: {
            title: "Authorized Applications - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/user/application/new",
        name: "newapplication",
        component: () => import("@/components/user/newapplication.vue"),
        meta: {
            title: "New Application - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/user/application/:appId/edit",
        name: "editapplication",
        component: () => import("@/components/user/editapplication.vue"),
        meta: {
            title: "Edit Application - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import("@/components/faq/faq.vue"),
        meta: {
            title: "FAQ - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/help/videos",
        name: "helpvideos",
        component: () => import("@/components/faq/helpvideos.vue"),
        meta: {
            title: "Help Videos - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/open-source",
        name: "open-source",
        component: () => import("@/components/utils/foss.vue"),
        meta: {
            title: "Free Open Source Software",
            isPublic: true
        }
    },
    {
        path: "/site/new",
        name: "newsite",
        component: () => import("@/components/site/newsite.vue"),
        meta: {
            title: "Create New Site - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/site/:siteId/overview",
        name: "siteOverview",
        component: () => import("@/components/site/siteoverview.vue"),
        meta: {
            title: "Site Overview - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: true
        }
    },
    {
        path: "/site/:siteId/location",
        name: "siteLocation",
        component: () => import("@/components/site/sitelocation.vue"),
        meta: {
            title: "Site Location - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "SITE_INFORMATIONS"
            }
        }
    },
    {
        path: "/site/:siteId/edit",
        name: "editsite",
        component: () => import("@/components/site/editsite.vue"),
        meta: {
            title: "Edit Site - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "SITE_INFORMATIONS"
            }
        }
    },
    {
        path: "/site/:siteId/tags",
        name: "tags",
        component: () => import("@/components/tag/tags.vue"),
        meta: {
            title: "Tags List - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "TAG_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/tags/new",
        name: "newTag",
        component: () => import("@/components/tag/newtag.vue"),
        meta: {
            title: "Create New Tag - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "TAG_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/tags/:tagId/edit",
        name: "editTag",
        component: () => import("@/components/tag/edittag.vue"),
        meta: {
            title: "Edit Tag - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "TAG_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/tagtypes",
        name: "tagModels",
        component: () => import("@/components/tag/tagmodels.vue"),
        meta: {
            title: "Tag Models List - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "TAG_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/tagtypes/new",
        name: "newTagModel",
        component: () => import("@/components/tag/newtagmodel.vue"),
        meta: {
            title: "Create New Tag type - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "TAG_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/tagtypes/:tagTypeId/edit",
        name: "editTagModel",
        component: () => import("@/components/tag/edittagmodel.vue"),
        meta: {
            title: "Edit Tag Type - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "TAG_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/gateways",
        name: "gateways",
        component: () => import("@/components/gateway/gateways.vue"),
        meta: {
            title: "Gateways List - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "GATEWAY"
            }
        }
    },
    {
        path: "/site/:siteId/gateways/new",
        name: "newGateway",
        component: () => import("@/components/gateway/newgateway.vue"),
        meta: {
            title: "Create New Gateway - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "GATEWAY"
            }
        }
    },
    {
        path: "/site/:siteId/gateways/:gatewayId/edit",
        name: "editGateway",
        component: () => import("@/components/gateway/editgateway.vue"),
        meta: {
            title: "Edit Gateway - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "GATEWAY"
            }
        }
    },
    {
        path: "/site/:siteId/gateways/location",
        name: "gatewaysLocation",
        component: () => import("@/components/gateway/gatewayslocation.vue"),
        meta: {
            title: "Gateways Location - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "GATEWAY"
            }
        }
    },
    {
        path: "/site/:siteId/assets",
        name: "assets",
        component: () => import("@/components/asset/assets.vue"),
        meta: {
            title: "Asset List - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "ASSET_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/assets/new",
        name: "newAsset",
        component: () => import("@/components/asset/newasset.vue"),
        meta: {
            title: "Create New Asset - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "ASSET_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/assets/:assetId/edit",
        name: "editAsset",
        component: () => import("@/components/asset/editasset.vue"),
        meta: {
            title: "Edit Asset - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "ASSET_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/assets/categories",
        name: "assetCategories",
        component: () => import("@/components/asset/categories.vue"),
        meta: {
            title: "Asset Categories - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "ASSET_CATEGORY_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/assets/categories/new",
        name: "newAssetCategory",
        component: () => import("@/components/asset/newcategory.vue"),
        meta: {
            title: "Create New Category - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "ASSET_CATEGORY_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/assets/categories/:assetCategoryId/edit",
        name: "editAssetCategory",
        component: () => import("@/components/asset/editcategory.vue"),
        meta: {
            title: "Edit Asset Category - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_PROVISIONING",
                subFeature: "ASSET_CATEGORY_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/buildings",
        name: "buildings",
        component: () => import("@/components/building/buildings.vue"),
        meta: {
            title: "Buildings and Floors - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "BUILDING_AND_FLOOR"
            }
        }
    },
    {
        path: "/site/:siteId/buildings/new",
        name: "newBuilding",
        component: () => import("@/components/building/newbuilding.vue"),
        meta: {
            title: "Create New Building - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "BUILDING_AND_FLOOR"
            }
        }
    },
    {
        path: "/site/:siteId/buildings/:buildingId/edit",
        name: "editBuilding",
        component: () => import("@/components/building/editbuilding.vue"),
        meta: {
            title: "Edit Building - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "BUILDING_AND_FLOOR"
            }
        }
    },
    {
        path: "/site/:siteId/buildings/:buildingId/floors",
        name: "floors",
        component: () => import("@/components/building/floors.vue"),
        meta: {
            title: "Floors - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "BUILDING_AND_FLOOR"
            }
        }
    },
    {
        path: "/site/:siteId/buildings/:buildingId/floors/new",
        name: "newFloor",
        component: () => import("@/components/building/newfloor.vue"),
        meta: {
            title: "Create New Floor - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "BUILDING_AND_FLOOR"
            }
        }
    },
    {
        path: "/site/:siteId/buildings/:buildingId/floors/:floorId/edit",
        name: "editFloor",
        component: () => import("@/components/building/editfloor.vue"),
        meta: {
            title: "Edit Floor - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "BUILDING_AND_FLOOR"
            }
        }
    },
    {
        path: "/site/:siteId/indoorAreas",
        name: "indoorAreas",
        component: () => import("@/components/indoorarea/indoorareas.vue"),
        meta: {
            title: "Indoor Areas - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "INDOOR_AREA"
            }
        }
    },
    {
        path: "/site/:siteId/geofences",
        name: "geofences",
        component: () => import("@/components/geofencing/geofences.vue"),
        meta: {
            title: "Geofences - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "GEOFENCE"
            }
        }
    },
    {
        path: "/site/:siteId/walkways",
        name: "walkways",
        component: () => import("@/components/walkway/walkways.vue"),
        meta: {
            title: "Walkways - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "WALKWAY"
            }
        }
    },
    {
        path: "/site/:siteId/walls",
        name: "walls",
        component: () => import("@/components/wall/walls.vue"),
        meta: {
            title: "Walls - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "WALL"
            }
        }
    },
    {
        path: "/site/:siteId/autocalibrationtags",
        name: "autocalibrationtags",
        component: () => import("@/components/autocalibrationtag/autocalibrationtags.vue"),
        meta: {
            title: "Autocalibration Beacons List - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "AUTOCALIBRATION_TAG"
            }
        }
    },
    {
        path: "/site/:siteId/autocalibrationtags/location",
        name: "autocalibrationtagsLocation",
        component: () => import("@/components/autocalibrationtag/autocalibrationtagslocation.vue"),
        meta: {
            title: "Autocalibration Beacons Location - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "AUTOCALIBRATION_TAG"
            }
        }
    },
    {
        path: "/site/:siteId/autocalibrationtags/:autoCalibrationTagId/edit",
        name: "editAutoCalibrationTag",
        component: () => import("@/components/autocalibrationtag/editautocalibrationtag.vue"),
        meta: {
            title: "Edit Autocalibration Beacon - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "AUTOCALIBRATION_TAG"
            }
        }
    },
    {
        path: "/site/:siteId/autocalibrationtags/new",
        name: "newAutoCalibrationTag",
        component: () => import("@/components/autocalibrationtag/newautocalibrationtag.vue"),
        meta: {
            title: "Create Autocalibration Beacon - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "AUTOCALIBRATION_TAG"
            }
        }
    },
    {
        path: "/site/:siteId/dashboard/assets",
        name: "assetsTrackingDashboard",
        component: () => import("@/components/dashboard/assettrackingdashboard.vue"),
        meta: {
            title: "Assets Tracking Dashboard - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_SEARCH",
                subFeature: "SEARCH"
            }
        }
    },
    // {
    //     path: "/sites/:siteId/search/viewer",
    //     name: "showFloorAssetsOnMap",
    //     component: () => import("@/components/map/showfloorassetsonmap.vue"),
    //     meta: {
    //         title: "Assets Location - OmniAccess Stellar Asset Tracking",
    //         isPublic: false
    //     }
    // },
    {
        path: "/assets/search",
        name: "showFloorAssetsOnMap",
        component: () => import("@/components/map/showfloorassetsonmap.vue"),
        meta: {
            title: "Assets Location - OmniAccess Stellar Asset Tracking",
            isPublic: false
        }
    },
    {
        path: "/site/:siteId/dashboard/history",
        name: "assetLocationHistory",
        component: () => import("@/components/playback/assetlocationhistory.vue"),
        meta: {
            title: "Assets Tracking Dashboard - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ASSET_SEARCH",
                subFeature: "SEARCH"
            }
        }
    },
    {
        path: "/site/:siteId/roles",
        name: "roles",
        component: () => import("@/components/role/roles.vue"),
        meta: {
            title: "Site Roles - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_PERMISSION",
                subFeature: "ROLE_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/roles/new",
        name: "newSiteRole",
        component: () => import("@/components/role/newrole.vue"),
        meta: {
            title: "New Site Role - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_PERMISSION",
                subFeature: "ROLE_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/roles/:roleId/edit",
        name: "editSiteRole",
        component: () => import("@/components/role/editrole.vue"),
        meta: {
            title: "Edit Site Role - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_PERMISSION",
                subFeature: "ROLE_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/users",
        name: "users",
        component: () => import("@/components/siteusers/users.vue"),
        meta: {
            title: "Site Users - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_PERMISSION",
                subFeature: "USER_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/users/new",
        name: "newSiteUser",
        component: () => import("@/components/siteusers/newsiteuser.vue"),
        meta: {
            title: "Invite New User - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_PERMISSION",
                subFeature: "USER_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/users/:userId/edit",
        name: "editSiteUser",
        component: () => import("@/components/siteusers/editsiteuser.vue"),
        meta: {
            title: "Edit User Permissions - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_PERMISSION",
                subFeature: "USER_MANAGEMENT"
            }
        }
    },
    {
        path: "/site/:siteId/guests",
        name: "listSiteGuests",
        component: () => import("@/components/guest/guests.vue"),
        meta: {
            title: "Site Guest Access - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                // feature: "SITE_PERMISSION",
                // subFeature: "ROLE_MANAGEMENT"
                feature: "GUEST_ACCESS"
            }
        }
    },
    {
        path: "/site/:siteId/guests/new",
        name: "newSiteGuest",
        component: () => import("@/components/guest/newguest.vue"),
        meta: {
            title: "New Site Guest Access - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                // feature: "SITE_PERMISSION",
                // subFeature: "ROLE_MANAGEMENT"
                feature: "GUEST_ACCESS"
            }
        }
    },
    {
        path: "/site/:siteId/guests/:guestId/edit",
        name: "editSiteGuest",
        component: () => import("@/components/guest/editguest.vue"),
        meta: {
            title: "Edit Site Guest Access - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                // feature: "SITE_PERMISSION",
                // subFeature: "ROLE_MANAGEMENT"
                feature: "GUEST_ACCESS"
            }
        }
    },

    {
        path: "/site/:siteId/geonotifications",
        name: "geonotifications",
        component: () => import("@/components/geonotifications/geonotifications.vue"),
        meta: {
            title: "Geo-notifications - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "NOTIFICATION"
            }
        }
    },
    {
        path: "/site/:siteId/geonotifications/new",
        name: "newGeonotification",
        component: () => import("@/components/geonotifications/newgeonotification.vue"),
        meta: {
            title: "Create new geo-notification - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "NOTIFICATION"
            }
        }
    },
    {
        path: "/site/:siteId/geonotifications/:notificationId/edit",
        name: "editGeonotification",
        component: () => import("@/components/geonotifications/editgeonotification.vue"),
        meta: {
            title: "Edit Geo-notification - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "NOTIFICATION"
            }
        }
    },
    {
        path: "/site/:siteId/geonotifications/history",
        name: "geonotificationsHistory",
        component: () => import("@/components/geonotifications/geonotificationshistory.vue"),
        meta: {
            title: "Notification history - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "NOTIFICATION"
            }
        }
    },
    /* PUSH BUTTON ALERT */
    {
        path: "/site/:siteId/pushbuttonalerts",
        name: "pushButtonAlerts",
        component: () => import("@/components/pushbuttonalert/pushbuttonalerts.vue"),
        meta: {
            title: "Push button alerts - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "PUSH_BUTTON_ALERT"
            }
        }
    },
    {
        path: "/site/:siteId/pushbuttonalerts/new",
        name: "newPushButtonAlert",
        component: () => import("@/components/pushbuttonalert/newpushbuttonalert.vue"),
        meta: {
            title: "Create new push button alert - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "PUSH_BUTTON_ALERT"
            }
        }
    },
    {
        path: "/site/:siteId/pushbuttonalerts/:alertId/edit",
        name: "editPushButtonAlert",
        component: () => import("@/components/pushbuttonalert/editpushbuttonalert.vue"),
        meta: {
            title: "Edit push button alert - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "PUSH_BUTTON_ALERT"
            }
        }
    },
    {
        path: "/site/:siteId/pushbuttonalerts/history",
        name: "pushButtonAlertsHistory",
        component: () => import("@/components/pushbuttonalert/pushbuttonalertshistory.vue"),
        meta: {
            title: "Alert history - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "PUSH_BUTTON_ALERT"
            }
        }
    },
    /* PUSH BUTTON EVENT TYPES */
    {
        path: "/site/:siteId/pushbuttoneventtypes",
        name: "pushButtonEventTypes",
        component: () => import("@/components/pushbuttonalert/pushbuttoneventtypes.vue"),
        meta: {
            title: "Push button alerts - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "PUSH_BUTTON_ALERT"
            }
        }
    },
    {
        path: "/site/:siteId/pushbuttoneventtypes/new",
        name: "newPushButtonEventType",
        component: () => import("@/components/pushbuttonalert/newpushbuttoneventtype.vue"),
        meta: {
            title: "Create new push button event type - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "PUSH_BUTTON_ALERT"
            }
        }
    },
    {
        path: "/site/:siteId/pushbuttoneventtypes/:eventTypeId/edit",
        name: "editPushButtonEventType",
        component: () => import("@/components/pushbuttonalert/editpushbuttoneventtype.vue"),
        meta: {
            title: "Edit push button event type - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "PUSH_BUTTON_ALERT"
            }
        }
    },

    /* ANALYTICS/DASHBOARDS */
    {
        path: "/site/:siteId/analytics/dashboards",
        name: "dashboards",
        component: () => import("@/components/analytics/dashboards.vue"),
        meta: {
            title: "Dashboards - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ANALYTICS",
                subFeature: "DASHBOARD"
            }
        }
    },
    {
        path: "/sites/:siteId/analytics/dashboards/new",
        name: "newDashboard",
        component: () => import("@/components/analytics/newdashboard.vue"),
        meta: {
            title: "Create New Dashboard - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ANALYTICS",
                subFeature: "DASHBOARD"
            }
        }
    },
    {
        path: "/sites/:siteId/analytics/dashboards/:dashboardId/edit",
        name: "editDashboard",
        component: () => import("@/components/analytics/editdashboard.vue"),
        meta: {
            title: "Edit Dashboard - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ANALYTICS",
                subFeature: "DASHBOARD"
            }
        }
    },
    {
        path: "/sites/:siteId/analytics/dashboards/:dashboardId/view",
        name: "dashboardWidgets",
        component: () => import("@/components/analytics/viewdashboard.vue"),
        meta: {
            title: "Dashboard View - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ANALYTICS",
                subFeature: "DASHBOARD"
            }
        }
    },
    /* ANALYTICS/CONTACT_EVENTS */
    {
        path: "/site/:siteId/analytics/contactevents",
        name: "contactEvents",
        component: () => import("@/components/analytics/contactevents.vue"),
        meta: {
            title: "Contact events - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "ANALYTICS",
                subFeature: "CONTACT_EVENTS"
            }
        }
    },
    {
        path: "/sites/:siteId/subscription",
        name: "subscription",
        component: () => import("@/components/subscription/subscription.vue"),
        meta: {
            title: "Subscription View - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "SITE_CONFIGURATION",
                subFeature: "SITE_SUBSCRIPTION_MANAGEMENT"
            }
        }
    },
    {
        path: "/sites/:siteId/troubleshooting/calibration",
        name: "siteCalibrationHealth",
        component: () => import("@/components/troubleshooting/sitecalibrationhealth.vue"),
        meta: {
            title: "Autocalibration history - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "TROUBLESHOOTING"
            }
        }
    },
    {
        path: "/sites/:siteId/troubleshooting/neuralnetwork",
        name: "siteNeuralNetworkHealth",
        component: () => import("@/components/troubleshooting/siteneuralnetworkhealth.vue"),
        meta: {
            title: "Neural network history - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "TROUBLESHOOTING"
            }
        }
    },
    {
        path: "/sites/:siteId/troubleshooting/gateways",
        name: "siteGatewaysHealth",
        component: () => import("@/components/troubleshooting/sitegatewayshealth.vue"),
        meta: {
            title: "Gateways Status - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "TROUBLESHOOTING"
            }
        }
    },
    {
        path: "/sites/:siteId/troubleshooting/beacons",
        name: "siteBeaconsHealth",
        component: () => import("@/components/troubleshooting/sitebeaconshealth.vue"),
        meta: {
            title: "Beacons Status - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "TROUBLESHOOTING"
            }
        }
    },
    {
        path: "/sites/:siteId/troubleshooting/tags",
        name: "siteTagsHealth",
        component: () => import("@/components/troubleshooting/sitetagshealth.vue"),
        meta: {
            title: "Tags Status - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "TROUBLESHOOTING"
            }
        }
    },
    {
        path: "/sites/:siteId/troubleshooting/heatmaps",
        name: "heatmaps",
        component: () => import("@/components/troubleshooting/heatmaps.vue"),
        meta: {
            title: "Heatmaps - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "TROUBLESHOOTING"
            }
        }
    },
    {
        //path: "/sites/:siteId/reports/new",
        path: "/sites/:siteId/reports/new",
        name: "newReport",
        component: () => import("@/components/reporting/generatereports.vue"),
        meta: {
            title: "Reports - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "REPORTING"
            }
        }
    },

    {
        //path: "/sites/:siteId/report/edit",
        path: "/sites/:siteId/reports/:reportId/edit",
        name: "editReport",
        component: () => import("@/components/reporting/editreport.vue"),
        meta: {
            title: "Reports - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "REPORTING"
            }
        }
    },

    {
        //path: "/sites/:siteId/reporting/reports",
        path: "/sites/:siteId/reports/generate",
        name: "generateReport",
        component: () => import("@/components/reporting/generatereports.vue"),
        meta: {
            title: "Reports - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "REPORTING"
            }
        }
    },

    {
        //path: "/sites/:siteId/reports",
        path: "/sites/:siteId/reports",
        name: "reports",
        component: () => import("@/components/reporting/reports.vue"),
        meta: {
            title: "Reports - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission: {
                feature: "REPORTING"
            }
        }
    },

    {   
        path : "/sites/:siteId/troubleshooting/deploymentchecking",
        name : "deploymentChecking",
        component : () => import("@/components/troubleshooting/deploymentchecking.vue"),
        meta : {    
            title : "DeploymentChecking - OmniAccess Stellar Asset Tracking",
            isPublic: false,
            requiresPermission:{    
                feature : "TROUBLESHOOTING"
            }
        }
    },

    {
        path: "/tags/:tagId/lastposition/view",
        name: "lastPosition",
        component: () => import("@/components/map/showlastpositionmap.vue"),
        meta: {
            title: "Last position - OmniAccess Stellar Asset Tracking",
            isPublic: false,
        }
    }
];
export default routes;