import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        reports: null,
        currentReport: null,
        showReportModal: false,
        hideReportModal: false,
        createAnother: false,
    };
};

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    siteReports(state) {
        return state.reports;
    },

    currentReport(state) {
        return state.currentReport;
    },

    showReportModal(state) {
        return state.showReportModal;
    },

    hideReportModal(state) {
        return state.hideReportModal;
    },
    createAnotherReport(state) {
        return state.createAnother;
    },
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_REPORTS(state, data) {
        state.reports = data.reports;
    },

    SET_CURRENT_SITE_REPORT(state, data) {
        state.currentReport = data.currentReport;
    },

    SET_CREATE_ANOTHER_REPORT(state, createAnother) {
        state.createAnother = createAnother;
    },

    RESET_DEFAULT_REPORT_STATE(state) {
        Object.assign(state, getDefaultState());
    },

    SHOW_REPORT_MODAL(state, data) {
        state.showReportModal = true;
        state.currentReport = data.report;
    },

    HIDE_REPORT_MODAL(state) {
        state.hideReportModal = true;
    },

    RESET_SHOW_REPORT_MODAL(state) {
        state.showReportModal = false;
    },

    RESET_HIDE_REPORT_MODAL(state) {
        state.hideReportModal = false;
    },

    RESET_CURRENT_REPORT(state) {
        state.currentReport = null;
    },
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherReport({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_REPORT", createAnother);
    },

    showReportModal({ commit }, data) {
        commit("SHOW_REPORT_MODAL", data);
    },

    hideReportModal({ commit }, data) {
        commit("HIDE_REPORT_MODAL", data);
        commit("RESET_CURRENT_REPORT", {});
    },

    resetShowReportModal({ commit }) {
        commit("RESET_SHOW_REPORT_MODAL", {});
    },
    resetHideReportModal({ commit }) {
        commit("RESET_HIDE_REPORT_MODAL", {});
    },

    /*
     * Action used to generate report
     */
    generateReport({ commit }, payload) {
        // POST sites/report/generate
        axiosBackend
            .post("/sites/" + payload.siteId + "/report/generate", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("reporting_ReportingGenerationSuccessfullyCreated"),
                    type: "success",
                });
            })
            .catch((error) => console.log(error));
    },

    /*
     * Action used to create report
     */
    createReport({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_REPORT", payload.createAnother);
        delete payload.createAnother;
        axiosBackend
            .post("/sites/" + payload.siteId + "/reports", payload, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("reporting_reportSuccessfullyCreated"),
                    type: "success",
                });

                // Creation OK => Route to reports list page
                router.push({ name: "reports", params: { fromAction: "createAPI" } });
            })
            .catch((error) => console.log(error));
    },

    /*
     * Action used to get all reports on site
     */
    getAllReportOnSite({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/reports", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                commit("SET_SITE_REPORTS", {
                    reports: res.data.data,
                });
            })
            .catch((error) => console.log(error));
    },

    /*
     * Action used to change the report state
     */
    updateReportState({ commit, dispatch }, payload) {
        axiosBackend
            .put(
                "/sites/" + payload.siteId + "/reports/" + payload.reportId + "/status",
                payload,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
            .then((res) => {
                // Reload up-to-date list of reports
                dispatch("getAllReportOnSite", payload.siteId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("reporting_reportSuccessfullyUpdated"),
                    type: "success",
                });
            })
            .catch((error) => {
                // Reload up-to-date list of reports
                dispatch("getAllReportOnSite", payload.siteId);
                console.log(error);
            });
    },

    /*
     * Action used to update the report by id
     */
    updateReport({ commit, dispatch }, payload) {
        axiosBackend
            .put(
                "/sites/" + payload.siteId + "/reports/" + payload.reportId,
                payload,
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
            .then((res) => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("reporting_reportSuccessfullyUpdated"),
                    type: "success",
                });

                router.push({ name: "reports", params: { fromAction: "createAPI" } });
            })
            .catch((error) => {
                console.log(error);
            });
    },

    /*
     * Action used to get report by identifier
     */
    getReportById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/reports/" + payload.reportId, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                commit("SET_CURRENT_SITE_REPORT", {
                    currentReport: res.data.data,
                });
            })
            .catch((error) => console.log(error));
    },

    /*
     * Action used to delete report by identifier
     */
    deleteReport({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/reports/" + payload.reportId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            })
            .then((res) => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("reporting_reportSuccessfullyDeleted"),
                    type: "success",
                });

                dispatch("getAllReportOnSite", payload.siteId);
                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch((error) => {
                console.log(error);
            });
    },

    /*
     * Action used to reset report on state
     */
    resetReportState({ commit }) {
        commit("RESET_DEFAULT_REPORT_STATE", {});
    },

    /*
     * Action used to reset current report
     */
    resetCurrentReportState({ commit }) {
        commit("RESET_CURRENT_REPORT", {});
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
