import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteGeofences: null,
        currentGeofence: { id: "", state: "", isHighlightFromMap: false }
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for siteGeofences
     */
    siteGeofences(state) {
        return state.siteGeofences;
    },

    currentGeofence(state) {
        return state.currentGeofence;
    },

    getGeofenceById(state) {
        return id => _.find(state.siteGeofences, ["id", id]);
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_GEOFENCES(state, data) {
        if (!state.siteGeofences) {
            state.siteGeofences = [];
        }
        state.siteGeofences = data;
    },

    REMOVE_GEOFENCE_BY_ID(state, geofenceId) {
        // Find geofence index
        let index = _.findIndex(state.siteGeofences, { id: geofenceId });
        state.siteGeofences.splice(index, 1);
    },

    SET_GEOFENCE(state, geofenceData) {
        if (state.siteGeofences) {
            // Find geofence index
            let index = _.findIndex(state.siteGeofences, { id: geofenceData.id });
            if (index >= 0) {
                // Update - replace the geofence in the array of geofences
                state.siteGeofences.splice(index, 1, geofenceData);
            } else {
                // Creation
                state.siteGeofences.push(geofenceData);
            }
        } else {
            state.siteGeofences = [];
            state.siteGeofences.push(geofenceData);
        }
    },

    SET_CURRENT_GEOFENCE(state, geofenceData) {
        state.currentGeofence = geofenceData;
    },

    RESET_DEFAULT_GEOFENCES_STATE(state) {
        Object.assign(state, getDefaultState())
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Action used to create a geofence
     */
    createGeofence({ commit }, payload) {
        axiosBackend
            .post("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/geofences", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current geofence in store
                commit("SET_CURRENT_GEOFENCE", { id: "", state: "" });

                // Store created geofence in store
                commit("SET_GEOFENCE", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("geofence_geofenceSuccessfullyCreated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of site geofences
     */
    getSiteGeofences({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/geofences", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store geofences
                commit("SET_GEOFENCES", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one geofence by its id
     */
    getGeofenceById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/geofences/" + payload.geofenceId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_GEOFENCE", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing geofence
     */
    updateGeofence({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/geofences/" + payload.id, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current geofence in store
                commit("SET_CURRENT_GEOFENCE", { id: "", state: "" });

                // Set user Data in VueX Auth store
                commit("SET_GEOFENCE", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("geofence_geofenceSuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a geofence
     */
    deleteGeofence({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/geofences/" + payload.geofenceId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_GEOFENCE_BY_ID", payload.geofenceId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("geofence_geofenceSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    setCurrentGeofence({ commit }, payload) {
        commit("SET_CURRENT_GEOFENCE", payload);
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetGeofencesState ({ commit }) {
        commit('RESET_DEFAULT_GEOFENCES_STATE');
    }
    
};

export default {
    state,
    getters,
    mutations,
    actions
};
