import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import notification from "@/store/modules/notification";
import sites from "@/store/modules/sites";
import applications from "@/store/modules/applications";
import deletemodal from "@/store/modules/deletemodal";
import importgatewaysmodal from "@/store/modules/importgatewaysmodal";
import importgatewaysfromovmodal from "@/store/modules/importgatewaysfromovmodal";
import importusersmodal from "@/store/modules/importusersmodal";
import importtagsmodal from "@/store/modules/importtagsmodal";
import importautocalibrationtagsmodal from "@/store/modules/importautocalibrationtagsmodal";
import tags from "@/store/modules/tags";
import tagModels from "@/store/modules/tagModels";
import tagtypes from "@/store/modules/tagtypes";
import buildings from "@/store/modules/buildings";
import widgets from "@/store/modules/widgets";
import dashboards from "@/store/modules/dashboards";
import floors from "@/store/modules/floors";
import gateways from "@/store/modules/gateways";
import indoorareas from "@/store/modules/indoorareas";
import geofences from "@/store/modules/geofences";
import walkways from "@/store/modules/walkways";
import walls from "@/store/modules/walls";
import autocalibrationtags from "@/store/modules/autocalibrationtags";
import shippedtags from "@/store/modules/shippedtags";
import assets from "@/store/modules/assets";
import assetcategories from "@/store/modules/assetcategories";
import siteusers from "@/store/modules/siteusers";
import siteroles from "@/store/modules/siteroles";
import siteguests from "@/store/modules/siteguests";
import troubleshootingheatmaps from "@/store/modules/troubleshootingheatmaps";
import geonotifications from "@/store/modules/geonotifications";
import notificationtypes from "@/store/modules/notificationtypes";
import subscription from "@/store/modules/subscription";
import alarm from "@/store/modules/alarms";
import map from "@/store/modules/map";
import sitehealth from "@/store/modules/sitehealth";
import report from "@/store/modules/report";
import textModal from "@/store/modules/textModal";
import assetlocationhistory from "@/store/modules/assetlocationhistory";
import contactevents from "@/store/modules/contactevents";
import contacteventsettings from "@/store/modules/contacteventsettings"
import pushbuttonalerts from "@/store/modules/pushbuttonalerts";
import pushbuttoneventtypes from "@/store/modules/pushbuttoneventtypes";
import broadcast from '@/store/modules/broadcast';
import troubleshootingchecking from '@/store/modules/troubleshootingchecking';
import siteTypeModal from '@/store/modules/siteTypeModal';
import sitetypes from '@/store/modules/sitetypes';
import assetunits from '@/store/modules/assetunits';
import assetUnitModal from '@/store/modules/assetUnitModal';
import bulkdeletemodal from '@/store/modules/bulkdeletemodal';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        /*
         * Boolean indicating if the something in application is currently loading
         */
        isLoading: false
    },
    getters: {
        /*
         * Getter for isLoading property
         */
        isLoading(state) {
            return state.isLoading;
        }
    },
    mutations: {
        /*
         * Toggle isLoading property
         */
        SET_IS_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        }
    },
    actions: {
        setIsLoading({commit}, isLoading) {
            commit("SET_IS_LOADING", isLoading);
        }
    },
    modules: {
        auth,
        notification,
        sites,
        deletemodal,
        applications,
        tags,
        tagModels,
        tagtypes,
        buildings,
        floors,
        gateways,
        autocalibrationtags,
        shippedtags,
        importgatewaysmodal,
        importgatewaysfromovmodal,
        importusersmodal,
        importtagsmodal,
        importautocalibrationtagsmodal,
        indoorareas,
        geofences,
        walkways,
        walls,
        assets,
        assetcategories,
        troubleshootingheatmaps,
        map,
        siteusers,
        siteroles,
        siteguests,
        dashboards,
        widgets,
        geonotifications,
        notificationtypes,
        subscription,
        alarm,
        sitehealth,
        report,
        textModal,
        assetlocationhistory,
        contactevents,
        contacteventsettings,
        pushbuttonalerts,
        pushbuttoneventtypes,
        broadcast,
        troubleshootingchecking,
        siteTypeModal,
        sitetypes,
        assetunits,
        assetUnitModal,
        bulkdeletemodal
    }
});