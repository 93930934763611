import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

/*
 * State: properties to store
 */
const state = {
    currentBuildingFloors: [],
    currentFloor: {
        map: null,
        mapCoordinates: null
    },
    createAnother: false
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    currentBuildingFloors(state) {
        return state.currentBuildingFloors;
    },

    currentFloor(state) {
        return state.currentFloor;
    },

    currentFloorMap(state) {
        return state.currentFloor.map;
    },

    floorById(state) {
        return id => _.find(state.currentBuildingFloors, ["id", id]);
    },

    createAnotherFloor(state) {
        return state.createAnother;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_BUILDING_FLOORS(state, data) {
        state.currentBuildingFloors = data.floors;
    },

    REMOVE_FLOOR_BY_ID(state, floorId) {
        // Find floor index
        let index = _.findIndex(state.currentBuildingFloors, { id: floorId });
        state.currentBuildingFloors.splice(index, 1);
    },

    SET_CURRENT_FLOOR(state, floorData) {
        // Set the current floor
        state.currentFloor = floorData.currentFloor;
    },

    UNSET_CURRENT_FLOOR(state) {
        for (var item in state.currentFloor) {
            state.currentFloor[item] = null;
        }
    },

    SET_FLOOR_MAP(state, floorMapData) {
        state.currentFloor.map = floorMapData;
    },

    UNSET_FLOOR_MAP(state) {
        state.currentFloor.map = null;
        state.currentFloor.mapCoordinates = null;
    },

    SET_CREATE_ANOTHER_FLOOR(state, createAnother) {
        state.createAnother = createAnother;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherFloor({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_FLOOR", createAnother);
    },

    /*
     * Action used to upload floor image to server
     */
    uploadFloorImage({ dispatch }, payload) {
        var formData = new FormData();
        formData.append("uploadedImage", payload.floorImage);

        axiosBackend
            .post("/sites/" + payload.metadata.siteId + "/buildings/" + payload.metadata.buildingId + "/floors/upload", formData, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "multipart/form-data"
                }
            })
            .then(res => {
                // When received a 204, get location URL in header and set into filename
                payload.metadata.mapUrl = res.headers.location;
                if (payload.nextAction === "NEW_FLOOR") {
                    dispatch("createFloor", payload.metadata);
                } else if (payload.nextAction === "EDIT_FLOOR") {
                    dispatch("updateFloor", payload.metadata);
                }
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to create a floor on a specified building
     */
    createFloor({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_FLOOR", payload.createAnother);
        delete payload.createAnother;

        axiosBackend
            .post("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("b&f_newFloorSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to floors list page
                router.push({ name: "floors", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of all floors for a specified building
     */
    getAllFloorsOfBuilding({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_BUILDING_FLOORS", {
                    floors: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one floor by its id
     */
    getFloorById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_CURRENT_FLOOR", {
                    currentFloor: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing floor
     */
    updateFloor({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("b&f_floorSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to floors page
                router.push({ name: "floors", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a floor
     */
    deleteFloor({ commit, dispatch }, data) {
        axiosBackend
            .delete("/sites/" + data.siteId + "/buildings/" + data.buildingId + "/floors/" + data.floorId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_FLOOR_BY_ID", data.floorId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("b&f_floorSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});

                // Remove local storage if this floor was last displayed element
                const isLastDisplayedFloorIdInWalls = localStorage.getItem(data.siteId + "_wallsDefinition_lastDisplayedFloorId") == data.floorId;
                if (isLastDisplayedFloorIdInWalls) {
                    localStorage.removeItem(data.siteId + "_wallsDefinition_lastDisplayedFloorId", null);
                }
                const isLastDisplayedFloorIdInWalkways = localStorage.getItem(data.siteId + "_walkwaysDefinition_lastDisplayedFloorId") == data.floorId;
                if (isLastDisplayedFloorIdInWalkways) {
                    localStorage.removeItem(data.siteId + "_walkwaysDefinition_lastDisplayedFloorId", null);
                }
                const isLastDisplayedFloorIdInIndoor = localStorage.getItem(data.siteId + "_indoorAreasDefinition_lastDisplayedFloorId") == data.floorId;
                if (isLastDisplayedFloorIdInIndoor) {
                    localStorage.removeItem(data.siteId + "_indoorAreasDefinition_lastDisplayedFloorId", null);
                }
                const isLastDisplayedFloorIdInGeofence = localStorage.getItem(data.siteId + "_geofencesDefinition_lastDisplayedFloorId") == data.floorId;
                if (isLastDisplayedFloorIdInGeofence) {
                    localStorage.removeItem(data.siteId + "_geofencesDefinition_lastDisplayedFloorId", null);
                }
                const isLastDisplayedFloorIdInGatewayLocation = localStorage.getItem(data.siteId + "_gatewaysLocation_lastDisplayedFloorId") == data.floorId;
                if (isLastDisplayedFloorIdInGatewayLocation) {
                    localStorage.removeItem(data.siteId + "_gatewaysLocation_lastDisplayedFloorId", null);
                }
                const isLastDisplayedFloorIdInAutotagLocation = localStorage.getItem(data.siteId + "_autocalibrationtagsLocation_lastDisplayedFloorId") == data.floorId;
                if (isLastDisplayedFloorIdInAutotagLocation) {
                    localStorage.removeItem(data.siteId + "_autocalibrationtagsLocation_lastDisplayedFloorId", null);
                }
            })

            .catch(error => console.log(error));
    },

    setCurrentFloor({ commit }, floorData) {
        commit("SET_CURRENT_FLOOR", {
            currentFloor: floorData
        });
    },

    unsetCurrentFloor({ commit }) {
        commit("UNSET_CURRENT_FLOOR");
    },

    setFloorMap({ commit }, data) {
        commit("SET_FLOOR_MAP", data);
    },

    unsetFloorMap({ commit }) {
        commit("UNSET_FLOOR_MAP");
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
