<template>
    <div class="leftMenu">
        <div class="kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" v-bind:class="[this.releasedFor == 'HAN' ? 'kt-aside-han' : '', 'kt-aside']" id="kt_aside">
            <button class="kt-aside-close" id="kt_aside_close_btn">
                <i class="la la-close"></i>
            </button>
            <!-- begin:: Aside -->
            <div class="kt-grid__item" v-bind:class="[this.releasedFor == 'HAN' ? 'kt-aside__brand-han' : '', 'kt-aside__brand']" id="kt_aside_brand" kt-hidden-height="65">
                <div class="kt-aside__brand-logo">
                    <a>
                        <img v-if="this.releasedFor == 'HAN'" alt="Logo" class="hanLogo" src="/assets/han/company_logo_white.png" />
                        <img v-else alt="Logo" class="aleLogo" src="/assets/ale/al_enterprise_wh.png" />
                    </a>
                </div>
                <div class="kt-aside__brand-tools">
                    <button class="kt-aside__brand-aside-toggler" id="kt_aside_toggler">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                    <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fill-rule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "></path>
                                    <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "></path>
                                </g>
                            </svg>
                        </span>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                    <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"></path>
                                    <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "></path>
                                </g>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            <!-- end:: Aside -->

            <!-- begin:: Aside Menu -->
            <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" class="kt-scroll" v-bind:class="[this.releasedFor == 'HAN' ? 'kt-aside-menu-han' : '', 'kt-aside-menu']" data-ktmenu-vertical="1" data-scroll="true" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
                    <ul class="kt-menu__nav">

                        <li class="kt-menu__section marginTZero">
                            <h4 v-bind:class="[this.releasedFor == 'HAN' ? 'kt-menu__section-text-han' : '', 'kt-menu__section-text']">{{ $t("main_home") }}</h4>
                            <i class="kt-menu__section-icon flaticon-more-v2"></i>
                        </li>

                        <li class="kt-menu__item"  :class="{ 'kt-menu__item--active': this.$route.name !== 'broadcast' }" aria-haspopup="true">
                            <router-link :to="{ name: 'home' }" v-bind:class="[this.releasedFor == 'HAN' ?  (this.$route.name !== 'broadcast' ? 'kt-menu__link-han selectedItemMenu-han' : 'kt-menu__link-han') : '', this.$route.name !== 'broadcast' ? 'kt-menu__link selectedItemMenu':  'kt-menu__link']">
                                <span v-if="this.releasedFor == 'ALE'" class="kt-menu__link-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path d="M3.95709826,8.41510662 L11.47855,3.81866389 C11.7986624,3.62303967 12.2013376,3.62303967 12.52145,3.81866389 L20.0429,8.41510557 C20.6374094,8.77841684 21,9.42493654 21,10.1216692 L21,19.0000642 C21,20.1046337 20.1045695,21.0000642 19,21.0000642 L4.99998155,21.0000673 C3.89541205,21.0000673 2.99998155,20.1046368 2.99998155,19.0000673 L2.99999828,10.1216672 C2.99999935,9.42493561 3.36258984,8.77841732 3.95709826,8.41510662 Z M10,13 C9.44771525,13 9,13.4477153 9,14 L9,17 C9,17.5522847 9.44771525,18 10,18 L14,18 C14.5522847,18 15,17.5522847 15,17 L15,14 C15,13.4477153 14.5522847,13 14,13 L10,13 Z" fill="#000000" />
                                        </g>
                                    </svg>
                                </span>
                                <i v-else class="icon-2x text-white kt-menu__link-icon flaticon-home-2"></i>
                                <span v-bind:class="[this.releasedFor == 'HAN' ? 'kt-menu__link-text-han' : '', 'kt-menu__link-text']" id="main_home">{{ $t("main_homePage") }}</span>
                            </router-link>
                        </li>

                        <li class="kt-menu__item" aria-haspopup="true">
                            <router-link v-on:click.native="showAboutModal" :to="{ name: 'home' }" v-bind:class="[this.releasedFor == 'HAN' ? 'kt-menu__link-han' : '', 'kt-menu__link']">
                                <span v-if="this.releasedFor == 'ALE'" class="kt-menu__link-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <circle fill="#000000" cx="12" cy="5" r="2" />
                                            <circle fill="#000000" cx="12" cy="12" r="2" />
                                            <circle fill="#000000" cx="12" cy="19" r="2" />
                                        </g>
                                    </svg>
                                </span>
                                <i v-else class="icon-2x text-white kt-menu__link-icon flaticon-more"></i>
                                <span v-bind:class="[this.releasedFor == 'HAN' ? 'kt-menu__link-text-han' : '', 'kt-menu__link-text']">{{ $t("main_about") }}</span>
                            </router-link>
                        </li>

                        <li class="kt-menu__item" aria-haspopup="true" :class="{ 'kt-menu__item--active': this.$route.name === 'broadcast' }" v-if="isSuperAdmin || isPLM">
                            <router-link :to="{ name: 'broadcast' }"  v-bind:class="[this.releasedFor == 'HAN' ?  (this.$route.name === 'broadcast' ? 'kt-menu__link-han selectedItemMenu-han' : 'kt-menu__link-han') : '', this.$route.name === 'broadcast' ? 'kt-menu__link selectedItemMenu':  'kt-menu__link']">
                                <span v-if="this.releasedFor == 'ALE'" class="kt-menu__link-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-broadcast" viewBox="0 0 16 16">
                                        <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/>
                                    </svg>
                                </span>
                                <span v-else class="kt-menu__link-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-broadcast" viewBox="0 0 16 16">
                                        <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/>
                                    </svg>
                                </span>
                                <span v-bind:class="[this.releasedFor == 'HAN' ? 'kt-menu__link-text-han' : '', 'kt-menu__link-text']">{{ $t("main_broadcast") }}</span>
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>
            <!-- end:: Aside Menu -->
        </div>
        <!-- end:: Aside -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR
        };
    },
    mounted: function() {
        console.log("Component(HomeMenu)::mounted() - called");
        KTLayout.init();
    },
    computed: {
        ...mapGetters([ "user"]),

        isSuperAdmin() {
            if (this.user) {
                return this.user.isSuperAdmin;
            }
            return false;
        },
        isPLM() {
            if (this.user) {
                return this.user.isPLM;
            }
            return false;
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        showAboutModal: function() {
            $("#aboutModal").modal("show");
        }
    }
};
</script>

<style scoped>
.leftMenu {
    display: -webkit-box;
}
.aleLogo {
    width: 120px;
}
.hanLogo {
    width: 95px;
    margin-left: -5px;
}
.marginTZero {
    margin-top: 0px !important;
}
.selectedItemMenu {
    border-left: 3px solid #5d78ff;
}
.selectedItemMenu-han {
    border-left: 3px solid white;
}
</style>
