<template>
    <div class="modal fade show" id="fingerprintStatusModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalLabel" aria-modal="true" style="display: none">
        <div class="modal-dialog" style="max-width: 565px;" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("fingerprint_in_progress") }}</h5>
                </div>
                <div class="modal-body">
                    <div class="kt-section kt-section--first">
                        <div class="kt-section__body">
                            <div v-if="this.fingerprintStatus === 'START'">
                                <div class="loader"></div>
                                <p style=" text-align: center; margin-top: 13px; color: black; ">{{ $t("fingerprint_unavailable") }}</p>
                                <p style=" text-align: center; color: #5578eb; font-weight: 400; margin: -5px 10px; ">{{ $t("fingerprint_unavailable_details") }}</p>
                                <p style="text-align: center;margin-top: 20px;color: black;">{{ $t("fingerprint_startedDate") }} {{ this.localFingerprintStartDate }} ({{ localFingerprintStartDateAgo }})</p>
                                <div style="margin: 25px auto;display: table;">
                                    <router-link :to="{ name: 'home' }" id="fingerprintmodal_goHomeButton" class="btn btn-brand btn-bold btn-sm btn-icon-h">
                                        {{ $t("fingerprint_backToHomePage") }}
                                    </router-link>
                                    <button v-if="this.fingerprintStatus === 'START' && isAdminUser()" id="cancelFingerprintStatusModal" type="button" class="btn btn-danger ml-2 btn-sm" data-dismiss="modal" @click="onCancelFingerprintStatus()">{{ $t("common_abortProcess") }}</button>
                                </div>
                            </div>

                            <div v-else-if="this.fingerprintStatus === 'SUCCESS'">
                                <div style=" margin: 0 auto; text-align: center; ">
                                    <i class="la la-check" style=" color: #0abb87; font-size: 7rem; margin-bottom: 24px; "></i>
                                </div>
                                <p style=" text-align: center; color: rgb(10, 187, 135); font-weight: 400; margin: -5px 10px; ">{{ $t("fingerprint_success") }}</p>
                            </div>

                            <div v-else-if="this.fingerprintStatus === 'INTERRUPTION'">
                                <div style=" margin: 0 auto; text-align: center; ">
                                    <i class="la la-exclamation-circle" style=" color: #fd397a; font-size: 7rem; margin-bottom: 24px; "></i>
                                </div>
                                <p style=" text-align: center; color: #fd397a; font-weight: 400; margin: -5px 10px; ">{{ $t("fingerprint_interruption") }}</p>
                            </div>

                            <div v-else-if="this.fingerprintStatus === 'FAILURE'">
                                <div style=" margin: 0 auto; text-align: center; ">
                                    <i class="la la-close" style=" color: #fd397a; font-size: 7rem; margin-bottom: 24px; "></i>
                                </div>
                                <p style=" text-align: center; color: #fd397a; font-weight: 400; margin: -5px 10px; ">{{ $t("fingerprint_failure") }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="this.fingerprintStatus === 'SUCCESS' || this.fingerprintStatus === 'FAILURE' || this.fingerprintStatus === 'INTERRUPTION'" class="modal-footer">
                    <button id="closeFingerprintStatusModal" type="button" class="btn btn-brand" @click="reloadPage()">{{ $t("common_close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import commonVueHelper from "../../helpers/commonVueHelper";
import moment from "moment";
export default {
    data() {
        return {
            scheduleFetchFingerprintStatus: null,
            fingerprintStatus: "START",
            localFingerprintStartDate: null,
            localFingerprintStartDateAgo: null,
            moment: moment
        };
    },
    created: function() {
        console.log("Component(FingerprintingStatusModal)::created() - called");
        this.getUserRolesAndSites();
    },
    mounted: function() {
        console.log("Component(FingerprintingStatusModal)::mounted() - called");
    },
    destroyed: function() {
        console.log(
            "Component(FingerprintingStatusModal)::destroyed() - called"
        );
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        showFingerprintInProgressModal() {
            console.log(
                "Component(FingerprintingStatusModal)::watch(showFingerprintInProgressModal) called"
            );
            if (this.showFingerprintInProgressModal) {
                console.log(
                    "Component(FingerprintingStatusModal)::showFingerprintInProgressModal() - show modal"
                );
                if (this.$route.params.siteId) {
                    $("#fingerprintStatusModal").modal("show");
                    this.getFingerprintStatusForSite(this.$route.params.siteId);
                    this.startFetchFingerprintStatusInterval();
                }
            } else {
                this.stopFetchFingerprintStatusInterval();
                $("#fingerprintStatusModal").modal("hide");
            }
        },

        latestFingerprintStartDate() {
            if (this.latestFingerprintStartDate) {
                this.localFingerprintStartDate = moment(
                    this.latestFingerprintStartDate
                ).format(
                    commonVueHelper.getDateStringFormatFromLocale(
                        moment().locale()
                    )
                );
                this.localFingerprintStartDateAgo = moment(
                    this.latestFingerprintStartDate
                ).fromNow();
            }
        },

        latestFingerprintStatus() {
            console.log(
                "Component(FingerprintingStatusModal)::watch(latestFingerprintStatus) called"
            );
            this.fingerprintStatus = this.latestFingerprintStatus;
            if (
                this.fingerprintStatus === "SUCCESS" ||
                this.fingerprintStatus === "INTERRUPTION" ||
                this.fingerprintStatus === "FAILURE"
            ) {
                this.stopFetchFingerprintStatusInterval();
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "showFingerprintInProgressModal",
            "latestFingerprintStatus",
            "latestFingerprintStartDate",
            "rolesBySiteId",
            "user"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getFingerprintStatusForSite",
            "cancelSiteProductionMode",
            "getUserRolesAndSites"
        ]),

        reloadPage() {
            window.location.reload();
        },

        startFetchFingerprintStatusInterval() {
            this.stopFetchFingerprintStatusInterval();
            var self = this;
            this.scheduleFetchFingerprintStatus = setInterval(function() {
                self.getFingerprintStatusForSite(self.$route.params.siteId);
            }, 10000);
        },

        stopFetchFingerprintStatusInterval() {
            if (this.scheduleFetchFingerprintStatus) {
                clearInterval(this.scheduleFetchFingerprintStatus);
            }
        },
        onCancelFingerprintStatus() {
            let siteData = {
                id: this.$route.params.siteId,
                isInProduction: false
            };
            // Update the site in the backend
            this.cancelSiteProductionMode(siteData);
        },
        isAdminUser() {
            if (this.user) {
                const siteUserRole = this.rolesBySiteId ? this.rolesBySiteId[this.$route.params.siteId] : "";
                if (this.user.isSuperAdmin || this.user.isTSUser || siteUserRole === "ADMIN") {
                    return true;
                }
            }
            return false;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.cursorPointer {
    cursor: pointer;
}
</style>
<style>
.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #5578eb;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: 20px auto;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
