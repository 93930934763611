import i18n from "../i18n";

const { detect } = require("detect-browser");
const _browser = detect();

const _min_all_version = -1;
const _min_no_version = 99999;
const _browsersInfoList = {
    ie: {
        minimumVersion: _min_no_version, // Compatible with NO version
        companyName: "Microsoft",
        browserName: "Internet Explorer",
        downloadUrl: "https://www.microsoft.com/en-us/download/internet-explorer.aspx",
        img: "ie.png"
    },
    edge: {
        minimumVersion: _min_all_version, // compatible with ALL version
        companyName: "Microsoft",
        browserName: "Edge",
        downloadUrl: "https://www.microsoft.com/"
    },
    chrome: {
        minimumVersion: 50,
        companyName: "Google",
        browserName: "Chrome",
        downloadUrl: "https://www.google.com/chrome/"
    },
    firefox: {
        minimumVersion: 50,
        companyName: "Mozilla",
        browserName: "Firefox",
        downloadUrl: "https://www.mozilla.org/"
    },
    safari: {
        minimumVersion: 9,
        companyName: "Apple",
        browserName: "Safari",
        downloadUrl: "https://support.apple.com/downloads/safari"
    }
};

/**
 * Class containing global methods about the browser compatibility
 */
export default class browserDetectionHelper {
    /**
     * Get the tyoe of the current browser
     * @return String can be "ie", "edge", "chrome", "firefox" or "safari"
     */
    static getCurrentType() {
        return _browser.name;
    }

    /**
     * Get the name of the current browser
     * @return String can be "ie", "edge", "chrome", "firefox" or "safari"
     */
    static getCurrentName() {
        return _browsersInfoList[_browser.name].browserName;
    }

    /**
     * Get the company name of the current browser
     * @return String
     */
    static getCurrentCompanyName() {
        return _browsersInfoList[_browser.name].companyName;
    }

    /**
     * Get the version of the current browser
     * @return String
     */
    static getCurrentVersion() {
        return _browser.version;
    }

    /**
     * Get the minimum version of the current browser
     * @return Integer
     */
    static getCurrentMinimumVersion() {
        return _browsersInfoList[_browser.name].minimumVersion;
    }

    /**
     * Get the company name of the current browser
     * @return String
     */
    static getCurrentDownloadUrl() {
        return _browsersInfoList[_browser.name].downloadUrl;
    }

    /**
     * Get the list of browsers info by browser type.
     * @returns JSON list
     */
    static getBrowsersInfoList() {
        return _browsersInfoList;
    }

    /**
     * Get the string of the minimum version.
     * @param {String} browserType
     * @returns String version
     */
    static getBrowserMinimumVersionString(browserType) {
        let minVersionTxt;
        switch (_browsersInfoList[browserType].minimumVersion) {
            case _min_all_version:
                minVersionTxt = i18n.t("common_allVersion");
                break;
            case _min_no_version:
                minVersionTxt = i18n.t("common_noVersion");
                break;
            default:
                minVersionTxt = _browsersInfoList[browserType].minimumVersion.toString();
        }
        return minVersionTxt;
    }

    /**
     * This function check if a browser has at least one supported version.
     * @param {String} browserType TYpe of the browser. It can be "ie", "edge", "chrome", "firefox" or "safari"
     * @returns true if browser is supported or false is the browser is not supported.
     */
    static isBrowserSupported(browserType) {
        let browserMinimumVersion = _browsersInfoList[browserType].minimumVersion;
        if (browserMinimumVersion === _min_no_version) {
            return false;
        }
        return true;
    }

    /**
     * This function check if the current browser version is supported by CTC or not.
     * @returns true if browser is supported or false is the browser is not supported.
     */
    static isCurrentSupportedVersion() {
        let browserMajorVersion = _browser.version.split(".")[0];
        let browserMinimumVersion = this.getCurrentMinimumVersion();
        if (browserMajorVersion >= browserMinimumVersion) {
            return true;
        }
        return false;
    }

    static getMsgNotCompatible() {
        let msg = "";
        let browserType = this.getCurrentType();
        if (!this.isBrowserSupported(browserType)) {
            // Browser type not supported
            msg = i18n.t("main_browserNotCompatible");
        } else if (!this.isCurrentSupportedVersion()) {
            // Browser version not supported
            msg = i18n.t("main_browserVersionNotCompatible", { browserVersion: this.getCurrentVersion() });
        }
        return msg;
    }
}
