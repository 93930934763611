/**
 * Class containing global methods about the authentication
 */
import axios from "axios";

export default class authHelper {
    /*
     * Getter used to check if local storage contains authantication data
     */
    static isLocalStorageAuthenticated() {
        console.log("Token: isLocalStorageAuthenticated()");

        // If user is not in lcoal storage, we consider that the user is not well logged
        const localStorageUser = localStorage.getItem("user");
        if (!localStorageUser) {
            return false;
        }

        const localStorageToken = localStorage.getItem("token");

        if (localStorageToken) {
            console.log("Token: isLocalStorageAuthenticated() token found, check if it's expired");
            return !this.isLocalStorageTokenExpired();
        } else {
            console.log("Token: isLocalStorageAuthenticated() token not found...");
            return false;
        }
    }

    /*
     * Getter used to check if local storage contains authantication token date expired
     */
    static isLocalStorageTokenExpired() {
        console.log("Token: isLocalStorageTokenExpired()");

        const expirationDate = localStorage.getItem("expirationDate");

        if (!expirationDate) {
            console.log("Token: isLocalStorageTokenExpired() expiration date not found!");
            // No session has been yet established
            return true;
        }

        const now = new Date();
        console.log("Token: isLocalStorageTokenExpired(): A token has been found in local storage. Now is:", now);
        console.log("Token: isLocalStorageTokenExpired(): A token has been found in local storage. Expiration date is:", expirationDate);
        if (now >= new Date(expirationDate)) {
            console.log("Token: isLocalStorageTokenExpired()  - Token has expired.");
            // Token is no more valid, it's expired
            return true;
        }
        console.log("Token: isLocalStorageTokenExpired()  - Token has NOT expired.");
        return false;
    }

    /*
     * Clear authentication data in local storage
     */
    static clearLocalStorage(saveRegisteredPushy) {
        let userToken = null;
        let pushyTokenAppId = null;
        let pushyTokenAuth = null;
        let pushyToken = null;
        let isRegisteredPushy = Pushy.isRegistered();

        // First save Pushy items Local Storage
        if (isRegisteredPushy) {
            pushyTokenAppId = localStorage.getItem('pushyTokenAppId');
            pushyTokenAuth =localStorage.getItem('pushyTokenAuth');
            pushyToken = localStorage.getItem('pushyToken');
            userToken = localStorage.getItem("token");
        }

        // Clear local storage
        console.log("Token: clearLocalStorage()");
        localStorage.clear();

        if(pushyTokenAppId && pushyTokenAuth && pushyToken){
            console.log("Token: clearLocalStorage() - Restore registered Pushy token " + pushyToken);
            // Restore Pushy items Local Storage (to test if Pushy is already registered)
            localStorage.setItem('pushyTokenAppId', pushyTokenAppId);
            localStorage.setItem('pushyTokenAuth', pushyTokenAuth);
            localStorage.setItem('pushyToken', pushyToken);
        }

        // Unregister Pushy device token
        if (pushyToken && userToken) {
            console.log("Token: clearLocalStorage() - Unregister Pushy token " + pushyToken + " in progress ...");
            // Delete the device token from the backend server
            axios.delete(process.env.VUE_APP_BACKEND_URL + "/device/token/" + pushyToken, {
                headers: { Authorization: "Bearer " + userToken }
            }).then(res => {
                console.log("Token: clearLocalStorage() - Successfully unregistered Pushy device token " + pushyToken);
            }).catch(err => {
                console.error("Token: clearLocalStorage() - Failed unregistered Pushy device token " + pushyToken + ":", err);
            });
        }
    }
}
