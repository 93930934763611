import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteTagTypes: null,
        currentTagType: null,
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for siteTags
     */
    siteTagTypes(state) {
        return state.siteTagTypes;
    },

    currentTagType(state) {
        return state.currentTagType;
    },
    createAnotherTagType(state) {
        return state.createAnother;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_TAG_TYPES(state, data) {
        if (!state.siteTagTypes) {
            state.siteTagTypes = data.tagTypes;
        }
    },

    REMOVE_TAG_TYPE_BY_ID(state, tagTypeId) {
        // Find tag index
        let index = _.findIndex(state.siteTagTypes, { id: tagTypeId });
        state.siteTagTypes.splice(index, 1);
    },

    SET_CURRENT_TAG_TYPE(state, tagType) {
        state.currentTagType = tagType.currentTagType;
    },

    SET_CREATE_ANOTHER_TAG_TYPE(state, createAnother) {
        state.createAnother = createAnother;
    },

    RESET_DEFAULT_TAG_TYPES_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherTagType({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_TAG_TYPE", createAnother);
    },

    /*
     * Action used to create a tag type
     */
    createTagType({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_TAG_TYPE", payload.createAnother);
        axiosBackend
            .post(
                "/sites/" + payload.siteId + "/tagtypes",
                {
                    name: payload.name,
                    reference: payload.reference,
                    rssiOneMeter: payload.rssiOneMeter,
                    batteryLifetime: payload.batteryLifetime,
                    image: payload.image,
                    imageName: payload.imageName
                },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("tag_newTagModelSuccessfullyCreated", { name: payload.name }),
                    type: "success"
                });

                // Creation OK => Route to tags list page
                router.push({ name: "tagModels", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of sites tag types
     */
    getSiteTagTypes({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/tagtypes", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_SITE_TAG_TYPES", {
                    tagTypes: _.get(res, 'data.data')
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one tag type by its id
     */
    getTagTypeById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/tagtypes/" + payload.tagTypeId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_CURRENT_TAG_TYPE", {
                    currentTagType: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing tag type
     */
    updateTagType({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/tagtypes/" + payload.tagTypeId, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_TAG_TYPE", {
                    currentTagType: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("tag_tagTypeSuccessfullyUpdated", { name: payload.name }),
                    type: "success"
                });

                // Update OK => Route to tags page
                router.push({ name: "tagModels", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a tag type
     */
    deleteTagModel({ commit, dispatch }, data) {

        axiosBackend
            .delete("/sites/" + data.siteId + "/tagTypes/" + data.tagTypeId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then((response) => {
                const responseData = _.get(response, 'data.data');
                if (responseData.errorCode) {
                    if(responseData.errorCode === 409) {
                        commit("SHOW_NOTIFICATION", {
                            text: i18n.t('tag_tagModelCannotDeleteTagInUse', { name: responseData.name }),
                            type: "error",
                        });
                    }
                    else {
                        commit("SHOW_NOTIFICATION", {
                            text: responseData.errorMsg,
                            type: "error"
                        });
                    }
                } else {
                    commit("REMOVE_TAG_TYPE_BY_ID", data.tagTypeId);
    
                    // Display notification
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("tag_tagModelSuccessfullyDeleted", { name: responseData.name }),
                        type: "success"
                    });
                }

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetTagTypesState ({ commit }) {
        commit('RESET_DEFAULT_TAG_TYPES_STATE');
    }
    
};

export default {
    state,
    getters,
    mutations,
    actions
};
