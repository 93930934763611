<template>
    <div class="modal fade show" id="assetUnitModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-modal="true" style="display: none; margin-top: 10%">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="actionAssetUnit === 'CREATE'">{{ $t("asset_newAssetUnit") }}</h5>
                    <h5 class="modal-title" v-else-if="actionAssetUnit === 'UPDATE'">{{ $t("asset_editAssetUnit") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onCancelButton()"></button>
                </div>
                <div class="modal-body">
                    <form class="kt-form kt-form--label-right" data-cy="NewSiteForm" autocomplete="off">
                        <div class="kt-portlet__body">
                            <div class="kt-section">
                                <div class="kt-section__body">
                                    <div class="form-group row validated">
                                        <div class="col-lg-12">
                                            <div class="input-group" style="display: flex; align-items: baseline; justify-content: space-between;">
                                                <div class="mr-2">
                                                    <span>{{ $t("site_siteName") }}</span>
                                                </div>
                                                <div style="width: 90%;">
                                                    <input id="newSiteNameInput" type="text" maxlength="80" class="form-control" :placeholder="$t('asset_inputInfoUnitName')" v-model="assetUnitNameInputVal" />
                                                    <div v-if="!$v.assetUnitNameInputVal.required" class="invalid-feedback">
                                                        {{ $t("error_fieldIsRequired") }}
                                                    </div>
                                                    <div v-else-if="!$v.assetUnitNameInputVal.minLen" class="invalid-feedback">
                                                        {{ $t("error_minLengthMsg", [$v.assetUnitNameInputVal.$params.minLen.min]) }}
                                                    </div>
                                                    <div v-else-if="!this.isNameUnique" class="invalid-feedback">
                                                        {{ $t("error_assetUnitNameIsNotUnique") }}
                                                    </div>
                                                    <span class="form-text text-muted">{{ $t("asset_detailInfoAssetUnitName") }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer" v-if="actionAssetUnit === 'CREATE'">
                    <button id="unitModalCancelButton" @click="onCancelButton()" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                    <button id="unitModalAddButton" @click="onAddAssetUnit()" type="button" data-dismiss="modal" class="btn btn-brand" :disabled="this.$v.$invalid || !isNameUnique">{{ $t("common_add") }}</button>
                </div>
                <div class="modal-footer" v-else-if="actionAssetUnit === 'UPDATE'">
                    <button id="unitModalCancelButton" @click="onCancelButton()" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                    <button id="unitModalAddButton" @click="onEditAssetUnit()" type="button" data-dismiss="modal" class="btn btn-brand" :disabled="this.$v.$invalid || !isNameUnique || !isFormDataChanged">{{ $t("common_save") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required, minLength} from "vuelidate/lib/validators";
import i18n from "../../i18n";

export default {
    data() {
        return {
            assetUnitNameInputVal: "",
            oldAssetUnitNameInputVal: "",
            isNameUnique: true,
            isFormDataChanged: false
        };
    },
    created: function() {
        console.log("Component(AssetUnitModal)::created() - called");
    },
    mounted: function() {
        console.log("Component(AssetUnitModal)::mounted() - called");
        $("#unitModal").keypress(event => {
            const keycode = event.keyCode ? event.keyCode : event.which;
            if (keycode == "13") {
                switch(this.actionAssetUnit) {
                    case "CREATE":
                        event.preventDefault();
                        if(!this.$v.$invalid && this.isNameUnique) {
                            this.onAddAssetUnit();
                        }
                        break;
                    case "UPDATE":
                        event.preventDefault();
                        if(!this.$v.$invalid && this.isNameUnique && this.isFormDataChanged) {
                            this.onEditAssetUnit();
                        }
                        break;
                }
            }
        });
    },
    destroyed: function() {
        console.log("Component(AssetUnitModal)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        assetUnitNameInputVal: {
            required,
            minLen: minLength(2)
        },
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        assetUnitNameInputVal: function(name) {
            console.log("Component(AssetUnitModal)::watch(assetUnitNameInputVal) called");

            this.isNameUnique = true;
            if (name) {
                if (this.assetUnit) {
                    for (let unit of this.assetUnits) {
                        if (unit.isDefault && unit.id !== this.assetUnit.id && i18n.t(unit.name) === name) {
                            return this.isNameUnique = false;
                        }
                        else if (unit.id !== this.assetUnit.id && unit.name === name) {
                            return this.isNameUnique = false;
                        }
                    }
                } else {
                    for (let unit of this.assetUnits) {
                        if (unit.isDefault && i18n.t(unit.name) === name) {
                            return this.isNameUnique = false;
                        }
                        else if (unit.name === name) {
                            return this.isNameUnique = false;
                        }
                    } 
                }
            }
            this.onChangeForm();
        },

        assetUnit: function(assetUnit) {
            console.log("Component(AssetUnitModal)::watch(assetUnit) called");
            this.assetUnitNameInputVal = assetUnit && assetUnit.name || "";
            this.oldAssetUnitNameInputVal = assetUnit && assetUnit.name || "";
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["actionAssetUnit", "assetUnit", "assetUnits"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["hideAssetUnitModal", "createNewAssetUnit", "editSiteTypeById", "resetAssetUnitModal", "editAssetUnitById"]),
        onCancelButton() {
            this.assetUnitNameInputVal = "";
            setTimeout(() => {
                this.resetAssetUnitModal();
            }, 50);
        },

        onAddAssetUnit() {
            console.log("Component(AssetUnitModal)::onAddAssetUnit() - called ");
            this.hideAssetUnitModal();

            const params = {
                isDefault: false,
                name: this.assetUnitNameInputVal
            };
            this.createNewAssetUnit({siteId: this.$route.params.siteId, ...params});

            setTimeout(() => {
                this.resetAssetUnitModal();
            }, 50);

        },

        onEditAssetUnit() {
            console.log("Component(AssetUnitModal)::onEditAssetUnit() - called ");
            this.hideAssetUnitModal();

            const params = {
                id: this.assetUnit.id,
                name: this.assetUnitNameInputVal
            };
            this.editAssetUnitById({siteId: this.$route.params.siteId, ...params});

            setTimeout(() => {
                this.resetAssetUnitModal();
            }, 50);
        },

        onChangeForm() {
            if (this.assetUnitNameInputVal === this.oldAssetUnitNameInputVal) {
                return this.isFormDataChanged = false;
            }
            return this.isFormDataChanged = true;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped></style>
