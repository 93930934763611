import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

/**
 * State: properties to store
 */
const state = {
    siteGeonotifications: null,
    currentGeonotification: null,
    createAnother: false
};

/**
 * Getters: Getters of properties defined in state
 */
const getters = {
    siteGeonotifications(state) {
        return state.siteGeonotifications;
    },

    currentGeonotification(state) {
        return state.currentGeonotification;
    },

    createAnotherGeonotification(state) {
        return state.createAnother;
    }
};

/**
 * Mutations (Setters) : Setters of properties defined in state
 */
const mutations = {
    SET_SITE_GEONOTIFICATIONS(state, data) {
        state.siteGeonotifications = data.geonotifications;
    },

    REMOVE_GEONOTIFICATION_BY_ID(state, notificationId) {
        // Find notification index
        let index = _.findIndex(state.siteGeonotifications, {
            id: notificationId
        });
        state.siteGeonotifications.splice(index, 1);
    },

    SET_CURRENT_GEONOTIFICATION(state, notificationData) {
        if (!state.siteGeonotifications) {
            state.siteGeonotifications = [];
        }
        // Set the current notification
        state.currentGeonotification = notificationData.currentGeonotification;
        let index = -1;
        if (notificationData.currentGeonotification) {
            index = _.findIndex(state.siteGeonotifications, {
                id: notificationData.currentGeonotification.id
            });
        }
        if (index >= 0) {
            state.siteGeonotifications.splice(
                index,
                1,
                notificationData.currentGeonotification
            );
        } else {
            if (notificationData.currentGeonotification) {
                state.siteGeonotifications.push(
                    notificationData.currentGeonotification
                );
            }
        }
    },

    SET_CREATE_ANOTHER_GEONOTIFICATION(state, createAnother) {
        state.createAnother = createAnother;
    },

    UNSET_CURRENT_GEONOTIFICATION(state) {
        state.currentGeonotification = null;
    }
};

/**
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /**
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherGeonotification({
        commit
    }, createAnother) {
        commit("SET_CREATE_ANOTHER_GEONOTIFICATION", createAnother);
    },

    /*
     * Action used to create a geonotification
     */
    createGeonotification({
        commit
    }, payload) {
        commit("SET_CREATE_ANOTHER_GEONOTIFICATION", payload.createAnother);
        axiosBackend
            .post(
                "/sites/" + payload.siteId + "/geonotifications", {
                    name: payload.name,
                    triggerCondition: payload.triggerCondition,
                    assetThreshold: payload.assetThreshold || 1,
                    geofenceId: payload.geofenceId,
                    startTime: payload.startTime,
                    endTime: payload.endTime,
                    severity: payload.severity,
                    isActive: payload.isActive,
                    isTriggerAll: payload.isTriggerAll,
                    notificationTypes: payload.notificationTypes,
                    assets: payload.assets,
                    assetCategories: payload.assetCategories,
                    isAllUsers: payload.isAllUsers,
                    roles: payload.roles,
                    users: payload.users,
                }, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }
            )
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("geo_notif_newNotificationSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to geonotifications list page
                router.push({
                    name: "geonotifications",
                    params: {
                        fromAction: "createAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /**
     * Action used to get the list of site geonotifications
     */
    getSiteGeonotifications({
        commit
    }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/geonotifications", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(res => {
                // Store site geonotifications in user GeoNotifications
                commit("SET_SITE_GEONOTIFICATIONS", {
                    geonotifications: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one geonotification by its id
     */
    getGeonotificationById({
        commit
    }, payload) {
        axiosBackend
            .get(
                "/sites/" +
                payload.siteId +
                "/geonotifications/" +
                payload.notificationId, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }
            )
            .then(res => {
                commit("SET_CURRENT_GEONOTIFICATION", {
                    currentGeonotification: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing geonotification
     */
    updateGeonotification({
        commit
    }, payload) {
        axiosBackend
            .put(
                "/sites/" +
                payload.siteId +
                "/geonotifications/" +
                payload.notificationId,
                payload, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }
            )
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_GEONOTIFICATION", {
                    currentGeonotification: res.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("geo_notif_notificationSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to geonotifications page
                router.push({
                    name: "geonotifications",
                    params: {
                        fromAction: "updateAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to change the geonotification state
     */
    updateGeonotificationState({
        commit, dispatch
    }, payload) {
        axiosBackend
            .put(
                "/sites/" +
                payload.siteId +
                "/geonotifications/" +
                payload.notificationId + "/deploy",
                payload, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }
            )
            .then(res => {

                // Reload up-to-date list of geonotifications
                dispatch("getSiteGeonotifications", payload.siteId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("geo_notif_notificationStatusSuccessfullyUpdated"),
                    type: "success"
                });

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a geonotification
     */
    deleteGeoNotification({
        commit,
        dispatch
    }, data) {
        axiosBackend
            .delete(
                "/sites/" + data.siteId + "/geonotifications/" + data.notificationId, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }
            )
            .then(() => {
                commit("REMOVE_GEONOTIFICATION_BY_ID", data.notificationId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("geo_notif_notificationSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset site geonotifications
     */
    resetSiteGeonotifications({
        commit
    }) {
        commit("SET_SITE_GEONOTIFICATIONS", {
            geonotifications: null
        });
    },

    unsetCurrentGeonotification({
        commit
    }) {
        commit("UNSET_CURRENT_GEONOTIFICATION");
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};