import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteWalkways: null,
        currentWalkway: { id: "", state: "", isHighlightFromMap: false }
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for siteWalkways
     */
    siteWalkways(state) {
        return state.siteWalkways;
    },

    currentWalkway(state) {
        return state.currentWalkway;
    },

    getWalkwayById(state) {
        return id => _.find(state.siteWalkways, ["id", id]);
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_WALKWAYS(state, data) {
        if (!state.siteWalkways) {
            state.siteWalkways = [];
        }
        state.siteWalkways = data;
    },

    REMOVE_WALKWAY_BY_ID(state, walkwayId) {
        // Find walkway index
        let index = _.findIndex(state.siteWalkways, { id: walkwayId });
        state.siteWalkways.splice(index, 1);
    },

    SET_WALKWAY(state, walkwayData) {
        if (state.siteWalkways) {
            // Find walkway index
            let index = _.findIndex(state.siteWalkways, { id: walkwayData.id });
            if (index >= 0) {
                // Update - replace the walkway in the array of walkways
                state.siteWalkways.splice(index, 1, walkwayData);
            } else {
                // Creation
                state.siteWalkways.push(walkwayData);
            }
        } else {
            state.siteWalkways = [];
            state.siteWalkways.push(walkwayData);
        }
    },

    SET_CURRENT_WALKWAY(state, walkwayData) {
        state.currentWalkway = walkwayData;
    },

    RESET_DEFAULT_WALKWAYS_STATE(state) {
        Object.assign(state, getDefaultState())
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Action used to create a walkway
     */
    createWalkway({ commit }, payload) {
        axiosBackend
            .post("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/walkways", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current walkway in store
                commit("SET_CURRENT_WALKWAY", { id: "", state: "" });

                // Store created walkway in store
                commit("SET_WALKWAY", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("walkway_walkwaySuccessfullyCreated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of site walkways
     */
    getSiteWalkways({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/walkways", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store walkways
                commit("SET_WALKWAYS", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one walkway by its id
     */
    getWalkwayById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/walkways/" + payload.walkwayId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_WALKWAY", res.data.data);
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing walkway
     */
    updateWalkway({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/walkways/" + payload.id, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current walkway in store
                commit("SET_CURRENT_WALKWAY", { id: "", state: "" });

                // Set user Data in VueX Auth store
                commit("SET_WALKWAY", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("walkway_walkwaySuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing walkway polygon
     */
    updateWalkwayPolygon({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/buildings/" + payload.buildingId + "/floors/" + payload.floorId + "/walkways/" + payload.id + "/polygon", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Reset current walkway in store
                commit("SET_CURRENT_WALKWAY", { id: "", state: "" });

                // Set user Data in VueX Auth store
                commit("SET_WALKWAY", res.data.data);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("walkway_walkwaySuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a walkway
     */
    deleteWalkway({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/walkways/" + payload.walkwayId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_WALKWAY_BY_ID", payload.walkwayId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("walkway_walkwaySuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    setCurrentWalkway({ commit }, payload) {
        commit("SET_CURRENT_WALKWAY", payload);
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetWalkwaysState ({ commit }) {
        commit('RESET_DEFAULT_WALKWAYS_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
