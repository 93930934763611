/*
 * State: properties to store
 */
const getDefaultState = () => {
    return {
        showBulkDeleteModal: false,
        hideBulkDeleteModal: false,
        bulkDeleteModalTextContent: "",
        actionName: "",
        params: {},
        dataBulkDelete: []
    }
};
const state = getDefaultState();
/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    /*
     * Toggle showBulkDeleteModal property
     */
    SHOW_BULK_DELETE_MODAL(state, data) {
        state.showBulkDeleteModal = true;
        state.bulkDeleteModalTextContent = data.textContent;
        state.actionName = data.actionName;
        state.params = data.params;
        state.dataBulkDelete = data.params.data;
    },

    /*
     * Toggle hideBulkDeleteModal property
     */
    HIDE_BULK_DELETE_MODAL(state) {
        state.hideBulkDeleteModal = true;
    },

    /*
     * Reset showBulkDeleteModal property
     */
    RESET_SHOW_BULK_DELETE_MODAL(state) {
        state.showBulkDeleteModal = false;
    },

    /*
     * Reset hideBulkDeleteModal property
     */
    RESET_HIDE_BULK_DELETE_MODAL(state) {
        state.hideBulkDeleteModal = false;
    },

    RESET_BULK_DELETE_MODAL(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for showBulkDeleteModal
     */
    showBulkDeleteModal(state) {
        return state.showBulkDeleteModal;
    },
    /*
     * Getter for hideBulkDeleteModal
     */
    hideBulkDeleteModal(state) {
        return state.hideBulkDeleteModal;
    },
    /*
     * Getter for bulkDeleteModalTextContent
     */
    getBulkDeleteModalTextContent(state) {
        return state.bulkDeleteModalTextContent;
    },

    dataBulkDelete(state) {
        return state.dataBulkDelete
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    showBulkDeleteModal({
        commit
    }, data) {
        commit("SHOW_BULK_DELETE_MODAL", data);
    },

    hideBulkDeleteModal({
        commit
    }) {
        commit("HIDE_BULK_DELETE_MODAL", {});
    },

    resetShowBulkDeleteModal({
        commit
    }) {
        commit("RESET_SHOW_BULK_DELETE_MODAL", {});
    },

    resetHideBulkDeleteModal({
        commit
    }) {
        commit("RESET_HIDE_BULK_DELETE_MODAL", {});
    },

    bulkDeleteResource({
        state,
        dispatch
    }) {
        switch (state.actionName) {
            case "BULK_DELETE_AUTOCALIBRATIONTAG":
                dispatch("bulkDeleteAutoCalibrationTag", {
                    siteId: state.params.siteId,
                    autoCalibrationTagIds: _.map(state.dataBulkDelete, autoCalibrationTag => autoCalibrationTag.id)
                });
                break;
        }
    },

    resetBulkDeleteModal({ commit }) {
        commit("RESET_BULK_DELETE_MODAL", {});
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};