const getDefaultState = () => {
    return {
        showAssetUnitModal: false,
        hideAssetUnitModal: false,
        actionAssetUnit: null,
        assetUnit: null,
    };
};

/*
 * State: properties to store
 */
const state = getDefaultState();

const getters = {
    showAssetUnitModal(state) {
        return state.showAssetUnitModal;
    },

    hideAssetUnitModal(state) {
        return state.hideAssetUnitModal;
    },

    actionAssetUnit(state) {
        return state.actionAssetUnit;
    },

    assetUnit(state) {
        return state.assetUnit;
    }

};

const mutations = {

    SHOW_ASSET_UNIT_MODAL(state, data) {
        state.showAssetUnitModal = true;
        state.actionAssetUnit = data.type;
        state.assetUnit = data.data
    },

    HIDE_ASSET_UNIT_MODAL(state) {
        state.hideAssetUnitModal = true;
        state.actionAssetUnit = null;
    },

    RESET_SHOW_ASSET_UNIT_MODAL(state) {
        state.showAssetUnitModal = false;
    },

    RESET_HIDE_ASSET_UNIT_MODAL(state) {
        state.hideAssetUnitModal = false;
    },

    RESET_SITE_TYPE_MODAL(state) {
        Object.assign(state, getDefaultState())
    }
};

const actions = {

    showAssetUnitModal({ commit }, data) {
        commit("SHOW_ASSET_UNIT_MODAL", data);
    },

    hideAssetUnitModal({ commit }) {
        commit("HIDE_ASSET_UNIT_MODAL", {});
    },

    resetShowAssetUnitModal({ commit }) {
        commit("RESET_SHOW_ASSET_UNIT_MODAL", {});
    },

    resetHideAssetUnitModal({ commit }) {
        commit("RESET_HIDE_ASSET_UNIT_MODAL", {});
    },

    resetAssetUnitModal({commit}) {
        commit("RESET_SITE_TYPE_MODAL", {});
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};
