import axiosBackend from "../../axios-backend";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        currentContacEventSetting: null
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for usersOfSite
     */

    currentContacEventSetting(state) {
        return state.currentContacEventSetting;
    }

};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_CURRENT_CONTACT_EVENT_SETTING(state, data) {
        state.currentContacEventSetting = data.contacEventSetting;
    },

    RESET_DEFAULT_CONTACT_EVENT_SETTINGS_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {

    /*
     * Action used to get the contact event setting of a user logged site
     */
    getSiteContactEventSettings({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/contacteventsettings", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Update application in store
                commit("SET_CURRENT_CONTACT_EVENT_SETTING", {
                    contacEventSetting: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing contact event setting
     */
    updateContactEventSettings({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/contacteventsettings/" + payload.contactEventSettingId, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Set role Data in VueX Auth store
                commit("SET_CURRENT_CONTACT_EVENT_SETTING", {
                    contacEventSetting: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("analytics_contactEventsSettingsSuccessfullyUpdated"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetContactEventSettingsState ({ commit }) {
        commit('RESET_DEFAULT_CONTACT_EVENT_SETTINGS_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
