import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        sitePushButtonAlerts: null,
        currentPushButtonAlert: null,
        pushButtonNotificationTypes: null,
        pushButtonAlertAssets: null,
        pushButtonAlertCategories: null,
        pushButtonAlertSubcategories: null,
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/**
 * Getters: Getters of properties defined in state
 */
const getters = {
    sitePushButtonAlerts(state) {
        return state.sitePushButtonAlerts;
    },

    currentPushButtonAlert(state) {
        return state.currentPushButtonAlert;
    },

    createAnotherPushButtonAlert(state) {
        return state.createAnother;
    },

    pushButtonNotificationTypes(state) {
        return state.pushButtonNotificationTypes;
    },

    pushButtonAlertAssets(state) {
        return state.pushButtonAlertAssets;
    },

    pushButtonAlertCategories(state) {
        return state.pushButtonAlertCategories;
    },

    pushButtonAlertSubcategories(state) {
        return state.pushButtonAlertSubcategories;
    }

};

/**
 * Mutations (Setters) : Setters of properties defined in state
 */
const mutations = {
    SET_SITE_PUSHBUTTON_ALERTS(state, data) {
        state.sitePushButtonAlerts = data.pushButtonAlerts;
        state.pushButtonAlertAssets = [];
        state.pushButtonAlertCategories = [];
        state.pushButtonAlertSubcategories = [];
        if (state.sitePushButtonAlerts) {
            // Array of push button alerts with trigger assets
            const pushButtonAlertWithAssets = state.sitePushButtonAlerts.filter(pushButtonAlert => {
                if (pushButtonAlert.assets && pushButtonAlert.assets.length > 0) {
                    return pushButtonAlert.assets.filter( elt => elt.asset && elt.asset.id );
                }
            });
            for (let pushButtonAlert of pushButtonAlertWithAssets) {
                // Array of assets associated to pushButtonAlert
                const assets = pushButtonAlert.assets.map(elt => elt.asset);
                // Array of all assets with an associated push button alarm (without duplicated asset id)
                state.pushButtonAlertAssets = _.unionBy(state.pushButtonAlertAssets, assets, 'id');
            }
            // Array of push button alerts with trigger asset categories
            const pushButtonAlertWithCategories = state.sitePushButtonAlerts.filter(pushButtonAlert => {
                if (pushButtonAlert.assetCategories && pushButtonAlert.assetCategories.length > 0) {
                    return pushButtonAlert.assetCategories.filter( elt => elt.assetCategory && elt.assetCategory.id );
                }
            });
            for (let pushButtonAlert of pushButtonAlertWithCategories) {
                // Array of asset categories associated to pushButtonAlert
                const assetCategories = pushButtonAlert.assetCategories.map(elt => elt.assetCategory);
                // Array of all asset categories with an associated push button alarm (without duplicated category id)
                state.pushButtonAlertCategories = _.unionBy(state.pushButtonAlertCategories, assetCategories, 'id');
            }
            // Array of push button alerts with trigger asset subcategories
            const pushButtonAlertWithSubcategories = state.sitePushButtonAlerts.filter(pushButtonAlert => {
                if (pushButtonAlert.assetSubcategories && pushButtonAlert.assetSubcategories.length > 0) {
                    return pushButtonAlert.assetSubcategories.filter( elt => elt.assetSubcategory && elt.assetSubcategory.id );
                }
            });
            for (let pushButtonAlert of pushButtonAlertWithSubcategories) {
                // Array of asset subcategories associated to pushButtonAlert
                const assetSubcategories = pushButtonAlert.assetSubcategories.map(elt => elt.assetSubcategory);
                // Array of all asset subcategories with an associated push button alarm (without duplicated subcategory id)
                state.pushButtonAlertSubcategories = _.unionBy(state.pushButtonAlertSubcategories, assetSubcategories, 'id');
            }
        }
    },

    REMOVE_PUSHBUTTON_ALERT_BY_ID(state, alertId) {
        // Find notification index
        let index = _.findIndex(state.sitePushButtonAlerts, {
            id: alertId
        });
        state.sitePushButtonAlerts.splice(index, 1);
    },

    SET_CURRENT_PUSHBUTTON_ALERT(state, data) {
        if (!state.sitePushButtonAlerts) {
            state.sitePushButtonAlerts = [];
        }
        // Set the current notification
        state.currentPushButtonAlert = data.currentPushButtonAlert;
        let index = -1;
        if (data.currentPushButtonAlert) {
            index = _.findIndex(state.sitePushButtonAlerts, { id: data.currentPushButtonAlert.id });
        }
        if (index >= 0) {
            state.sitePushButtonAlerts.splice(index, 1, data.currentPushButtonAlert);
        } else {
            if (data.currentPushButtonAlert) {
                state.sitePushButtonAlerts.push(data.currentPushButtonAlert);
            }
        }
    },

    SET_CREATE_ANOTHER_PUSHBUTTON_ALERT(state, createAnother) {
        state.createAnother = createAnother;
    },

    SET_PUSHBUTTON_NOTIFICATION_TYPES(state, data) {
        state.pushButtonNotificationTypes = data.pushButtonNotificationTypes;
    },

    RESET_DEFAULT_PUSHBUTTON_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/**
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /**
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherPushButtonAlert({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_PUSHBUTTON_ALERT", createAnother);
    },

    /*
     * Action used to create a push button alert
     */
    createPushButtonAlert({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_PUSHBUTTON_ALERT", payload.createAnother);
        axiosBackend
            .post(
                "/sites/" + payload.siteId + "/pushbuttonalerts",
                payload,
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alert_newAlertSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to pushButtonAlerts list page
                router.push({
                    name: "pushButtonAlerts",
                    params: {
                        fromAction: "createAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /**
     * Action used to get the list of site push button alerts
     */
    getSitePushButtonAlerts({ commit }, siteId) {
        axiosBackend
            .get(
                "/sites/" + siteId + "/pushbuttonalerts",
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(res => {
                // Store site pushButtonAlerts in user PushButtonAlerts
                commit("SET_SITE_PUSHBUTTON_ALERTS", {
                    pushButtonAlerts: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one push button alert by its id
     */
    getPushButtonAlertById({ commit }, payload) {
        axiosBackend
            .get(
                "/sites/" + payload.siteId + "/pushbuttonalerts/" + payload.alertId,
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(res => {
                commit("SET_CURRENT_PUSHBUTTON_ALERT", {
                    currentPushButtonAlert: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing push button alert
     */
    updatePushButtonAlert({ commit }, payload) {
        axiosBackend
            .put(
                "/sites/" + payload.siteId + "/pushbuttonalerts/" + payload.alertId,
                payload,
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_PUSHBUTTON_ALERT", {
                    currentPushButtonAlert: res.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alert_alertStatusSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to pushButtonAlerts page
                router.push({
                    name: "pushButtonAlerts",
                    params: {
                        fromAction: "updateAPI"
                    }
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to change the push button alert state
     */
    updatePushButtonAlertState({ commit, dispatch }, payload) {
        axiosBackend
            .put(
                "/sites/" + payload.siteId + "/pushbuttonalerts/" + payload.alertId + "/deploy",
                payload,
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(res => {

                // Reload up-to-date list of pushButtonAlerts
                dispatch("getSitePushButtonAlerts", payload.siteId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alert_alertStatusSuccessfullyUpdated"),
                    type: "success"
                });

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a push button alert
     */
    deletePushButtonAlert({ commit, dispatch }, data) {
        axiosBackend
            .delete(
                "/sites/" + data.siteId + "/pushbuttonalerts/" + data.alertId,
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(() => {
                commit("REMOVE_PUSHBUTTON_ALERT_BY_ID", data.alertId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("alert_alertSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /**
     * Action used to get the list of push button notification types
     */
    getPushButtonNotificationTypes({ commit }) {
        axiosBackend
            .get(
                "/pushbuttonnotificationtypes",
                { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
            )
            .then(res => {
                // Store site geonotifications in userNotifications
                commit("SET_PUSHBUTTON_NOTIFICATION_TYPES", {
                    pushButtonNotificationTypes: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetPushButtonAlertState({ commit }) {
        commit('RESET_DEFAULT_PUSHBUTTON_STATE');
    },

};

export default {
    state,
    getters,
    mutations,
    actions
};