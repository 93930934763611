import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        siteAutoCalibrationTags: null,
        currentAutoCalibrationTag: null,
        currentAutoCalibrationTagLocation: { id: "", state: "", isHighlightFromMap: false },
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {

    /*
     * Getter for siteAutoCalibrationTags
     */
    siteAutoCalibrationTags(state) {
        return state.siteAutoCalibrationTags;
    },

    currentAutoCalibrationTag(state) {
        return state.currentAutoCalibrationTag;
    },

    currentAutoCalibrationTagLocation(state) {
        return state.currentAutoCalibrationTagLocation;
    },

    getAutocalibrationTagById: state => actTagId => {
        return state.siteAutoCalibrationTags.find(actTag => actTag.id == actTagId);
    },

    createAnotherAutocalibrationTag(state) {
        return state.createAnother;
    }

};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_SITE_AUTOCALIBRATIONTAGS(state, data) {
        if (!state.siteAutoCalibrationTags) {
            state.siteAutoCalibrationTags = [];
        }
        state.siteAutoCalibrationTags = data.autocalibrationtags;
    },

    PUSH_SITE_AUTOCALIBRATION_TAGS(state, data) {
        if (!state.siteAutoCalibrationTags) {
            state.siteAutoCalibrationTags = [];
        }
        state.siteAutoCalibrationTags = state.siteAutoCalibrationTags.concat(data.tags);
    },

    SET_SITE_AUTOCALIBRATIONTAG(state, userData) {
        if (!state.siteAutoCalibrationTags) {
            state.siteAutoCalibrationTags = [];
        }
        state.siteAutoCalibrationTags.push(userData.autocalibrationtags);
    },

    REMOVE_AUTOCALIBRATIONTAG_BY_ID(state, autoCalibrationTagId) {
        // Find gateway index
        let index = _.findIndex(state.siteAutoCalibrationTags, { id: autoCalibrationTagId });
        state.siteAutoCalibrationTags.splice(index, 1);
    },

    REMOVE_AUTOCALIBRATIONTAG_BY_IDS(state, autoCalibrationTagIds) {
        for(let autoCalibrationTagId of autoCalibrationTagIds) {
            // Find gateway index
            let index = _.findIndex(state.siteAutoCalibrationTags, { id: autoCalibrationTagId });
            state.siteAutoCalibrationTags.splice(index, 1);
        }
    },

    SET_CURRENT_AUTOCALIBRATIONTAG(state, autocalibrationtagData) {
        // Set the currentAutoCalibrationTag
        state.currentAutoCalibrationTag = autocalibrationtagData.currentAutoCalibrationTag;
        if (state.siteAutoCalibrationTags) {
            // Find AutoCalibrationTag index
            let index = _.findIndex(state.siteAutoCalibrationTags, { id: autocalibrationtagData.currentAutoCalibrationTag.id });
            if (index >= 0) {
                // Replace the AutoCalibrationTag in the array of AutoCalibrationTags
                state.siteAutoCalibrationTags.splice(index, 1, autocalibrationtagData.currentAutoCalibrationTag);
            } else {
                state.siteAutoCalibrationTags.push(autocalibrationtagData.currentAutoCalibrationTag);
            }
        } else {
            state.siteAutoCalibrationTags = [];
            state.siteAutoCalibrationTags.push(autocalibrationtagData.currentAutoCalibrationTag);
        }
    },

    SET_CURRENT_AUTOCALIBRATIONTAG_LOCATION(state, autocalibrationtagData) {
        state.currentAutoCalibrationTagLocation = autocalibrationtagData;
    },

    SET_CREATE_ANOTHER_AUTOCALIBRATIONTAG(state, createAnother) {
        state.createAnother = createAnother;
    },

    RESET_DEFAULT_AUTOTAGS_STATE(state) {
        Object.assign(state, getDefaultState())
    }

};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Set "createAnother" boolean in vuex state
     */
    setCreateAnotherAutocalibrationTag({ commit }, createAnother) {
        commit("SET_CREATE_ANOTHER_AUTOCALIBRATIONTAG", createAnother);
    },

    /*
     * Action used to create a gateway
     */
    createAutoCalibrationTag({ commit }, payload) {
        commit("SET_CREATE_ANOTHER_AUTOCALIBRATIONTAG", payload.createAnother);
        delete payload.createAnother;
        axiosBackend
            .post("/sites/" + payload.siteId + "/autocalibrationtags", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_SITE_AUTOCALIBRATIONTAG", {
                    autocalibrationtags: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("act_autocalibrationtagSuccessfullyCreated"),
                    type: "success"
                });

                // Creation OK => Route to gateways list page
                router.push({ name: "autocalibrationtags", params: { fromAction: "createAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to create multiple autocalibration tags
     */
    massImportAutocalibrationTags({ commit }, payload) {
        commit("SET_UPLOAD_AUTOCALIBRATION_TAGS_IN_PROGRESS", true);

        axiosBackend
            .post("/sites/" + payload.siteId + "/autocalibrationtags/massimport", payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_UPLOAD_AUTOCALIBRATION_TAGS_IN_PROGRESS", false);

                // Add new tags to the list
                commit("PUSH_SITE_AUTOCALIBRATION_TAGS", {
                    tags: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("act_beaconsSuccessfullyCreated"),
                    type: "success"
                });

                // Ask to hide import modal now
                commit("HIDE_IMPORT_AUTOCALIBRATION_TAGS_MODAL", {});
            })
            .catch(error => {
                commit("SET_UPLOAD_AUTOCALIBRATION_TAGS_IN_PROGRESS", false);
                console.log(error);
            });
    },

    /*
     * Action used to get the list of gateways of site
     */
    getSiteAutoCalibrationTags({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/autocalibrationtags", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_SITE_AUTOCALIBRATIONTAGS", {
                    autocalibrationtags: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get one AutoCalibrationTag by its id
     */
    getAutoCalibrationTagById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/autocalibrationtags/" + payload.autoCalibrationTagId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_CURRENT_AUTOCALIBRATIONTAG", {
                    currentAutoCalibrationTag: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update an existing AutoCalibrationTag
     */
    updateAutoCalibrationTag({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/autocalibrationtags/" + payload.autoCalibrationTagId, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_AUTOCALIBRATIONTAG", {
                    currentAutoCalibrationTag: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("act_autocalibrationtagSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to gateways page
                router.push({ name: "autocalibrationtags", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a gateway
     */
    deleteAutoCalibrationTag({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/autocalibrationtags/" + payload.autoCalibrationTagId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_AUTOCALIBRATIONTAG_BY_ID", payload.autoCalibrationTagId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("act_autocalibrationtagSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    bulkDeleteAutoCalibrationTag({commit, dispatch}, payload) {

        axiosBackend
            .delete("/sites/" + payload.siteId + "/autocalibrationtags", {
                data: {
                    autoCalibrationTagIds: payload.autoCalibrationTagIds
                },
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_AUTOCALIBRATIONTAG_BY_IDS", payload.autoCalibrationTagIds);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text:  payload.autoCalibrationTagIds.length === 1 ?
                        i18n.t("act_autocalibrationtagSuccessfullyDeleted") :
                        i18n.t("act_autocalibrationtagsSuccessfullyDeleted", {count: payload.autoCalibrationTagIds.length}),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideBulkDeleteModal", {});

                setTimeout(() => {
                    dispatch("resetBulkDeleteModal", {});
                }, 1500);
            })
            .catch(error => console.log(error));
    },

    /**
     * Set act currently created/edited or deleted
     * @param commit
     * @param payload
     */
    setCurrentAutoCalibrationTagLocation({ commit }, payload) {
        commit("SET_CURRENT_AUTOCALIBRATIONTAG_LOCATION", payload);
    },

    /**
     * update the act with the location
     * @param commit
     * @param payload
     */
    setAutoCalibrationTagLocation({ commit, rootState }, payload) {
        axiosBackend
            .put(
                "/sites/" + payload.siteId + "/autocalibrationtags/" + payload.id + "/location",
                { autoCalibrationTag: payload },
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(res => {
                let updatedAutoCalibrationTagLocation = res.data.data.updatedResource;

                commit("SET_CURRENT_AUTOCALIBRATIONTAG", {
                    currentAutoCalibrationTag: updatedAutoCalibrationTagLocation
                });

                // Reset current act location
                commit("SET_CURRENT_AUTOCALIBRATIONTAG_LOCATION", { id: "", state: "" });

                // Check if the removed actag is the last one added to the dashboard. If it is the case reset variable to compute distance.
                if (!payload.location && rootState.map.lastAddedObjectId && rootState.map.lastAddedObjectId == payload.id){
                    // Reset current gateway location
                    commit("SET_LAST_ADDED_OBJECT_LOCATION", {
                        lastAddedObjectLocation: null,
                        lastAddedObjectType: null,
                        lastAddedObjectId: null
                    });
                }

            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetAutocalibrationTagsState ({ commit }) {
        commit('RESET_DEFAULT_AUTOTAGS_STATE');
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
