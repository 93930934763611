import axiosBackend from "../../axios-backend";
import router from "../../route/router";
import i18n from "../../i18n";

const getDefaultState = () => {
    return {
        usersOfSite: [],
        pendingInvitations: [],
        currentSiteUser: null,
        createAnother: false
    };
}

/*
 * State: properties to store
 */
const state = getDefaultState();

/*
 * Getters: Getters of properties defined in state
 */
const getters = {
    /*
     * Getter for usersOfSite
     */

    usersOfSite(state) {
        return state.usersOfSite;
    },

    currentSiteUser(state) {
        return state.currentSiteUser;
    },

    pendingInvitations(state) {
        return state.pendingInvitations;
    }
};

/*
 * Mutations (Setters): Setters of properties defined in state
 */
const mutations = {
    SET_USERS_OF_SITES(state, data) {
        state.usersOfSite = data.siteUsers;
    },

    SET_PENDING_INVITATIONS_OF_SITE(state, data) {
        state.pendingInvitations = data.pendingInvitations;
    },

    PUSH_PENDING_INVITATIONS_OF_SITE(state, data) {
        if (!state.pendingInvitations) {
            state.pendingInvitations = [];
        }
        state.pendingInvitations = state.pendingInvitations.concat(data.pendingInvitations);
    },

    REMOVE_SITE_USER_BY_ID(state, userId) {
        let index = _.findIndex(state.usersOfSite, (usersOfSite) => {
            return usersOfSite.user.id === userId
        });
        state.usersOfSite.splice(index, 1);
    },

    REMOVE_PENDING_INVITATION_BY_ID(state, invitationId) {
        let index = _.findIndex(state.pendingInvitations, { id: invitationId });
        state.pendingInvitations.splice(index, 1);
    },

    SET_CURRENT_SITE_USER(state, data) {
        // Set the currentSite
        state.currentSiteUser = data.currentSiteUser;
        let index = _.findIndex(state.usersOfSite, { id: data.currentSiteUser.id });
        if (index >= 0) {
            state.usersOfSite.splice(index, 1, data.currentSiteUser);
        } else {
            state.usersOfSite.push(data.currentSiteUser);
        }
    },

    RESET_DEFAULT_SITEUSERS_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {
    /*
     * Action used to invite user(s) to join site
     */
    sendInviteToUsers({ commit }, jsonData) {
        // Inform store that upload is in progress
        commit("SET_UPLOAD_USERS_IN_PROGRESS", true);

        axiosBackend
            .post("/sites/" + jsonData.siteId + "/users", jsonData.data, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                if (jsonData.from === "MASS_IMPORT") {
                    // Inform store that upload is not more in progress
                    commit("SET_UPLOAD_USERS_IN_PROGRESS", false);

                    // Update pending activations
                    commit("PUSH_PENDING_INVITATIONS_OF_SITE", {
                        pendingInvitations: res.data.data
                    });

                    // Display notification
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("siteusers_invitationsSuccessfullySent"),
                        type: "success"
                    });

                    // Ask to hide import modal now
                    commit("HIDE_IMPORT_USERS_MODAL", {});
                } else {
                    // Display notification
                    commit("SHOW_NOTIFICATION", {
                        text: i18n.t("siteusers_invitationSuccessfullySent"),
                        type: "success"
                    });

                    // Creation OK => Route to login page
                    router.push({ name: "users", params: { fromAction: "createAPI" } });
                }
            })
            .catch(error => {
                // Inform store that upload is not more in progress
                commit("SET_UPLOAD_USERS_IN_PROGRESS", false);
                console.log(error);
            });
    },

    /*
     * Action used to reinvite a user to site by sending an email again
     */
    reInviteToUser({ commit }, jsonData) {
        axiosBackend
            .put(
                "/sites/" + jsonData.siteId + "/users/invitations/" + jsonData.invitationId + "/reinvite",
                {},
                {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") }
                }
            )
            .then(() => {
                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_invitationSuccessfullySent"),
                    type: "success"
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of users associated to a site
     */
    getUsersOfSite({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/users", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store created user site in userSites
                commit("SET_USERS_OF_SITES", {
                    siteUsers: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get the list of pending user invitations for a given site
     */
    getPendingInvitationsOfSite({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/users/invitations?type=user", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_PENDING_INVITATIONS_OF_SITE", {
                    pendingInvitations: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to delete a pending invitation
     */
    deletePendingInvitation({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/users/invitations/" + payload.invitationId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_PENDING_INVITATION_BY_ID", payload.invitationId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_pendingInvitationSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to get site user by id
     */
    getSiteUserById({ commit }, payload) {
        axiosBackend
            .get("/sites/" + payload.siteId + "/users/" + payload.userId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                commit("SET_CURRENT_SITE_USER", {
                    currentSiteUser: res.data.data
                });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to update the role of a user in a given site
     */
    updateUserOfSiteById({ commit }, payload) {
        axiosBackend
            .put("/sites/" + payload.siteId + "/users/" + payload.userId, payload, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Set user Data in VueX Auth store
                commit("SET_CURRENT_SITE_USER", {
                    currentSiteUser: res.data.data
                });

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_userAccessSuccessfullyUpdated"),
                    type: "success"
                });

                // Update OK => Route to users page
                router.push({ name: "users", params: { fromAction: "updateAPI" } });
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to revoke access to site for a given user
     */
    revokeUserAccessToSite({ commit, dispatch }, payload) {
        axiosBackend
            .delete("/sites/" + payload.siteId + "/users/" + payload.userId, {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(() => {
                commit("REMOVE_SITE_USER_BY_ID", payload.userId);

                // Display notification
                commit("SHOW_NOTIFICATION", {
                    text: i18n.t("siteusers_userAccessSuccessfullyDeleted"),
                    type: "success"
                });

                // Ask to hide generic delete modal now
                dispatch("hideDeleteModal", {});
            })
            .catch(error => console.log(error));
    },

    /*
     * Action used to reset state to default value
     * (To avoid any memory leak)
     */
    resetSiteUsersState ({ commit }) {
        commit('RESET_DEFAULT_SITEUSERS_STATE');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
