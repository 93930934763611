import axiosBackend from "../../axios-backend";
import {helpers} from "@turf/turf";
import distance from "@turf/distance"

function deepKeyBy(arr, key) {
    if (arr.length > 0 && arr[0][key]) {
        return _(arr)
            .map(function(o) {
                return _.mapValues(o, function(v) {
                    return _.isArray(v) && v.length > 0 ? deepKeyBy(v, key) : v;
                });
            })
            .keyBy(key)
            .value();
    } else {
        return arr;
    }
}

/*
 * State: properties to store
 */
const state = {
    /*
     * This is the mode for which the map is currently used
     * Could be gatewaysLocation, indoorAreasDefinition, geofencesDefinition, autocalibrationtagsLocation, assetsTracking, siteLocation..
     */
    mode: "",

    /*
     * This is the current longitude of the map
     */
    currentMapLongitude: 0.01,

    /*
     * This is the current latitude of the map
     */
    currentMapLatitude: 0.01,

    /*
     * This is the zoom level currently attached to the displayed map
     */
    zoom: null,

    /*
     * This is a map (Object) indexed by building ids that gives the building representation and their floors by buildingId
     */
    buildingsAndTheirFloorsMap: {},

    /*
     * This is a array (Array) containing all buildings and their floors
     */
    buildingsAndTheirFloorsArray: [],

    /*
     * This array contains objects of all floors selected to be displayed on the map
     */
    selectedFloors: [],

    /*
     * This is the current site Id.
     * To be used for local storage identifiers
     */
    selectedSiteId: null,

    /*
     * This is the current Building Id selected in the floors selector.
     * Warning : This state is only populated when there is only one choice permitted in the floors selector
     */
    selectedBuildingId: null,

    /*
     * This is the current Floor Id selected in the floors selector.
     * Warning : This state is only populated when there is only one choice permitted in the floors selector
     */
    selectedFloorId: null,

    /*
     * This boolean is used to know if map has moved at least one time since his first instanciation in current component
     */
    hasMovedFromInitialInit: false,

    /*
     * This is the current map tiles layer
     */
    currentMapTilesLayerOpacity: 30,

    /*
     * This is the name of the tiles layer
     */
    currentMapTileLayerName: "OpenStreetMap",

    /*
     * Boolean to indicate if dashbboard will be refreshed or not
     */
    isMapRefreshingEnabled: true,

    /*
     * Integer to indicate the dashbboard interval refreshing in seconds
     */
    mapRefreshingIntervalInSeconds: 5,

    /*
     * Boolean to indicate if assets labels will have to be displayed on the map or not
     */
    isAssetsLabelsDisplayed: true,

    /*
     * Boolean to indicate if assets layer will have to be displayed on the map or not
     */
    isAssetsLayerDisplayed: true,

    /*
     * Boolean to indicate if gateways layer will have to be displayed on the map or not
     */
    isGatewaysLayerDisplayed: false,

    /*
     * Boolean to indicate if heatmaps layer will have to be displayed on the map or not
     */
    isHeatmapsLayerDisplayed: false,

    /*
     * Boolean to indicate if autocalibration tags layer will have to be displayed on the map or not
     */
    isAutocalibrationTagsLayerDisplayed: false,

    /*
     * Boolean to indicate if indoor areas layer will have to be displayed on the map or not
     */
    isIndoorAreasLayerDisplayed: false,

    /*
     * Boolean to indicate if geofences layer will have to be displayed on the map or not
     */
    isGeofencesLayerDisplayed: false,

    /*
     * Boolean to indicate if walkways layer will have to be displayed on the map or not
     */
    isWalkwaysLayerDisplayed: false,

    /*
     * Boolean to indicate if walls layer will have to be displayed on the map or not
     */
    isWallsLayerDisplayed: false,

    /*
     * JSON containing name of labels to display on each row for all assets
     */
    assetsLabelsDisplayedValues: {
        row1: "MAP_ASSET_NAME",
        row2: "MAP_NONE",
        row3: "MAP_NONE",
        row4: "MAP_NONE"
    },

    /*
     * Array containing a list of active filters objects defined as follow:
     *
     *  {
     *       id: "1", // Only for UI
     *       keyLabel: "MAP_CATEGORY", // Only for UI
     *       keyValue: "ASSET_C_PATIENTS", // For UI and Backend search
     *       value: "Patients" // For UI
     *   },
     */
    activeFilters: [],

    /*
     * A JSON object representing the search items that should be added to the search request
     */
    searchedItems: [],

    /*
     * Boolean to indicate if filtering option is enabled or not
     */
    isMapFilteringEnabled: false,

    /*
     * Background picture to be displayed as layout on current map
     * Useful for demo tours for example
     */
    backgroundPicture: null,

    /*
     * This is an array of mac addresses of assets tags.
     * Their marker will be automatically highlighed on the map
     */
    arrayOfAssetsMacAddressToHighlight: [],

    /*
     * This is a JSON object containing lat/lnt of last added point.
     * Used to compute the distance between the last added and the point which is actually added
     */
    lastAddedObjectLocation: null,

    /*
     * This is the type of object that has been last added GATEWAY or ACTAG
     */
    lastAddedObjectType: null,

    /*
     * This is the id of object that has been last added GATEWAY or ACTAG
     */
    lastAddedObjectId: null,

    /*
     * This is the lat/lng of last point added to the map.
     */
    mouseMovePointLatLng: null
};

/*
 * Getters
 */
const getters = {

    distanceBetweenLastAddedObjectAndCurrentPoint(state) {
        if (state.lastAddedObjectLocation && state.mouseMovePointLatLng) {
            var from = helpers.point([state.lastAddedObjectLocation.coordinates[0], state.lastAddedObjectLocation.coordinates[1]]);
            var to = helpers.point([state.mouseMovePointLatLng.lng, state.mouseMovePointLatLng.lat]);
            var options = {units: 'meters'};
            var d = distance(from, to, options);
            // Keep only 3 decimals
            return parseFloat(d).toFixed(3);;
        }
    },

    lastAddedObjectName(state, getters) {
        if (state.lastAddedObjectType === "GATEWAY") {
            var gateway = getters.getGatewayById(state.lastAddedObjectId)
            return gateway.name;
        } else if (state.lastAddedObjectType === "ACTAG") {
            var actag = getters.getAutocalibrationTagById(state.lastAddedObjectId)
            return actag.serialNumber;
        }
    },

    lastAddedObjectType(state) {
        return state.lastAddedObjectType;
    },

    arrayOfAssetsMacAddressToHighlight(state) {
        return state.arrayOfAssetsMacAddressToHighlight;
    },

    mapMode(state) {
        return state.mode;
    },

    activeFilters(state) {
        return state.activeFilters;
    },

    searchedItems(state) {
        return state.searchedItems;
    },

    currentMapTilesLayerOpacity(state) {
        return state.currentMapTilesLayerOpacity;
    },

    currentMapTileLayerName(state) {
        return state.currentMapTileLayerName;
    },

    hasMovedFromInitialInit(state) {
        return state.hasMovedFromInitialInit;
    },

    currentMapLatitude(state) {
        return state.currentMapLatitude;
    },

    currentMapLongitude(state) {
        return state.currentMapLongitude;
    },

    currentMapZoom(state) {
        return state.zoom;
    },

    buildingsAndTheirFloorsMap(state) {
        return state.buildingsAndTheirFloorsMap;
    },

    buildingsAndTheirFloorsArray(state) {
        return state.buildingsAndTheirFloorsArray;
    },

    getFloorByBuildingIdAndFloorId: state => (buildingId, floorId) => {
        return state.buildingsAndTheirFloorsMap[buildingId].floors[floorId];
    },

    getBuildingById: state => buildingId => {
        return state.buildingsAndTheirFloorsMap[buildingId];
    },

    selectedFloors(state) {
        return state.selectedFloors;
    },

    // This method can be used only when there is only one choice permitted in the floor selector.
    getSelectedBuildingId(state) {
        return state.selectedBuildingId;
    },

    // This method can be used only when there is only one choice permitted in the floor selector.
    getSelectedFloorId(state) {
        return state.selectedFloorId;
    },

    isMapRefreshingEnabled(state) {
        return state.isMapRefreshingEnabled;
    },

    mapRefreshingIntervalInSeconds(state) {
        return state.mapRefreshingIntervalInSeconds;
    },

    isAssetsLabelsDisplayed(state) {
        return state.isAssetsLabelsDisplayed;
    },

    isGatewaysLayerDisplayed(state) {
        return state.isGatewaysLayerDisplayed;
    },

    isHeatmapsLayerDisplayed(state) {
        return state.isHeatmapsLayerDisplayed;
    },

    isAutocalibrationTagsLayerDisplayed(state) {
        return state.isAutocalibrationTagsLayerDisplayed;
    },

    isAssetsLayerDisplayed(state) {
        return state.isAssetsLayerDisplayed;
    },

    isIndoorAreasLayerDisplayed(state) {
        return state.isIndoorAreasLayerDisplayed;
    },

    isGeofencesLayerDisplayed(state) {
        return state.isGeofencesLayerDisplayed;
    },

    isWalkwaysLayerDisplayed(state) {
        return state.isWalkwaysLayerDisplayed;
    },

    isWallsLayerDisplayed(state) {
        return state.isWallsLayerDisplayed;
    },

    assetsLabelsDisplayedValues(state) {
        return state.assetsLabelsDisplayedValues;
    },

    isMapFilteringEnabled(state) {
        return state.isMapFilteringEnabled;
    },

    backgroundPicture(state) {
        return state.backgroundPicture;
    },

    lastAddedObjectLocation(state) {
        return state.lastAddedObjectLocation;
    }
};

/*
 * Mutations
 */
const mutations = {
    SET_CURRENT_LOCATION(state, locationData) {
        state.currentMapLongitude = locationData.longitude;
        state.currentMapLatitude = locationData.latitude;
        state.zoom = locationData.zoom;
    },

    SET_MAP_HAS_MOVED_FROM_INITIAL_INIT(state, hasMoved) {
        state.hasMovedFromInitialInit = hasMoved;
    },

    SET_BUILDINGS_AND_THEIR_FLOORS(state, data) {
        // Create a map of building indexed by buildingId
        state.buildingsAndTheirFloorsMap = deepKeyBy(data.buildings, "id");
        state.buildingsAndTheirFloorsArray = data.buildings;
    },

    ADD_FLOOR_TO_SELECTED_FLOORS(state, data) {
        state.selectedFloors.push(state.buildingsAndTheirFloorsMap[data.buildingId].floors[data.floorId]);
    },

    REMOVE_ALL_FLOORS_TO_SELECTED_FLOORS(state) {
        state.selectedFloors = [];
    },

    SET_SELECTED_SITE_ID(state, siteId) {
        state.selectedSiteId = siteId;
    },

    SET_SELECTED_BUILDING_ID(state, buildingId) {
        state.selectedBuildingId = buildingId;
    },

    SET_SELECTED_FLOOR_ID(state, floorId) {
        state.selectedFloorId = floorId;
    },

    SET_CURRENT_MAP_OPACITY_LAYER(state, opacityValue) {
        state.currentMapTilesLayerOpacity = opacityValue;
    },

    SET_CURRENT_MAP_TILE_LAYER_NAME(state, tileName) {
        state.currentMapTileLayerName = tileName;
    },

    SET_MAP_REFRESHING_IS_ENABLED(state, value) {
        state.isMapRefreshingEnabled = value;
    },

    SET_MAP_REFRESHING_INTERVAL(state, value) {
        state.mapRefreshingIntervalInSeconds = value;
    },

    SET_ASSETS_LABELS_ARE_DISPLAYED(state, value) {
        state.isAssetsLabelsDisplayed = value;
    },

    SET_ASSETS_LABELS_DISPLAYED_VALUES(state, rowValues) {
        state.assetsLabelsDisplayedValues = rowValues;
    },

    SET_GATEWAYS_LAYER_IS_DISPLAYED(state, isDisplayed) {
        state.isGatewaysLayerDisplayed = isDisplayed;
    },

    SET_HEATMAPS_LAYER_IS_DISPLAYED(state, isDisplayed) {
        state.isHeatmapsLayerDisplayed = isDisplayed;
    },

    SET_AUTOCALIBRATION_TAGS_LAYER_IS_DISPLAYED(state, isDisplayed) {
        state.isAutocalibrationTagsLayerDisplayed = isDisplayed;
    },

    SET_ASSETS_LAYER_IS_DISPLAYED(state, isDisplayed) {
        state.isAssetsLayerDisplayed = isDisplayed;
    },

    SET_INDOOR_AREAS_LAYER_IS_DISPLAYED(state, isDisplayed) {
        state.isIndoorAreasLayerDisplayed = isDisplayed;
    },

    SET_GEOFENCES_LAYER_IS_DISPLAYED(state, isDisplayed) {
        state.isGeofencesLayerDisplayed = isDisplayed;
    },

    SET_WALKWAYS_LAYER_IS_DISPLAYED(state, isDisplayed) {
        state.isWalkwaysLayerDisplayed = isDisplayed;
    },

    SET_WALLS_LAYER_IS_DISPLAYED(state, isDisplayed) {
        state.isWallsLayerDisplayed = isDisplayed;
    },

    ADD_ACTIVE_FILTER(state, filterObj) {
        state.activeFilters.push(filterObj);
    },

    ADD_ALL_ACTIVE_FILTERS(state, activeFilters) {
        state.activeFilters = activeFilters;
    },

    REMOVE_ACTIVE_FILTER(state, removedFilterId) {
        let index = _.findIndex(state.activeFilters, { id: removedFilterId });
        state.activeFilters.splice(index, 1);
    },

    REMOVE_ALL_ACTIVE_FILTERS(state) {
        state.activeFilters = [];
    },

    SET_MAP_FILTERING_IS_ENABLED(state, value) {
        state.isMapFilteringEnabled = value;
    },

    SET_BACKGROUND_PICTURE(state, data) {
        state.backgroundPicture = data;
    },

    SET_MAP_MODE(state, mode) {
        state.mode = mode;
    },

    RESET_MAP_MODE(state) {
        state.mode = "";
    },

    ADD_SEARCHED_ITEM(state, searchedItem) {
        state.searchedItems.push(searchedItem);
    },

    RESET_SEARCHED_ITEM(state) {
        state.searchedItems = [];
    },

    SET_ARRAY_OF_MAC_ADDRESS_TO_HIGHLIGHT(state, dataArr) {
        state.arrayOfAssetsMacAddressToHighlight = dataArr;
    },

    RESET_ARRAY_OF_MAC_ADDRESS_TO_HIGHLIGHT(state) {
        state.arrayOfAssetsMacAddressToHighlight = [];
    },

    SET_LAST_ADDED_OBJECT_LOCATION(state, data) {
        state.lastAddedObjectLocation = data.lastAddedObjectLocation
        state.lastAddedObjectType = data.type
        state.lastAddedObjectId = data.id
    },

    SET_MOUSE_MOVE_POINT(state, data) {
        state.mouseMovePointLatLng = data.point
    },

    RESET_DISTANCE_BETWEEN_LAST_ADDED_OBJECT(state) {
        state.mouseMovePointLatLng = null;
    }
};

/*
 * Actions: List of methods to fetch, update or delete data
 */
const actions = {


    /*
     * Action used to get the list of site buildings and their floors
     */
    getBuildingsAndTheirFloors({ commit }, siteId) {
        axiosBackend
            .get("/sites/" + siteId + "/buildings/floors", {
                headers: { Authorization: "Bearer " + localStorage.getItem("token") }
            })
            .then(res => {
                // Store site buildings in userBuildings
                commit(
                    "SET_BUILDINGS_AND_THEIR_FLOORS",
                    {
                        buildings: res.data.data
                    },
                    null,
                    { root: true }
                );
            })
            .catch(error => console.log(error));
    },

    setMapLocation({ commit }, data) {
        commit("SET_CURRENT_LOCATION", data);
    },

    addFloorToSelectedFloors({ commit }, data) {
        commit("ADD_FLOOR_TO_SELECTED_FLOORS", data);
    },

    removeAllFloorsToSelectedFloors({ commit }, data) {
        commit("REMOVE_ALL_FLOORS_TO_SELECTED_FLOORS", data);
    },

    setCurrentSelectedBuildingId({ commit }, buildingId) {
        commit("SET_SELECTED_BUILDING_ID", buildingId);
    },

    setCurrentSelectedFloorId({ commit }, floorId) {
        commit("SET_SELECTED_FLOOR_ID", floorId);
    },

    setHasMovedFromInitialInit({ commit }, hasMoved) {
        commit("SET_MAP_HAS_MOVED_FROM_INITIAL_INIT", hasMoved);
    },

    setCurrentMapTilesLayerOpacity({ commit, state }, opacityValue) {
        commit("SET_CURRENT_MAP_OPACITY_LAYER", opacityValue);

        if (state.mode === "assetsTracking" && opacityValue > 0 && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_brightness", opacityValue);
        }
    },

    setCurrentMapTilesLayerName({ commit, state }, tileName) {
        commit("SET_CURRENT_MAP_TILE_LAYER_NAME", tileName);

        if (state.mode === "assetsTracking" && tileName && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_mapTileLayerName", tileName);
        }
    },

    setMapRefreshingEnabled({ commit, state }, value) {
        commit("SET_MAP_REFRESHING_IS_ENABLED", value);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isMapRefreshingEnabled", value);
        }
    },

    setMapRefreshingInterval({ commit, state }, value) {
        commit("SET_MAP_REFRESHING_INTERVAL", value);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_mapRefreshingInterval", value);
        }
    },

    setAssetsLabelsDisplayed({ commit, state }, value) {
        commit("SET_ASSETS_LABELS_ARE_DISPLAYED", value);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isAssetsLabelsDisplayed", value);
        }
    },

    setAssetsLabelsDisplayedValues({ commit, state }, rowValues) {
        commit("SET_ASSETS_LABELS_DISPLAYED_VALUES", rowValues);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_assetsLabelsDisplayed", JSON.stringify(rowValues));
        }
    },

    setGatewaysLayerDisplayed({ commit, state }, isDisplayed) {
        commit("SET_GATEWAYS_LAYER_IS_DISPLAYED", isDisplayed);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isGatewaysLayerDisplayed", isDisplayed);
        }
    },

    setHeatmapsLayerDisplayed({ commit }, isDisplayed) {
        commit("SET_HEATMAPS_LAYER_IS_DISPLAYED", isDisplayed);
    },

    setAutocalibrationTagsLayerDisplayed({ commit, state }, isDisplayed) {
        commit("SET_AUTOCALIBRATION_TAGS_LAYER_IS_DISPLAYED", isDisplayed);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isAutocalibrationTagsLayerDisplayed", isDisplayed);
        }
    },

    setAssetsLayerDisplayed({ commit, state }, isDisplayed) {
        commit("SET_ASSETS_LAYER_IS_DISPLAYED", isDisplayed);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isAssetsLayerDisplayed", isDisplayed);
        }
    },

    setIndoorAreasLayerDisplayed({ commit, state }, isDisplayed) {
        commit("SET_INDOOR_AREAS_LAYER_IS_DISPLAYED", isDisplayed);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isIndoorAreasLayerDisplayed", isDisplayed);
        }
    },

    setGeofencesLayerDisplayed({ commit, state }, isDisplayed) {
        commit("SET_GEOFENCES_LAYER_IS_DISPLAYED", isDisplayed);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isGeofencesLayerDisplayed", isDisplayed);
        }
    },

    setWalkwaysLayerDisplayed({ commit, state }, isDisplayed) {
        commit("SET_WALKWAYS_LAYER_IS_DISPLAYED", isDisplayed);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isWalkwaysLayerDisplayed", isDisplayed);
        }
    },

    setWallsLayerDisplayed({ commit, state }, isDisplayed) {
        commit("SET_WALLS_LAYER_IS_DISPLAYED", isDisplayed);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isWallsLayerDisplayed", isDisplayed);
        }
    },

    getDashboardPreferencesFromLocalStorage({ commit }, siteId) {
        if (typeof localStorage != "undefined") {
            // Store siteId for later use (on setters)
            commit("SET_SELECTED_SITE_ID", siteId);

            let isAssetsLabelsDisplayedLocalStorage = localStorage.getItem(siteId + "_assetsTrackingDashboard_isAssetsLabelsDisplayed");
            if (isAssetsLabelsDisplayedLocalStorage != null) {
                let isDisplayed = isAssetsLabelsDisplayedLocalStorage == "true" ? true : false;
                commit("SET_ASSETS_LABELS_ARE_DISPLAYED", isDisplayed);
            }

            let assetsLabelsDisplayed = localStorage.getItem(siteId + "_assetsTrackingDashboard_assetsLabelsDisplayed");
            if (assetsLabelsDisplayed != null) {
                let parsed = JSON.parse(assetsLabelsDisplayed);
                commit("SET_ASSETS_LABELS_DISPLAYED_VALUES", parsed);
            }

            let mapRefreshingEnabled = localStorage.getItem(siteId + "_assetsTrackingDashboard_isMapRefreshingEnabled");
            if (mapRefreshingEnabled != null) {
                let valueToStore = mapRefreshingEnabled === "true" ? true : false;
                commit("SET_MAP_REFRESHING_IS_ENABLED", valueToStore);
            }

            let mapRefreshingInterval = localStorage.getItem(siteId + "_assetsTrackingDashboard_mapRefreshingInterval");
            if (mapRefreshingInterval != null) {
                commit("SET_MAP_REFRESHING_INTERVAL", mapRefreshingInterval);
            }

            let mapTileLayerName = localStorage.getItem(siteId + "_assetsTrackingDashboard_mapTileLayerName");
            if (mapTileLayerName != null && mapTileLayerName != "") {
                commit("SET_CURRENT_MAP_TILE_LAYER_NAME", mapTileLayerName);
            }

            let opacityValue = localStorage.getItem(siteId + "_assetsTrackingDashboard_brightness");
            if (opacityValue != null && opacityValue > 0) {
                commit("SET_CURRENT_MAP_OPACITY_LAYER", opacityValue);
            }

            let isAssetsLayerDisplayedLocalStorage = localStorage.getItem(siteId + "_assetsTrackingDashboard_isAssetsLayerDisplayed");
            if (isAssetsLayerDisplayedLocalStorage != null) {
                let isDisplayed = isAssetsLayerDisplayedLocalStorage == "true" ? true : false;
                commit("SET_ASSETS_LAYER_IS_DISPLAYED", isDisplayed);
            }

            let isGatewaysLayerDisplayedLocalStorage = localStorage.getItem(siteId + "_assetsTrackingDashboard_isGatewaysLayerDisplayed");
            if (isGatewaysLayerDisplayedLocalStorage != null) {
                let isDisplayed = isGatewaysLayerDisplayedLocalStorage == "true" ? true : false;
                commit("SET_GATEWAYS_LAYER_IS_DISPLAYED", isDisplayed);
            }

            let isAutocalibrationTagsLayerDisplayedLocalStorage = localStorage.getItem(siteId + "_assetsTrackingDashboard_isAutocalibrationTagsLayerDisplayed");
            if (isAutocalibrationTagsLayerDisplayedLocalStorage != null) {
                let isDisplayed = isAutocalibrationTagsLayerDisplayedLocalStorage == "true" ? true : false;
                commit("SET_AUTOCALIBRATION_TAGS_LAYER_IS_DISPLAYED", isDisplayed);
            }

            let isIndoorAreasLayerDisplayedLocalStorage = localStorage.getItem(siteId + "_assetsTrackingDashboard_isIndoorAreasLayerDisplayed");
            if (isIndoorAreasLayerDisplayedLocalStorage != null) {
                let isDisplayed = isIndoorAreasLayerDisplayedLocalStorage == "true" ? true : false;
                commit("SET_INDOOR_AREAS_LAYER_IS_DISPLAYED", isDisplayed);
            }

            let isGeofencesLayerDisplayedLocalStorage = localStorage.getItem(siteId + "_assetsTrackingDashboard_isGeofencesLayerDisplayed");
            if (isGeofencesLayerDisplayedLocalStorage != null) {
                let isDisplayed = isGeofencesLayerDisplayedLocalStorage == "true" ? true : false;
                commit("SET_GEOFENCES_LAYER_IS_DISPLAYED", isDisplayed);
            }

            let isWalkwaysLayerDisplayedLocalStorage = localStorage.getItem(siteId + "_assetsTrackingDashboard_isWalkwaysLayerDisplayed");
            if (isWalkwaysLayerDisplayedLocalStorage != null) {
                let isDisplayed = isWalkwaysLayerDisplayedLocalStorage == "true" ? true : false;
                commit("SET_WALKWAYS_LAYER_IS_DISPLAYED", isDisplayed);
            }

            let isWallsLayerDisplayedLocalStorage = localStorage.getItem(siteId + "_assetsTrackingDashboard_isWallsLayerDisplayed");
            if (isWallsLayerDisplayedLocalStorage != null) {
                let isDisplayed = isWallsLayerDisplayedLocalStorage == "true" ? true : false;
                commit("SET_WALLS_LAYER_IS_DISPLAYED", isDisplayed);
            }

            let mapFilteringEnabled = localStorage.getItem(siteId + "_assetsTrackingDashboard_isMapFilteringEnabled");
            if (mapFilteringEnabled != null) {
                let valueToStore = mapFilteringEnabled === "true" ? true : false;
                commit("SET_MAP_FILTERING_IS_ENABLED", valueToStore);
            }

            let activeFilters = localStorage.getItem(siteId + "_assetsTrackingDashboard_activeFilters");
            if (activeFilters != null) {
                let parsed = JSON.parse(activeFilters);
                commit("ADD_ALL_ACTIVE_FILTERS", parsed);
            }
        }
    },

    addNewActiveFilter({ commit, state }, filterObj) {
        commit("ADD_ACTIVE_FILTER", filterObj);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_activeFilters", JSON.stringify(state.activeFilters));
        }
    },

    removeActiveFilter({ commit }, id) {
        commit("REMOVE_ACTIVE_FILTER", id);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.removeItem(state.selectedSiteId + "_assetsTrackingDashboard_activeFilters");
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_activeFilters", JSON.stringify(state.activeFilters));
        }
    },

    removeAllActiveFilters({ commit }) {
        commit("REMOVE_ALL_ACTIVE_FILTERS");

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.removeItem(state.selectedSiteId + "_assetsTrackingDashboard_activeFilters");
        }
    },

    clearAllActiveFilters({ commit }) {
        commit("REMOVE_ALL_ACTIVE_FILTERS");
    },

    setMapFilteringEnabled({ commit, state }, value) {
        commit("SET_MAP_FILTERING_IS_ENABLED", value);

        if (state.mode === "assetsTracking" && typeof localStorage != "undefined") {
            localStorage.setItem(state.selectedSiteId + "_assetsTrackingDashboard_isMapFilteringEnabled", value);
        }
    },

    setMapBackgroundPicture({ commit }, data) {
        commit("SET_BACKGROUND_PICTURE", data);
    },

    setMapMode({ commit }, mode) {
        commit("SET_MAP_MODE", mode);
    },

    resetGatewaysLayerDisplayed({ commit }) {
        commit("SET_GATEWAYS_LAYER_IS_DISPLAYED", false);
    },

    resetHeatmapsLayerDisplayed({ commit }) {
        commit("SET_HEATMAPS_LAYER_IS_DISPLAYED", false);
    },

    resetAutocalibrationTagsLayerDisplayed({ commit }) {
        commit("SET_AUTOCALIBRATION_TAGS_LAYER_IS_DISPLAYED", false);
    },

    resetAssetsLayerDisplayed({ commit }) {
        commit("SET_ASSETS_LAYER_IS_DISPLAYED", false);
    },

    resetIndoorAreasLayerDisplayed({ commit }) {
        commit("SET_INDOOR_AREAS_LAYER_IS_DISPLAYED", false);
    },

    resetGeofencesLayerDisplayed({ commit }) {
        commit("SET_GEOFENCES_LAYER_IS_DISPLAYED", false);
    },

    resetWalkwaysLayerDisplayed({ commit }) {
        commit("SET_WALKWAYS_LAYER_IS_DISPLAYED", false);
    },

    resetWallsLayerDisplayed({ commit }) {
        commit("SET_WALLS_LAYER_IS_DISPLAYED", false);
    },

    resetMapMode({ commit }) {
        commit("RESET_MAP_MODE");
    },

    addSearchedItem({ commit }, searchObj) {
        commit("ADD_SEARCHED_ITEM", searchObj);
    },

    resetSearchedItems({ commit }) {
        commit("RESET_SEARCHED_ITEM");
    },

    setArrayOfMacAddressToHighlight({ commit }, dataArr) {
        commit("SET_ARRAY_OF_MAC_ADDRESS_TO_HIGHLIGHT", dataArr);
    },

    resetArrayOfMacAddressToHighlight({ commit }) {
        commit("RESET_ARRAY_OF_MAC_ADDRESS_TO_HIGHLIGHT");
    },

    storeLastAddedObjectLocation({commit}, payload) {
        commit("SET_LAST_ADDED_OBJECT_LOCATION", payload);
    },

    setMouseMovePoint({commit}, latlng) {
        commit("SET_MOUSE_MOVE_POINT", {
            point: latlng
        });
    },

    resetDistanceBetweenLastAddedObjectAndCurrentPoint({ commit }) {
        commit("RESET_DISTANCE_BETWEEN_LAST_ADDED_OBJECT");
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
