import Vue from "vue";
import App from "./App.vue";
import { router } from "./route";
import store from "./store";
import i18n from "./i18n";
import Footer from "./components/footer.vue";
import Header from "./components/header.vue";
import Welcome from "./components/auth/welcome.vue";
import MobileMenu from "./components/menus/mobilemenu.vue";
import HomeMenu from "./components/menus/homemenu.vue";
import SiteConfigurationMenu from "./components/menus/siteconfigurationmenu.vue";
import Multiselect from "vue-multiselect";
import Vuelidate from "vuelidate";
import ConfirmLeavePageModal from "./components/modals/confirmleavepagemodal.vue";
import VueTour from 'vue-tour';
const moment = require("moment");
require("moment/locale/fr");
require("vue-tour/dist/vue-tour.css");

Vue.config.productionTip = false;

// These components will be available globally in the entire app
Vue.component("app-footer", Footer);
Vue.component("app-header", Header);
Vue.component("app-mobilemenu", MobileMenu);
Vue.component("app-homemenu", HomeMenu);
Vue.component("app-siteconfigurationmenu", SiteConfigurationMenu);
Vue.component("app-welcome", Welcome);
Vue.component("app-multiselect", Multiselect);
Vue.component("app-confirmleavepagemodal", ConfirmLeavePageModal);

// Declare Vue plugins
Vue.use(VueTour);
Vue.use(Vuelidate);
Vue.use(require("vue-moment"), {
    moment
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");
